import React, { Component } from 'react'
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import {Form} from "react-bootstrap"
import './EditOrganizationDialog.css'
import axios from "axios"
import {rootUrl} from "../../../config"
import Swal from "sweetalert2"
import {injectIntl} from 'react-intl'

class EditOrganizationDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      organizationId: this.props.organizationId,
      organizationName: this.props.organizationName,
      file: null,
      filePreview: null,
      fileBase64: null
    }

    this.fileUpload = React.createRef()
  }

  componentDidMount() {
    this.getOrganizationLogo()
  }

  saveOrganization() {
    const editOrganization = () => {
      axios.post(rootUrl + '/api/v1/administration/edit_organization', {
        organizationId: this.state.organizationId,
        name: this.state.organizationName,
        logo: this.state.fileBase64
      })
        .then((res) => {
          this.props.onClose()
        })
        .catch(e => {
          console.log(e)
          Swal.fire({
            title: this.props.intl.formatMessage({id: "dialogs.error_title"}),
            text: this.props.intl.formatMessage({id: "dialogs.error_message"}),
            type: 'error',
            confirmButtonText: 'OK'
          })
        })
    }

    if (this.state.file) {
      this.toBase64(this.state.file, editOrganization)
    } else {
      editOrganization()
    }
  }

  getOrganizationLogo() {
    axios.get(rootUrl + '/api/v1/administration/get_organization_logo/' + this.state.organizationId)
      .then((res) => {
      this.setState({
        fileBase64: res.data
      })
    })
  }

  toBase64(file, callback) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.setState({
        fileBase64: reader.result
      }, callback)
    }
  }

  handleFileChosen(file) {
    this.setState({
      file: file,
      filePreview: URL.createObjectURL(file)
    })
  }

  render() {
    return (
      <PopupDialog
        title={this.props.title}
        onClose={() => this.props.onClose()}
      >
        U kunt hier de naam en het logo van de organisatie aanpassen.

        <div className="row m-t-10">
          <div className="col-xs-12">
            <Form.Group>
              <Form.Label>Naam</Form.Label>
              <Form.Control
                value={this.state.organizationName}
                onChange={(e) => this.setState({organizationName: e.target.value})}
                required
                placeholder="Naam van de organisatie"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Logo</Form.Label><br />
              {this.state.filePreview && <><img alt="" src={this.state.filePreview} className={"preview-logo"} /><br /></>}
              {!this.state.filePreview && this.state.fileBase64 && <><img alt="" src={this.state.fileBase64} className={"preview-logo"} /><br /></>}
              <input
                type="file"
                className={"custom-file-upload"}
                name={"custom-file-upload"}
                id={"custom-file-upload"}
                accept={".png,.jpg,.jpeg,.gif,.bmp"}
                onChange={(e) => this.handleFileChosen(e.target.files[0])}
                ref={this.fileUpload}
              />
              <label htmlFor="custom-file-upload" className={"btn btn-primary"}>
                <i className={"fa fa-upload m-r-5"} /> {(this.state.file ? this.state.file.name : "Geen bestand gekozen")}
              </label>
              {this.state.file &&
              <span
                className={"fa fa-remove remove-file-button m-l-10"}
                onClick={() => {
                  this.setState({
                    file: null,
                    filePreview: null
                  }, () => {
                    this.fileUpload.current.value = ""
                  })
                }}
              />}
            </Form.Group>

            <hr className={"m-t-20 m-b-20"} />
          </div>
        </div>

        <button
          className="btn btn-primary"
          onClick={() => this.saveOrganization()}
        >
          <i className="fa fa-save"/> Opslaan
        </button>
        <button className="btn btn-secondary m-l-5" onClick={()=> this.props.onClose()}> Annuleren</button>
      </PopupDialog>
    )
  }
}

export default injectIntl(EditOrganizationDialog)
