import React, { Component } from 'react'
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import {Dropdown, Form} from "react-bootstrap"
import './CopySurveyDialog.css'
import axios from 'axios'
import {rootUrl} from "../../../config";
import Swal from "sweetalert2";
import LoadingSpinner from "../../Miscellaneous/LoadingSpinner/LoadingSpinner"
import {FormattedMessage, injectIntl} from "react-intl"
import InputDialog from "../InputDialog/InputDialog"

class CopySurveyDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openingText: "",
      loading: true,
      selectedIndex: -1,
      items: [],
      ids: [],
      newSurveyTitle: "",
      toCopyId: props.surveyId,
      showNewProjectDialog: false,
      loadingCopy: false
    }
  }

  componentDidMount() {
    this.getProjects()
  }

  handleCopy() {
    this.setState({loadingCopy: true})
    axios.post(rootUrl + '/api/v1/survey/duplicate', {id: this.state.toCopyId, projectId: this.state.ids[this.state.selectedIndex], name: this.state.newSurveyTitle, duplicateRespondents: this.props.duplicateRespondents})
      .then((res) => {
        this.setState({loadingCopy: false})
        this.props.onClose()
      })
      .catch((e) => {
        console.log(e)
        this.setState({loadingCopy: false})
      })
  }

  insertProject(projectName, callback) {
    axios.post(rootUrl + '/api/v1/new_project',
      {
        projectName: projectName
      }).then((res) => {
      callback()
    }).catch((error) => {
      console.log(error)
    })
  }

  getProjects() {
    axios.get(rootUrl + '/api/v1/dashboard/projects/'
    ).then((res) => {
      const projects = res.data["projects"]
      this.setState({
        items: projects.map(item => item["name"]),
        ids: projects.map(item => item["id"]),
        selectedIndex: (projects.length > 0 ? projects.length -1 : -1),
        loading: false,
        showNewProjectDialog: false
      })
    }).catch((error) => {
      Swal.fire({
        title: this.props.intl.formatMessage({id: "dialogs.error_title"}),
        text: this.props.intl.formatMessage({id: "dialogs.error_message"}),
        type: 'error',
        confirmButtonText: 'OK'
      }).then(() =>{
        this.props.onClose()
      })
    })
  }

  render() {
    return (
      <PopupDialog
        title={this.props.title}
        onClose={() => this.props.onClose()}
      >
        {this.state.loading &&
          <LoadingSpinner className={"modal-loading"} />
        }
        <FormattedMessage id={"copy_survey.info"} defaultMessage={"U heeft hier de mogelijkheid deze vragenlijst te kopiëren. Alle pagina's en vragen worden overgenomen."} />

        <Form.Group className={"m-t-20"}>
          <Form.Label><FormattedMessage id={"copy_survey.new_survey_name"} /></Form.Label>
          <Form.Control
            className={"form-control"}
            onChange={(e) => this.setState({newSurveyTitle: e.target.value})}
            value={this.state.newSurveyTitle}
            placeholder={this.props.intl.formatMessage({id: "copy_survey.new_survey_name_placeholder"})}
          />
        </Form.Group>

        <Form.Group className={"m-b-20"}>
          <Form.Label><FormattedMessage id={"copy_survey.copy_to_project"} /></Form.Label>
          <Dropdown className="dropdown-bar">
            <Dropdown.Toggle key={this.state.items.length} variant="light" id="dropdown-basic">
              {(this.state.items.length > 0 ? this.state.items[this.state.selectedIndex] : this.props.intl.formatMessage({id: "copy_survey.no_projects_available"}))}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {this.state.items.map((item, index) => (
                <Dropdown.Item
                  onClick={() => {
                    this.setState({
                      selectedIndex: index
                    })
                  }}
                  key={index + 1}
                  className={(this.state.selectedIndex === index ? "active" : "")}
                >
                  {index + 1}. {item}
                </Dropdown.Item>
              ))}
              {this.state.items.length > 0 && <Dropdown.Divider />}
              <Dropdown.Item onClick={() => this.setState({showNewProjectDialog: true})}><i className={"fa fa-plus"} /> Een onderzoek aanmaken...</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>

        <button
          className="btn btn-primary"
          onClick={() => this.handleCopy()}
          disabled={!(this.state.items.length >= 0 && this.state.selectedIndex > -1 && this.state.newSurveyTitle !== "") || this.state.loadingCopy}
        >
          {this.state.loadingCopy && <LoadingSpinner className={"modal-loading"} />}
          <i className="fa fa-copy"/> <FormattedMessage id={"copy_survey.copy_survey"} />
        </button>
        <button className="btn btn-secondary m-l-5" onClick={()=> this.props.onClose()}> <FormattedMessage id={"ui.cancel"} /></button>
        {this.state.showNewProjectDialog &&
        <InputDialog
          title={"Nieuwe project"}
          informationText={"Vul de naam voor het nieuwe project in en klik op OK om het aan te maken."}
          inputLabel={"Projectnaam"}
          onClose={() => this.setState({showNewProjectDialog: false})}
          onSubmit={(value) => {
            this.insertProject(value, () => {
              this.getProjects()
              })
            }
          }
        />
        }
      </PopupDialog>
    )
  }
}

export default injectIntl(CopySurveyDialog)
