import React, { Component } from 'react'
import DashboardFrame from '../../../components/Dashboard/DashboardFrame/DashboardFrame'
import ListTable from "../../../components/Miscellaneous/ListTable/ListTable"
import {withRouter} from 'react-router'
import './Administration.css'
import { rootUrl } from '../../../config'
import axios from 'axios'
import {Dropdown} from "semantic-ui-react"
import {Form, Tabs, Tab} from "react-bootstrap"
import InputDialog from "../../../components/Dashboard/InputDialog/InputDialog"
import UsersPanel from "./UsersPanel/UsersPanel"
import Swal from "sweetalert2";
import EditOrganizationDialog from "../../../components/Dashboard/EditOrganizationDialog/EditOrganizationDialog"
import EditClientDialog from "../../../components/Dashboard/EditClientDialog/EditClientDialog"
import NewClientDialog from "../../../components/Dashboard/NewClientDialog/NewClientDialog"
import AllUsersPanel from "./AllUsersPanel/AllUsersPanel"
import { FormattedMessage, injectIntl } from 'react-intl'
import ExportRespondentCountDialog
  from "../../../components/Dashboard/ExportRespondentCountDialog/ExportRespondentCountDialog"
import XLSX from "xlsx"

class Administration extends Component {
  constructor(props) {
    super(props)

    this.state = {
      clients: [],
      organizations: [],
      selectedClient: -1,
      showNewClientDialog: false,
      showNewOrganizationDialog: false,
      showEditOrganizationDialog: false,
      showEditClientDialog: false,
      showExportRespondentCountDialog: false
    }
  }

  componentDidMount() {
    this.fetchClients(() => {})
  }

  fetchClients(callback) {
    axios.get(rootUrl + '/api/v1/administration/get_clients')
      .then((res) => {
        this.setState({
          clients: res.data
        }, () => {
          if (callback) callback()
        })
      })
      .catch(e => console.log(e))
  }

  fetchOrganizations(callback) {
    if (this.state.selectedClient >= 0) {
      axios.get(rootUrl + '/api/v1/administration/get_organizations/' + this.state.clients[this.state.selectedClient].id)
        .then((res) => {
          this.setState({
            organizations: res.data
          }, () => {
            if (callback) {
              callback()
            }
          })
        })
        .catch(e => console.log(e))
    }
  }

  insertClient(name, settingsId) {
    axios.post(rootUrl + '/api/v1/administration/insert_client', {
      name: name,
      settingsId: settingsId
    })
      .then((res) => {
        this.fetchClients(() => {
          this.setState({
            showNewClientDialog: false
          })
        })
      })
      .catch(e => console.log(e))
  }

  insertOrganization(name, client_id) {
    axios.post(rootUrl + '/api/v1/administration/insert_organization', {
      name: name,
      clientId: client_id
    })
      .then((res) => {
        this.fetchOrganizations(() => {
          this.setState({
            showNewOrganizationDialog: false
          })
        })
      })
      .catch(e => console.log(e))
  }

  editOrganization(name, organizationId) {
    axios.post(rootUrl + '/api/v1/administration/edit_organization', {
      name: name,
      organizationId: organizationId
    })
      .then((res) => {
        this.fetchOrganizations(() => {
          this.setState({
            showEditOrganizationDialog: false
          })
        })
      })
      .catch(e => {
        console.log(e)
        Swal.fire({
          title: this.props.intl.formatMessage({id: "dialogs.error_title"}),
          text: this.props.intl.formatMessage({id: "dialogs.error_message"}),
          type: 'error',
          confirmButtonText: 'OK'
        })
      })
  }

  editClient(name, clientId, settingsId, benchmarkEnabled, logoBase64, template) {
    axios.post(rootUrl + '/api/v1/administration/edit_client', {
      name: name,
      clientId: clientId,
      settingsId: settingsId,
      benchmarkEnabled: benchmarkEnabled,
      logo: logoBase64,
      template: template
    })
      .then((res) => {
        this.fetchClients(() => {
          this.setState({
            showEditClientDialog: false
          })
        })
      })
      .catch(e => {
        console.log(e)
        Swal.fire({
          title: this.props.intl.formatMessage({id: "dialogs.error_title"}),
          text: this.props.intl.formatMessage({id: "dialogs.error_message"}),
          type: 'error',
          confirmButtonText: 'OK'
        })
      })
  }

  downloadRespondentCount(clientId, startDate, endDate){
    axios.post(rootUrl + '/api/v1/administration/download_respondent_count', {
      clientId,
      startDate,
      endDate
    })
      .then((res) => {
        console.log(res)

        var worksheet = XLSX.utils.aoa_to_sheet(res.data);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, "Export");

        /* write workbook (use type 'binary') */
        var wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'});

        /* generate a download */
        function s2ab(s) {
          var buf = new ArrayBuffer(s.length);
          var view = new Uint8Array(buf);
          for (var i=0; i!==s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        }

        let blob = new Blob([s2ab(wbout)],{type:"application/octet-stream"})

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'export.xlsx'
        link.click();

        this.setState({
          showExportRespondentCountDialog: false
        })
      })
      .catch(e => console.log(e))
  }

  removeOrganization(name, id) {
    Swal.fire({
      title: this.props.intl.formatMessage({id: "dialogs.remove_organization"}),
      text: this.props.intl.formatMessage({id: "dialogs.remove_organization_are_you_sure1"}) + name + this.props.intl.formatMessage({id: "dialogs.remove_organization_are_you_sure2"}),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: this.props.intl.formatMessage({id: "ui.remove"}),
      cancelButtonText: this.props.intl.formatMessage({id: "ui.cancel"}),
    }).then((result) => {
      if (result.value) {
        axios.post(rootUrl + '/api/v1/administration/remove_organization', {id: id})
          .then(() => {
            this.fetchOrganizations()
          })
          .catch(e => {
            console.log(e)
            Swal.fire({
              title: this.props.intl.formatMessage({id: "dialogs.error_title"}),
              text: this.props.intl.formatMessage({id: "dialogs.error_message"}),
              type: 'error',
              confirmButtonText: 'OK'
            })
          })
      }
    })
  }

  removeClient(name, id) {
    Swal.fire({
      title: this.props.intl.formatMessage({id: "dialogs.remove_client"}),
      text: this.props.intl.formatMessage({id: "dialogs.remove_client_are_you_sure1"}) + name + this.props.intl.formatMessage({id: "dialogs.remove_client_are_you_sure2"}),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: this.props.intl.formatMessage({id: "ui.remove"}),
      cancelButtonText: this.props.intl.formatMessage({id: "ui.cancel"}),
    }).then((result) => {
      if (result.value) {
        axios.post(rootUrl + '/api/v1/administration/remove_client', {id: id})
          .then(() => {
            this.fetchClients()
          })
          .catch(e => {
            console.log(e)
            Swal.fire({
              title: this.props.intl.formatMessage({id: "dialogs.error_title"}),
              text: this.props.intl.formatMessage({id: "dialogs.error_message"}),
              type: 'error',
              confirmButtonText: 'OK'
            })
          })
      }
    })
  }

  render() {
    const role = localStorage.getItem('role')

    return (
      <DashboardFrame
        title={this.props.intl.formatMessage({id: "users.users"})}
        dashboardTitle={this.props.intl.formatMessage({id: "users.users"})}
        onLanguageChanged={(language) => this.props.onLanguageChanged(language)}>
        <div className={"admin-tab-container"}>
          <Tabs defaultActiveKey="users">
            {parseInt(role) === 1 &&
              <Tab eventKey="clients" title={this.props.intl.formatMessage({id: "users.clients"})}>
                {/* Klanten */}
                <div className="slide-up pd-20">
                  <div className="col-12">
                    <h4 className="header-title m-t-0"><FormattedMessage id={"users.clients"} defaultMessage={"Klanten"} /></h4>
                    <p className="text-muted font-13 m-b-30"><FormattedMessage id={"users.client_info"} defaultMessage={"Dit zijn alle klanten in het platform."} /></p>

                    <ListTable headers={["#", this.props.intl.formatMessage({id: "users.client_name"}), this.props.intl.formatMessage({id: "users.organizations"})]}
                               rows={this.state.clients.map((list, index) => {
                                 return [index + 1,
                                   list.name,
                                   list.organization_count]
                               })}
                               // removeButton
                               editButton
                               exportRespondentCountButton
                               onEditButtonClicked={(row) => {
                                 this.setState({
                                   editClient: this.state.clients[row[0] - 1],
                                   showEditClientDialog: true
                                 })
                               }}
                               onRemoveButtonClicked={(row) => {
                                 const client = this.state.clients[row[0] - 1]
                                 this.removeClient(client.name, client.id)
                               }}
                               onExportRespondentCountButtonClicked={(row) => {
                                 this.setState({
                                   editClient: this.state.clients[row[0] - 1],
                                   showExportRespondentCountDialog: true
                                 })
                               }
                               }
                    />

                    <button
                      className="btn btn-primary"
                      onClick={() => this.setState({showNewClientDialog: true})}>
                      <i className="fa fa-plus"/> <FormattedMessage id={"users.create_new_client"} defaultMessage={"Nieuwe klant aanmaken"} />
                    </button>
                  </div>
                </div>
              </Tab>
            }

            {parseInt(role) === 1 &&
              <Tab eventKey="organizations" title={this.props.intl.formatMessage({id: "users.organizations"})}>
                {/* Organisaties */}
                <div className="slide-up pd-20">
                  <div className="col-12">
                    <h4 className="header-title m-t-0"><FormattedMessage id={"users.organizations"} defaultMessage={"Organisaties"} /></h4>
                    <p className="text-muted font-13 m-b-30"><FormattedMessage id={"users.organization_info"} defaultMessage={"Dit zijn alle organisaties van de geselecteerde klant."} /></p>

                    <Form.Group>
                      <Form.Label><FormattedMessage id={"users.client_name"} defaultMessage={"Klantnaam"} /></Form.Label><br/>
                      <Dropdown
                        placeholder={this.props.intl.formatMessage({id: "users.select_client_placeholder"})}
                        selection
                        options={this.state.clients.map((option, index) => {
                          return {key: index, text: option.name, value: index}
                        })}
                        value={this.selectedClient}
                        onChange={(e, {value}) => {
                          this.setState({selectedClient: value}, () => this.fetchOrganizations())
                        }}
                      />
                    </Form.Group>

                    <ListTable headers={["#", this.props.intl.formatMessage({id: "users.organization_name"}), this.props.intl.formatMessage({id: "users.users"})]}
                               rows={this.state.organizations.map((list, index) => {
                                 return [index + 1,
                                   list.name,
                                   list.users_count]
                               })}
                               // removeButton
                               editButton
                               onEditButtonClicked={(row) => {
                                 this.setState({
                                   editOrganization: this.state.organizations[row[0] - 1],
                                   showEditOrganizationDialog: true
                                 })
                               }}
                               onRemoveButtonClicked={(row) => {
                                 const organization = this.state.organizations[row[0] - 1]
                                 this.removeOrganization(organization.name, organization.id)
                               }}
                    />

                    <button
                      className="btn btn-primary"
                      disabled={this.state.selectedClient < 0}
                      onClick={() => this.setState({showNewOrganizationDialog: true})}
                    >
                      <i className="fa fa-plus"/> <FormattedMessage id={"users.create_new_organization"} defaultMessage={"Nieuwe organisatie aanmaken"} />
                    </button>
                  </div>
                </div>
              </Tab>
            }
            <Tab eventKey="users" title={this.props.intl.formatMessage({id: "users.users"})}>
              <UsersPanel
                key={
                  // Generates a string all clients concatenated with organizations, which causes a rerender
                  // of the UsersPanel updating the underlying clients and organizations.
                  (this.state.clients.concat(this.state.organizations).map((item) => {
                    return item.name
                  })).join("")
                }
                className={"slide-up pd-20"} />
            </Tab>
            {parseInt(role) === 1 &&
            <Tab eventKey="allUsers" title={this.props.intl.formatMessage({id: "users.all_users"})}>
              <AllUsersPanel
                key={
                  // Generates a string all clients concatenated with organizations, which causes a rerender
                  // of the UsersPanel updating the underlying clients and organizations.
                  (this.state.clients.concat(this.state.organizations).map((item) => {
                    return item.name
                  })).join("")
                }
                className={"slide-up pd-20"} />
            </Tab>

            }
          </Tabs>
        </div>

        {this.state.showNewClientDialog &&
          <NewClientDialog
            title={this.props.intl.formatMessage({id: "users.new_client"})}
            informationText={this.props.intl.formatMessage({id: "users.client_placeholder"})}
            inputLabel={this.props.intl.formatMessage({id: "users.client_name"})}
            onClose={() => this.setState({showNewClientDialog: false})}
            onSubmit={(value, settingsId) => {
              this.insertClient(value, settingsId, null)
            }}
          />
        }

        {this.state.showNewOrganizationDialog &&
          <InputDialog
            title={this.props.intl.formatMessage({id: "users.new_organization"})}
            informationText={this.props.intl.formatMessage({id: "users.organization_placeholder"}) + " '" + this.state.clients[this.state.selectedClient].name + "'."}
            inputLabel={this.props.intl.formatMessage({id: "users.organization_name"})}
            onClose={() => this.setState({showNewOrganizationDialog: false})}
            onSubmit={(value) => {
              this.insertOrganization(value, this.state.clients[this.state.selectedClient].id)
            }}
          />
        }

        {this.state.showEditClientDialog &&
          <EditClientDialog
            title={"Klant aanpassen"}
            informationText={this.props.intl.formatMessage({id: "users.client_placeholder"}) + " '" + this.state.editClient.name + "'."}
            inputLabel={this.props.intl.formatMessage({id: "users.organization_name"})}
            inputText={this.state.editClient.name}
            clientId={this.state.editClient.id}
            templateId={this.state.editClient.template}
            settingsValue={this.state.editClient.settings_id}
            // clientId={this.state.editClient.id}
            onClose={() => this.setState({showEditClientDialog: false})}
            onSubmit={(value, settingsId, benchmarkEnabled, logoBase64, template) => {
              console.log(template)
              this.editClient(value, this.state.editClient.id, settingsId, benchmarkEnabled, logoBase64, template)
            }}
          />
        }

        {this.state.showEditOrganizationDialog &&
          <EditOrganizationDialog
            title={this.props.intl.formatMessage({id: "users.edit_organization"})}
            organizationId={this.state.editOrganization.id}
            organizationName={this.state.editOrganization.name}
            onClose={() => this.setState({showEditOrganizationDialog: false}, () => this.fetchOrganizations())}
          />
        }

        {this.state.showExportRespondentCountDialog &&
          <ExportRespondentCountDialog
            title={"Respondentaantallen exporteren"}
            clientId={this.state.editClient.id}
            onClose={() => this.setState({showExportRespondentCountDialog: false})}
            onSubmit={(startDate, endDate) => {
              this.downloadRespondentCount(this.state.editClient.id, startDate, endDate)
            }}
          />
        }
      </DashboardFrame>
    )
  }
}

export default withRouter(injectIntl(Administration))
