import React, { Component } from 'react'
import ListTable from "../../../../components/Miscellaneous/ListTable/ListTable"
import { rootUrl } from '../../../../config'
import axios from 'axios'
import {Dropdown} from "semantic-ui-react"
import {Form} from "react-bootstrap"
import AddUserDialog from "../AddUserDialog/AddUserDialog"
import EditUserDialog from "../EditUserDialog/EditUserDialog";
import Swal from "sweetalert2";
import ChangeAccessDialog from "../ChangeAccessDialog/ChangeAccessDialog"
import { FormattedMessage, injectIntl } from 'react-intl'

class UsersPanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      clients: [],
      organizations: [],
      users: [],
      selectedClient: -1,
      selectedOrganization: -1,
      showNewUserDialog: false,
      showEditUserDialog: false
    }

    if (parseInt(localStorage.getItem('role')) === 2) {
      this.state.selectedOrganization = parseInt(localStorage.getItem('organization_id'))
      this.fetchUsers()
    }
  }

  componentDidMount() {
    if (parseInt(localStorage.getItem('role')) === 1) {
      this.fetchClients()
    }
  }

  fetchClients(callback) {
    axios.get(rootUrl + '/api/v1/administration/get_clients')
      .then((res) => {
        this.setState({
          clients: res.data
        }, () => {
          if (callback) {
            callback()
          }
        })
      })
      .catch(e => console.log(e))
  }

  fetchOrganizations(callback) {
    if (this.state.selectedClient >= 0) {
      axios.get(rootUrl + '/api/v1/administration/get_organizations/' + this.state.clients[this.state.selectedClient].id)
        .then((res) => {
          this.setState({
            organizations: res.data
          }, () => {
            if (callback) {
              callback()
            }
          })
        })
        .catch(e => console.log(e))
    }
  }

  fetchUsers(callback) {
    if (this.state.selectedOrganization >= 0) {
      axios.get(rootUrl + '/api/v1/administration/get_users/' + this.state.selectedOrganization)
        .then((res) => {
          this.setState({
            users: res.data
          }, () => {
            if (callback) {
              callback()
            }
          })
        })
        .catch(e => console.log(e))
    }
  }

  handleEditButtonClick(row) {
    const index = row[0] - 1
    let user = this.state.users[index]
    if (user.email === null) user.email = ""
    user.organizationId = this.state.selectedOrganization

    this.setState({
      editUser: user,
      showEditUserDialog: true
    })
  }

  handleRemoveButtonClick(row) {
    const index = row[0] - 1
    let user = this.state.users[index]

    Swal.fire({
      title: this.props.intl.formatMessage({id: "dialogs.are_you_sure"}),
      text: this.props.intl.formatMessage({id: "dialogs.removal_cannot_be_reversed"}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.props.intl.formatMessage({id: "ui.remove"}),
      cancelButtonText: this.props.intl.formatMessage({id: "ui.cancel"})
    }).then((result) => {
      if (result.value) {
        axios.post(rootUrl + '/api/v1/administration/remove_user', {
          id: user.id,
          organizationId: this.state.selectedOrganization
        })
          .then((res) => {
            this.fetchUsers()

            Swal.fire({
              title: this.props.intl.formatMessage({id: "dialogs.success"}),
              text: this.props.intl.formatMessage({id: "dialogs.removal_successful"}),
              type: 'success',
              confirmButtonText: 'OK'
            })
          })
          .catch(e => console.log(e))
      }
    })
  }

  handleChangeAccessButtonClick(row) {

    const index = row[0] - 1
    let user = this.state.users[index]
    this.setState({
      editUser: user,
      showChangeAccessDialog: true
    })
  }

  render() {
    const role = parseInt(localStorage.getItem('role'))
    const organizationName = localStorage.getItem('organization_name')
    const roles = [
      {
        role: this.props.intl.formatMessage({id: "role.superadmin"}),
        value: 1
      },{
        role: this.props.intl.formatMessage({id: "role.admin"}),
        value: 2
      },{
        role: this.props.intl.formatMessage({id: "role.researcher"}),
        value: 3
      },{
        role: this.props.intl.formatMessage({id: "role.observer"}),
        value: 4
      }]

    return (
      <>
        <div className={this.props.className ? this.props.className : ""}>
          <div className="col-12">
            {role === 1 &&
            <>
              <h4 className="header-title m-t-0"><FormattedMessage id={"users.users"} defaultMessage={"Gebruikers"} /></h4>
              <p className="text-muted font-13 m-b-30"><FormattedMessage id={"users.users_info"} defaultMessage={"Dit zijn alle gebruikers van de geselecteerde organisatie."} /></p>

              <Form.Group>
                <Form.Label><FormattedMessage id={"users.client"} defaultMessage={"Klant"} /></Form.Label><br/>
                <Dropdown
                  placeholder={this.props.intl.formatMessage({id: "users.select_client_placeholder"})}
                  selection
                  options={this.state.clients.map((option, index) => {
                    return {key: index, text: option.name, value: index}
                  })}
                  onChange={(e, {value}) => {
                    this.setState({
                      selectedClient: value,
                      selectedOrganization: -1,
                      organizations: [],
                      users: []
                    }, () => this.fetchOrganizations())
                  }}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label><FormattedMessage id={"users.organization"} defaultMessage={"Organisatie"} /></Form.Label><br/>
                <Dropdown
                  placeholder={this.props.intl.formatMessage({id: "users.select_organization_placeholder"})}
                  selection
                  options={this.state.organizations.map((option, index) => {
                    return {key: index, text: option.name, value: option.id}
                  })}
                  onChange={(e, {value}) => {
                    this.setState({selectedOrganization: value}, () => this.fetchUsers())
                  }}
                />
              </Form.Group>
            </>
            }

            {role === 2 &&
              <>
              <Form.Group className={"m-b-20"}>
                <Form.Label><FormattedMessage id={"users.organization"} defaultMessage={"Organisatie"} /></Form.Label><br/>
                {organizationName}
              </Form.Group>
              </>
            }

            <button
              className="btn btn-primary"
              disabled={
                role === 1 && (this.state.selectedClient < 0 || this.state.selectedOrganization < 0)
              }
              onClick={() => this.setState({showNewUserDialog: true})}
            >
              <i className="fa fa-plus"/> <FormattedMessage id={"users.create_new_user"} defaultMessage={"Nieuwe gebruiker aanmaken"} />
            </button><br /><br />

            <ListTable headers={["#", this.props.intl.formatMessage({id: "users.username"}), this.props.intl.formatMessage({id: "users.email"}), this.props.intl.formatMessage({id: "users.role"})]}
                       rows={this.state.users.map((list, index) => {
                         return [index + 1,
                           list.username,
                           (list.email ? list.email : "-"),
                           roles[list.role - 1].role]
                       })}
                       onEditButtonClicked={(row) => {
                         this.handleEditButtonClick(row)
                       }}
                       onRemoveButtonClicked={(row) => {
                         this.handleRemoveButtonClick(row)
                       }}
                       onChangeAccessButtonClicked={(row) => {
                         this.handleChangeAccessButtonClick(row)
                       }}
                       editButton
                       // removeButton
                       changeAccessButton={role===1}
            />
          </div>
        </div>

        {
          this.state.showNewUserDialog &&
          <AddUserDialog
            title={this.props.intl.formatMessage({id: "users.new_user"})}
            organizationId={this.state.selectedOrganization}
            onClose={() => this.setState({showNewUserDialog: false}, () => this.fetchUsers())} />
        }

        {
          this.state.showEditUserDialog &&
          <EditUserDialog
            title={this.props.intl.formatMessage({id: "users.edit_user"})}
            editData={this.state.editUser}
            onClose={() => this.setState({showEditUserDialog: false}, () => this.fetchUsers())} />
        }

        {
          this.state.showChangeAccessDialog &&
          <ChangeAccessDialog
            editData={this.state.editUser}
            onClose={() => this.setState({showChangeAccessDialog: false}, () => this.fetchUsers())} />
        }
      </>
    )
  }
}

export default injectIntl(UsersPanel)
