import React, { Component } from 'react'
import './SurveyDropdown.css'
import {Dropdown} from "semantic-ui-react"
import axios from "axios"
import {rootUrl} from "../../../config"
import { injectIntl } from 'react-intl'

class SurveyDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      surveyItems: [],
      surveyIds: [],
      selectedSurveyIndex: -1
    }
  }

  componentDidMount() {
    this.getSurveys()
  }

  getSurveys(callback) {
    this.setState({selectedIndex: -1})
    if(this.props.active) {
      axios.post(rootUrl + '/api/v1/dashboard/projectssummary')
        .then((res) => {
          const surveys = res.data["surveys"]
          this.setState({
              surveyItems: surveys.map(item => {return {survey_name: item["survey_name"], project_name: item['project_name']}}),
              surveyIds: surveys.map(item => item["survey_id"])
            },
            () => {
              typeof callback === 'function' && callback()
            })
        }).catch((error) => {
        console.log(error)
      })
    } else {
      axios.post(rootUrl + '/api/v1/dashboard/projectssummary')
        .then((res) => {
          const surveys = res.data["surveys"]
          if(surveys && surveys.length === 1) {
            this.setState({
              selectedSurveyIndex: 0
            }, () => {
              this.props.onSurveyChanged(surveys[0].survey_id)
            })
          }
          this.setState({
              surveyItems: surveys.map(item => {return {survey_name: item["survey_name"], project_name: item['project_name']}}),
              surveyIds: surveys.map(item => item["survey_id"])
            },
            () => {
              typeof callback === 'function' && callback()
            })
        }).catch((error) => {
        console.log(error)
      })
    }

  }

  changeSelectedSurvey(value) {
    const selectedSurveyIndex = this.state.surveyIds.indexOf(value)

    this.setState({
      selectedSurveyIndex: selectedSurveyIndex
    }, () => {
      this.props.onChange(value)
    })
  }

  render() {
    return (
      <Dropdown
        placeholder={this.props.intl.formatMessage({id: "dashboard.select_survey_placeholder"})}
        selection
        onChange={(e, {value}) => {
          this.changeSelectedSurvey(value)
        }}
        className={this.props.className}
        options={this.state.surveyItems.map((item, index) => {
          return {key: index, text: item["survey_name"] + ' (' + item["project_name"] + ')', value: this.state.surveyIds[index]}
        })}
      />
    )
  }
}

export default injectIntl(SurveyDropdown)
