import React, { Component } from 'react'
import DashboardFrame from '../../../components/Dashboard/DashboardFrame/DashboardFrame'
import ListTable from "../../../components/Miscellaneous/ListTable/ListTable"
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import './Broadcasts.css'
import { rootUrl } from '../../../config'
import axios from 'axios'
import RecipientsDialog from "../../../components/Dashboard/RecipientsDialog/RecipientsDialog"
import Swal from "sweetalert2"
import {FormattedMessage, injectIntl} from 'react-intl'

class Broadcasts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeList: [true, false, false],
      lists: [],
      showRecipientsDialog: false
    }

    this.fetchMailingLists()
  }

  handleSwitchChange(index) {
    let list = this.state.activeList
    list[index] = !list[index]

    this.setState({
      activeList: list
    })
  }

  sendNow(row) {
    axios.post(rootUrl+ '/api/v1/send-mailing-now', {mailingListId: this.state.lists[row[0]-1].mailing_list_id})
      .then(() => {
        Swal.fire({
          title: this.props.intl.formatMessage({id: "dialogs.broadcasts_sent"}),
          type: 'success'
        }).then(() => {
          this.fetchMailingLists()
        })
      })
      .catch((e) => {
        if (e.response.status === 429) {
          Swal.fire({
            title: this.props.intl.formatMessage({id: "dialogs.sent_too_quickly"}),
            type: 'warning'
          })
        } else {
          console.log(e)
          Swal.fire({
            title: this.props.intl.formatMessage({id: "dialogs.error_title"}),
            text: this.props.intl.formatMessage({id: "dialogs.error_message"}),
            type: 'error'
          })
        }
      })
  }

  fetchMailingLists() {
    axios.get(rootUrl + '/api/v1/broadcast/get_all')
      .then((res) => {
        this.setState({
          lists: res.data
        })
      })
      .catch(e => console.log(e))
  }

  removeMailingList(row) {
    let lists = this.state.lists
    let toRemove = lists.splice(row[0]-1, 1)

    Swal.fire({
      title: this.props.intl.formatMessage({id: "dialogs.are_you_sure"}),
      text: this.props.intl.formatMessage({id: "dialogs.remove_broadcast_are_you_sure1"}) + toRemove[0].internal_name + this.props.intl.formatMessage({id: "dialogs.remove_broadcast_are_you_sure2"}),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: this.props.intl.formatMessage({id: "ui.remove"}),
      cancelButtonText: this.props.intl.formatMessage({id: "ui.cancel"}),
    }).then((result) => {
      if (result.value) {
        axios.post(rootUrl + '/api/v1/broadcast/remove_broadcast', {id: toRemove[0].mailing_list_id})
          .then(() => {
            this.setState({
              lists: lists
            })
          })
          .catch(e => console.log(e))
      }
    })
  }

  showRecipientsDialog(row) {
    let entry = this.state.lists[row[0]-1]
    let id = entry.mailing_list_id
    let title = entry.internal_name

    this.setState({
      showRecipientsDialog: true,
      recipientsDialogSurveyId: id,
      recipientsDialogTitle: title
    })
  }

  render() {
    return (
      <DashboardFrame
        title={this.props.intl.formatMessage({id: "broadcasts.broadcasts"})}
        dashboardTitle={this.props.intl.formatMessage({id: "broadcasts.broadcasts"})}
        onLanguageChanged={(language) => this.props.onLanguageChanged(language)}>
        <div className="card-box slide-up">
          <div className="col-12">
            <h4 className="header-title m-t-0"><FormattedMessage id={"broadcasts.broadcasts"} defaultMessage={"Verzendlijsten"} /></h4>
            <p className="text-muted font-13 m-b-30"><FormattedMessage id={"broadcasts.info"} defaultMessage={"Hier vindt u de verzendlijsten per vragenlijst. Met deze verzendlijst kunt u ontvangers uitnodigen de vragenlijst in te vullen. U kunt zelf de inhoud en de afzender van de uitnodiging bepalen."} /></p>

            <ListTable headers={["#", this.props.intl.formatMessage({id:"broadcasts.subject"}), this.props.intl.formatMessage({id:"broadcasts.survey"}), this.props.intl.formatMessage({id:"broadcasts.recipients"}), this.props.intl.formatMessage({id:"broadcasts.last_broadcast"})]}
                       rows={this.state.lists.map((list, index) => {
                         return [index + 1,
                                list.subject,
                                list.internal_name,
                                list.count,
                                new Date(list.last_broadcast).getFullYear() < 2000 ?
                                  this.props.intl.formatMessage({id: "broadcasts.no_last_broadcast"}) :
                                  new Date(list.last_broadcast).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) + " " + new Date(list.last_broadcast).toLocaleDateString()]
                       })}
                       removeButton
                       recipientsButton
                       sendNowButton
                       onSendNowButtonClicked={(row) => this.sendNow(row)}
                       onRemoveButtonClicked={(row) => this.removeMailingList(row)}
                       onRecipientsButtonClicked={(row) => this.showRecipientsDialog(row)}
            />

            {this.state.showRecipientsDialog &&
              <RecipientsDialog
                title={this.props.intl.formatMessage({id: "recipients.title"})}
                onClose={() => {
                  this.fetchMailingLists()
                  this.setState({showRecipientsDialog: false})
                }}
                surveyTitle={this.state.recipientsDialogTitle}
                mailingListId={this.state.recipientsDialogSurveyId}
              />}

            <Link to={this.props.intl.formatMessage({id: "link.new_broadcast"})} className="btn btn-primary"><i className="fa fa-plus"/> <FormattedMessage id={"broadcasts.new_broadcast"} defaultMessage={"Nieuwe verzendlijst aanmaken"} /></Link>
          </div>
        </div>
      </DashboardFrame>
    )
  }
}

export default withRouter(injectIntl(Broadcasts))
