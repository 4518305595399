const emailTemplate = `<html lang="en">
    <head>
      <title>{{title}}</title>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <style type="text/css">
          body, table, td, a { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
          table, td { mso-table-lspace: 0pt; mso-table-rspace: 0pt; }
          img { -ms-interpolation-mode: bicubic; }
  
          img { border: 0; height: auto; line-height: 100%; outline: none; text-decoration: none; }
          table { border-collapse: collapse !important; }
          body { height: 100% !important; margin: 0 !important; padding: 0 !important; width: 100% !important; }
      </style>
    </head>
    <body style="background-color: {{backgroundcolor}}; margin: 0 !important; padding: 60px 0 60px 0 !important;">
      <table border="0" cellspacing="0" cellpadding="0" role="presentation" width="100%">
        <tr>
            <td style="background-color: {{backgroundcolor}}; font-size: 0;"></td>
            <td width="600" style="background-color: white; border-radius: 4px; color: grey; font-family: sans-serif; font-size: 18px; line-height: 28px; padding: 40px 40px;">
              <article>
                <img alt="Logo" src="{{logo}}" height="60" width="60" style="color: white; display: block; font-family: sans-serif; font-size: 18px; font-weight: bold; height: auto; max-width: 100%; text-align: center; margin-left: auto; margin-right: auto;"><br />
                <h1 style="color: black; font-size: 38px; font-weight: bold; line-height: 48px; margin: 0 0 30px 0; text-align: center;">{{title}}</h1>
                <p style="margin: 40px 0 30px 0;">{{{message}}}</p>
                <p style="margin: 60px 0 0 0; text-align: center;">
                  <a href="#" target="_blank" style="font-size: 18px; font-family: sans-serif; color: white; text-decoration: none; border-radius: 8px; -webkit-border-radius: 8px; background-color: {{buttoncolor}}; border-top: 13px solid {{buttoncolor}}; border-bottom: 13px solid {{buttoncolor}}; display: inline-block; margin-bottom: 14px; width: 100%;">Yes, I want to participate in this study</a><br />
                  <a href="#" target="_blank" style="font-size: 18px; font-family: sans-serif; color: white; text-decoration: none; border-radius: 8px; -webkit-border-radius: 8px; background-color: {{buttoncolor}}; border-top: 13px solid {{buttoncolor}}; border-bottom: 13px solid {{buttoncolor}}; display: inline-block; margin-bottom: 14px; width: 100%;">yes, I want to participate in this study, because it has been longer than 1 year when I last participated in this study</a><br />
                  <a href="#" target="_blank" style="font-size: 18px; font-family: sans-serif; color: white; text-decoration: none; border-radius: 8px; -webkit-border-radius: 8px; background-color: {{buttoncolor}}; border-top: 13px solid {{buttoncolor}}; border-bottom: 13px solid {{buttoncolor}}; display: inline-block; margin-bottom: 14px; width: 100%;">No, I have already filled out this survey</a><br />
                  <a href="#" target="_blank" style="font-size: 18px; font-family: sans-serif; color: white; text-decoration: none; border-radius: 8px; -webkit-border-radius: 8px; background-color: {{buttoncolor}}; border-top: 13px solid {{buttoncolor}}; border-bottom: 13px solid {{buttoncolor}}; display: inline-block; margin-bottom: 14px; width: 100%;">No, I do not want to participate in this study</a><br />
  <!--                <a href="{{rememberMe}}" target="_blank" style="font-size: 18px; font-family: sans-serif; color: white; text-decoration: none; border-radius: 8px; -webkit-border-radius: 8px; background-color: {{buttoncolor}}; border-top: 13px solid {{buttoncolor}}; border-bottom: 13px solid {{buttoncolor}}; display: inline-block; width: 100%;">Herinner mij later</a>-->
                  <p align="center" style="font-size: 11px;">In case the buttons do not redirect you to the survey, copy the following link to your browser's address bar: {{goToSurvey}}</p>
                </p>
              </article>
            </td>
            <td style="background-color: {{backgroundcolor}}; font-size: 0;"></td>
        </tr>
      </table>
    </body>
  </html>`

export default emailTemplate