import React, { Component } from 'react'
import {FormattedMessage} from 'react-intl'
import './FilterList.css'


class FilterList extends Component {
  render() {
    return (
      <>
        <div
          style={(this.props.style ? this.props.style : "")}
          className={(this.props.className ? this.props.className : "") + " filter-list"}>
          {this.props.children}
          {this.props.children.props.children.length === 0 &&
            <div className={"filter-placeholder"}>
              <FormattedMessage id={"dashboard.no_filters_selected"} defaultMessage={"Geen filters geselecteerd."} />
            </div>}
        </div>
      </>
    )
  }
}

export default FilterList
