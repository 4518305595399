import React, { Component } from 'react'
import IonRangeSlider from 'react-ion-slider'
import {injectIntl} from 'react-intl'
import './DateRangeSlider.css'

class DateRangeSlider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      min: this.props.min,
      max: this.props.max,
      from: this.props.from,
      to: this.props.to
    }

    this.slider = React.createRef()
  }

  dateToTimestamp(date) {
    return date.valueOf()
  }

  timestampToDate(timestamp) {
    let d = new Date(timestamp)
    let lang = this.props.intl.formatMessage({id: "misc.slider_language"}) //e.g. "nl-NL"

    return d.toLocaleDateString(lang, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })
  }

  toServerReadableDate(timestamp) {
    let d = new Date(timestamp)
    return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
  }

  handleOnFinish(data) {
    let obj = {
      from: this.toServerReadableDate(data.from),
      to: this.toServerReadableDate(data.to)
    }

    this.props.onFinish(obj)
  }

  stringToDate(str) {
    var parts = str.split('-')
    var d = new Date(parts[0], parts[1] - 1, parts[2])
    return d
  }

  updateSettings(min, max, from, to) {
    this.setState({
      min: min,
      max: max,
      from: from,
      to: to
    }, () => {
      this.slider.current.update({
        min: this.dateToTimestamp(this.stringToDate(this.state.min)),
        max: this.dateToTimestamp(this.stringToDate(this.state.max)),
        from: this.dateToTimestamp(this.stringToDate(this.state.from)),
        to: this.dateToTimestamp(this.stringToDate(this.state.to))
      })
    })
  }

  render() {
    return (
      <>
        <IonRangeSlider
              skin={"flat"}
              type={"double"}
              grid={true}
              min={this.dateToTimestamp(this.stringToDate(this.state.min))}
              max={this.dateToTimestamp(this.stringToDate(this.state.max))}
              from={this.dateToTimestamp(this.stringToDate(this.state.from))}
              to={this.dateToTimestamp(this.stringToDate(this.state.to))}
              onFinish={(data) => this.handleOnFinish(data)}
              prettify={this.timestampToDate.bind(this)}
              ref={this.slider}
        />
      </>
    )
  }
}

export default injectIntl(DateRangeSlider, {forwardRef: true})