import React, {Component} from 'react'
import RadiobuttonRange from './RadiobuttonRange'
import MultipleChoice from './MultipleChoice'
import Routing from './Routing'
import QuestionTypes from '../../QuestionTypes'
import {injectIntl} from 'react-intl'

class QuestionFactory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      placeholder: this.props.intl.formatMessage({id: "survey.answer"})
    }
  }

  // Multiple choice options have been changed, so let the parent SelectComponent know to pass it up to surveyFrame.
  onOptionsChangedHandler(options, openOption) {
    this.props.onOptionsChanged(options, openOption)
  }

  // onOpenOptionsChangedHandler(openOption) {
  //   this.props.onOptionsChanged(openOption)
  // }

  render() {
    return (
      <div>
        {/* The correct component is shown based on this.props.type */}

        {this.props.type === QuestionTypes.OpenShort &&
          <input
            className={"survey-form-control " + this.props.className}
            placeholder={this.state.placeholder}
            />}

        {this.props.type === QuestionTypes.OpenLong &&
          <textarea
            className={"survey-form-control open-textarea " + this.props.className}
            placeholder={this.state.placeholder}
          />}

        {this.props.type === QuestionTypes.MultipleChoiceSingle &&
          <MultipleChoice
            focused={this.props.focused}
            type={QuestionTypes.MultipleChoiceSingle}
            onOptionsChanged={(options, openOption) => this.onOptionsChangedHandler(options, openOption)}
            options={this.props.options}
            openOption={this.props.openOption}
            selectedTranslation={this.props.selectedTranslation}
            hasWeight={this.props.hasWeight}
            weight={this.props.weight} />
        }

        {this.props.type === QuestionTypes.MultipleChoiceMultiple &&
          <MultipleChoice
            focused={this.props.focused}
            type={QuestionTypes.MultipleChoiceMultiple}
            onOptionsChanged={(options, openOption) => this.onOptionsChangedHandler(options, openOption)}
            options={this.props.options}
            openOption={this.props.openOption}
            selectedTranslation={this.props.selectedTranslation}
            hasWeight={this.props.hasWeight}
            weight={this.props.weight} />
        }

        {this.props.type === QuestionTypes.Dropdown &&
          <MultipleChoice
            focused={this.props.focused}
            type={QuestionTypes.Dropdown}
            onOptionsChanged={(options, openOption) => this.onOptionsChangedHandler(options, openOption)}
            options={this.props.options}
            openOption={this.props.openOption}
            selectedTranslation={this.props.selectedTranslation}
            hasWeight={this.props.hasWeight}
            weight={this.props.weight} />
        }

        {this.props.type === QuestionTypes.Email &&
          <input
            className={"survey-form-control " + this.props.className}
            placeholder={"voorbeeld@domein.com"}
            type={"email"}
          />}

        {this.props.type === QuestionTypes.Date &&
          <input
            className={"survey-form-control " + this.props.className}
            placeholder={this.state.placeholder}
            type={"date"}
          />}

        {this.props.type === QuestionTypes.Number &&
          <input type={"number"}
                 className={"survey-form-control " + this.props.className}
                 min={0}
          />
        }

        {this.props.type === QuestionTypes.Grade &&
          <RadiobuttonRange min={1} max={10} className={this.props.className} />
        }

        {this.props.type === QuestionTypes.NetPromoter &&
          <RadiobuttonRange 
            focused={this.props.focused}
            type={QuestionTypes.NetPromoter}
            onOptionsChanged={(openOption) => this.onOptionsChangedHandler(openOption)}
            openOption={this.props.openOption}
            min={1} 
            max={5} 
            className={this.props.className} 
          />
        }

        {
          this.props.type === QuestionTypes.NetPromoterRouting &&
          <Routing
            focused={this.props.focused}
            type={QuestionTypes.NetPromoter}
            min={0}
            max={10}
            onOptionsChanged={(options, openOption) => this.onOptionsChangedHandler(options, openOption)}
            options={this.props.options}
          />
        }

        {this.props.type === QuestionTypes.Likert &&
        <MultipleChoice
          focused={this.props.focused}
          type={QuestionTypes.Likert}
          onOptionsChanged={(options, openOption) => this.onOptionsChangedHandler(options, openOption)}
          options={this.props.options}
          openOption={this.props.openOption}
          selectedTranslation={this.props.selectedTranslation}
          hasWeight={this.props.hasWeight}
          weight={this.props.weight} />
        }

        {this.props.type === QuestionTypes.Routing &&
        <Routing
          focused={this.props.focused}
          type={QuestionTypes.Routing}
          onOptionsChanged={(options, openOption) => this.onOptionsChangedHandler(options, openOption)}
          options={this.props.options}
          selectedTranslation={this.props.selectedTranslation}
        />
        }
      </div>
    )
  }
}

export default injectIntl(QuestionFactory, {forwardRef: true})
