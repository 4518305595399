import React, { Component } from 'react'
import axios from "axios"
import {rootUrl} from '../../../config'
import {Form} from "react-bootstrap"

class LanguageSelection extends Component {
  constructor(props) {
    super(props)
    document.body.style.backgroundColor = "#fff"

    const params = this.props.match.params
    this.state = {
      uuid: params.id,
      color: "#3b699c",
      translations: [],
      selectedTranslation: ""
    }

    this.checkSurveyReferral(this.state.uuid)
    this.getSurveyColor(this.state.uuid)
    this.getSurveyTranslations(this.state.uuid)
  }

  getSurveyTranslations(uuid) {
    axios.get(rootUrl + '/api/v1/survey/get_survey_translations/' + uuid)
      .then((res) => {
        let selectedTranslation = ""
        let translations = res.data
        if (translations.length > 0) {
          if (translations.length === 1) {
            this.redirectToSurvey(translations[0])
          } else {
            selectedTranslation = translations[0]
          }
        }

        this.setState({
          translations: translations,
          selectedTranslation: selectedTranslation
        })
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Load survey color in state
  getSurveyColor(uuid) {
    axios.get(rootUrl + '/api/v1/survey/get_survey_color/' + uuid
    ).then((res) => {
      this.setState({
        color: res.data
      })
    }).catch((error) => {
      console.log(error)
    })
  }

  getRadiobuttonLabel(translation) {
    let translationLabels = {
      "nl": "Nederlands",
      "de": "Deutsch",
      "en": "English",
      "ru": "Russkiy",
      "es": "Español",
      "fr": "Français",
      "it": "Italiano",
      "pl": "Polskie",
      "se": "Svenska",
      "tr": "Türk",
      "ar": "العربية"
    }

    return translationLabels[translation]
  }

  redirectToSurvey(translation) {
    const base = "/"
    let translationIndicators = {
      "nl": "vragenlijst",
      "de": "fragebogen",
      "en": "survey",
      "ru": "anketa",
      "es": "cuestionario",
      "fr": "questionnaire",
      "it": "questionario",
      "pl": "ankiety",
      "se": "frageformularet",
      "tr": "anketin",
      "ar": "aistibyan"
    }

    let url = base + translationIndicators[translation] + "/" + this.state.uuid
    if (window.location.pathname.endsWith("preview")) {
      url += "/preview"
    }

    window.location = url
  }

  checkSurveyReferral(uuid) {
    axios.get(rootUrl + '/api/v1/survey/check_referral/' + uuid)
      .then(res => {
        if(res.data.redirect_to) {
          window.location = '/language-selection/' + res.data.redirect_to
        }
        if(res.data.status === 2 && res.data.redirect_to == null) {
          this.setState({
            name: "Survey not active. Please check provider.",
            surveyNotFound: true
          })
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  render() {
    return (
      <div className="survey">
        <div className={"survey-main-top"} style={{backgroundColor: this.state.color}} ref={this.surveyTop}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-xs-12 main-title"}>
                <h1 className={"header header1"}>
                  Select your language
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className={"container survey-container"} style={{maxWidth: "460px"}}>
          <div className={"survey-panel"}>
            <div className="survey-top" />

            <div className="survey-content">
              <div className={"row"}>
                {this.state.translations.map((translation, index) => {
                  return (
                    <>
                      <Form.Check
                        type={"radio"}
                        label={this.getRadiobuttonLabel(translation)}
                        name={"multi-group"}
                        id={translation + index}
                        key={translation + index}
                        className={"radio"}
                        checked={this.state.selectedTranslation === translation}
                        style={{display: "inlineblock", width:"80%"}}
                        onChange={() => this.setState({selectedTranslation: translation})}
                      />
                      <br />
                    </>
                  )
                })}
              </div>
            </div>

            <div className="survey-bottom">
              <button
                className={"forward"}
                onClick={() => this.redirectToSurvey(this.state.selectedTranslation)}
                disabled={this.state.selectedTranslation.length === ""}
              >
                Start
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LanguageSelection
