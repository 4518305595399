import React, {Component} from 'react'
import DashboardFrame from '../../../components/Dashboard/DashboardFrame/DashboardFrame'
import './AskAQuestion.css'
import {withRouter} from 'react-router'
import axios from "axios"
import {Form} from "react-bootstrap"
import {rootUrl} from '../../../config'
import {FormattedMessage, injectIntl} from 'react-intl'
import Swal from "sweetalert2"

class AskAQuestion extends Component {
  constructor(props) {
    super(props)

    this.state = {
      subject: "",
      message: "",
      email: ""
    }
  }

  sendSupportMail() {
    axios.post(rootUrl + '/api/v1/administration/send_support_mail',
      {
        subject: this.state.subject,
        message: this.state.message,
        email: this.state.email
      }
    ).then((res) => {
      Swal.fire({
        title: this.props.intl.formatMessage({id: "dialogs.success"}),
        text: this.props.intl.formatMessage({id: "ask_a_question.successfully_sent"}),
        type: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        this.setState({
          subject: "",
          message: "",
          email: ""
        })
      })
    }).catch((error) => {
      console.log(error.response)
      Swal.fire({
        title: this.props.intl.formatMessage({id: "dialogs.error_title"}),
        text: this.props.intl.formatMessage({id: "dialogs.error_message"}),
        type: 'error',
        confirmButtonText: 'OK'
      })
    })
  }

  render() {
    return (
      <DashboardFrame
        title={this.props.intl.formatMessage({id: "ask_a_question.ask_a_question"})}
        dashboardTitle={this.props.intl.formatMessage({id: "ask_a_question.ask_a_question"})}
        onLanguageChanged={(language) => this.props.onLanguageChanged(language)}>
        <div>
          <div className={"card-box slide-up ask-a-question-box"}>
            <h4 className="header-title m-b-5"><FormattedMessage id={"ask_a_question.ask_a_question"} defaultMessage={"Stel een vraag"} /></h4>
            <p className="text-muted font-13 m-b-30">
              <FormattedMessage id={"ask_a_question.info"} /><br /><br />
              <a href={this.props.intl.formatMessage({id: "ask_a_question.manual_link"})} download><i className={"fa fa-download"} /> <FormattedMessage id={"ask_a_question.download_manual"} /></a><br />
            </p>

            <Form.Group>
              <Form.Label><FormattedMessage id={"ask_a_question.email"} defaultMessage={"E-mail"} /></Form.Label>
              <Form.Control
                value={this.state.email}
                onChange={(e) => this.setState({email: e.target.value})}
                required
                type={"email"}
                placeholder={this.props.intl.formatMessage({id: "ask_a_question.email"})}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label><FormattedMessage id={"ask_a_question.subject"} defaultMessage={"Onderwerp"} /></Form.Label>
              <Form.Control
                value={this.state.subject}
                onChange={(e) => this.setState({subject: e.target.value})}
                required
                placeholder={this.props.intl.formatMessage({id: "ask_a_question.subject"})}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label><FormattedMessage id={"ask_a_question.message"} defaultMessage={"Bericht"} /></Form.Label>
              <Form.Control
                value={this.state.message}
                onChange={(e) => this.setState({message: e.target.value})}
                placeholder={this.props.intl.formatMessage({id: "ask_a_question.message_placeholder"})}
                as={"textarea"}
                rows={"10"}
                tabIndex={"8"}
              />
            </Form.Group>

            <button
              className={"btn btn-primary m-t-10"}
              onClick={() => this.sendSupportMail()}
              disabled={this.state.email === "" || this.state.subject === "" || this.state.message === ""}
            >
              <i className="fa fa-send"/> <FormattedMessage id={"ask_a_question.send"} defaultMessage={"Versturen"} />
            </button>
          </div>
        </div>
      </DashboardFrame>
    )
  }
}

export default withRouter(injectIntl(AskAQuestion))

