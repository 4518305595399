import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
// import {Redirect} from "react-router-dom"
import {Form} from "react-bootstrap"
// import {SketchPicker} from "react-color"
import React from "react"
import LoadingSpinner from "../../Miscellaneous/LoadingSpinner/LoadingSpinner"
import axios from 'axios'
import Clusters from "../../../screens/Dashboard/Clusters/Clusters"
import {injectIntl} from 'react-intl'

const {rootUrl} = require("../../../config")

function EditBenchmarkSettingDialog(props) {
  const [name, setName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [loading, setLoading] = React.useState(typeof props.currentSettingId === 'number')

  if(loading) {
    axios.get(rootUrl + '/api/v1/benchmark/settings/' + props.currentSettingId)
      .then((res) => {
        setDescription(res.data.description)
        setName(res.data.name)
        setLoading(false)
      })
  }

  const save = () => {
    setLoading(true)

    if(typeof props.currentSettingId === 'number') {
      axios.post(rootUrl + '/api/v1/benchmark/settings/' + props.currentSettingId, {name, description})
        .then(() => {
          props.onClose()
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      axios.post(rootUrl + '/api/v1/benchmark/create_settings', {name, description})
        .then(() => {
          props.onClose()
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  return (
    <PopupDialog
      title={props.title}
      onClose={() => props.onClose()}
      style={{width: '80%'}}
    >
      {loading ?
        <div className='col-12'>
          <LoadingSpinner className='m-10'/>
        </div>
        : <div className="row m-b-10">
        <div className="col-xs-12">
          <Form.Group>
            <Form.Label>{props.intl.formatMessage({id: "edit_benchmark.name"})}</Form.Label>
            <Form.Control
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder={props.intl.formatMessage({id: "edit_benchmark.name_of_setting"})}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>{props.intl.formatMessage({id: "edit_benchmark.description"})}</Form.Label>
            <Form.Control
              as='textarea'
              rows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={props.intl.formatMessage({id: "edit_benchmark.description_of_setting"})}
            />
          </Form.Group>


        </div>
      </div>
      }
      {typeof props.currentSettingId === 'number' && <Clusters id={props.currentSettingId}/>}


      <div>
        <button
          className="btn btn-primary"
          onClick={save}
        >
          <i className="fa fa-save"/> {props.intl.formatMessage({id: "ui.save"})}
        </button>
        <button className="btn btn-secondary m-l-5" onClick={props.onClose}> {props.intl.formatMessage({id: "ui.cancel"})}</button>
      </div>

    </PopupDialog>
  )
}

export default injectIntl(EditBenchmarkSettingDialog)
