import React, { Component } from 'react'
import './CardBase.css'

class CardBase extends Component {
    constructor(props) {
      super(props)

      this.state = {
        widthClass: (this.props.blockWidth) ? "width" + this.props.blockWidth : "",
        heightClass: (this.props.blockHeight) ? "height" + this.props.blockHeight : ""
      }
    }

    sizeChanged(width, height, callback) {
      this.setState({
        widthClass: "width" + width,
        heightClass: "height" + height
      }, () => {
        typeof callback === 'function' && callback()
      })
    }

    render() {
        return (
          <div className={"CardBase slide-up " + this.state.widthClass + " " + this.state.heightClass}>
            <div className="card-content">
              <div className="top">
                <h4 className="header-title pull-left">
                  {this.props.cardTitle}
                  {this.props.dimensionTitle &&
                  <span className={"dimension-title"}> ({this.props.dimensionTitle})</span>}
                </h4>
                <div className="optionButtons">
                  {this.props.optionButtons}
                </div>
              </div>
              {
                this.props.blockWidth > 1 &&
                <div className="card-text">
                  <span className="text-muted">{this.props.cardText}</span>
                </div>
              }
              {this.props.children}
            </div>
          </div>
        )
    }
}

export default CardBase
