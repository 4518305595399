import React, { Component } from 'react'
import AddComponent from './BuilderComponents/AddComponent/AddComponent'
import SelectComponent from './BuilderComponents/SelectComponent/SelectComponent'
import './PageBuilder.css'
import {injectIntl} from 'react-intl'

class PageBuilder extends Component {
  constructor(props) {
    super(props)

    /*
      Components is an array of components inside the page.
      AnimateLastComponent specifies whether the last component on the page should 'slide-up', when a component is added.
     */
    this.state = {
      components: (this.props.components ? this.props.components : []),
      refList: [],
      animateLastComponent: false,
      dimensions: this.props.dimensions || []
    }
  }

  getComponents() {
    return this.state.components
  }

  // Add component with type @questionType to the state components array. Pass these changes up to surveyFrame.
  addComponent(questionType) {
    const newComponent = {
      type: questionType,
      name: this.props.intl.formatMessage({id: "component_builder.component_name_placeholder"}),
      questionText: {},
      focused: false,
      required: true,
      options: [],
      openOption: false,
      hasWeight: false,
      weight: 1,
      dimensions: [],
      selectedDimension: -1
    }
    newComponent.questionText[this.props.selectedTranslation] = this.props.intl.formatMessage({id: "component_builder.question_placeholder"})

    if (questionType === "NetPromoter") {
      newComponent.questionText[this.props.selectedTranslation] = this.props.intl.formatMessage({id: "component_builder.nps"})
    }
    
    if (questionType === "Likert") {
      let likertScale = [
        this.props.intl.formatMessage({id: "likert.completely_agree"}),
        this.props.intl.formatMessage({id: "likert.agree"}),
        this.props.intl.formatMessage({id: "likert.neutral"}),
        this.props.intl.formatMessage({id: "likert.disagree"}),
        this.props.intl.formatMessage({id: "likert.completely_disagree"})
      ]

      newComponent.options = []
      for (let key = 0; key < likertScale.length; key++) {
        let option = {key: key, weight: 0, translations: {}}
        option["translations"][this.props.selectedTranslation] = likertScale[key]
        newComponent.options.push(option)
      }
    } else if (questionType === "MultipleChoiceSingle" || questionType === "MultipleChoiceMultiple") {
      const numberOfOptions = 3
      newComponent.options = []
      for (let optionNumber = 0; optionNumber < numberOfOptions; optionNumber++) {
        let option = {key: optionNumber, weight: 0, translations: {}}
        option["translations"][this.props.selectedTranslation] = ""
        newComponent.options.push(option)
      }
    }

    this.setState({
      components: [...this.state.components, newComponent],
      animateLastComponent: true
    }, () => {
      this.passSettingsUp()
    })
  }

  // Delete component from the components state array. Pass these changes up to surveyFrame.
  deleteComponent(index) {
    let components = this.state.components
    components.splice(index, 1)

    this.setState({
      components: components,
      animateLastComponent: false
    }, () => {
      this.passSettingsUp()
    })
  }

  // Settings of a component have been changed, modify these settings in the state components array and
  // pass these changes up to surveyFrame.
  onSettingsChangedHandler(index, newSettings) {
    let components = this.state.components
    let questionText = components[index].questionText
    questionText[this.props.selectedTranslation] = newSettings.questionText

    let newComponent = newSettings
    newComponent.questionText = questionText
    components[index] = newComponent

    this.setState({
      components: components,
      animateLastComponent: false
    }, () => {
      this.passSettingsUp()
    })
  }

  // Pass all page data up to surveyFrame.
  passSettingsUp() {
    this.props.onComponentsChanged(this.state.components)
  }

  // Focus on a single component and make all the other components unfocused.
  focusComponent(index) {
    let components = this.state.components

    for (let i = 0; i < components.length; i++) {
      if (i === index) {
        components[i].focused = true
      } else {
        components[i].focused = false
      }
    }

    this.setState({
      components: components
    })
  }

  render() {
    // Generate a random string of 10 characters to create a unique key.
    let randomString = Math.random().toString(36).substring(2, 12) + Math.random().toString(36).substring(2, 12)

    return (
      <>
        {
          this.state.components.map((component, index) => {
            return <React.Fragment key={randomString + index}>
              <SelectComponent
                key={randomString + index}
                questionType={component.type}
                focused={component.focused}
                options={component.options}
                openOption={component.openOption}
                questionText={component.questionText[this.props.selectedTranslation]}
                hasWeight={component.hasWeight}
                weight={component.weight}
                required={component.required}
                name={component.name}
                dimensions={this.state.dimensions}
                selectedDimension={component.selectedDimension}
                selectedTranslation={this.props.selectedTranslation}
                deleteComponent={() => this.deleteComponent(index)}
                onSettingsChanged={(state) => this.onSettingsChangedHandler(index, state)}
                className={(index === this.state.components.length - 1 && this.state.animateLastComponent)
                            ? "slide-up"
                            : ""}
                onFocus={() => this.focusComponent(index)}/>
              <hr className={"question-component-hr"} /><br />
            </React.Fragment>
          })
        }
        <AddComponent onComponentAdded={this.addComponent.bind(this)} />
      </>
    )
  }
}

export default injectIntl(PageBuilder, { forwardRef: true })
