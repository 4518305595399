import React, { Component } from 'react'
import DashboardFrame from '../../../components/Dashboard/DashboardFrame/DashboardFrame'
import GraphCardHolder from '../../../components/Dashboard/GraphCardHolder/GraphCardHolder'
import SelectSurvey from "../../../components/Miscellaneous/SelectSurvey/SelectSurvey"
import './Results.css'
import {withRouter} from 'react-router'

class Results extends Component {
  constructor(props) {
    super(props)
    this.surveyHolder = React.createRef()
  }

  surveyChangedHandler(id) {
    this.forceUpdate()
    this.surveyHolder.current.clear()
    this.surveyHolder.current.getSurveyResults(id)
    this.surveyHolder.current.getSurveyName()
  }

  render() {
    let colorScheme = (localStorage.getItem("colorScheme") ? localStorage.getItem("colorScheme") : "LiveResearch")

    return (
      <DashboardFrame
        onSurveyChanged={this.surveyChangedHandler.bind(this)}
        title="Dashboard"
        dashboardTitle="Resultaten"
        {...this.props}
      >
        {!parseInt(localStorage.getItem('selectedSurvey')) && <SelectSurvey />}
        <GraphCardHolder
          ref={this.surveyHolder}
          page="results"
          colorSchemeName={colorScheme}
        />
      </DashboardFrame>
    )
  }
}

export default withRouter(Results)
