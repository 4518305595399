import React, { Component } from 'react'
import ListTable from "../../Miscellaneous/ListTable/ListTable"
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import './ShareDialog.css'
import {Form} from "react-bootstrap"
import axios from 'axios'
import config from '../../../config'
import Swal from "sweetalert2"
import {FormattedMessage, injectIntl} from "react-intl"

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

class ShareDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      emailValue: "",
      emails: [],
      organisationUsers: []
    }

    this.fetchEmails()
  }

  saveAndSend() {
    axios.post(config.rootUrl + '/api/v1/survey/share', {emails: this.state.emails, surveyId: this.props.surveyId})
      .then((res) => {
        Swal.fire({
          title: this.props.intl.formatMessage({id: "dialogs.success"}),
          text: this.props.intl.formatMessage({id: "dialogs.surveys_shared"}),
          type: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          this.props.onClose()
        })
      })
      .catch(e => {
        console.log(e)
        Swal.fire({
          title: this.props.intl.formatMessage({id: "dialogs.error_title"}),
          text: this.props.intl.formatMessage({id: "dialogs.error_message"}),
          type: 'error',
          confirmButtonText: 'OK'
        })
      })
  }

  fetchEmails() {
    let emails = []
    axios.get(config.rootUrl + '/api/v1/survey/share/' + this.props.surveyId)
      .then((res) => {
        emails = res.data.users.map((user) => user.email).filter(onlyUnique)
        this.setState({
          emails: emails
        }, () => {
          this.fetchOrganisationUsers()
        })
      })
      .catch(e => console.log(e))
  }

  fetchOrganisationUsers() {
    axios.post(config.rootUrl + '/api/v1/survey/get_users_with_access', {surveyId: this.props.surveyId})
      .then(res => {
        this.setState({organisationUsers: res.data})
      })
      .catch(e => console.log(e))
  }

  validateEmail(mail) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)
  }

  addEmail() {
    if (!this.state.emails.includes(this.state.emailValue) && this.validateEmail(this.state.emailValue)) {
      let emails = this.state.emails
      emails.push(this.state.emailValue)

      this.setState({
        emails: emails,
        emailValue: ""
      })
    }
  }

  removeItem(row) {
    const rowIndex = parseInt(row[0]) - 1
    let emails = this.state.emails
    let toRemove = emails.splice(rowIndex, 1)[0]

    axios.post(config.rootUrl + '/api/v1/survey/remove_access', {email: toRemove, surveyId: this.props.surveyId})
      .then(() => {
        this.setState({
          emails: emails
        })
      })
      .catch(e => {
        console.log(e)
      })
  }

  render() {
    return (
      <PopupDialog title={this.props.title} onClose={() => this.props.onClose()} style={{width: '50%'}}>
        <FormattedMessage id={"share_survey.info1-1"} defaultMessage={"Nodig mensen via e-mail uit om de resultaten van de vragenlijst "} />{this.props.surveyTitle}<FormattedMessage id={"share_survey.info1-2"} defaultMessage={"' te kunnen zien."} />

        <Form.Group className={"m-t-20"}>
          <Form.Label><FormattedMessage id={"share_survey.add_email"} defaultMessage={"E-mail toevoegen"} /></Form.Label>
          <Form.Control
            placeholder={"voorbeeld@voorbeeld.nl"}
            className={"form-control add-email-textbox"}
            onChange={(e) => this.setState({emailValue: e.target.value})}
            value={this.state.emailValue}
          />
          <button
            className="btn btn-primary m-l-5 add-email-button"
            onClick={() => this.addEmail()}
            disabled={!(this.validateEmail(this.state.emailValue))}
          >
            <i className="fa fa-plus" />
          </button>
        </Form.Group>

        <ListTable headers={["#", this.props.intl.formatMessage({id: "share_survey.email"})]}
                   rows={this.state.emails.map((email, index) => {
                     return [index+1, email]
                   })}
                   className={"m-t-10"}
                   removeButton
                   onRemoveButtonClicked={(row) => this.removeItem(row)}
                   key={42}
        />

        <hr style={{margin: 20}}/>
        <div><FormattedMessage id={"share_survey.info2"} defaultMessage={"Gebruikers die reeds toegang hebben via hun organisatie:"} /></div>
        <ListTable headers={["#", this.props.intl.formatMessage({id: "share_survey.email"}), this.props.intl.formatMessage({id: "share_survey.username"})]}
                   rows={this.state.organisationUsers.map((user, index) => {
                     return [index+1, user.email, user.username]
                   })}
                   className={"m-t-10"}
                   key={49}
        />

        <button className="btn btn-primary" onClick={() => this.saveAndSend()}>
          <i className="fa fa-save"/> <FormattedMessage id={"share_survey.save_and_send"} defaultMessage={"Opslaan en versturen"} />
        </button>
        <button className="btn btn-secondary m-l-5" onClick={()=> this.props.onClose()}> Sluiten</button>
      </PopupDialog>
    )
  }
}

export default injectIntl(ShareDialog)
