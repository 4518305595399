import React, {Component} from 'react'
import {Button, Form} from "react-bootstrap"
import {Redirect, Link} from 'react-router-dom'
import axios from 'axios'
import {rootUrl} from "../../../config";
import sha256 from "sha256";
import Swal from "sweetalert2";

class Register extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      username: "",
      email: "",
      password: "",
      redirect: false
    }
  }

  validateEmail(mail) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)
  }
  
  validateForm() {
    return this.state.username.length > 0 &&
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.validateEmail(this.state.email)
  }
  
  /**
   * Sets the state of the controlId to the changed text.
   */
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  register() {
    axios.post(rootUrl + '/api/v1/register', {
      'password': sha256(this.state.password),
      'email': this.state.email,
      'username': this.state.username
    }).then((res) => {
      console.log(res)
      Swal.fire({
        title: 'Gelukt',
        text: 'Uw account is bij ons geregistreerd. U wordt nu doorgestuurd naar de login pagina.',
        type: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        this.setState({redirect: true})
      })
    }).catch((err) => {
      console.log(err)
      Swal.fire({
        title: 'Foutmelding',
        text: 'Er is iets fout gegaan bij het aanmaken van het account. Controleer uw internetverbinding.',
        type: 'error',
        confirmButtonText: 'OK'
      })
    })
  }
  
  render() {
    return (
      <>
        {this.state.redirect && <Redirect to={'/login'} />}

        <div className="account-pages login-background"/>
        <div className="clearfix"/>
        <div className="wrapper-page">
          
          <div className="account-bg">
            <div className="card-box login-card">
              <div className="text-xs-center m-t-20">
                <Link to='/login' className="logo .live-green">
                  <img src="/images/logo60.png" alt="" id="login-logo" width="40" height="40"/><br/>
                  <span id="login-title" className="text-live-green">LiveResearch</span>
                </Link>
              </div>
              
              
              {/* Start form */}
              <div className="m-t-30 m-b-10">
                <div className="col-xs-12 text-xs-center">
                  <h6 className="text-muted text-uppercase m-b-0 m-t-0">Registreren</h6>
                </div>

                <div className={"col-xs-12"}>
                  <form className="form-horizontal m-t-20">
                    <Form.Group controlId="username">
                      <Form.Label>Gebruikersnaam</Form.Label>
                      <Form.Control
                        value={this.state.username}
                        onChange={this.handleChange}
                        type="username"
                        required
                        placeholder="Gebruikersnaam"
                      />
                    </Form.Group>

                    <Form.Group controlId="email">
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        value={this.state.email}
                        onChange={this.handleChange}
                        required
                        type={"email"}
                        placeholder="voorbeeld@voorbeeld.nl"
                      />
                    </Form.Group>

                    <Form.Group controlId="password">
                      <Form.Label>Wachtwoord</Form.Label>
                      <Form.Control
                        value={this.state.password}
                        onChange={this.handleChange}
                        required
                        type={"password"}
                        placeholder="Wachtwoord"
                      />
                    </Form.Group>

                    <Button
                      className="text-center m-t-10 btn btn-success btn-block waves-effect waves-light btn-live-green"
                      disabled={!this.validateForm()}
                      onClick={() => this.register()}
                    >
                      Registreren
                    </Button>

                  </form>
                </div>
              </div>
              {/* End form */}
            
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Register
