import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { FormattedMessage, injectIntl } from "react-intl";
import axios from "axios";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Divider, Dropdown, Form } from "semantic-ui-react";
import XLSX from "xlsx";

import DashboardFrame from "../../../components/Dashboard/DashboardFrame/DashboardFrame";
import TimeBarChartWithErrorBars from "./TimeBarChartWithErrorBars";
import { rootUrl } from "../../../config";
import "./Benchmark.css";
import LoadingSpinner from "../../../components/Miscellaneous/LoadingSpinner/LoadingSpinner";
import BarChartWithErrorBars from "./BarChartWithErrorBars";
let bmData = [];
let bmDataQuestionLevel = [];
let renderCount = 0;
const TimeBenchmarkBarChart = ({ intl, onLanguageChanged, ...props }) => {
  const now = new Date();
  const [benchmarkData, setBenchmarkData] = useState([]);
  const [surveyName, setSurveyName] = useState(null);
  const [dimensionLevelLoading, setDimensionLevelLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState("avg_score");
  const [graciousLoading, setGraciousLoading] = useState(false);
  const [selectedDimension, setSelectedDimension] = useState(null)
  const [benchmarkButtons, setBenchmarkButtons] = useState([]);
  const [questionLevelLoading, setQuestionLevelLoading] = useState(true);

  const [periodOne, setPeriodOne] = useState({
    id: "period_one",
    start_date: new Date(now.getFullYear(), 0, 1),
    end_date: now,
    label: `${new Date(now.getFullYear(), 0, 1).toLocaleDateString()} - ${now.toLocaleDateString()}`,
  });

  const [periodTwo, setPeriodTwo] = useState({
    id: "period_two",
    start_date: new Date(now.getFullYear() - 1, 0, 1),
    end_date: new Date(now.getFullYear() - 1, 11, 31),
    label: `${new Date(now.getFullYear() - 1, 0, 1).toLocaleDateString()} - ${new Date(now.getFullYear() - 1, 11, 31).toLocaleDateString()}`,
  });

  const [surveyId, setSurveyId] = useState(
    isNaN(parseInt(localStorage.getItem("selectedSurvey"))) ? null : parseInt(localStorage.getItem("selectedSurvey")),
  );

  const getBenchmarkData = async () => {
    setDimensionLevelLoading(true);
    if (!surveyId) return;
    setError(null);
    try {
      const locale = localStorage.getItem("language_locale");
      const response = await axios.post(
        `${rootUrl}/api/v1/benchmark/time-comparison`,
        {
          survey_id: surveyId,
          locale,
          period_one: periodOne,
          period_two: periodTwo,
        },
      );

      bmData = response.data.data;

      setBenchmarkData(response.data.data);
      setSurveyName(response.data.surveyName);
    } catch (err) {
      console.log(err);
      setError(err.message);
    } finally {
      getBenchmarkDataQuestionLevel();
      setDimensionLevelLoading(false);
    }
  };

  const getBenchmarkDataQuestionLevel = async () => {
    setError(null);
    if (!surveyId) return;
    try {
      const locale = localStorage.getItem("language_locale");
      const responseSingleSurvey = await axios.post(
        `${rootUrl}/api/v1/benchmark/question-level-time-comparison`,
        {
          survey_id: surveyId,
          locale,
          period_one: periodOne,
          period_two: periodTwo,
        },
      );

      const dimensions = responseSingleSurvey.data.data.reduce((acc, x) => {
        if (!acc.find(d => d.dimension === x.dimension)) {
          acc.push({dimension: x.dimension, dimension_id: x.dimension_id});
        }
        return acc;
      }, [])

      bmDataQuestionLevel = responseSingleSurvey.data.data
      setBenchmarkButtons([...dimensions].concat({dimension: intl.formatMessage({ id: "benchmark.show_all_dimensions" }), dimension_id: null}))
      selectedDimension && setBenchmarkData(bmDataQuestionLevel.filter(x => x.dimension_id === selectedDimension))
    } catch (err) {
      console.log(err);
    } finally {
      setQuestionLevelLoading(false);
      setGraciousLoading(false);
    }
  };

  const handleSurveyChange = (id) => {
    setSurveyId(id);
    setError(null);
  };

  const handleMetricChange = (metric) => {
    setSelectedMetric(metric);
  };

  const formatDateForInput = (date) => {
    return date.toISOString().split('T')[0];
  };

  const handlePeriodOneChange = (field, value) => {
    const date = new Date(value);
    
    // Check if date is valid before using it
    if (isNaN(date.getTime())) {
      // Invalid date - don't update state
      return;
    }
    
    setPeriodOne({
      ...periodOne,
      [field]: date,
      label: `${periodOne.start_date.toLocaleDateString()} - ${field === 'end_date' ? date.toLocaleDateString() : periodOne.end_date.toLocaleDateString()}`,
    });
  };

  const handlePeriodTwoChange = (field, value) => {
    const date = new Date(value);
    
    // Check if date is valid before using it
    if (isNaN(date.getTime())) {
      // Invalid date - don't update state
      return;
    }
    
    setPeriodTwo({
      ...periodTwo,
      [field]: date,
      label: `${periodTwo.start_date.toLocaleDateString()} - ${field === 'end_date' ? date.toLocaleDateString() : periodTwo.end_date.toLocaleDateString()}`,
    });
  };

  const handleExportToExcel = () => {
    const wb = XLSX.utils.book_new();

    // Add header information sheet
    const headerData = [
      ['Survey', surveyName || ''],
      ['Period One', periodOne.label],
      ['Period Two', periodTwo.label],
      ['Export Date', new Date().toLocaleDateString()],
      [], // Empty row for spacing
    ];
    const wsHeader = XLSX.utils.aoa_to_sheet(headerData);
    XLSX.utils.book_append_sheet(wb, wsHeader, "Info");

    // Prepare benchmark data for export
    const exportData = benchmarkData.map(item => ({
      'Dimension': item.dimension,
      'Label': item.label,
      [`${periodOne.label} QI Score`]: item.period_one_qi_score,
      [`${periodTwo.label} QI Score`]: item.period_two_qi_score,
      [`${periodOne.label} QI Score CI`]: item.period_one_confidence_interval_qi_score,
      [`${periodTwo.label} QI Score CI`]: item.period_two_confidence_interval_qi_score,
      [`${periodOne.label} Average Score`]: item.period_one_avg_score,
      [`${periodTwo.label} Average Score`]: item.period_two_avg_score,
      [`${periodOne.label} Average Score CI`]: item.period_one_confidence_interval_avg_score,
      [`${periodTwo.label} Average Score CI`]: item.period_two_confidence_interval_avg_score,
    }));

    // Create data worksheet
    const wsData = XLSX.utils.json_to_sheet(exportData);
    XLSX.utils.book_append_sheet(wb, wsData, "Time Comparison Data");

    // Generate filename
    const fileName = `time_comparison_${surveyName || 'survey'}_${new Date().toISOString().split('T')[0]}.xlsx`;

    // Save file
    XLSX.writeFile(wb, fileName);
  };

  const mapDataForBarChart = () => {
    if (!benchmarkData || benchmarkData.length === 0) return [];
    
    return benchmarkData.map(item => ({
      dimension: item.dimension,
      dimension_id: item.dimension_id,
      label: item.label,
      survey_qi_score: item.period_one_qi_score,
      survey_confidence_interval_qi_score: item.period_one_confidence_interval_qi_score,
      survey_avg_score: item.period_one_avg_score,
      survey_confidence_interval_avg_score: item.period_one_confidence_interval_avg_score,
      cluster_qi_score: item.period_two_qi_score,
      cluster_confidence_interval_qi_score: item.period_two_confidence_interval_qi_score,
      cluster_avg_score: item.period_two_avg_score,
      cluster_confidence_interval_avg_score: item.period_two_confidence_interval_avg_score,
    }));
  };

  const handleFilterClick = (bars, e) => {
    if(questionLevelLoading) {
      return
    }
    try {
      const index = bars[0]._index
      const dimension_id = bars[0]._chart.config.data.datasets[0].dimension_ids[index];
      handleDimensionChange(dimension_id)

    } catch (e) {
      return
    }
  
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {intl.formatMessage({ id: "benchmark.qi_score_tooltip" })}
    </Tooltip>
  );

  const handleDimensionChange = (dimension_id) => {
    console.log(dimension_id)
    setSelectedDimension(dimension_id)
    dimension_id && setBenchmarkData(bmDataQuestionLevel.filter(x => x.dimension_id === dimension_id))
    !dimension_id && setBenchmarkData(bmData)
  }



  return (
    <DashboardFrame
      onSurveyChanged={handleSurveyChange}
      title="Dashboard"
      dashboardTitle={"Benchmark"}
      onLanguageChanged={onLanguageChanged}
      {...props}
    >

    {!surveyId && (
          <div className="holder-spinner">{intl.formatMessage({ id: "benchmark.select_a_survey" })}</div>
        )}
        
      {surveyId && 
      <div>
      <div className='benchmark-card slide-up'>
        <div className="date-picker-container">
          <div className="period-selector">
            <h4>{intl.formatMessage({ id: "benchmark.period_one" })}</h4>
            <div className="date-inputs">
              <div className="date-input-group">
                <label>{intl.formatMessage({ id: "benchmark.start_date" })}:</label>
                <input
                  type="date"
                  value={formatDateForInput(periodOne.start_date)}
                  onChange={(e) => handlePeriodOneChange('start_date', e.target.value)}
                  max={formatDateForInput(periodOne.end_date)}
                />
              </div>
              <div className="date-input-group">
                <label>{intl.formatMessage({ id: "benchmark.end_date" })}:</label>
                <input
                  type="date"
                  value={formatDateForInput(periodOne.end_date)}
                  onChange={(e) => handlePeriodOneChange('end_date', e.target.value)}
                  min={formatDateForInput(periodOne.start_date)}
                />
              </div>
            </div>
          </div>
          
          <div className="period-selector">
            <h4>{intl.formatMessage({ id: "benchmark.period_two" })}</h4>
            <div className="date-inputs">
              <div className="date-input-group">
                <label>{intl.formatMessage({ id: "benchmark.start_date" })}:</label>
                <input
                  type="date"
                  value={formatDateForInput(periodTwo.start_date)}
                  onChange={(e) => handlePeriodTwoChange('start_date', e.target.value)}
                  max={formatDateForInput(periodTwo.end_date)}
                />
              </div>
              <div className="date-input-group">
                <label>{intl.formatMessage({ id: "benchmark.end_date" })}:</label>
                <input
                  type="date"
                  value={formatDateForInput(periodTwo.end_date)}
                  onChange={(e) => handlePeriodTwoChange('end_date', e.target.value)}
                  min={formatDateForInput(periodTwo.start_date)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="date-picker-container">
          <Button onClick={getBenchmarkData}>{intl.formatMessage({ id: "benchmark.fetch" })}</Button>
        </div>
      </div>
                <div className="benchmark-card slide-up">
            <div
              className="btn-toolbar"
              role="toolbar"
              aria-label="Toolbar with button groups"
              style={{ marginBottom: 10 }}
            >
          

              <div
                className="btn-group mr-2"
                role="group"
                aria-label="Second group"
                style={{ marginLeft: 20 }}
              >
                {[
                  {
                    key: "qi_score",
                    text: intl.formatMessage({ id: "benchmark.qi_score" }),
                  },
                  {
                    key: "avg_score",
                    text: intl.formatMessage({ id: "benchmark.average_score" }),
                  },
                ].map((x, i) => {
                  if (x.key === 'avg_score') {
                    return (
                      <Button
                        key={i}
                        variant={
                          x.key === selectedMetric ? "primary" : "secondary"
                        }
                        disabled={graciousLoading}
                        onClick={() => handleMetricChange(x.key)}
                  
                      >
                        {x.text}
                      </Button>
                    );
                  } else {
                    return (
                      <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <Button
                        key={i}
                        variant={
                          x.key === selectedMetric ? "primary" : "secondary"
                        }
                        disabled={graciousLoading}
                        onClick={() => handleMetricChange(x.key)}
  
                      >
                        {x.text}
                      </Button>
                    </OverlayTrigger>
                    );
                  }
                })}
              </div>
              <div
                className="btn-group mr-2"
                role="group"
                aria-label="Help group"
                style={{ margin: 8 }}
              >
                <Button
                  variant="link"
                  className="p-0 d-flex align-items-center"
                  onClick={() => window.open('/benchmark-explanation', '_blank')}
                  title={intl.formatMessage({ id: "benchmark_explanation.title" })}
                >
                  <i className="fas fa-question-circle" style={{ fontSize: '1.2rem', color: '#6c757d' }}></i>
                </Button>
              </div>

              <div
                className="btn-group mr-2"
                role="group"
                aria-label="Second group"
                style={{ marginLeft: 20 }}
              >
            <Dropdown
              placeholder={intl.formatMessage({id: "benchmark.select_dimension"})}
              selection
              value={selectedDimension}
              style={{marginTop: -2}}
              disabled={questionLevelLoading}
              onChange={(e, {value}) => handleDimensionChange(value)}
              options={benchmarkButtons.map((item, index) => {
                return {
                  key: index,
                  text: item.dimension,
                  value: item.dimension_id
                }
              })}
            />
          </div>

              <div
                className="btn-group mr-2"
                role="group"
                aria-label="Export group"
                style={{ marginLeft: 20 }}
              >
                <Button
                  variant="secondary"
                  disabled={questionLevelLoading || graciousLoading || benchmarkData.length === 0}
                  onClick={handleExportToExcel}
                >
                  <i className="fas fa-file-excel mr-2" style={{marginRight: 5}}></i>
                  {intl.formatMessage({ id: "benchmark.export_to_excel" })}
                </Button>
              </div>

        
            </div>

            {dimensionLevelLoading && (
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "200px", position: "relative"}}>
                <LoadingSpinner />
              </div>
            )}

            {!dimensionLevelLoading && !error && benchmarkData.length > 0 && (
              
              <div>
                <BarChartWithErrorBars
                selectedDimension={selectedDimension}
                labels={benchmarkData.map((x) => x.label)}
                datasets={mapDataForBarChart()}
                surveyName={intl.formatMessage({ id: "benchmark.period_one" })}
                clusterName={intl.formatMessage({ id: "benchmark.period_two" })}
                selectedMetric={selectedMetric}
                onFilterClick={handleFilterClick}
                maximalScoreLabel={intl.formatMessage({ id: "benchmark.maximal_score" })}
                negativeLabel={intl.formatMessage({ id: "benchmark.negative" })}
                positiveLabel={intl.formatMessage({ id: "benchmark.positive" })}
              />
              </div>
            )}

            {!dimensionLevelLoading &&
              !error &&
              benchmarkData.length === 0 &&
              intl.formatMessage({ id: "benchmark.no_data_available" })}
          </div>
          </div>
          }
      
    </DashboardFrame>
  );
};

TimeBenchmarkBarChart.propTypes = {
  intl: PropTypes.object.isRequired,
  onLanguageChanged: PropTypes.func.isRequired,
};

export default withRouter(injectIntl(TimeBenchmarkBarChart)); 