import React, { Component } from 'react'
import './LoadingSpinner.css'

class LoadingSpinner extends Component {
  render() {
    return (
      <div className={"loading-container " + this.props.className}>
        <div className={"loader"} />
      </div>
    )
  }
}

export default LoadingSpinner
