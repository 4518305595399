// @ts-nocheck
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { FormattedMessage, injectIntl } from "react-intl";
import axios from "axios";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import {Dropdown} from "semantic-ui-react"
import XLSX from "xlsx";

import DashboardFrame from "../../../components/Dashboard/DashboardFrame/DashboardFrame";
import BarChartWithErrorBars from "./BarChartWithErrorBars";
import { rootUrl } from "../../../config";
import "./Benchmark.css";
import LoadingSpinner from "../../../components/Miscellaneous/LoadingSpinner/LoadingSpinner";

let bmData = [];
let bmDataQuestionLevel = [];
let renderCount = 0;
const BenchmarkBarChart = ({ intl, onLanguageChanged, ...props }) => {
  const now = new Date();
  const [benchmarkData, setBenchmarkData] = useState([]);
  const [surveyName, setSurveyName] = useState(null);
  const [clusterName, setClusterName] = useState(null);
  const [dimensionLevelLoading, setDimensionLevelLoading] = useState(true);
  const [questionLevelLoading, setQuestionLevelLoading] = useState(true);
  const [error, setError] = useState(null);
  const [benchmarkButtons, setBenchmarkButtons] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState("avg_score");
  const [graciousLoading, setGraciousLoading] = useState(false);
  const [period, setPeriod] = useState({
    id: "all_time",
    start_date: new Date(2000, 0, 1),
    end_date: now,
    label: intl.formatMessage({ id: "benchmark.all_time" }),
  });

  const [surveyId, setSurveyId] = useState(
    isNaN(parseInt(localStorage.getItem("selectedSurvey"))) ? null : parseInt(localStorage.getItem("selectedSurvey")),
  );
  const [selectedDimension, setSelectedDimension] = useState(null)
  const [collapsed, setCollapsed] = useState(true)

  const getBenchmarkDataSingleSurvey = async () => {
    if (!surveyId) return;
    setError(null)
    try {
      const locale = localStorage.getItem("language_locale");
      const responseSingleSurvety = await axios.post(
        `${rootUrl}/api/v1/benchmark/bar-chart-single-survey`,
        {
          survey_id: surveyId,
          locale,
          period,
        },
      );

      bmData = responseSingleSurvety.data.data;
      !selectedDimension && setBenchmarkData(bmData);
      (surveyName !== responseSingleSurvety.data.surveyName) && setSurveyName(responseSingleSurvety.data.surveyName);
      (clusterName !== responseSingleSurvety.data.clusterName) && setClusterName(responseSingleSurvety.data.clusterName);
    } catch (err) {
      console.log(err);
      setError(err.message);
    } finally {
      setDimensionLevelLoading(false);
      setGraciousLoading(false);
    }
  };

  const getBenchmarkDataQuestionLevel = async () => {
    setError(null);
    if (!surveyId) return;
    try {
      const locale = localStorage.getItem("language_locale");
      const responseSingleSurvey = await axios.post(
        `${rootUrl}/api/v1/benchmark/question-level`,
        {
          survey_id: surveyId,
          locale,
          period,
        },
      );

      const dimensions = responseSingleSurvey.data.data.reduce((acc, x) => {
        if (!acc.find(d => d.dimension === x.dimension)) {
          acc.push({dimension: x.dimension, dimension_id: x.dimension_id});
        }
        return acc;
      }, [])

      bmDataQuestionLevel = responseSingleSurvey.data.data
      setBenchmarkButtons([...dimensions].concat({dimension: intl.formatMessage({ id: "benchmark.show_all_dimensions" }), dimension_id: null}))
      selectedDimension && setBenchmarkData(bmDataQuestionLevel.filter(x => x.dimension_id === selectedDimension))
    } catch (err) {
      console.log(err);
    } finally {
      setQuestionLevelLoading(false);
      setGraciousLoading(false);
    }
  };

  const renderButtons = () => {
    const now = new Date();

    const periods = [
      {
        id: "all_time",
        start_date: new Date(2000, 0, 1),
        end_date: now,
        label: intl.formatMessage({ id: "benchmark.all_time" }),
      },
      {
        id: "last_12_months",
        start_date: new Date(now.getFullYear(), now.getMonth() - 11, 1),
        end_date: now,
        label: intl.formatMessage({ id: "benchmark.last_12_months" }),
      },
      {
        id: "ytd",
        start_date: new Date(now.getFullYear(), 0, 1),
        end_date: now,
        label: intl.formatMessage({ id: "benchmark.ytd" }),
      },
      {
        id: "prev_year",
        start_date: new Date(now.getFullYear() - 1, 0, 1),
        end_date: new Date(now.getFullYear() - 1, 11, 31),
        label: String(now.getFullYear() - 1),
      },
      {
        id: "prev_prev_year",
        start_date: new Date(now.getFullYear() - 2, 0, 1),
        end_date: new Date(now.getFullYear() - 2, 11, 31),
        label: String(now.getFullYear() - 2),
      },
    ];
    return (
      <div className="btn-group mr-2" role="group" aria-label="First group">
        {(() => {
          return periods.map((p) => (
            <Button
              key={p.id}
              variant={p.id === period.id ? "primary" : "secondary"}
              disabled={graciousLoading}
              onClick={() => handleTimePeriodChange(p)}
            >
              {p.label}
            </Button>
          ));
        })()}
      </div>
    );
  };

  const handleTimePeriodChange = (period) => {
    setGraciousLoading(true);
    setPeriod(period);
  };

  const handleFilterClick = (bars, e) => {
    if(questionLevelLoading) {
      return
    }
    try {
      const index = bars[0]._index
      const dimension_id = bars[0]._chart.config.data.datasets[0].dimension_ids[index];
      handleDimensionChange(dimension_id)

    } catch (e) {
      return
    }
  
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {intl.formatMessage({ id: "benchmark.qi_score_tooltip" })}
    </Tooltip>
  );

  const handleDimensionChange = (dimension_id) => {
    setSelectedDimension(dimension_id)
    dimension_id && setBenchmarkData(bmDataQuestionLevel.filter(x => x.dimension_id === dimension_id))
    !dimension_id && setBenchmarkData(bmData)
  }

  const handleMetricChange = (metric) => {
    setSelectedMetric(metric);
  };

  useEffect(() => {
    getBenchmarkDataSingleSurvey();
    getBenchmarkDataQuestionLevel();
  }, [surveyId, period]);

  const handleSurveyChange = (id) => {
    setDimensionLevelLoading(true);
    setQuestionLevelLoading(true);
    setSurveyId(id);
    setError(null)
  };

  const handleExportToExcel = () => {
    // Create a workbook
    const wb = XLSX.utils.book_new();

    // Add header information sheet
    const headerData = [
      ['Survey', surveyName || ''],
      ['Cluster', clusterName || ''],
      ['Period', period.label],
      ['Export Date', new Date().toLocaleDateString()],
      [], // Empty row for spacing
    ];
    const wsHeader = XLSX.utils.aoa_to_sheet(headerData);
    XLSX.utils.book_append_sheet(wb, wsHeader, "Info");

    // Prepare benchmark data for export
    const exportData = benchmarkData.map(item => ({
      'Dimension': item.dimension,
      'Label': item.label,
      'Survey QI Score': item.survey_qi_score,
      'Cluster QI Score': item.cluster_qi_score,
      'Survey QI Score CI': item.survey_confidence_interval_qi_score,
      'Cluster QI Score CI': item.cluster_confidence_interval_qi_score,
      'Survey Average Score': item.survey_avg_score,
      'Cluster Average Score': item.cluster_avg_score,
      'Survey Average Score CI': item.survey_confidence_interval_avg_score,
      'Cluster Average Score CI': item.cluster_confidence_interval_avg_score,
      'Survey': surveyName || '',
      'Cluster': clusterName || ''
    }));

    // Create data worksheet
    const wsData = XLSX.utils.json_to_sheet(exportData);
    XLSX.utils.book_append_sheet(wb, wsData, "Benchmark Data");

    // Generate filename with survey and cluster
    const fileName = `benchmark_${surveyName || 'survey'}_${clusterName || 'cluster'}_${new Date().toISOString().split('T')[0]}.xlsx`;

    // Save file
    XLSX.writeFile(wb, fileName);
  };

  return (
    <DashboardFrame
      onSurveyChanged={handleSurveyChange}
      title="Dashboard"
      dashboardTitle={intl.formatMessage({ id: "benchmark.benchmark" })}
      onLanguageChanged={onLanguageChanged}
      {...props}
    >
      <div>
        {!dimensionLevelLoading && <div className="benchmark-card slide-up">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="header-title pull-left benchmark-title">
              <FormattedMessage
                id="benchmark.info_title"
                defaultMessage="Benchmark informatie"
              />
            </h4>
            <button 
              className="btn btn-link"
              style={{marginTop: -5}}
              onClick={() => setCollapsed(!collapsed)}
            >
              {collapsed ? (
                <i className="fas fa-chevron-down" />
              ) : (
                <i className="fas fa-chevron-up" />
              )}
            </button>
          </div>
          
          
          { !collapsed && 
          <div>
  

            <p className="cluster-label">
            
          <span
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "benchmark.info" }),
            }}
          />
        </p></div>
          }

          
        </div>}

        {dimensionLevelLoading && surveyId && (
          <div className="holder-spinner">
            <LoadingSpinner />
          </div>
        )}
        {dimensionLevelLoading && !surveyId && (
          <div className="holder-spinner">{intl.formatMessage({ id: "benchmark.select_a_survey" })}</div>
        )}

        {error && (
          <div className="benchmark-card error">
            {intl.formatMessage({ id: "benchmark.error_message" })}: {error}
          </div>
        )}

        {!dimensionLevelLoading && (
          <div className="benchmark-card slide-up">
            <div
              className="btn-toolbar"
              role="toolbar"
              aria-label="Toolbar with button groups"
              style={{ marginBottom: 10 }}
            >
              {renderButtons()}

              <div
                className="btn-group mr-2"
                role="group"
                aria-label="Second group"
                style={{ marginLeft: 20 }}
              >
                {[
                  {
                    key: "qi_score",
                    text: intl.formatMessage({ id: "benchmark.qi_score" }),
                  },
                  {
                    key: "avg_score",
                    text: intl.formatMessage({ id: "benchmark.average_score" }),
                  },
                ].map((x, i) => {
                  if (x.key === 'avg_score') {
                    return (
                      <Button
                        key={i}
                        variant={
                          x.key === selectedMetric ? "primary" : "secondary"
                        }
                        disabled={graciousLoading}
                        onClick={() => handleMetricChange(x.key)}
                  
                      >
                        {x.text}
                      </Button>
                    );
                  } else {
                    return (
                      <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <Button
                        key={i}
                        variant={
                          x.key === selectedMetric ? "primary" : "secondary"
                        }
                        disabled={graciousLoading}
                        onClick={() => handleMetricChange(x.key)}
  
                      >
                        {x.text}
                      </Button>
                    </OverlayTrigger>
                    );
                  }
                })}
              </div>
              <div
                className="btn-group mr-2"
                role="group"
                aria-label="Help group"
                style={{ margin: 8 }}
              >
                <Button
                  variant="link"
                  className="p-0 d-flex align-items-center"
                  onClick={() => window.open('/benchmark-explanation', '_blank')}
                  title={intl.formatMessage({ id: "benchmark_explanation.title" })}
                >
                  <i className="fas fa-question-circle" style={{ fontSize: '1.2rem', color: '#6c757d' }}></i>
                </Button>
              </div>

              <div
                className="btn-group mr-2"
                role="group"
                aria-label="Second group"
                style={{ marginLeft: 20 }}
              >
            <Dropdown
              placeholder={intl.formatMessage({id: "benchmark.select_dimension"})}
              selection
              value={selectedDimension}
              style={{marginTop: -2}}
              disabled={questionLevelLoading}
              onChange={(e, {value}) => handleDimensionChange(value)}
              options={benchmarkButtons.map((item, index) => {
                return {
                  key: index,
                  text: item.dimension,
                  value: item.dimension_id
                }
              })}
            />
          </div>

              <div
                className="btn-group mr-2"
                role="group"
                aria-label="Export group"
                style={{ marginLeft: 20 }}
              >
                <Button
                  variant="secondary"
                  disabled={graciousLoading || benchmarkData.length === 0}
                  onClick={handleExportToExcel}
                >
                  <i className="fas fa-file-excel mr-2" style={{marginRight: 5}}></i>
                  {intl.formatMessage({ id: "benchmark.export_to_excel" })}
                </Button>
              </div>

              {graciousLoading && (
                <div
                  style={{
                    display: "inline-block",
                    width: "20px",
                    height: "20px",
                    marginLeft: "10px",
                    marginTop: "8px",
                    border: "3px solid #f3f3f3",
                    borderTop: "3px solid #3498db",
                    borderRadius: "50%",
                    animation: "spin 1s linear infinite",
                  }}
                >
                  <style>
                    {`
                    @keyframes spin {
                      0% { transform: rotate(0deg); }
                      100% { transform: rotate(360deg); }
                    }
                  `}
                  </style>
                </div>
              )}
            </div>

            {!dimensionLevelLoading && !error && benchmarkData.length > 0 && (
              <BarChartWithErrorBars
                selectedDimension={selectedDimension}
                labels={benchmarkData.map((x) => x.label)}
                datasets={benchmarkData}
                surveyName={surveyName}
                clusterName={clusterName}
                selectedMetric={selectedMetric}
                onFilterClick={handleFilterClick}
                maximalScoreLabel={intl.formatMessage({ id: "benchmark.maximal_score" })}
                negativeLabel={intl.formatMessage({ id: "benchmark.negative" })}
                positiveLabel={intl.formatMessage({ id: "benchmark.positive" })}
              />
            )}

            {!dimensionLevelLoading &&
              !error &&
              benchmarkData.length === 0 &&
              intl.formatMessage({ id: "benchmark.no_data_available" })}
          </div>
        )}
      </div>
    </DashboardFrame>
  );
};

BenchmarkBarChart.propTypes = {
  intl: PropTypes.object.isRequired,
  onLanguageChanged: PropTypes.func.isRequired,
};

export default withRouter(injectIntl(BenchmarkBarChart));
