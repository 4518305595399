import React, { Component } from 'react'
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import LoadingSpinner from "../../Miscellaneous/LoadingSpinner/LoadingSpinner"
import {Form} from "react-bootstrap"
import './ExportBenchmark.css'
import axios from 'axios'
import {rootUrl} from '../../../config'
import {FormattedMessage, injectIntl} from 'react-intl'
import XLSX from 'xlsx'

class ExportBenchmark extends Component {
  constructor(props) {
    super(props)
    console.log(props)

    this.state = {
      openingText: "",
      loading: false
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    return [year, month, day].join('-')
  }

  stringToDate(str) {
    var parts = str.split('-')
    var d = new Date(parts[0], parts[1] - 1, parts[2])
    return d
  }

  handleExcelExport(timedata) {
      console.log(timedata)
      let surveyId = localStorage.getItem('selectedSurvey')

      if (!(surveyId)) {
        return console.error("Not possible to export pdf, no survey id or name available")
      }

      this.setState({
        loading: false
      }, () => {
        let locale = localStorage.getItem("language_locale");

        axios.post(rootUrl + '/api/v1/excel', {surveyId: surveyId, locale: locale})
          .then(res => {
            var worksheet = XLSX.utils.aoa_to_sheet(res.data);
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, worksheet, "Export");

            /* write workbook (use type 'binary') */
            var wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'});

            /* generate a download */
            function s2ab(s) {
              var buf = new ArrayBuffer(s.length);
              var view = new Uint8Array(buf);
              for (var i=0; i!==s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
              return buf;
            }

            let blob = new Blob([s2ab(wbout)],{type:"application/octet-stream"})

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'export.xlsx'
            link.click();
            this.setState({
              loading:false
            })
          })
          .catch(e => {
            console.log(e)
            this.setState({
              loading:false
            })
          })
      })
  }

  render() {
    return (
      <PopupDialog
        title={this.props.intl.formatMessage({id: "export.dialog_title"})}
        onClose={() => this.props.onClose()}
      >
        {this.state.loading &&
          <LoadingSpinner className={"modal-loading"} />
        }

        <br/>
        <FormattedMessage id={"export.info"} defaultMessage={"U heeft hier de mogelijkheid een openingstekst in het geëxporteerde document te plaatsen. Dit is optioneel."} />

        <Form.Group className={"m-t-20"}>
          <Form.Label><FormattedMessage id={"export.dialog_title"} defaultMessage={"Openingstekst"} /></Form.Label>
          <Form.Control
            className={"form-control"}
            onChange={(e) => this.setState({openingText: e.target.value})}
            value={this.state.openingText}
            as={"textarea"}
            rows={5}
          />
        </Form.Group>

        <button className="btn btn-primary" onClick={() => this.handleExcelExport()}>
          <i className="fa fa-arrow-alt-circle-down"/> <FormattedMessage id={"export.generate_export_excel"} defaultMessage={"Export genereren XLSX"} />
        </button>

        <button className="btn btn-secondary m-l-5" onClick={()=> this.props.onClose()}> <FormattedMessage id={"ui.cancel"} /></button>
      </PopupDialog>
    )
  }
}

export default injectIntl(ExportBenchmark)
