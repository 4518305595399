import React, { Component } from 'react'
import axios from "axios"
import ReactHtmlParser from 'react-html-parser'
import {rootUrl} from '../../../config'
import './InformationPage.css'
import {injectIntl, FormattedMessage} from "react-intl"

class InformationPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      content: ""
    }

    this.getPageContent()
  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i') // fragment locator
    return !!pattern.test(str);
  }

  getPageContent() {
    let url = rootUrl + "/api/v1/survey/get_" + this.props.type + "_message/" + this.props.uuid + '/' + this.props.locale
    axios.post(url
    ).then((res) => {
      this.setState({
        content: res.data
      }, () => {
        if (this.validURL(this.state.content)) {
          if (this.state.content.startsWith("http")) {
            window.location.replace(this.state.content)
          } else {
            window.location.replace("http://" + this.state.content)
          }
        }
      })
    }).catch((error) => {
      console.log(error)
    })
  }

  render() {
    return (
      <div style={{width: "100%"}}>
        {this.props.logo && <><img alt="" src={this.props.logo} className={"survey-logo"} /><br /></>}
        <h4 className={"col-md-10 header m-b-30"  + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " arabic-text" : "")}>
          {((this.props.uuid === 'feb8b895-698e-41ed-b6d6-55e49c1c3dac' && this.props.type === "begin") ? <FormattedMessage id={"survey.start_of_survey_pivot_park"} defaultMessage={"Inleiding klanttevredenheidsonderzoek"} /> : (this.props.type === 'begin' && <FormattedMessage id={"survey.start_of_survey"} defaultMessage={"Begin van onderzoek"} />)) ||
          (this.props.type === "end" && <FormattedMessage id={"survey.end_of_survey"} defaultMessage={"Einde van onderzoek"} />)}
        </h4>
        <div className={"component-holder"  + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " arabic-text" : "")}>
          {!this.validURL(this.state.content) && ReactHtmlParser(this.state.content)}
          {this.validURL(this.state.content) && <span><FormattedMessage id={"survey.redirect_warning"} /></span>}
          {(this.props.type === "begin")
            ? <><br /><br /><FormattedMessage id={"survey.start_message"} /></>
            : <><br /><br /><FormattedMessage id={"survey.end_message"} /></>}
        </div>
      </div>
    )
  }
}

export default injectIntl(InformationPage)
