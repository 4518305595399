import React, { Component } from 'react'
import './Privacy.css'

class Privacy extends Component {
  render() {
    return (
      <div className="survey">
        <div className={"survey-main-top"} style={{backgroundColor: "#3B699C"}}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-xs-12 main-title"}>
                <h1 className={"header header1"}>
                  Privacy statement
                </h1>
                <p>LiveResearch survey application</p>
              </div>
            </div>
          </div>
        </div>

        <div className={"container survey-container"}>
          <div className={"survey-panel"}>
            <div className="survey-top" />
            <div className="survey-content privacy-content">
              <div className={"row"}>
                {/* Connect SB BV Operating under the name of LiveResearch respects the privacy of its clients, in particular their rights regarding the automatic processing of personal & suvey data. We have therefore formulated and implemented a policy on complete transparency with our customers with regard to the processing of personal data, its purpose(s) and the possibilities to exercise your legal rights in the best possible way.<br /><br />

                If you require any additional information about the protection of personal data, please visit the website of the Dutch Data Protection Authority (Autoriteit Persoonsgegevens): <a href={"https://autoriteitpersoonsgegevens.nl/nl"}>https://autoriteitpersoonsgegevens.nl/nl</a>.<br /><br /><br />

                <h4>Article 1 - Legal definition</h4>
                Party responsible for processing personal data (hereinafter: "the controller"): Connect SB BV, established in Hanzeweg 14, 2803 MC Gouda, The Netherlands, Chamber of Commerce number: 27325577.<br /><br /><br />


                <h4>Article 2 - The processing of personal data</h4>
                <ol className={"fix-ordered-list-style"}>
                  <li>Personal data means any information relating to an identified or identifiable natural person (‘data subject’).</li>
                  <li>An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</li>
                    <li>We process the following categories of personal data from you:
                      <ul className={"fix-unordered-list-style m-l-20"}>
                        <li>Survey data</li>
                        <li>email address for invitation purposes only</li>
                      </ul>
                    </li>
                    <li>Collected survey data cannot be traced back to an individual natural person</li>
                </ol><br /><br /><br />

                <h4>Article 3 - Purpose of processing</h4>
                We do not collect your personal information without a purpose. Your personal data are processed for a better service to you.<br /><br /><br />

                <h4>Article 4 - Registration of personal data</h4>
                Your personal data are recorded in an (electronic) register.<br /><br /><br />

                <h4>Article 5 - Your rights regarding information</h4>
                <ol className={"fix-ordered-list-style"}>
                  <li>Pursuant to Article 13 paragraph 2 sub b GDPR each data subject has the right to information on and access to, rectification, erasure and restriction of processing of his personal data, as well as the right to object to the processing and the right to data portability.</li>
                  <li>You can exercise these rights by contacting us at paul.brinkhof@weconnect.nl.</li>
                  <li>Each request must be accompanied by a copy of a valid ID, on which you put your signature and state the address where we can contact you.</li>
                  <li>Within one month of the submitted request, you will receive an answer from us.</li>
                  <li>Depending on the complexity and the number of the requests this period may be extended to two months.</li>
                </ol><br />

                <h4>Article 6 - Legal obligations</h4>
                In case of infringement of any law or regulation, of which a visitor is suspected and for which the authorities require the personal data collected by the collector, they will be provided to them after an explicit and reasoned request of those authorities, after which these personal data do not fall anymore under the protection of the provisions of this Privacy policy.<br /><br /><br />

                <h4>Article 7 - Commercial offers</h4>
                <ol className={"fix-ordered-list-style"}>
                  <li>You may receive commercial offers from the collector. If you do not wish to receive them (anymore), please send us an email to the following address: paul.brinkhof@weconnect.nl.</li>
                  <li>Your personal data will not be used by our partners for commercial purposes.</li>
                </ol><br /><br /><br />

                <h4>Article 8 - Data retention</h4>
                The collected data are used and retained for the duration determined by law.<br /><br /><br />

                <h4>Article 9 - Applicable Law</h4>
                These conditions are governed by Dutch law. The court in the district where the collector has its place of business has the sole jurisdiction if any dispute regarding these conditions may arise, save when a legal exception applies.<br /><br /><br />


                <h4>Article 10 - Contact</h4>
                For requests, questions, product information or more information, please contact: Paul Brinkhof, paul.brinkhof@weconnect.nl.<br /><br />

                This privacy statement applies from 03 december 2020 on until further notice. */}

                <i>Last updated: 12-1-2022</i><br/>

                You’ve received a request to complete the Patient-Centredness Questionnaire Infertility
                questionnaire (“Questionnaire”). Your answers to the Questionnaire help us measure in a structured
                scientific manner whether and to what extent your fertility clinic was centred on your needs a
                patient. Your clinic may have invited you to complete the Questionnaire by presenting you with a
                printed card with a QR-code to the online Questionnaire, or you may have received an invite with a
                link to the online Questionnaire via e-mail. This privacy statement explains whether we process any
                personal data in connection with the Questionnaire, and if so, what personal data and why.<br /><br />

                This privacy statement is an addendum to the privacy statement of the fertility clinic (hereinafter:
                “Clinic”) where you’re being treated. If you would like to read the complete privacy statement of
                your Clinic, please visit its website. You can most probably find it in the footer of the homepage of its
                website. The complete privacy statement also contains the more generic information that the Clinic
                needs to provide you under applicable data protection laws.<br /><br />

                The Clinic acts as the “controller” for all personal data that is processed in connection with the Questionnaire 
                and is your contact point regarding that processing. “We” or “us” as used below is a reference to the Clinic.<br /><br />

                <h6>What personal data do we process and why?</h6>
                Your answers to the Questionnaire are provided anonymously to us. We do not and cannot connect your name, nor any other data 
                that we can use to identify you, with the answers that you provide to the questions in the Questionnaire. This statement is substantiated 
                in more detail below.<br/><br/>

                <i>If you access the Questionnaire via a QR-code</i>

                The card that you received contains a QR-code which will lead you to a generic Questionnaire link. When you click on the link you can 
                complete the Questionnaire. We do not retain any log data that can be used to identify any person accessing the link at a specific date and time.<br/><br/>

                <i>If you access the Questionnaire via e-mail</i>

                The e-mail invite that you received contains a link which will lead you to the generic Questionnaire. When you click on the link you can complete the Questionnaire. As with the card, we do not retain any log data that can be used to identify any person accessing the link at a specific date and time.
                We do process your e-mail address to send you the e-mail invite. To be able to send the e-mail invite, we will upload your e-mail address onto the LiveResearch platform. The LiveResearch platform is a platform that is provided to us by “Connect SB B.V.”. From that platform, we can send e-mails containing the generic Questionnaire link. Note that, no traffic is tracked to this link. 
                Your e-mail address is health data as it identifies the fact that you are a patient of a fertility clinic. As you have provided us with your e-mail address in order to receive the link, we presume that you consent to our processing of your e-mail address for sending you the link and as further described in this statement. <br /><br />
                
                <h6>How long do we retain your personal data?</h6>
                All e-mail addresses that were uploaded to the LiveResearch platform are automatically deleted direct after the e-mail invite has been sent. 
                The collected survey data will be retained. However, this is not personal data as we have no way to link it to you.<br /><br />

                <h6>Cookies?</h6>
                The LiveResearch platform uses session cookies. A non-traceable code (token) will be placed temporarily on your device’s working memory when you start the Questionnaire. The token disappears as soon as you finish the Questionnaire. In other words, when you’re filling in the Questionnaire, the token tracks where you’re at. But only for that time-being.<br /><br />
               
                <h6>Do we share your personal data?</h6>
                We do not share your personal data. The anonymous survey data can be accessed by us via a survey dashboard and a benchmark tool on the LiveResearch platform. Through the survey dashboard we can benchmark our survey results with other fertility clinics in our region. This anonymous data can and may be processed in scientific research, which may lead to publication on this subject - the satisfaction of fertility patients with their practitioners and the treatment clinic.
                The LiveResearch platform is a product from supplier Connect SB B.V. with whom we have signed a Data Processing Agreement. Connect SB hosts the platform at an independent certified hosting provider located in the Netherlands: True Hosting, which is part of Broad Horizon. Broad Horizon is certified according ISO 9001, ISO 27001 and ISO 7510-081.<br/><br />

                <h6>Do we share your data outside the European Union?</h6><br />
                <p>No, we do not share collected data outside the European Union. All the collected data is hosted in the Netherlands.<p style={{"color":"white"}}>working memory when you start the Questionnaire. The token disappears as soon as you finish the Questionnaire. In other words, when you’re filling in the Questionnaire, the token tracks where you’re at. But only for that time-being.</p></p>
               
                {/* <h6>Questions?</h6> */}
                <i>In case you have questions about this privacy statement (or other privacy related questions), please contact your Clinic.<p style={{"color":"white"}}>working memory when you start the Questionnaire. The token disappears as soon as you finish the Questionnaire. In other words, when you’re filling in the Questionnaire, the token tracks where you’re at. But only for that time-being.</p></i>
  
              </div>
            </div>

            <div className="survey-bottom" />
          </div>
        </div>
      </div>
    )
  }
}

export default Privacy
