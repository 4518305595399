import React, { Component } from 'react'
import Page from '../Page/Page'
import InformationPage from '../InformationPage/InformationPage'
import './Survey.css'
import {Progress} from 'semantic-ui-react'
import Cookies from 'universal-cookie'
import axios from "axios"
import {rootUrl} from '../../../config'
import {injectIntl, FormattedMessage} from 'react-intl'

class Survey extends Component {
  constructor(props) {
    super(props)
    document.body.style.backgroundColor = "#fff"

    const cookies = new Cookies()
    const params = this.props.match.params

    let locale = "nl"
    if (window.location.href.includes("vragenlijst")) {
      locale = "nl"
    } else if (window.location.href.includes("fragebogen")) {
      locale = "de"
    } else if (window.location.href.includes("survey")) {
      locale = "en"
    } else if (window.location.href.includes("cuestionario")) {
      locale = "es"
    } else if (window.location.href.includes("questionnaire")) {
      locale = "fr"
    } else if (window.location.href.includes("questionario")) {
      locale = "it"
    } else if (window.location.href.includes("ankiety")) {
      locale = "pl"
    } else if (window.location.href.includes("anketa")) {
      locale = "ru"
    } else if (window.location.href.includes("frageformularet")) {
      locale = "se"
    } else if (window.location.href.includes("anketin")) {
      locale = "tr"
    } else if (window.location.href.includes("aistibyan")) {
      locale = "ar"
    }

    this.state = {
      name: "",
      uuid: params.id,
      pages: [],
      currentPageIndex: -1, // 0 = Start page, pages.length + 1 = End page
      token: cookies.get(params.id),
      pageValid: true,
      pageAnswers: [],
      color: "#3b699c",
      logoBase64: null,
      routeToPage: null,
      lastVisitedPage: 0,
      loading: false,
      routeToUrl: null,
      locale: locale
    }

    this.checkSurveyReferral(this.state.uuid)
    this.getSurveyName(this.state.uuid)
    this.getSurveyPages(this.state.uuid)
    this.getSurveyColor(this.state.uuid)
    this.getSurveyLogo(this.state.uuid)

    this.surveyTop = React.createRef()
    this.forwardButton = React.createRef()
  }

  componentDidMount() {
    this.props.onLanguageChanged(this.state.locale)
  }

  scrollToTop() {
    this.surveyTop.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })
  }

  scrollToBottom() {
    this.forwardButton.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })
  }

  // Sets token for saving the respondents answers under the same id in the database.
  setToken(uuid) {
    const cookies = new Cookies()

    let tokenExpiration = new Date() // Gets the current datetime
    tokenExpiration.setHours(tokenExpiration.getHours() + 4) // Add 4 hours to the current datetime

    axios.post(rootUrl + '/api/v1/respondent/get_respondent_token/' + uuid + '/' + this.state.locale
    ).then((res) => {
      this.setState({
        token: res.data
      }, () => {
        cookies.set(uuid, this.state.token, { path: "/survey", expires: tokenExpiration })
      })
    }).catch((error) => {
      console.log(error)
    })
  }

  // Validate the current page. This means that all questions have been answered.
  validatePage(valid, answers) {
    this.setState({
      pageValid: valid,
      answers: answers
    })
  }

  submitAnswers(callback) {
    if (this.state.currentPageIndex === 0 || window.location.pathname.endsWith("preview")) {
      callback()
      return
    }

    if (this.state.answers.length > 0) {
      this.setState({loading: true})
      const data = {
        token: this.state.token,
        page: this.state.pages[this.state.currentPageIndex - 1].position,
        answers: this.state.answers
      }

      axios.post(rootUrl + '/api/v1/respondent/save_page_response/', data
      ).then(() => {
        callback()
      }).catch((error) => {
        console.log(error)
        callback()
      })
    } else {
      callback()
    }
  }

  //TODO Referrals fixen in db!!
  //TODO link referrals in survey overzicht aanpassen naar enkel UUID
  checkSurveyReferral(uuid) {
    axios.get(rootUrl + '/api/v1/survey/check_referral/' + uuid)
      .then(res => {
        if(res.data.redirect_to) {
          window.location = '/language-selection/' + res.data.redirect_to
        }
        if(res.data.status === 2 && res.data.redirect_to == null) {
          this.setState({
            name: "Survey not active. Please check provider.",
            surveyNotFound: true
          })
        }
      })
      .catch(e => {
        console.log(e)
      })
  }


  // Load survey title in state.
  getSurveyName(uuid) {
    axios.post(rootUrl + '/api/v1/survey/get_survey_name/' + uuid + '/' + this.state.locale
    ).then((res) => {
      this.setState({
        name: res.data
      })
    }).catch((error) => {
      this.setState({
        name: "404: Onderzoek niet gevonden",
        surveyNotFound: true
      }, () => {
        let url = "https://dashboard.liveresearch.com/language-selection/" + this.state.uuid
        if (window.location.pathname.endsWith("preview")) {
          url += "/preview"
        }
        window.location = url
      })
      console.log(error)
    })
  }

  // Load survey color in state
  getSurveyColor(uuid) {
    axios.get(rootUrl + '/api/v1/survey/get_survey_color/' + uuid
    ).then((res) => {
      this.setState({
        color: res.data
      })
    }).catch((error) => {
      console.log(error)
    })
  }

  // Load survey pages in state.
  getSurveyPages(uuid) {
    axios.post(rootUrl + '/api/v1/survey/get_translated_survey_pages', {
      id: uuid,
      locale: this.state.locale
    }
    ).then((res) => {
      this.setState({
        pages: res.data,
        currentPageIndex: 0
      }, () => {
        this.loadPage()
      })
    }).catch((error) => {
      console.log(error)
    })
  }

  getSurveyLogo(uuid) {
    axios.get(rootUrl + '/api/v1/survey/get_survey_logo/' + uuid)
      .then((res) => {
        this.setState({
          logoBase64: res.data
        })
      })
      .catch(() => {
        console.log("Survey logo not found.")
      })
  }

  // Load big title of survey.
  loadName() {
    return this.state.name
  }

  // Load page component with new settings
  loadPage() {
    // Key is necessary to show React that a new component is loaded.
    if (this.state.currentPageIndex === 0) {
      // Show start page
      return <InformationPage
                logo={this.state.logoBase64}
                type={"begin"}
                uuid={this.state.uuid}
                locale={this.state.locale}
              />
    } else if (this.state.currentPageIndex === this.state.pages.length + 1) {
      // Show end page
      return <InformationPage
                logo={this.state.logoBase64}
                type={"end"}
                uuid={this.state.uuid}
                locale={this.state.locale}
              />
    } else if (this.state.currentPageIndex > 0 && this.state.currentPageIndex < this.state.pages.length + 1) {
      return <Page
                key={this.state.currentPageIndex}
                pageIndex={this.state.currentPageIndex}
                uuid={this.state.uuid}
                loading={this.state.loading}
                validatePage={this.validatePage.bind(this)}
                answers={this.state.pageAnswers[this.state.currentPageIndex - 1]}
                scrollToTop={() => this.scrollToTop()}
                scrollToBottom={() => this.scrollToBottom()}
                onRoutingChanged={(nextPage) => this.onRoutingChanged(nextPage)}
                locale={this.state.locale}
              />
    } else {
      return false
    }
  }

  onRoutingChanged(nextPage) {
    if(typeof nextPage === 'string' && nextPage.length > 5) {
      this.setState({
        routeToUrl: nextPage
      })
    }
    if (nextPage === this.state.currentPageIndex + 1) {
      this.setState({
        routeToPage: null
      })
    } else {
      this.setState({
        routeToPage: nextPage
      })
    }
  }

  // Go page back.
  goBackward() {
    // if (this.state.currentPageIndex >= 1) {
      this.scrollToTop()
      this.setState({
        // currentPageIndex: this.state.currentPageIndex - 1,
        currentPageIndex: this.state.lastVisitedPage,
        pageValid: true,
        answers: this.state.pageAnswers[this.state.currentPageIndex - 2]
      })
    // }
  }

  // Go page forward.
  goForward() {
    // console.log(this.state.currentPageIndex)
    this.setState({
      lastVisitedPage: this.state.currentPageIndex
    })
    // console.log(this.state.lastVisitedPage)

    if (typeof this.state.token === "undefined"
      && !window.location.pathname.endsWith("preview")) {
      this.setToken(this.state.uuid)
    }

    // Save answers to current page in pageAnswers.
    if (this.state.currentPageIndex !== 0) {
      let tempPageAnswers = this.state.pageAnswers

      if (this.state.currentPageIndex > this.state.pageAnswers.length) {
        tempPageAnswers.push(this.state.answers)
      } else {
        tempPageAnswers[this.state.currentPageIndex - 1] = this.state.answers
      }

      this.setState({
        pageAnswers: tempPageAnswers
      })
    }

    // Increment currentPageIndex and make the new page invalid by default.
    if (this.state.currentPageIndex !== this.state.pages.length + 1) {
      this.setState({
        loading: true
      }, () => {
        this.submitAnswers(() => {
          let nextPageIndex = this.state.currentPageIndex + 1
          if (this.state.routeToPage !== null) {
            nextPageIndex = this.state.routeToPage
          }

          if (this.state.routeToUrl !== null) {
            window.open(this.state.routeToUrl,"_self")
          }

          this.setState({
            currentPageIndex: nextPageIndex,
            pageValid: false,
            loading: false,
            routeToPage: null
          }, () => {
            this.scrollToTop()
          })
        })
      })
    }
  }

  // Get subtitle of page.
  getPageTitle() {
    console.log(this.state.currentPageIndex < this.state.pages.length + 1)
    return this.state.currentPageIndex > 0
      && this.state.currentPageIndex < this.state.pages.length + 1
      && this.state.pages[this.state.currentPageIndex - 1].title 
  }

  render() {
    return (
      <div className="survey">
        <div className={"survey-main-top"} style={{backgroundColor: this.state.color}} ref={this.surveyTop}>
          <div className={"container"}>
            <div className={"row"}>
              {
                this.loadName().length > 20
                ?
                  <div className={"col-xs-12 main-title"}>
                    {(this.state.currentPageIndex === 0 || this.state.currentPageIndex === this.state.pages.length + 1)
                      ? <h3 className={"header"} style={{marginTop: 60, lineHeight: '30px'}}>{this.loadName()}</h3>
                      : <h3 className={"header"} style={{marginTop: 60, lineHeight: '30px'}}>{this.getPageTitle()}</h3>
                    }
                    <p>{this.state.currentPageIndex !== 0 && this.state.currentPageIndex !== this.state.pages.length + 1 && this.loadName()}</p>
                  </div>
                  :
                  <div className={"col-xs-12 main-title"}>
                    <h1 className={"header header1"}>
                      {(this.state.currentPageIndex === 0 || this.state.currentPageIndex === this.state.pages.length + 1)
                        ? this.loadName()
                        : this.getPageTitle()}</h1>
                    <p>{this.state.currentPageIndex !== 0 && this.state.currentPageIndex !== this.state.pages.length + 1 && this.loadName()}</p>
                  </div>
                  }
            </div>
          </div>
        </div>

        {!this.state.surveyNotFound &&
        <div className={"container survey-container"}>
          <div className={"survey-panel"}>
            <div className="survey-top">
              {this.state.currentPageIndex > 0
                && this.state.currentPageIndex < this.state.pages.length + 1
                && this.state.pages.length > 1
                && <Progress value={this.state.currentPageIndex} total={this.state.pages.length} progress='ratio' />}
            </div>

            <div className="survey-content">
              <div className={"row"}>
                {this.loadPage()}
                {this.state.currentPageIndex === this.state.pages.length
                  && <div className={"full-width component-holder last-page-message" + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " arabic-text" : "")}>
                        <FormattedMessage id={"survey.last_page_warning"} defaultMessage={"Dit is de laatste pagina van dit onderzoek. Als u op &apos;volgende&apos; klikt worden de antwoorden verstuurd."} />
                     </div>}
              </div>
            </div>

            <div className="survey-bottom">
              {this.state.currentPageIndex > 0 && !(this.state.currentPageIndex === (this.state.pages.length + 1)) && 
              
                <>
                  <button
                    name={"backward"}
                    className={"backward"}
                    onClick={() => this.goBackward()}
                    disabled={this.state.currentPageIndex === 0 || this.state.currentPageIndex === this.state.pages.length + 1}
                  >
                    <FormattedMessage id={"survey.previous"} defaultMessage={"Vorige"} />
                  </button>
                  <div className={"button-divider"} />
                </>
              }
              
              {this.state.currentPageIndex !== this.state.pages.length + 1 &&
                <>
                  <button
                    name={"forward"}
                    className={"forward"}
                    onClick={() => this.goForward()}
                    ref={this.forwardButton}
                    disabled={this.state.currentPageIndex === this.state.pages.length + 1
                              || (typeof this.state.token === "undefined"
                              && !window.location.pathname.endsWith("preview")
                              && this.state.currentPageIndex !== 0)
                              || !this.state.pageValid
                              || this.state.loading}
                    >

                      {this.state.currentPageIndex < this.state.pages.length &&
                        <FormattedMessage id={"survey.next"} defaultMessage={"Volgende"} />}
                      {this.state.currentPageIndex === this.state.pages.length &&
                        <FormattedMessage id={"survey.submit"} defaultMessage={"Antwoorden versturen"} />}
                    </button>
                </>
              }
            </div>
          </div>
        </div>
      }
        {/*this.state.currentPageIndex === 0 && window.innerWidth <= 768 &&
          <div className={"mobile-bar"}>
            <button className={"mobile-start-button"}>
              Onderzoek starten
            </button>
          </div>
        */}
        <div className={"privacy-link"} >
          <a href={"/privacy-statement"} target={"_blank"}>
            <FormattedMessage id={"survey.privacy_link"} />
          </a>
        </div>
      </div>
    )
  }
}

export default injectIntl(Survey)
