import PopupDialog from "../../../../components/Miscellaneous/PopupDialog/PopupDialog"
// import {Form} from "react-bootstrap"
import {Dropdown} from "semantic-ui-react"
// import CheckedListTable from "../../../../components/Miscellaneous/CheckedListTable/CheckedListTable"
import React from "react"
import axios from 'axios';
import ListTable from "../../../../components/Miscellaneous/ListTable/ListTable"
import Button from "react-bootstrap/Button"
import {injectIntl} from 'react-intl'

const {rootUrl} = require("../../../../config")

function ChangeAccessDialog(props) {
  const [organizationRequest, setOrganizationRequest] = React.useState({loading: true, organizations: [], possibleOrganizations: []})
  const [selectedOrganization, setSelectedOrganization] = React.useState(null)
  console.log(props)

  if(organizationRequest.loading) {
    axios.post(rootUrl + '/api/v1/administration/get_admin_access', {userId: props.editData.id})
      .then(res => {
        setOrganizationRequest({loading: false, organizations: res.data.adminOrganizations, possibleOrganizations: res.data.possibleOrganizations})
      })
      .catch(e => {
        console.log(e)
        setOrganizationRequest({ loading: false, ...organizationRequest})
      })
  }

  const handleRemoveButtonClick = (row) => {
    console.log(row)
    axios.post(rootUrl + '/api/v1/administration/remove_admin_access', {organizationId: row[1], userId: props.editData.id})
      .then(res => {
        setOrganizationRequest({...organizationRequest, loading: false, organizations: res.data})
      })
      .catch(e => {
        console.log(e)
      })
  }

  const addSelectedOrganization = () => {
    axios.post(rootUrl + '/api/v1/administration/give_admin_access', {organizationId: selectedOrganization, userId: props.editData.id})
      .then(res => {
        setOrganizationRequest({...organizationRequest, organizations: res.data})
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
    <PopupDialog
      title={props.intl.formatMessage({id: "edit_access.edit_access"})}
      onClose={() => props.onClose()}
    >
      {props.intl.formatMessage({id: "edit_access.info"})}

      <div className="row m-t-30 m-b-30">
        <div className="col-xs-12">
          <p>{props.intl.formatMessage({id: "edit_access.original_organisation"})} {organizationRequest.organizations && organizationRequest.organizations.length > 0 ? organizationRequest.organizations[0].name : ''}</p>
          <ListTable headers={[props.intl.formatMessage({id: "edit_access.organisation"})]}
                     rows={organizationRequest.organizations.filter(x =>  !x.home_organization).map((organization, index) => {
                       return [organization.name, organization.id]
                     })}
                     onRemoveButtonClicked={(row) => {
                       handleRemoveButtonClick(row)
                     }}

                     removeButton
          />
        </div>
        <div className='col-xs-12'>
          <span className={"cluster-label"}> {props.intl.formatMessage({id: "edit_access.add_organisation"})} </span>
          <Dropdown
            placeholder={props.intl.formatMessage({id: "edit_access.select_organisation"})}
            selection
            options={organizationRequest.possibleOrganizations.map((option, index) => {
              return {key: index, text: option.name, value: option.id}
            })}
            value={selectedOrganization}
            onChange={(e, {value}) => setSelectedOrganization(value)}
          />
          <Button style={{marginLeft: 10}} disabled={!selectedOrganization} onClick={addSelectedOrganization}>+</Button>
        </div>
      </div>

      <button className="btn btn-secondary m-l-5" onClick={()=> props.onClose()}> {props.intl.formatMessage({id: "ui.close"})}</button>
    </PopupDialog>
  )
}

export default injectIntl(ChangeAccessDialog)