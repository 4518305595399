import React, { Component } from 'react'
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import LoadingSpinner from "../../Miscellaneous/LoadingSpinner/LoadingSpinner"
import {Form} from "react-bootstrap"
import './ExportDialog.css'
import axios from 'axios'
import {rootUrl} from '../../../config'
import {FormattedMessage, injectIntl} from 'react-intl'
import XLSX from 'xlsx'

class ExportDialog extends Component {
  constructor(props) {
    super(props)
    console.log(props)

    this.state = {
      openingText: "",
      loading: false
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    return [year, month, day].join('-')
  }

  stringToDate(str) {
    var parts = str.split('-')
    var d = new Date(parts[0], parts[1] - 1, parts[2])
    return d
  }


  // handleExcelExport() {
  //   let surveyId = localStorage.getItem('selectedSurvey')
  //
  //   if (!(surveyId)) {
  //     return console.error("Not possible to export pdf, no survey id or name available")
  //   }
  //
  //   this.setState({
  //     loading: true
  //   }, () => {
  //     let locale = localStorage.getItem("language_locale");
  //
  //     axios.post(rootUrl + '/api/v1/excel', {surveyId: surveyId, locale: locale})
  //       .then(res => {
  //         let data = res.data
  //
  //         let columns = res.data.map(x =>  {
  //           return {
  //             question_text: x.question_text,
  //             count: x.count,
  //             type: x.type,
  //           }
  //         })
  //
  //         let out = Object.values(
  //           columns.reduce( (c, e) => {
  //             if (!c[e.question_text]) c[e.question_text] = e;
  //             return c;
  //           }, {})
  //         );
  //
  //         const grouped = data.reduce((acc, curr) => {
  //           if(!acc[curr.respondent_id]) acc[curr.respondent_id] = []; //If this type wasn't previously stored
  //           acc[curr.respondent_id].push(curr);
  //           return acc;
  //         },{});
  //
  //         let newColumns = []
  //         out.map(x => {
  //           if(x.type !== 'multiplechoice_multiple') {
  //             newColumns.push(x.question_text)
  //           } else {
  //             for(let i = 0; i < x.count; i++) {
  //               newColumns.push(x.question_text)
  //             }
  //           }
  //         })
  //
  //         let result = [["id", "timestamp"].concat(newColumns)]
  //
  //         for(let key in grouped) {
  //
  //           let startingArray;
  //           startingArray = new Array(result[0].length).fill(null)
  //           startingArray[0] = key
  //           startingArray[1] = grouped[key][0].created_at
  //           let flag = 0;
  //           let flagArray = []
  //           for(let i = 0; i < grouped[key].length; i++) {
  //             if(grouped[key][i].type === "multiplechoice_single" || grouped[key][i].type === "routing") {
  //               startingArray[i + 2 + flag] = grouped[key][i].option_text
  //             } else if (grouped[key][i].string_answer) {
  //               startingArray[i + 2 + flag] = grouped[key][i].string_answer
  //             } else if ( grouped[key][i].int_answer) {
  //               startingArray[i + 2 + flag] = grouped[key][i].int_answer
  //             } else if(grouped[key][i].type === "multiplechoice_multiple") {
  //               if(flagArray.filter(x => x.id === grouped[key][i].question_id).length === 0){
  //                 flagArray.push({id: grouped[key][i].question_id, start: i})
  //                 flag += grouped[key][i].count -1
  //                 startingArray[i + 2 + grouped[key][i].position - 1] = grouped[key][i].option_text
  //                 console.log(grouped[key][i].option_text)
  //                 console.log("Increasing flag for ", grouped[key][i].question_id + " " + key + " by " + grouped[key][i].count)
  //               } else {
  //                 flag -= 1
  //                 startingArray[i + 2] = grouped[key][i].option_text
  //               }
  //             }
  //           }
  //
  //
  //           result.push(startingArray)
  //
  //         }
  //
  //         var worksheet = XLSX.utils.aoa_to_sheet(result);
  //         var wb = XLSX.utils.book_new();
  //         XLSX.utils.book_append_sheet(wb, worksheet, "Export");
  //
  //         /* write workbook (use type 'binary') */
  //         var wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'});
  //
  //         /* generate a download */
  //         function s2ab(s) {
  //           var buf = new ArrayBuffer(s.length);
  //           var view = new Uint8Array(buf);
  //           for (var i=0; i!==s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  //           return buf;
  //         }
  //
  //         let blob = new Blob([s2ab(wbout)],{type:"application/octet-stream"})
  //
  //         const link = document.createElement('a');
  //         link.href = window.URL.createObjectURL(blob);
  //         link.download = 'export.xlsx'
  //         link.click();
  //         this.setState({
  //           loading:false
  //         })
  //
  //
  //       })
  //       .catch(e => {
  //         console.log(e)
  //         this.setState({
  //           loading:false
  //         })
  //       })
  //
  //   })
  //
  // }

  handleExcelExport() {
      let surveyId = localStorage.getItem('selectedSurvey')

      if (!(surveyId)) {
        return console.error("Not possible to export pdf, no survey id or name available")
      }

      this.setState({
        loading: false
      }, () => {
        let locale = localStorage.getItem("language_locale");

        axios.post(rootUrl + '/api/v1/excel', {surveyId: surveyId, locale: locale})
          .then(res => {
            var worksheet = XLSX.utils.aoa_to_sheet(res.data);
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, worksheet, "Export");

            /* write workbook (use type 'binary') */
            var wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'});

            /* generate a download */
            function s2ab(s) {
              var buf = new ArrayBuffer(s.length);
              var view = new Uint8Array(buf);
              for (var i=0; i!==s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
              return buf;
            }

            let blob = new Blob([s2ab(wbout)],{type:"application/octet-stream"})

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'export.xlsx'
            link.click();
            this.setState({
              loading:false
            })
          })
          .catch(e => {
            console.log(e)
            this.setState({
              loading:false
            })
          })
      })
  }

  handleExport(textOnly) {
    let surveyId = localStorage.getItem('selectedSurvey')

    if (!(surveyId)) {
      return console.error("Not possible to export pdf, no survey id or name available")
    }

    console.log(this.props)
    let end = this.stringToDate(this.props.sliderTo)
    end = end.setDate(end.getDate() + 1)

    this.setState({
      loading: true
    }, () => {
      let locale = localStorage.getItem("language_locale");

      axios.post(rootUrl + '/api/v1/survey/get_survey_name/' + surveyId + '/' + locale)
        .then((res) => {
          if (!res.data) {
            return console.error("Error in exportPdf function")
          }

          const surveyName = res.data

          axios.post(rootUrl + `/api/v1/report${textOnly ? "-text-only" : ""}`,
            //TODO add logo
            {
              id: surveyId,
              fromDate: this.props.sliderFrom !== null ? this.props.sliderFrom : this.props.surveyStart,
              toDate: this.formatDate(end),
              filter: this.props.filterIds,
              surveyName: surveyName,
              header: (this.state.openingText ? this.state.openingText : null),
              logo: null,
              colorScheme: localStorage.getItem('colorScheme'),
              locale: this.props.intl.formatMessage({id: "misc.locale"})
            },
            //TODO The * from the cors acceptor in the server only allows without credentials.
            {
              withCredentials: false,
              headers: {
                'Accept': 'application/pdf',
              },
              responseType: 'blob',
            }
          ).then((res) => {
            let blob = res.data

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              // IE11
              window.navigator.msSaveOrOpenBlob(blob, surveyName + ".pdf")
            } else {
              // Other browsers
              // 2. Create blob link to download
              const url = window.URL.createObjectURL(new Blob([blob], {type: 'application/pdf'}))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', surveyName + ".pdf")
              // 3. Append to html page
              document.body.appendChild(link)
              // 4. Force download
              link.click()
              // 5. Clean up and remove the link
              link.parentNode.removeChild(link)
            }

            this.setState({loading: false}, () => {
              this.props.onClose()
            })
          }).catch((error) => {
            console.log(error)
            this.setState({loading: false})
          })
        })
    })
  }


  render() {
    return (
      <PopupDialog
        title={this.props.intl.formatMessage({ id: "export.dialog_title" })}
        onClose={() => this.props.onClose()}
      >
        {this.state.loading &&
          <LoadingSpinner className={"modal-loading"}/>
        }

        <br/>
        <FormattedMessage id={"export.info"}
                          defaultMessage={"U heeft hier de mogelijkheid een openingstekst in het geëxporteerde document te plaatsen. Dit is optioneel."}/>

        <Form.Group className={"m-t-20"}>
          <Form.Label><FormattedMessage id={"export.dialog_title"} defaultMessage={"Openingstekst"}/></Form.Label>
          <Form.Control
            className={"form-control"}
            onChange={(e) => this.setState({ openingText: e.target.value })}
            value={this.state.openingText}
            as={"textarea"}
            rows={5}
          />
        </Form.Group>

        <div>
          <button className="btn btn-primary" onClick={() => this.handleExcelExport()}>
            <i className="fa fa-arrow-alt-circle-down"/> <FormattedMessage id={"export.generate_export_excel"}
                                                                           defaultMessage={"Export genereren XLSX"}/>
          </button>
          <button className="btn btn-primary m-l-5" onClick={() => this.handleExport(false)}>
            <i className="fa fa-arrow-alt-circle-down"/> <FormattedMessage id={"export.generate_export"}
                                                                           defaultMessage={"Export genereren PDF"}/>
          </button>
        </div>

        <br/>

        <div>
          <button className="btn btn-primary" onClick={() => this.handleExport(true)}>
            <i className="fa fa-arrow-alt-circle-down"/> <FormattedMessage id={"export.generate_export_text"}
                                                                           defaultMessage={"Export genereren PDF (enkel opmerkingen)"}/>
          </button>
          <button className="btn btn-secondary m-l-5" onClick={() => this.props.onClose()}><FormattedMessage
            id={"ui.cancel"}/></button>
        </div>


      </PopupDialog>
    )
  }
}

export default injectIntl(ExportDialog)
