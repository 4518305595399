import React, {Component} from 'react'
import {Dropdown} from 'semantic-ui-react'
import QuestionTypes from '../../QuestionTypes'
import { injectIntl } from 'react-intl'

class LanguageDropdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: (this.props.type ? this.props.type : QuestionTypes.Routing)
    }
  }

  render() {
    // This array contains the HTML and the type of all options that are being loaded into the dropdown.
    const options = [
      { key: QuestionTypes.OpenShort, text: <span><i className="fa fa-i-cursor" /> {this.props.intl.formatMessage({id: "question_type.open_short"})}</span>, value: QuestionTypes.OpenShort },
      { key: QuestionTypes.OpenLong, text: <span><i className="fa fa-align-left" /> {this.props.intl.formatMessage({id: "question_type.open_long"})}</span>, value: QuestionTypes.OpenLong },
      { key: QuestionTypes.MultipleChoiceSingle, text: <span><i className="fa fa-circle" /> {this.props.intl.formatMessage({id: "question_type.multiple_choice_single"})}</span>, value: QuestionTypes.MultipleChoiceSingle },
      { key: QuestionTypes.MultipleChoiceMultiple, text: <span><i className="fa fa-check-square" /> {this.props.intl.formatMessage({id: "question_type.multiple_choice_multiple"})}</span>, value: QuestionTypes.MultipleChoiceMultiple },
      { key: QuestionTypes.Dropdown, text: <span><i className="fa fa-toggle-down" /> {this.props.intl.formatMessage({id: "question_type.dropdown"})}</span>, value: QuestionTypes.Dropdown },
      { key: QuestionTypes.Date, text:  <span><i className="fa fa-calendar" /> {this.props.intl.formatMessage({id: "question_type.date"})}</span>, value: QuestionTypes.Date },
      { key: QuestionTypes.Email, text: <span><i className="fa fa-at" /> {this.props.intl.formatMessage({id: "question_type.email"})}</span>, value: QuestionTypes.Email },
      { key: QuestionTypes.Number, text:  <span><i className="fa fa-sort-numeric-up" /> {this.props.intl.formatMessage({id: "question_type.number"})}</span>, value: QuestionTypes.Number },
      { key: QuestionTypes.Grade, text:  <span><i className="fa fa-th-list" /> {this.props.intl.formatMessage({id: "question_type.grade"})}</span>, value: QuestionTypes.Grade },
      { key: QuestionTypes.NetPromoter, text: <span><i className="fa fa-th-list" /> {this.props.intl.formatMessage({id: "question_type.net_promoter"})}</span>, value: QuestionTypes.NetPromoter },
      { key: QuestionTypes.NetPromoterRouting, text: <span><i className="fa fa-th-list" /> {this.props.intl.formatMessage({id: "question_type.net_promoter_routing"})}</span>, value: QuestionTypes.NetPromoterRouting },
      { key: QuestionTypes.Likert, text:<span><i className="fa fa-circle" /> {this.props.intl.formatMessage({id: "question_type.likert"})}</span>, value: QuestionTypes.Likert},
      { key: QuestionTypes.Routing, text:<span><i className="fa fa-circle" /> {this.props.intl.formatMessage({id: "question_type.routing"})}</span>, value: QuestionTypes.Routing}
    ]

    return <Dropdown
        className={this.props.className}
        placeholder={this.props.intl.formatMessage({id: "component_builder.question_type"})}
        onChange={(e, {value}) => {
          e.preventDefault()
          this.setState({selected: value}, () => {
            this.props.onChange(value)
          })
        }}
        selection
        options={options}
        defaultValue={this.state.selected}
      />
  }


}

export default injectIntl(LanguageDropdown)
