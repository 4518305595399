import React, { Component } from 'react'
import {injectIntl} from 'react-intl'
import ReactHtmlParser from 'react-html-parser'

class ComponentHolder extends Component {
  render() {
    return (
      <div className={"full-width " + (this.props.className ? this.props.className : "")}>
        <h4 className={"col-md-10 header m-b-30" + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " arabic-text" : "")}>
          {ReactHtmlParser(this.props.text.replace('&nbsp;', ' '))}
          {this.props.text.includes('http') && <img src={this.props.text.split(' ').filter(x => x.includes('http'))[0]} alt="" style={{maxWidth: 200, margin: 20}}/>}
          {this.props.required && <span style={{color: "red"}}> *</span>}
        </h4>
        <div className={"component-holder"}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default injectIntl(ComponentHolder)
