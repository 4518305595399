import React, { Component } from 'react'
import {Button} from "react-bootstrap"
import ComponentHolder from "../ComponentHolder/ComponentHolder"
import { injectIntl } from "react-intl"

class NumberControl extends Component {
  constructor(props) {
    super(props)

    this.state = {
      number: (this.props.answer ? this.props.answer : 1)
    }
  }

  handleChange(event) {
    let value = parseInt(event.target.value)

    this.setState({
      number: value
    })
  }

  validateAnswer() {
    this.props.validateAnswer(this.props.position, true, this.state.number)
  }

  render() {
    return (
      <ComponentHolder {...this.props}>
        <input type={"number"}
               className={"survey-form-control" + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " f-right clear-right" : "")}
               value={this.state.number}
               onChange={this.handleChange.bind(this)}
               min={1}
        /><br />
        <Button
          className={"btn btn-primary" + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " f-right clear-right" : "")}
          onClick={() => this.validateAnswer()}
          onMouseEnter={() => this.validateAnswer()}
          onMouseLeave={e => (e.target.style.backgroundColor = "#90EE90", e.target.style.borderColor = "#90EE90")}
          style={{"marginTop":"-12px", "width":"100px"}}>
          OK
        </Button>
      </ComponentHolder>
    )
  }
}

export default injectIntl(NumberControl)
