import React, { Component } from 'react'
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import {Form} from "react-bootstrap"
import {Dropdown} from "semantic-ui-react"
import axios from 'axios'

const {rootUrl} = require("../../../config")

class NewClientDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inputText: (this.props.inputText ? this.props.inputText : ""),
      benchmarkSettings: this.props.settingsValue || null,
      possibleBenchmarkSettings: []
    }

    this.fetchBenchmarkSettings()
  }

  fetchBenchmarkSettings() {
    axios.get(rootUrl + '/api/v1/benchmark/all_benchmark_settings')
      .then(res => {
        this.setState({
          possibleBenchmarkSettings: res.data
        })
      })
      .catch(e => console.log(e))
  }

  render() {
    return (
      <PopupDialog
        title={this.props.title}
        onClose={() => this.props.onClose()}
        style={{marginTop: "100px"}}
      >
        {this.props.informationText}

        <Form.Group className={"m-t-20"}>
          <Form.Label>{this.props.inputLabel}</Form.Label>
          <Form.Control
            className={"form-control"}
            onChange={(e) => this.setState({inputText: e.target.value} )}
            value={this.state.inputText}
          />
        </Form.Group>

        <Form.Group className={"m-t-20"}>
          <Form.Label> Benchmark instelling </Form.Label><br />
          <Dropdown
            placeholder='Selecteer een instelling...'
            selection
            options={this.state.possibleBenchmarkSettings.map((option, index) => {
              return {key: index, text: option.name, value: option.id}
            })}
            value={this.state.benchmarkSettings}
            onChange={(e, {value}) => this.setState({benchmarkSettings: value})}
          />
        </Form.Group>

        <div style={{overflow: "hidden"}}>
          <button
            className="btn btn-primary f-right"
            onClick={() => this.props.onSubmit(this.state.inputText, this.state.benchmarkSettings)}
            disabled={this.inputText === ""}>
            {this.props.submitButtonText || "Bevestigen"}
          </button>
          <button className="btn btn-secondary m-r-5 f-right" onClick={() => this.props.onClose()}> Annuleren</button>
        </div>
      </PopupDialog>
    )
  }
}

export default NewClientDialog
