import React, { Component } from 'react'
import './FilterListItem.css'
import ReactTooltip from "react-tooltip";

class FilterListItem extends Component {
  render() {
    return (
      <>
        <div
          className={"filter-list-item"}
          data-for={"main-tooltip"}
          data-tip={this.props.questionText}
          data-iscapture={"true"}>
          <span className={"cursor-default"}>
            {this.props.text}
          </span>
          <i className={"fa fa-remove m-l-5 cursor-pointer"} onClick={() => this.props.onDeleteItem()} />
        </div>
        <ReactTooltip
          id={"main-tooltip"}
          place={"bottom"}
          type={"dark"}
          effect={"solid"}
        />
      </>
    )
  }
}

export default FilterListItem