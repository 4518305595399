import React from 'react'
import { NavLink } from 'react-router-dom'
import {Dropdown} from 'react-bootstrap'
import Flags from 'country-flag-icons/react/3x2'
import {FormattedMessage, injectIntl} from 'react-intl'
import './Sidebar.css'

class Sidebar extends React.Component {
    getSearchParams(){
        let query = ""
        if(this.props.location){
            query = this.props.location.search
        }
        return query
    }

    render() {
        const role = localStorage.getItem('role')
        const benchmark_enabled = localStorage.getItem('benchmark_enabled') === '1'
        const roles = [
            {
                role: this.props.intl.formatMessage({id: "role.superadmin"}),
                value: 1
            },{
                role: this.props.intl.formatMessage({id: "role.admin"}),
                value: 2
            },{
                role: this.props.intl.formatMessage({id: "role.researcher"}),
                value: 3
            },{
                role: this.props.intl.formatMessage({id: "role.observer"}),
                value: 4
            }]

        return (
            <div className="left side-menu position-fixed">
                <div className="sidebar-inner slimscrollleft">
                    <div id="sidebar-menu" >
                        <ul>
                            <li className="text-muted menu-title"><FormattedMessage id={"sidebar.navigation"} defaultMessage={"Navigatie"} /></li>
                            <li className="has_sub">
                                <NavLink to={"/dashboard" + this.getSearchParams()}><i className={"zmdi zmdi-view-dashboard"}/><span> Dashboard </span></NavLink>
                                {/*<NavLink to={"/resultaten" + this.getSearchParams()}><i className={"zmdi zmdi-chart"}/><span> Resultaten </span> </NavLink>*/}
                            </li>
                            <Dropdown.Divider/>

                            {role <= 3 &&
                            <>
                                <li className="text-muted menu-title"><FormattedMessage id={"sidebar.research"} defaultMessage={"Onderzoek"} /></li>
                                <li>
                                    {/*<NavLink to={"/projecten"}><i className={"zmdi zmdi-layers"}/><span> Onderzoeken </span> </NavLink>*/}
                                    <NavLink to={this.props.intl.formatMessage({id: "link.surveys"})}><i className={"zmdi zmdi-search-in-file"}/><span> <FormattedMessage id={"sidebar.surveys"} defaultMessage={"Vragenlijsten"} /> </span></NavLink>
                                </li>
                                <Dropdown.Divider/>
                            </>
                            }

                            {role <= 3 &&
                            <>
                                <li className="text-muted menu-title"><FormattedMessage id={"sidebar.tools"} defaultMessage={"Tools"} /></li>
                                <li>
                                    {benchmark_enabled && <NavLink to={this.props.intl.formatMessage({id: "link.benchmark"})}><i className={"zmdi zmdi-shuffle"}/><span> <FormattedMessage id={"sidebar.benchmark"} defaultMessage={"Benchmark"} /> </span></NavLink>}
                                    {benchmark_enabled && <NavLink to={this.props.intl.formatMessage({id: "link.benchmark-time"})}><i className={"zmdi zmdi-shuffle"}/><span> <FormattedMessage id={"sidebar.benchmark-time"} defaultMessage={"Benchmark tijd"} /> </span></NavLink>}

                                    <NavLink to={this.props.intl.formatMessage({id: "link.broadcasts"})}><i className={"zmdi zmdi-email"}/><span> <FormattedMessage id={"sidebar.broadcasts"} defaultMessage={"Verzendlijsten"} /> </span></NavLink>
                                    {role <= 2 &&
                                      <>
                                        <NavLink to={this.props.intl.formatMessage({id: "link.administration"})}><i className={"zmdi zmdi-accounts"}/><span> <FormattedMessage id={"sidebar.users"} defaultMessage={"Gebruikers"} /> </span></NavLink>
                                      </>
                                    }
                                    {role <= 1 &&
                                    <>
                                        <NavLink to={this.props.intl.formatMessage({id: "link.benchmark_settings"})}><i className={"zmdi zmdi-code-setting"}/><span> <FormattedMessage id={"sidebar.edit_benchmark"} defaultMessage={"Benchmark bewerken"} /> </span></NavLink>
                                    </>
                                    }
                                    {/*<NavLink to={"/instellingen"}><i className={"zmdi zmdi-settings"}/><span> Instellingen </span> </NavLink>*/}
                                </li>
                                <Dropdown.Divider/>
                            </>
                            }

                            <li>
                                <NavLink to={this.props.intl.formatMessage({id: "link.ask_a_question"})} className={"waves-effect"}><i className={"zmdi zmdi-pin-help"}/><span> <FormattedMessage id={"sidebar.ask_a_question"} defaultMessage={"Stel een vraag"} /> </span></NavLink>
                                <NavLink to={"/logout"} className={"waves-effect"}><i className={"zmdi zmdi-arrow-left"}/><span> <FormattedMessage id={"sidebar.logout"} defaultMessage={"Log uit"} /> </span></NavLink>
                            </li>
                        </ul>
                        <div className="clearfix"/>
                    </div>
                    <div className="clearfix"/>
                </div>

                <div className={"email-holder"}>
                    <i className={"fa fa-user m-r-10"}/>{localStorage.getItem('username')} <i
                    className={"fa fa-cog settings-cog m-r-10"} onClick={() => this.props.editPasswordButtonClicked()}/><br/>
                    <i className={"fa fa-building m-r-10"}/>{localStorage.getItem('organization_name')}<br/>
                    <i className={"fa fa-wrench"}
                       style={{marginRight: "8px"}}/>{localStorage.getItem('role') && roles[localStorage.getItem('role') - 1].role}<br/>
                    <i className={"fa fa-code-fork m-r-10"}
                       style={{marginRight: "8px"}}/> Version: {localStorage.getItem('versionNumber')}
                </div>

                <div className={"sidebar-flag-holder"}>
                    <Flags.NL title="Dutch" className="flag" style={{marginRight: "5px"}} onClick={() => this.props.onLanguageChanged("nl")}/>
                    <Flags.DE title="German" className="flag" onClick={() => this.props.onLanguageChanged("de")}/>
                    <Flags.GB title="English" className="flag" onClick={() => this.props.onLanguageChanged("en")}/>
                    <Flags.FR title="French" className="flag" onClick={() => this.props.onLanguageChanged("fr")}/>

                </div>
            </div>
        )
    }
}

export default injectIntl(Sidebar)
