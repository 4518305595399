import React, { Component } from 'react'
import {FormattedMessage, injectIntl} from "react-intl"
import './BenchmarkExplanation.css'

class BenchmarkExplanation extends Component {

  render() {
    return (
      <div className="survey">
        <div className={"survey-main-top"} style={{backgroundColor: "#3B699C"}}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-xs-12 main-title"}>
                <h1 className={"header header1"}>
                {this.props.intl ? this.props.intl.formatMessage({id: "benchmark_explanation.title"}) : "Benchmark explanation"}
                </h1>
                <p>LiveResearch survey application</p>
              </div>
            </div>
          </div>
        </div>

        <div className={"container survey-container"}>
          <div className={"survey-panel"}>
            <div className="survey-top" />
            <div className="survey-content privacy-content">
              <div className={"row"}>
                <div className="col-xs-12">
                  
                  <h3>{this.props.intl ? this.props.intl.formatMessage({id: "benchmark_explanation.average_score_title"}) : "Average score explanation"}</h3>
                  <div>{this.props.intl ? this.props.intl.formatMessage({id: "benchmark_explanation.average_score_explanation"}) : "The average of a domain is calculated by taking the average of all questions within a domain. This is a score between 0 and 4 where strongly agree yields 4 points and strongly disagree 0. For a 'not applicable' answer, the score is not included. In the benchmark, an average of these scores is shown within the domain. The average score of the organization being compared is excluded from the benchmark so that your own score does not influence the benchmark."}</div>
                  <br/>
                  <h3>{this.props.intl ? this.props.intl.formatMessage({id: "benchmark_explanation.qi_score_title"}) : "QI score explanation"}</h3>
                  <div>{this.props.intl ? this.props.intl.formatMessage({id: "benchmark_explanation.qi_score_explanation"}) : "QI score is a score between 1 and 20. A high score means that the question or domain in question deserves extra attention. In other words, the lower the score, the more positive.\n\nTo calculate the QI score, the average score per question and the importance score are considered. The importance score is calculated using research from Radboud University Nijmegen. The lowest score is 2.57 and the highest score is 4.55. This represents how important the score of the question is for patients.\n\nQI score formula: (5-average score of question)*importance score of question\n\nIn the benchmark, an average of these QI scores is shown within the domain. The QI score of the organization being compared is excluded from the benchmark so that your own score does not influence the benchmark."}</div>
                </div>
              </div>
            </div>

            <div className="survey-bottom" />
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(BenchmarkExplanation)
