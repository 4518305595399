import React, { Component } from 'react'
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import {Form} from "react-bootstrap"
import {Redirect} from 'react-router-dom'
import {SketchPicker} from "react-color"
import axios from "axios"
import {rootUrl} from "../../../config"
import Swal from "sweetalert2"
import {Dropdown} from "semantic-ui-react"
import {FormattedMessage, injectIntl} from 'react-intl'
// import QrCodeWithLogo from "qrcode-with-logos";

class EditSurveyDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      surveyName: (this.props.editData["internal_name"] ? this.props.editData["internal_name"] : ""),
      description: (this.props.editData["description"] ? this.props.editData["description"] : ""),
      surveyColor: (this.props.editData["color"] ? this.props.editData["color"] : ""),
      redirectToSurvey: (this.props.editData["redirect_to"] ? this.props.editData["redirect_to"] : null),
      possibleReferrals: [],
      redirect: false,
      status: (this.props.editData["status"] ? parseInt(this.props.editData["status"]) : 1),
      selectedOrganization: null,
      possibleOrganizations: []
    }
  }

  componentDidMount() {
    this.fetchOrganization()
    this.fetchReferrals()
  }

  fetchOrganization() {
    axios.get(rootUrl + '/api/v1/survey/organization/' + this.props.editData.id)
      .then(res => {
        this.setState({
          selectedOrganization: res.data.organizationId,
          possibleOrganizations: res.data.organizations
        })
      })
      .catch(e => console.log(e))
  }


  fetchReferrals() {
    axios.post(rootUrl + '/api/v1/dashboard/projectssummary', {})
      .then(res => {
        this.setState({
          possibleReferrals: res.data.surveys
        })
      })
      .catch(e => console.log(e))
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  changeSurveyStatus = () => {
    this.setState({
      status: this.state.status === 1 ? 2 : 1,
      redirectToSurvey: this.state.status === 1 ? null : this.state.redirectToSurvey
    })
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  // This method saves the color in the state in hex value and passes it up to the surveyFrame.
  handleColorChange = (color) => {
    this.setState({ surveyColor: color.hex })
  }

  generateRespondents = (lang) => {
    axios.post(rootUrl + '/api/v1/generate', {id: this.props.editData.id, amount: 40, language: lang})
      .then(res => {
        Swal.fire({
          title: this.props.intl.formatMessage({id: "dialogs.success"}),
          text: "Respondenten gegenereerd!",
          type: 'success',
          confirmButtonText: 'OK'
        })
      })
  }

  saveSurveySettings() {
    axios.post(rootUrl + '/api/v1/survey/edit_survey_settings/' + this.props.editData.id,
      {
        internal_name: this.state.surveyName,
        description: this.state.description,
        color: this.state.surveyColor,
        organizationId: this.state.selectedOrganization,
        status: this.state.status,
        redirectToSurvey: this.state.status === 2 ? this.state.redirectToSurvey : null
      }
    ).then((res) => {
      Swal.fire({
        title: this.props.intl.formatMessage({id: "dialogs.success"}),
        text: this.props.intl.formatMessage({id: "dialogs.settings_saved_successfully"}),
        type: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        this.props.onClose()
      })
    }).catch((error) => {
      console.log(error.response.data)
      Swal.fire({
        title: this.props.intl.formatMessage({id: "dialogs.error_title"}),
        text: this.props.intl.formatMessage({id: "dialogs.error_message"}),
        type: 'error',
        confirmButtonText: 'OK'
      })
    })
  }

  render() {
    return (
      <PopupDialog
        title={this.props.intl.formatMessage({id: "surveys.edit_survey"})}
        onClose={() => this.props.onClose()}
      >
        {this.state.redirect && <Redirect to={this.props.intl.formatMessage({id: "link.edit_survey"}) + this.props.editData.id} />}
        <FormattedMessage id={"edit_survey.info"} defaultMessage={"U kunt hier de basisopties van de vragenlijst veranderen. Als de inhoud van de vragenlijst aangepast moet worden, zult u een nieuwe vragenlijst moeten aanmaken."} />

        <div className="row m-t-10">
          <div className="col-xs-12">
            <Form.Group>
              <Form.Label><FormattedMessage id={"edit_survey.name"} defaultMessage={"Naam"} /></Form.Label>
              <Form.Control
                value={this.state.surveyName}
                onChange={(e) => this.setState({surveyName: e.target.value})}
                required
                placeholder={this.props.intl.formatMessage({id: "edit_survey.name_placeholder"})}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label><FormattedMessage id={"edit_survey.description"} defaultMessage={"Beschrijving"} /></Form.Label>
              <Form.Control
                value={this.state.description}
                onChange={(e) => this.setState({description: e.target.value})}
                placeholder={this.props.intl.formatMessage({id: "edit_survey.description_placeholder"})}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label><FormattedMessage id={"edit_survey.color"} defaultMessage={"Kleur"} /></Form.Label><br />
              <div className="settings-swatch" onClick={this.handleClick}>
                <div className="settings-colorbox" style={{background: this.state.surveyColor}} />
              </div>
            </Form.Group>

            <span style={{marginLeft: 0}}> <FormattedMessage id={"surveys.survey_under_organization"} defaultMessage={"Deze vragenlijst valt onder organisatie:"} /> </span><br/>
            <Dropdown
              style={{marginBottom: 5}}
              disabled={!(parseInt(localStorage.getItem('role')) && parseInt(localStorage.getItem('role')) === 1)}
              placeholder={this.props.intl.formatMessage({id: "users.select_organization_placeholder"})}
              selection
              options={this.state.possibleOrganizations.map((option, index) => {
                return {key: index, text: option.name, value: option.id}
              })}
              value={this.state.selectedOrganization}
              onChange={(e, {value}) => this.setState({selectedOrganization: value})}
            />

            <Form.Group className={"m-b-10"}>
              <Form.Label><FormattedMessage id={"surveys.link_to_survey"} defaultMessage={"Link naar onderzoek"} /></Form.Label><br />
              <a
                rel={"noopener noreferrer"}
                href={"https://dashboard.liveresearch.com/language-selection/" + this.props.editData.uuid}
                target={"_blank"}>
                {"https://dashboard.liveresearch.com/language-selection/" + this.props.editData.uuid}
              </a>
            </Form.Group>

            <button
              disabled={!this.props.surveyEditable}
              className="btn btn-success m-t-20"
              onClick={()=> this.setState({
                redirect: true
              })}>
              <FormattedMessage id={"surveys.edit_survey"} defaultMessage={"Vragenlijst aanpassen"} />
            </button>

           <Form.Group style={{marginTop: 10}}>
              <Form.Label>Status onderzoek: <p style={{color: this.state.status === 1 ? 'green':'red'}}>{this.state.status === 1 ? 'Actief' : 'Inactief'}</p></Form.Label>
              <button className={this.state.status === 1 ? 'btn btn-warning' : 'btn btn-success'} onClick={this.changeSurveyStatus}>{this.state.status === 1 ? 'Deactiveren' : 'Activeren'}</button>
            </Form.Group>

            {
              this.state.status === 2 &&
              <div>
                <span style={{marginLeft: 0}}>Gebruikers doorverwijzen naar: </span><br/>
                <Dropdown
                  style={{marginBottom: 5}}
                  disabled={!(parseInt(localStorage.getItem('role')) && parseInt(localStorage.getItem('role')) === 1)}
                  placeholder={'Selecteer vragenlijst'}
                  selection
                  options={this.state.possibleReferrals.map((option, index) => {
                    return {key: index, text: option.survey_name, value: option.uuid}
                  })}
                  value={this.state.redirectToSurvey}
                  onChange={(e, {value}) => this.setState({redirectToSurvey: value})}
                />
              </div>
            }

            <div>
              <button className={'btn btn-info'} onClick={() => this.generateRespondents('nl')}>Genereer 40 NL respondenten (let op: doe dit enkel voor dummy vragenlijsten!)</button>

              <button style={{marginTop: 20}} className={'btn btn-info'} onClick={() => this.generateRespondents('de')}>Genereer 40 DE respondenten (let op: doe dit enkel voor dummy vragenlijsten!)</button>
            </div>



            <hr className={"m-t-20 m-b-20"} />

          {this.state.displayColorPicker ?
            <div className="settings-popover">
              <div className="settings-cover" onClick={ this.handleClose }/>
              <SketchPicker color={ this.state.surveyColor } onChange={ this.handleColorChange } />
            </div>
            : null }
          </div>


        </div>

        <button
          className="btn btn-primary"
          onClick={() => this.saveSurveySettings()}
        >
          <i className="fa fa-save"/> <FormattedMessage id={"ui.save"} defaultMessage={"Opslaan"} />
        </button>
        <button className="btn btn-secondary m-l-5" onClick={()=> this.props.onClose()}> <FormattedMessage id={"ui.cancel"} defaultMessage={"Annuleren"} /></button>
      </PopupDialog>
    )
  }
}

export default injectIntl(EditSurveyDialog)
