import React, {Component} from 'react'
import {Button, Form} from 'react-bootstrap'
import {Dropdown} from 'semantic-ui-react'
import QuestionTypes from '../../QuestionTypes'
import { FormattedMessage, injectIntl } from 'react-intl'
import './MultipleChoice.css'

class MultipleChoice extends Component {
  constructor(props) {
    super(props)

    let sampleOptions = []
    if(this.props.type === QuestionTypes.Likert) {
      // Generate initial options for the likert scale
      let likertScale = [
        this.props.intl.formatMessage({id: "likert.completely_agree"}),
        this.props.intl.formatMessage({id: "likert.agree"}),
        this.props.intl.formatMessage({id: "likert.neutral"}),
        this.props.intl.formatMessage({id: "likert.disagree"}),
        this.props.intl.formatMessage({id: "likert.completely_disagree"})
      ]

      for (let key = 0; key < likertScale.length; key++) {
        let option = {key: key, weight: 0, translations: {}}
        option["translations"][this.props.selectedTranslation] = likertScale[key]
        sampleOptions.push(option)
      }
    } else {
      // Generate sample options
      const numberOfOptions = 3
      for (let optionNumber = 0; optionNumber < numberOfOptions; optionNumber++) {
        let option = {key: optionNumber, weight: 0, translations: {}}
        option["translations"][this.props.selectedTranslation] = ""
        sampleOptions.push(option)
      }
    }

    /**
     * @type {{answers: *[]}}
     */
    this.state = {
      options: ((this.props.options && this.props.options.length !== 0) ? this.props.options : sampleOptions),
      openOption: (this.props.openOption ? this.props.openOption : null)
    }
    //this.props.onOptionsChanged(this.state.options, this.state.openOption)
  }

  /**
   * This method will change the state within the answers state.
   * This.props.onOptionsChanged is called to pass the new options up to surveyFrame.
   * @param event
   */
  handleChangeMC = event => {
    let oldOptions = this.state.options
    oldOptions[event.target.id].value = event.target.value
    this.setState({
      options: oldOptions
    }, () => {
      this.props.onOptionsChanged(this.state.options, this.state.openOption)
    })
  }

  handleWeightChange(index, value) {
    let oldOptions = this.state.options
    oldOptions[index].weight = value
    this.setState({
      options: oldOptions
    }, () => {
      this.props.onOptionsChanged(this.state.options, this.state.openOption)
    })
  }

  /**
   * This method will change the states
   * of the Form Answers that have a controlId.
   * This.props.onOptionsChanged is called to pass the new options up to surveyFrame.
   */
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, () => {
      this.props.onOptionsChanged(this.state.options, this.state.openOption)
    })
  }

  validate() {
    const {answers, question_string, type_of_question} = this.state

    if (!type_of_question) return "De soort vraag is nog niet gekozen."
    if (type_of_question.length < 2) return "De soort vraag is nog niet gekozen."

    if (!question_string) return "Er is geen vraag ingevuld."
    if (question_string.length < 2) return "De lengte van de vraag is te kort."

    if (answers.length < 2) return "De multiple choice vraag heeft minimaal 2 mogelijkeden nodig."

    for (let index in answers) {
      if (!answers[index].value) return `Multiple choice optie ${(Number(index) + 1)} is leeg.`
      const value = answers[index].value
      if (value.length < 2) return `Multiple choice optie ${(Number(index) + 1)} is te kort.`
    }
    return null
  }

  /**
   * Adds an option to the multiplechoice
   * question.
   */
  addOption() {
    let oldOptions = this.state.options
    let length = oldOptions.length - 1
    let newKey = 0
    if (oldOptions[length]) {
      newKey = oldOptions[length].key + 1
    }
    const newControl = {key: newKey, weight: 0, translations: {}}
    oldOptions[length + 1] = newControl
    this.setState({options: oldOptions}, () => {
      this.props.onOptionsChanged(this.state.options, this.state.openOption)
    })
  }

  addOpenOption() {
    this.setState({
      openOption: {placeholder: "Open antwoord...", value: "Anders...", translations: {}}
    }, () => {
      this.props.onOptionsChanged(this.state.options, this.state.openOption)
    })
  }

  removeOpenOption() {
    this.setState({
      openOption: null
    }, () => {
      this.props.onOptionsChanged(this.state.options, this.state.openOption)
    })
  }

  // Change the value of the open option.
  changeOpenOption(newValue) {
    let oldOpenOption = this.state.openOption
    oldOpenOption.value = newValue

    this.setState({
      openOption: oldOpenOption
    }, () => {
      this.props.onOptionsChanged(this.state.options, this.state.openOption)
    })
  }

  /**
   * Removes one option within the multiple choice question.
   * Each option has a unique key.
   * @param key
   */
  removeOption(key) {
    let oldOptions = this.state.options

    let index = oldOptions.findIndex(c => c.key === key)

    oldOptions.splice(index, 1)

    for (let i in oldOptions) {
      oldOptions[i].placeholder = "Optie " + (Number(i) + 1)
    }

    this.setState({options: oldOptions}, () => {
      this.props.onOptionsChanged(this.state.options, this.state.openOption)
    })
  }

  render() {
    // Generate a random string of 10 characters to create a unique key.
    let randomString = Math.random().toString(36).substring(2, 12) + Math.random().toString(36).substring(2, 12)

    return (
      <>
        {!this.props.focused && (this.props.type === QuestionTypes.Dropdown) &&
        <Dropdown
          className={this.props.className}
          placeholder="Selecteer een antwoord..."
          onChange={(e, {value}) => {
            e.preventDefault()
            this.setState({selected: value})
          }}
          selection
          options={this.state.options.map((item, index) => {
            return {key: index, text: (item.value ? item.value : this.props.intl.formatMessage({id: "component_builder.no_option_entered"})), value: index}
          })}
        />
        }

        {/*all form answers of one multiple choice question with options to delete*/}
        {this.state.options.map((con, index) =>
          <Form.Group controlId={index} key={con.key}>
            {!this.props.focused &&
            (this.props.type === QuestionTypes.MultipleChoiceSingle
              || this.props.type === QuestionTypes.MultipleChoiceMultiple
              || this.props.type === QuestionTypes.Likert) &&

              <Form.Check
                type={(this.props.type === QuestionTypes.MultipleChoiceSingle || this.props.type === QuestionTypes.Likert ? "radio" : "checkbox")}
                label={(con.value ? con.value : this.props.intl.formatMessage({id: "component_builder.no_option_entered"}))}
                name={"multi-group"}
                id={"multibutton" + randomString + index.toString()}
                className={(this.props.type === QuestionTypes.MultipleChoiceSingle || this.props.type === QuestionTypes.Likert ? "radio" : "checkbox")}
              />}

            {this.props.focused &&
              <>
                <Form.Control
                  required
                  key={con.key}
                  placeholder={con.placeholder}
                  onChange={this.handleChangeMC}
                  className={"option-textbox m-r-5"}
                  value={(con.value ? con.value : "")}
                  style={{display: "inline-block", width: "70%"}} />
                <Button
                  className={"btn-danger option-remove dropdown-action-button"}
                  onClick={() => this.removeOption(con.key)}
                >
                  <i className={"fa fa-remove"} />
                </Button>

                {this.props.hasWeight > 0 &&
                  <>
                    <span className={"weight-label m-l-10 m-r-5"}>Gewicht:</span>
                    <input type={"number"}
                      className={"form-control weight-control"}
                      onChange={(e) => this.handleWeightChange(index, e.target.value)}
                      min={0}
                      step={0.01}
                      key={con.key}
                      value={con.weight}
                    />
                  </>
                }
              </>
              }
          </Form.Group>
        )}

        {/* Show open option */}
        {!this.props.focused && this.state.openOption &&
        (this.props.type === QuestionTypes.MultipleChoiceSingle
          || this.props.type === QuestionTypes.MultipleChoiceMultiple
          || this.props.type === QuestionTypes.Likert
          || this.props.type === QuestionTypes.NetPromoter
        ) &&
          <Form.Group>
            <Form.Check
              type={(this.props.type === QuestionTypes.MultipleChoiceSingle || this.props.type === QuestionTypes.Likert ? "radio" : "checkbox")}
              label={(this.state.openOption.value ? this.state.openOption.value : this.props.intl.formatMessage({id: "component_builder.no_option_entered"}))}
              name={"multi-group"}
              id={"openbutton" + randomString}
              className={(this.props.type === QuestionTypes.MultipleChoiceSingle || this.props.type === QuestionTypes.Likert ? "radio" : "checkbox")}
            />

            <input
              id={"open" + randomString}
              type={"text"}
              className={"survey-form-control mc-textbox m-l-30"}
              placeholder={"Antwoord..."}
              />
          </Form.Group>
        }

        {this.props.focused && this.state.openOption &&
        <Form.Group>
          <Form.Label className={"open-option-label"}>Open antwoord</Form.Label>
          <Form.Control
            required
            placeholder={this.state.openOption.placeholder}
            onChange={(e) => this.changeOpenOption(e.target.value)}
            className={"option-textbox m-r-5"}
            value={this.state.openOption.value}
            style={{display: "inline-block", width: "80%"}} />

          <Button
            className={"btn-danger option-remove dropdown-action-button"}
            onClick={() => this.removeOpenOption()}
          >
            <i className={"fa fa-remove"} />
          </Button>
        </Form.Group>
        }

        {/* Buttons for adding (open) options */}
        {this.props.focused &&
          <>
            <Button
              className="btn btn-primary"
              onClick={() => this.addOption()}>
              <i className={"fa fa-plus"} /> <FormattedMessage id={"component_builder.add_option"} defaultMessage={"Optie toevoegen"} />
            </Button>
            {this.props.type !== QuestionTypes.Dropdown && this.props.openOption === null &&
              <Button
                className={"btn btn-secondary m-l-5"}
                onClick={() => this.addOpenOption()}>
                <FormattedMessage id={"component_builder.add_open_option"} defaultMessage={"Open antwoord toevoegen"} />
              </Button>
            }
          </>
        }
      </>
    )
  }
}

export default injectIntl(MultipleChoice)
