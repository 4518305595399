import React, { Component } from 'react'
import QuestionTypeDropdown from '../QuestionTypeDropdown/QuestionTypeDropdown'
import QuestionTypes from '../../QuestionTypes'
import './AddComponent.css'

class AddComponent extends Component {
  constructor(props) {
    super(props)

    /*
      Type specifies the currently selected question type in the dropdown.
     */
    this.state = {
      type: QuestionTypes.Routing
    }
  }

  render() {
    return (
      <>
        <QuestionTypeDropdown
          onChange={(value) => this.setState({type: value})}/>

        <button
          className="btn btn-primary m-l-5 dropdown-action-button"
          onClick={() => this.props.onComponentAdded(this.state.type)}>
          <i className="fa fa-plus" />
        </button>
      </>
    )
  }
}

export default AddComponent
