import React, { Component } from 'react'
import axios from 'axios'
import './TopbarDropdown.css'
import {rootUrl} from '../../../config'
import {injectIntl} from "react-intl"

class TopbarDropdown extends Component {
    constructor(props) {
      super(props)
      this.state = {
        type: this.props.type,
        selectedIndex: -1,
        ids: [],
        items: []
      }
    }

    setActive(index) {
      this.setState({selectedIndex:index})
      this.props.onChange(this.state.ids[index])
    }

    setIdActive(id) {
      let index = this.state.ids.indexOf(id)
      if (index > -1) this.setActive(index)
    }

    getText() {
      if (this.state.selectedIndex === -1) {
        return ((this.props.type === 'projects') ? "Onderzoek" : this.props.intl.formatMessage({id: "surveys.survey"}))
      } else {
        return this.truncateString(this.state.items[this.state.selectedIndex].survey_name)
      }
    }

    truncateString(str) {
      if (str.length > 60) str = str.substring(0, 57) + "..."
      return str
    }

    componentDidMount() {
      let selectedProject = localStorage.getItem('selectedProject')
      let selectedSurvey = localStorage.getItem('selectedSurvey')

      if (this.props.type === 'projects') {
        this.getProjects(() => {
          if (selectedProject) {
            this.setIdActive(parseInt(selectedProject))
          }
        })
      } else if (this.props.type === 'surveys') {
        // if (selectedSurvey) this.setIdActive(parseInt(selectedSurvey))
        this.getSurveys( () => {
          if (selectedSurvey) {
            this.setIdActive(parseInt(selectedSurvey))
          }
        })
      }
    }

    componentDidUpdate() {
      if (localStorage.getItem('selectedSurvey') && this.state.selectedIndex < 0) {
        this.setIdActive(parseInt(localStorage.getItem('selectedSurvey')))
      }
    }

    getSurveys(callback) {
      this.setState({selectedIndex: -1})
        axios.post(rootUrl + '/api/v1/dashboard/projectssummary')
          .then((res) => {
          const surveys = res.data["surveys"]
          this.setState({
                            items: surveys.map(item => {return {survey_name: item["survey_name"], project_name: item['project_name']}}),
                            ids: surveys.map(item => item["survey_id"])
                          },
                  () => {
                            typeof callback === 'function' && callback()
          })
        }).catch((error) => {
          console.log(error)
        })
    }

    render() {
      return (
        <div className="btn-group">
          <button style={{border: '1px solid white', minWidth: 200}} type="button" className="btn btn-dark dropdown-toggle waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {this.getText()}
          </button>
          <div className="dropdown-menu drop-to-left">
            {this.state.items.length > 0 ? this.state.items.map((item, index) => {
              return (
                <button onClick={() => this.setActive(index)}
                        className={"dropdown-item" + ((this.state.selectedIndex !== index) ? "" : " dropdown-active")}
                        key={index + 1}>{index + 1}. {item.survey_name} <span className={((this.state.selectedIndex !== index) ? "dropdown-inactive-grey" : " dropdown-active")}>({item.project_name})</span></button>
                )

            }) : <span>Geen resultaten</span>}
            {/* naar aanleiding van commentaar van Paul hier geen toevoegknoppen meer.*/}
            {/*{this.state.items.length !== 0 && <div className="dropdown-divider" />}*/}
            {/*<NavLink to={(this.props.type === 'projects') ? '/projecten/nieuw-project' : '/onderzoeken/nieuw-onderzoek'}>*/}
            {/*  <button className="dropdown-item">*/}
            {/*    <i className="fa fa-plus" /> Nieuw{(this.props.type === 'projects') ? ' onderzoek' : 'e vragenlijst'}*/}
            {/*  </button>*/}
            {/*</NavLink>*/}
          </div>
        </div>
      )
    }
}

export default injectIntl(TopbarDropdown, { forwardRef: true })
