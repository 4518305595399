import React, { Component } from 'react'
import './PopupDialog.css'

class PopupDialog extends Component {
  render() {
    return (
      <div className={"modal popup-fade black-background"} style={{display: "block"}}>
        <div
          className={"modal-dialog modal-dialog-centered " + (this.props.className ? this.props.className : "")}
          style={(this.props.style ? this.props.style : {})}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title f-left">{this.props.title}</h5>
              <button type="button" className="close f-right" onClick={() => this.props.onClose()}>×</button>
            </div>
            <div className="modal-body">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PopupDialog