import React, { Component } from 'react'
import { MDBDataTable } from 'mdbreact'
import CardBase from '../CardBase/CardBase'
import { injectIntl } from 'react-intl'
import './TableCard.css'

class TableCard extends Component {
    constructor(props) {
      super(props)

      this.state = {
        columns: (this.props.columns) ? this.props.columns : [],
        rows: (this.props.rows) ? this.props.rows : [],
        dateVisible: (this.props.dateVisible) ? this.props.dateVisible : true
      }
    }

    toggle() {
      this.setState({dateVisible: !this.state.dateVisible})
    }

    render() {
        return (
          <CardBase blockHeight={this.props.blockHeight} blockWidth={this.props.blockWidth}
                    cardTitle={this.props.index + '. ' + this.props.cardTitle} cardText={this.props.cardText || ''}
                    optionButtons={[
                      <span onClick={this.toggle.bind(this)}
                            key={1}
                            className={"optionButton fa fa-" + (this.state.dateVisible ? "minus" : "plus")} />
                    ]}>
            <MDBDataTable className={"card-table " + (this.state.dateVisible ? "" : "hide-dates")}
              striped
              bordered
              hover
              entriesLabel={this.props.intl.formatMessage({id: "dashboard.table_rows_per_page"})}
              entries={20}
              infoLabel={[this.props.intl.formatMessage({id: "dashboard.table_info_label1"}), this.props.intl.formatMessage({id: "dashboard.table_info_label2"}), this.props.intl.formatMessage({id: "dashboard.table_info_label3"}), this.props.intl.formatMessage({id: "dashboard.table_info_label4"})]}
              paginationLabel={[this.props.intl.formatMessage({id: "dashboard.table_previous"}), this.props.intl.formatMessage({id: "dashboard.table_next"})]}
              searchLabel={this.props.intl.formatMessage({id: "dashboard.table_search"})}
              data={{columns:this.state.columns, rows:this.state.rows}}
            />
          </CardBase>
        )
    }
}

export default injectIntl(TableCard)
