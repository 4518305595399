import React, { Component } from 'react'
import DashboardFrame from '../../../components/Dashboard/DashboardFrame/DashboardFrame'
import SurveySettings from './SurveySettings/SurveySettings'
import SurveyFrame from './SurveyFrame/SurveyFrame'
import {Redirect, withRouter} from 'react-router'
import axios from "axios"
import Swal from "sweetalert2"
import {rootUrl} from '../../../config'

class SurveyEditor extends Component {
  constructor(props) {
    super(props)
    this.surveySettings = React.createRef()
    this.surveyFrame = React.createRef()

    this.state = {
      redirect: false,
      editSurveyId: this.props.match.params.editSurveyId
    }
  }

  onColorChange = (color) => {
    this.surveyFrame.current.changeColor(color)
  }

  onSelectedTranslationChange = (locale) => {
    this.surveyFrame.current.changeSelectedTranslation(locale)
  }

  onTranslationsChanged = (translations) => {
    this.surveyFrame.current.changeTranslations(translations)
  }

  onSurveyTitleChange = (title) => {
    this.surveyFrame.current.changeSurveyTitle(title)
  }

  onProjectChange = (id) => {
    this.surveyFrame.current.changeProjectId(id)
  }

  onSave() {
    const surveySettings = this.surveySettings.current.getSurveySettings()
    const pages = this.surveyFrame.current.getSurveyPages()

    axios.post(rootUrl + '/api/v1/survey/edit_survey/', {
      projectId: surveySettings.selectedProjectId,
      surveySettings: surveySettings,
      pages: pages
    }).then(() => {
      Swal.fire({
        title: 'Onderzoek opgeslagen',
        text: 'Het nieuwe onderzoek is opgeslagen.',
        type: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        this.setState({redirect: true})
      })
    }).catch((error) => {
      console.log(error)
      Swal.fire({
        title: 'Oeps! Er is iets fout gegaan...',
        text: 'Er is iets misgegaan bij het opslaan van het onderzoek. Controleer uw internetverbinding en probeer het opnieuw.',
        type: 'error',
        confirmButtonText: 'OK'
      })
    })
  }

  render() {
    return (
      <DashboardFrame
        title="Vragenlijst aanpassen"
        dashboardTitle="Vragenlijst aanpassen"
        onLanguageChanged={(language) => this.props.onLanguageChanged(language)}>
        {this.state.redirect && <Redirect to={'/vragenlijsten'} />}
        <SurveySettings
          onColorChange={this.onColorChange.bind(this)}
          onSurveyTitleChange={this.onSurveyTitleChange.bind(this)}
          onSelectedTranslationChange={this.onSelectedTranslationChange.bind(this)}
          onTranslationsChange={this.onTranslationsChanged.bind(this)}
          onSave={this.onSave.bind(this)}
          ref={this.surveySettings}
          editSurveyId={this.state.editSurveyId}
        />
        <SurveyFrame
          ref={this.surveyFrame}
          editSurveyId={this.state.editSurveyId}
        />
      </DashboardFrame>
    )
  }
}

export default withRouter(SurveyEditor)
