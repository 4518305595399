import React, {Component} from 'react'
import CardBase from '../CardBase/CardBase'
import './IndicatorCard.css'
import scrollToComponent from 'react-scroll-to-component'
import injectIntl from "react-intl/lib/src/components/injectIntl"

class IndicatorCard extends Component {
  constructor(props) {
    super(props)
    this.cardRef = React.createRef()
    this.chartRef = React.createRef()



    this.state = {
      tableVisible: this.props.tableVisible,
      blockWidth: this.props.blockWidth,
      blockHeight: this.props.blockHeight
    }
  }


  getChart() {
    if (this.props.chartType === 'indicator') {
      return <div style={{textAlign: 'center'}}>
        <h1 style={{fontSize: 100, verticalAlign: 'middle', margin: 40}}>{this.props.indicatorAmount.toFixed(2)}</h1>
      </div>
    }
  }

  toggle() {
    let newWidth = (!this.state.tableVisible ? 4 : this.props.blockWidth)
    let newHeight = (!this.state.tableVisible ? 2 : this.props.blockHeight)

    this.setState({
      tableVisible: !this.state.tableVisible,
      blockWidth: newWidth,
      blockHeight: newHeight
    }, () => {
      this.cardRef.current.sizeChanged(newWidth, newHeight, () => {
        this.chartRef.current.chartInstance.resize() // Redraw chart when toggled

        if (this.state.tableVisible) {
          scrollToComponent(this.cardRef.current)
        }
      })
    })
  }



  render() {
    return (
      <CardBase
        ref={this.cardRef}
        blockHeight={this.state.blockHeight}
        blockWidth={this.state.blockWidth}
        cardTitle={this.props.index === 0 ? this.props.cardTitle : this.props.index + '. ' + this.props.cardTitle.replace('&nbsp;', ' ')}
        dimensionTitle={this.props.dimensionTitle}
        optionButtons={
          []
        }
        cardText={this.props.cardText.replace('&nbsp;', ' ')}>
        <div className={"result-content"}>
          <div className={"graphWrapper " + (this.state.tableVisible ? "expanded2" : "")}>
            {this.props.indicatorAmount || this.props.indicatorAmount === 0 ? this.getChart() :
              <p>Nog geen data beschikbaar.</p>}

          </div>
        </div>
      </CardBase>
    )
  }
}

export default injectIntl(IndicatorCard)
