import React, { Component } from 'react'
import DashboardFrame from '../../../components/Dashboard/DashboardFrame/DashboardFrame'
import ListTable from '../../../components/Miscellaneous/ListTable/ListTable'
import {withRouter} from 'react-router'
import {NavLink} from "react-router-dom"
import axios from "axios"
import {rootUrl} from '../../../config'
import EditSurveyDialog from "../../../components/Dashboard/EditSurveyDialog/EditSurveyDialog"
import Swal from "sweetalert2"
import ShareDialog from "../../../components/Dashboard/ShareDialog/ShareDialog"
import CopySurveyDialog from "../../../components/Dashboard/CopySurveyDialog/CopySurveyDialog"
import { FormattedMessage, injectIntl } from 'react-intl'
import {Form} from "react-bootstrap"
import ShowQRCodeDialog from "../../../components/Dashboard/ShowQRCodeDialog/ShowQRCodeDialog"

class Surveys extends Component {
  constructor(props) {
    super(props)
    this.state = {
      surveys: [],
      showEditSurveyDialog: false,
      showShareSurveyDialog: false,
      showCopySurveyDialog: false,
      showQRCodeDialog: false,
      search: '',
      duplicateRespondents: false
    }
  }

  componentDidMount() {
    this.getSurveys()
    this.getSurveysSummary(() => {})
  }

  getSurveys(callback) {
    axios.post(rootUrl + '/api/v1/dashboard/surveys'
    ).then((res) => {
      this.setState({
        surveys: res.data["surveys"]
      },
      () => {
        typeof callback === 'function' && callback()
      })
    }).catch((error) => {
      console.log(error.response.data)
    })
  }

  getSurveysSummary(callback) {
    this.setState({selectedIndex: -1})
    axios.post(rootUrl + '/api/v1/dashboard/projectssummary')
      .then((res) => {
        typeof callback === 'function' && callback()
      }).catch((error) => {
        console.log(error)
    })
  }

  shareButtonClickHandler(row) {
    this.setState({
      showShareDialog: true,
      shareDialogSurveyTitle: row[1],
      shareDialogSurveyId: row[2]
    })
  }

  QRCodeButtonClickedHandler(row) {
    this.setState({
      showQRCodeDialog: true,
      QRDialogSurveyTitle: row[1],
      QRDialogSurveyId: row[2]
    })
  }

  closeShareDialog() {
    this.setState({
      showShareDialog: false
    })
    this.getSurveys()
  }

  closeQRDialog() {
    this.setState({
      showQRCodeDialog: false
    })
    this.getSurveys()
  }

  copyButtonClickHandler(row, duplicateRespondents) {
    console.log(duplicateRespondents)
    this.setState({
      showCopySurveyDialog: true,
      copySurveyDialogTitle: row[1],
      copySurveyDialogId: row[2],
      duplicateRespondents: duplicateRespondents
    })
  }

  closeCopySurveyDialog() {
    this.setState({
      showCopySurveyDialog: false
    }, () => {
      this.getSurveys()
    })
  }

  editButtonClickHandler(row) {
    const id = row[2]

    axios.get(rootUrl + '/api/v1/survey/get_survey_settings/' + id
    ).then((res) => {
      let editData = res.data[0]
      editData.id = id
      this.setState({
        showEditSurveyDialog: true,
        editData: editData,
        surveyEditable: true//parseInt(row[3]) === 0
      })
    }).catch((error) => {
      console.log(error.response.data)
    })
  }

  removeButtonClickedHandler(row) {
    const name = row[1]
    const id = row[2]

    Swal.fire({
      title: 'Vragenlijst verwijderen',
      text: "Weet je zeker dat je vragenlijst " + name + " wilt verwijderen?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Verwijderen',
      cancelButtonText: 'Annuleren'
    }).then((result) => {
      if (result.value) {
        axios.post(rootUrl + '/api/v1/survey/remove_survey',
          {id: id}
        ).then((res) => {
          let temp = this.state.surveys
          let index = temp.findIndex(x => x.id === id)
          temp.splice(index, 1)
          this.setState({
            surveys: temp
          })
          Swal.fire({
            title: `Vragenlijst verwijderd.`,
            type: 'success'
          })
          this.forceUpdate()
        }).catch((error) => {
          Swal.fire({
            title: `Vragenlijst verwijderen niet gelukt!`,
            type: 'warning'
          })
          console.log(error.response.data)
        })
      }
    })
  }

  previewButtonClickedHandler(row) {
    const id = row[2]
    let surveyObj = this.state.surveys.find(x => x.id === id)
    if(!surveyObj) {
      return;
    } else {
      window.open( "/language-selection/" + surveyObj.uuid + '/preview', "_blank")
    }
  }

  goToSurveyClickedHandler(row) {
    const id = row[2]
    let surveyObj = this.state.surveys.find(x => x.id === id)
    if(!surveyObj) {
      return;
    } else {
      window.open( "/language-selection/" + surveyObj.uuid, "_blank")
    }
  }

  render() {
    const intl = this.props.intl

    return (
      <>
        <DashboardFrame
          title={this.props.intl.formatMessage({id: "surveys.surveys"})}
          dashboardTitle={this.props.intl.formatMessage({id: "surveys.surveys"})}
          onLanguageChanged={(language) => this.props.onLanguageChanged(language)}>
          <div className="card-box slide-up">
            <div className="col-12">
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div>
                  <h4 className="header-title m-t-0"><FormattedMessage id={"surveys.surveys"} defaultMessage={"Vragenlijsten"} /></h4>
                  <p className="text-muted font-13 m-b-30"><FormattedMessage id={"surveys.surveys_info"} defaultMessage={"Hier staan alle vragenlijsten waar u toegang tot heeft. U kunt een voorbeeld bekijken door op de 'preview' knop te drukken."} /></p>
                </div>
                <div style={{marginTop: 10}}>
                  <Form.Group>
                    <Form.Control
                      value={this.state.search}
                      onChange={(e) => this.setState({search: e.target.value})}
                      placeholder={this.props.intl.formatMessage({id: "surveys.search"})}
                    />
                  </Form.Group>
                </div>
              </div>


              <ListTable headers={["#",
                            intl.formatMessage({id: "surveys.survey_title"}),
                            intl.formatMessage({id: "surveys.identificator"}),
                            intl.formatMessage({id: "surveys.respondents"}),
                            intl.formatMessage({id: "surveys.project"}),
                            intl.formatMessage({id: "surveys.organization"}),
                            intl.formatMessage({id: "surveys.status"})]}
                         rows={this.state.surveys.filter(x =>
                           this.state.search === '' ||
                           (typeof x["internal_name"] == "string" && x["internal_name"].toLowerCase().includes(this.state.search.toLowerCase())) ||
                           (typeof x["project_name"] == "string" && x["project_name"].toLowerCase().includes(this.state.search.toLowerCase())) ||
                             (typeof x["organization_name"] == "string" && x["organization_name"].toLowerCase().includes(this.state.search.toLowerCase()))
                         ).map((item, itemIndex) => {
                           return [itemIndex + 1, item["internal_name"],item["id"], item["count"], item['project_name'], item['organization_name'], item['status'] === 1 ? this.props.intl.formatMessage({id: "status.active"}) : this.props.intl.formatMessage({id: "status.inactive"})]
                         })}
                         duplicateButton={parseInt(localStorage.getItem('role')) === 1}
                         duplicateRespondents={parseInt(localStorage.getItem('role')) === 1}
                         previewButton
                        //  QRCodeButton
                         onCopyButtonClicked={(row, duplicateRespondents) => this.copyButtonClickHandler(row, duplicateRespondents)}
                         onRemoveButtonClicked={(row) => this.removeButtonClickedHandler(row)}
                         onPreviewButtonClicked={(row) => this.previewButtonClickedHandler(row)}
                         goToSurveyButtonClicked={(row) => this.goToSurveyClickedHandler(row)}
                         editButton={parseInt(localStorage.getItem('role')) === 1}
                         shareButton={parseInt(localStorage.getItem('role')) === 1}
                         removeButton={parseInt(localStorage.getItem('role')) === 1}
                         onShareButtonClicked={(row) => this.shareButtonClickHandler(row)}
                         onEditButtonClicked={(row) => this.editButtonClickHandler(row)}
                         onQRCodeButtonClicked={(row) => this.QRCodeButtonClickedHandler(row)}
                         goToSurveyButton
              />

              {this.state.showEditSurveyDialog &&
                <EditSurveyDialog
                  onClose={() => this.setState({showEditSurveyDialog: false}, () => {
                    this.getSurveys()
                  })}
                  title={"Vragenlijst aanpassen"}
                  editData={this.state.editData}
                  surveyEditable={!!(this.state.surveyEditable)}
                />
              }

              {this.state.showShareDialog &&
                <ShareDialog
                  title={this.props.intl.formatMessage({id: "share_survey.title"}) + " " + this.state.shareDialogSurveyId}
                  onClose={() => this.closeShareDialog()}
                  surveyTitle={this.state.shareDialogSurveyTitle}
                  surveyId={this.state.shareDialogSurveyId}
                />
              }

              {this.state.showCopySurveyDialog &&
                <CopySurveyDialog
                  title={this.props.intl.formatMessage({id: "copy_survey.copy_survey"}) + " " + this.state.copySurveyDialogId}
                  onClose={() => this.closeCopySurveyDialog()}
                  surveyTitle={this.state.copySurveyDialogTitle}
                  surveyId={this.state.copySurveyDialogId}
                  duplicateRespondents={this.state.duplicateRespondents}
                />
              }

              {
                this.state.showQRCodeDialog &&
                <ShowQRCodeDialog
                title={this.props.intl.formatMessage({id: "qr_code.qr_code"})}
                onClose={() => this.closeQRDialog()}
                surveyTitle={this.state.QRDialogSurveyTitle}
                surveyId={this.state.QRDialogSurveyId}
                surveyUUID={this.state.surveys.find(x => x.id === this.state.QRDialogSurveyId).uuid
                }
                />
              }

              {parseInt(localStorage.getItem('role')) === 1 &&
              <NavLink to={this.props.intl.formatMessage({id: "link.new_survey"})}>
                <button className="btn btn-primary">
                  <i className="fa fa-plus"/> <FormattedMessage id={"surveys.new_survey"} defaultMessage={"Vragenlijst aanmaken"} />
                </button>
              </NavLink>}
            </div>
          </div>
        </DashboardFrame>
      </>
    )
  }
}

export default withRouter(injectIntl(Surveys))
