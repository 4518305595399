import React, { Component } from 'react'
import {Form} from "react-bootstrap"
import ComponentHolder from "../ComponentHolder/ComponentHolder"
import { injectIntl } from "react-intl";
import IonRangeSlider from 'react-ion-slider'
import './NetPromoter.css'

class NetPromoter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      radio: ((typeof this.props.answer !== "undefined") ? this.props.answer : -1)
    }
  }

  handleClick = nr => {
    this.setState({
      radio: nr
    }, () => {
      this.props.validateAnswer(this.props.position, true, nr)
    })
    console.log(this.props.options);
    if (this.props.hasRoutingInformation) {
      if(this.props.options[nr].route_to_page) {
        this.props.onRoutingChanged(this.props.options[nr].route_to_page)
      } else if (this.props.options[nr].route_to_url) {
        this.props.onRoutingChanged(this.props.options[nr].route_to_url)
      }
    }
  }

  // loadOptions() {
  //   let result = []
  //   for (let i = 0; i <= 10; i++) {
  //     result.push(
  //       <Form.Check
  //         key={i}
  //         type={"radio"}
  //         label={i.toString()}
  //         name={"radio-group"}
  //         checked={this.state.radio === i}
  //         onChange={() => this.handleClick(i)}
  //         className={"radio" + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " f-right clear-right" : "")}
  //       />
  //     )
  //   }

  //   return result
  // }

  loadOptions() {
    let result = []
    var custom_values = ["Erg moeilijk", "Moeilijk", "Verwachte moeite", "Makkelijk", "Erg makkelijk"];


    // for (let i = 0; i <= 10; i++) {
    result.push(
        <React.Fragment>
          <IonRangeSlider
              skin={"flat"}
              type={"single"}
              grid={true}
              min={1}
              max={5}
              values={custom_values}
              // onFinish={(data) => console.log(data.from)}
              onFinish={(data) => this.handleClick(data.from)}
              ref={this.slider}
          />
            {/* <input
              type={"text"}
              className={"survey-netpromoter" + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " f-right clear-right" : "")}
              placeholder={this.props.intl.formatMessage({id: "survey.elaborate"})}
              onChange={(e) => {
                this.setState({openText: e.target.value},
                  () => {
                    this.validateQuestion()
                  })
              }}
              value={this.state.openText}
            /> */}
        </React.Fragment>
      )

    // }

    return result
  }

  render() {
    return (
      <ComponentHolder {...this.props}>
        <Form>
         {this.loadOptions()}
        </Form>
      </ComponentHolder>
    )
  }
}

export default injectIntl(NetPromoter)
