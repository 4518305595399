import React, { Component } from 'react'
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import {Form} from "react-bootstrap"
import axios from "axios"
import {rootUrl} from "../../../config"
import Swal from "sweetalert2"
import {FormattedMessage, injectIntl} from 'react-intl'
import sha256 from "sha256"

class EditPasswordDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstPassword: "",
      secondPassword: ""
    }
  }

  changePassword() {
    if (this.state.firstPassword === this.state.secondPassword && this.state.firstPassword !== "") {
      axios.post(rootUrl + '/api/v1/change_password',
        {
          password: sha256(this.state.firstPassword)
        }
      ).then((res) => {
        Swal.fire({
          title: this.props.intl.formatMessage({id: "dialogs.success"}),
          text: this.props.intl.formatMessage({id: "dialogs.password_saved"}),
          type: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          this.props.onClose()
        })
      }).catch((error) => {
        console.log(error.response)
        Swal.fire({
          title: this.props.intl.formatMessage({id: "dialogs.error_title"}),
          text: this.props.intl.formatMessage({id: "dialogs.error_message"}),
          type: 'error',
          confirmButtonText: 'OK'
        })
      })
    } else {
      Swal.fire({
        title: this.props.intl.formatMessage({id: "dialogs.warning_title"}),
        text: this.props.intl.formatMessage({id: "dialogs.passwords_not_equal"}),
        type: 'warning',
        confirmButtonText: 'OK'
      })
    }
  }

  render() {
    return (
      <PopupDialog
        title={this.props.intl.formatMessage({id: "edit_password.edit_password"})}
        onClose={() => this.props.onClose()}
      >
        <FormattedMessage id={"edit_password.info"} defaultMessage={"U kunt hier uw wachtwoord wijzigen."} />

        <div className="row m-t-20 m-b-20">
          <div className="col-xs-12">
            <Form.Group>
              <Form.Label><FormattedMessage id={"edit_password.password"} defaultMessage={"Wachtwoord"} /></Form.Label>
              <Form.Control
                value={this.state.firstPassword}
                onChange={(e) => this.setState({firstPassword: e.target.value})}
                required
                placeholder={this.props.intl.formatMessage({id: "edit_password.password"})}
                type={"password"}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label><FormattedMessage id={"edit_password.repeat_password"} defaultMessage={"Wachtwoord herhalen"} /></Form.Label>
              <Form.Control
                value={this.state.secondPassword}
                onChange={(e) => this.setState({secondPassword: e.target.value})}
                required
                placeholder={this.props.intl.formatMessage({id: "edit_password.repeat_password"})}
                type={"password"}
              />
            </Form.Group>
          </div>
        </div>

        <button
          className="btn btn-primary"
          onClick={() => this.changePassword()}
        >
          <i className="fa fa-save"/> <FormattedMessage id={"ui.save"} defaultMessage={"Opslaan"} />
        </button>
        <button className="btn btn-secondary m-l-5" onClick={()=> this.props.onClose()}> <FormattedMessage id={"ui.cancel"} defaultMessage={"Annuleren"} /></button>
      </PopupDialog>
    )
  }
}

export default injectIntl(EditPasswordDialog)
