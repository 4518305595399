import React, { Component } from 'react'
import {Form} from "react-bootstrap"
import ComponentHolder from "../ComponentHolder/ComponentHolder"
import { injectIntl } from "react-intl"
import IonRangeSlider from 'react-ion-slider'
import './GradeControlSlider.css'

class GradeControl extends Component {
  constructor(props) {
    super(props)

    this.state = {
      radio: ((typeof this.props.answer !== "undefined") ? this.props.answer : -1)
    }
  }

  handleClick = nr => {
    this.setState({
      radio: nr
    }, () => {
      this.props.validateAnswer(this.props.position, true, nr)
    })
  }

  loadOptions() {
    let result = []
    let randomString = Math.random().toString(36).substring(2, 12) + Math.random().toString(36).substring(2, 12)

      result.push(
        <IonRangeSlider
        skin={"flat"}
        type={"single"}
        // grid={true}
        min={1}
        max={10}
        // onFinish={(data) => this.handleOnFinish(data)}
        onFinish={(data) => this.handleClick(data.from)}
        ref={this.slider}
        />
      )

    return result
  }

  // loadOptions() {
  //   let result = []
  //   let randomString = Math.random().toString(36).substring(2, 12) + Math.random().toString(36).substring(2, 12)

  //   for (let i = 1; i <= 10; i++) {
  //     result.push(
  //       <Form.Check
  //         key={i}
  //         type={"radio"}
  //         label={i.toString()}
  //         name={"radio-group"}
  //         checked={this.state.radio === i}
  //         onChange={() => this.handleClick(i)}
  //         id={"radiobutton" + randomString + i.toString()}
  //         className={"radio" + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " f-right clear-right" : "")}
  //       />
  //     )
  //   }

  //   return result
  // }

  render() {
    return (
      <ComponentHolder {...this.props}>
        <Form>
         {this.loadOptions()}
        </Form>
      </ComponentHolder>
    )
  }
}

export default injectIntl(GradeControl)
