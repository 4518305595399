import React, { Component } from 'react'
import SingleMultipleChoice from '../../../components/Survey/SingleMultipleChoice/SingleMultipleChoice'
import MultipleChoice from '../../../components/Survey/MultipleChoice/MultipleChoice'
import DropdownControl from '../../../components/Survey/DropdownControl/DropdownControl'
import NumberControl from '../../../components/Survey/NumberControl/NumberControl'
import TextareaControl from '../../../components/Survey/TextareaControl/TextareaControl'
import TextboxControl from '../../../components/Survey/TextboxControl/TextboxControl'
import GradeControl from '../../../components/Survey/GradeControl/GradeControl'
import NetPromoterRouting from '../../../components/Survey/NetPromoterRouting/NetPromoterRouting'
import NetPromoter from '../../../components/Survey/NetPromoter/NetPromoter'
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner/LoadingSpinner'
import axios from "axios"
import './Page.css'
import {rootUrl} from '../../../config'
import smoothscroll from 'smoothscroll-polyfill'
import scrollIntoView from 'scroll-into-view-if-needed'
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'
import {FormattedMessage, injectIntl} from 'react-intl'
import {Badge} from "mdbreact"
import {Button} from "react-bootstrap"

class Page extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pageIndex: this.props.pageIndex,
      components: [],
      loading: true,
      activeComponent: 0,
      componentRefs: {}
    }

    this.getPageComponents(this.props.uuid, this.state.pageIndex)
    this.bottomOfPage = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true)
  }

  handleScroll = (event) => {
    let offsets = []
    for (let i = 0; i < Object.keys(this.state.componentRefs).length; i++) {
      try {
        let rect = this.state.componentRefs[i].current.getBoundingClientRect()
        offsets.push(rect.top + rect.height)
      } catch(e) {}
    }

    if (offsets.length > 0) {
      let goal = window.screen.height / 2
      let closest = offsets.reduce((prev, curr) => {
        return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev)
      })

      let activeElement = 0
      if (event.srcElement.scrollTop > 50) {
        activeElement = offsets.indexOf(closest)
      }

      // Bottom of page, so active element is last element.
      // this.bottomOfPage is absolutely positioned at the bottom of the page.
      // 120 is a correction for the remaining space under the bottom of the page.
      if (this.bottomOfPage.current.getBoundingClientRect().top + 120 <= window.innerHeight) {
        activeElement = offsets.length - 1
      }

      this.setState({
        activeComponent: activeElement
      })
    }
  }

  validateAnswer(position, valid, answer, scrollToNext = true) {
    let components = this.state.components
    components[position].valid = valid
    components[position].answer = answer

    let activeComponent = this.state.activeComponent
    let scrollToEnd = (valid && activeComponent === this.state.components.length - 1 && scrollToNext)
    if (valid && !scrollToEnd && scrollToNext) {
      activeComponent++
    }

    this.setState({
      components: components,
      activeComponent: activeComponent
    }, () => {
      this.checkPageValidity()

      if (scrollToEnd) {
        // Scroll to bottom of page.
        this.props.scrollToBottom()
      } else if (valid && scrollToNext) {
        smoothscroll.polyfill()

        // Scroll to next component
        let element = this.state.componentRefs[this.state.activeComponent].current
        /*element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })*/

        const scrollIntoViewSmoothly =
          'scrollBehavior' in document.documentElement.style
            ? scrollIntoView
            : smoothScrollIntoView

        scrollIntoViewSmoothly(element, {
          block: 'center',
          scrollMode: 'always',
          behavior: 'smooth'
        })
      }
    })
  }

  checkPageValidity() {
    let answers = []

    for (let i = 0; i < this.state.components.length; i++) {
      answers.push({
                      position: i+1,
                      answer: this.state.components[i].answer
                    })
      if (this.state.components[i].valid === false && this.state.components[i].required) {
        this.props.validatePage(false, [])
        return
      }
    }

    this.props.validatePage(true, answers)
  }

   goToQuestion(index) {
    smoothscroll.polyfill()

    // Scroll to next component
    let element = this.state.componentRefs[index].current
    /*element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })*/

    const scrollIntoViewSmoothly =
      'scrollBehavior' in document.documentElement.style
        ? scrollIntoView
        : smoothScrollIntoView

    scrollIntoViewSmoothly(element, {
      block: 'center',
      scrollMode: 'always',
      behavior: 'smooth'
    })
  }

  loadComponents() {
    let result = []
    let component
    //let componentRefs = this.state.componentRefs

    for (let i = 0; i < this.state.components.length; i++) {
      component = this.state.components[i]
      switch(component.type) {
        case 1:
          result.push(
            <div
              ref={this.state.componentRefs[i]}
              key={i}
              style={{width:"100%"}}>
              <SingleMultipleChoice
                key={i}
                position={i}
                text={i+1 + '. ' + component.text}
                options={component.options}
                required={component.required}
                validateAnswer={this.validateAnswer.bind(this)}
                answer={typeof this.props.answers !== 'undefined' && this.props.answers.length > 0 && this.props.answers[i].answer}
                className={"component-bottom-margin"
                  + (i === 0 ? "" : " component-top-margin")
                  + (this.state.activeComponent !== i ? " inactive-component" : "")}
              />
            </div>)
          break
        case 2:
          result.push(
            <div
              ref={this.state.componentRefs[i]}
              key={i}
              style={{width:"100%"}}>
              <MultipleChoice
                key={i}
                position={i}
                text={i+1 + '. ' + component.text}
                options={component.options}
                required={component.required}
                validateAnswer={this.validateAnswer.bind(this)}
                answer={typeof this.props.answers !== 'undefined' && this.props.answers.length > 0 && this.props.answers[i].answer}
                className={"component-bottom-margin"
                  + (i === 0 ? "" : " component-top-margin")
                  + (this.state.activeComponent !== i ? " inactive-component" : "")}
              />
            </div>)
          break
        case 3:
          result.push(
            <div
              ref={this.state.componentRefs[i]}
              key={i}
              style={{width:"100%"}}>
              <DropdownControl
                key={i}
                position={i}
                text={i+1 + '. ' + component.text}
                options={component.options}
                required={component.required}
                validateAnswer={this.validateAnswer.bind(this)}
                answer={typeof this.props.answers !== 'undefined' && this.props.answers.length > 0 && this.props.answers[i].answer}
                className={"component-bottom-margin"
                  + (i === 0 ? "" : " component-top-margin")
                  + (this.state.activeComponent !== i ? " inactive-component" : "")}
              />
            </div>)
          break
        case 4:
          result.push(
            <div
              ref={this.state.componentRefs[i]}
              key={i}
              style={{width:"100%"}}>
                <TextareaControl
                  key={i}
                  position={i}
                  text={i+1 + '. ' + component.text}
                  required={component.required}
                  validateAnswer={this.validateAnswer.bind(this)}
                  answer={typeof this.props.answers !== 'undefined' && this.props.answers.length > 0 && this.props.answers[i].answer}
                  className={"component-bottom-margin"
                    + (i === 0 ? "" : " component-top-margin")
                    + (this.state.activeComponent !== i ? " inactive-component" : "")}
                />
            </div>)
          break
        case 5:
          result.push(
            <div
              ref={this.state.componentRefs[i]}
              key={i}
              style={{width:"100%"}}>
              <TextboxControl
                key={i}
                position={i}
                required={component.required}
                text={i+1 + '. ' + component.text}
                type={"text"}
                placeholder={this.props.intl.formatMessage({id: "survey.answer"})}
                validateAnswer={this.validateAnswer.bind(this)}
                answer={typeof this.props.answers !== 'undefined' && this.props.answers.length > 0 && this.props.answers[i].answer}
                className={"component-bottom-margin"
                  + (i === 0 ? "" : " component-top-margin")
                  + (this.state.activeComponent !== i ? " inactive-component" : "")}
              />
            </div>)
          break
        case 6:
          result.push(
            <div
              ref={this.state.componentRefs[i]}
              key={i}
              style={{width:"100%"}}>
              <TextboxControl
                key={i}
                position={i}
                required={component.required}
                text={i+1 + '. ' + component.text}
                type={"date"}
                validateAnswer={this.validateAnswer.bind(this)}
                answer={typeof this.props.answers !== 'undefined' && this.props.answers.length > 0 && this.props.answers[i].answer}
                className={"component-bottom-margin"
                  + (i === 0 ? "" : " component-top-margin")
                  + (this.state.activeComponent !== i ? " inactive-component" : "")}
              />
            </div>)
          break
        case 7:
          result.push(
            <div
              ref={this.state.componentRefs[i]}
              key={i}
              style={{width:"100%"}}>
              <TextboxControl
                key={i}
                position={i}
                required={component.required}
                text={i+1 + '. ' + component.text}
                type={"email"}
                placeholder={"voorbeeld@domein.nl"}
                validateAnswer={this.validateAnswer.bind(this)}
                answer={typeof this.props.answers !== 'undefined' && this.props.answers.length > 0 && this.props.answers[i].answer}
                className={"component-bottom-margin"
                  + (i === 0 ? "" : " component-top-margin")
                  + (this.state.activeComponent !== i ? " inactive-component" : "")}
              />
            </div>)
          break
        case 8:
          result.push(
            <div
              ref={this.state.componentRefs[i]}
              key={i}
              style={{width:"100%"}}>
              <NumberControl
                key={i}
                position={i}
                required={component.required}
                text={i+1 + '. ' + component.text}
                number={10}
                validateAnswer={this.validateAnswer.bind(this)}
                answer={typeof this.props.answers !== 'undefined' && this.props.answers.length > 0 && this.props.answers[i].answer}
                className={"component-bottom-margin"
                  + (i === 0 ? "" : " component-top-margin")
                  + (this.state.activeComponent !== i ? " inactive-component" : "")}
              />
            </div>)
          break
        case 9:
          result.push(
            <div
              ref={this.state.componentRefs[i]}
              key={i}
              style={{width:"100%"}}>
              <GradeControl
                key={i}
                position={i}
                required={component.required}
                text={i+1 + '. ' + component.text}
                validateAnswer={this.validateAnswer.bind(this)}
                answer={typeof this.props.answers !== 'undefined' && this.props.answers.length > 0 && this.props.answers[i].answer}
                className={"component-bottom-margin"
                  + (i === 0 ? "" : " component-top-margin")
                  + (this.state.activeComponent !== i ? " inactive-component" : "")}
              />
            </div>)
          break
        case 10:
          result.push(
            <div
              ref={this.state.componentRefs[i]}
              key={i}
              style={{width:"100%"}}>
              <NetPromoter
                key={i}
                position={i}
                required={component.required}
                text={i+1 + '. ' + component.text}
                validateAnswer={this.validateAnswer.bind(this)}
                answer={typeof this.props.answers !== 'undefined' && this.props.answers.length > 0 && this.props.answers[i].answer}
                className={"component-bottom-margin"
                  + (i === 0 ? "" : " component-top-margin")
                  + (this.state.activeComponent !== i ? " inactive-component" : "")}
              />
            </div>)
          break
        case 11:
          result.push(
            <div
              ref={this.state.componentRefs[i]}
              key={i}
              style={{width:"100%"}}>
              <SingleMultipleChoice
                hasRoutingInformation={true}
                onRoutingChanged={(nextPage) => this.props.onRoutingChanged(nextPage)}
                key={i}
                position={i}
                text={i+1 + '. ' + component.text}
                options={component.options}
                required={component.required}
                validateAnswer={this.validateAnswer.bind(this)}
                answer={typeof this.props.answers !== 'undefined' && this.props.answers.length > 0 && this.props.answers[i].answer}
                className={"component-bottom-margin"
                + (i === 0 ? "" : " component-top-margin")
                + (this.state.activeComponent !== i ? " inactive-component" : "")}
              />
            </div>)
          break
      case 12:
        result.push(
          <div
            ref={this.state.componentRefs[i]}
            key={i}
            style={{width:"100%"}}>
            <NetPromoterRouting
              hasRoutingInformation={true}
              onRoutingChanged={(nextPage) => this.props.onRoutingChanged(nextPage)}
              options={component.options}
              key={i}
              position={i}
              required={component.required}
              text={i+1 + '. ' + component.text}
              validateAnswer={this.validateAnswer.bind(this)}
              answer={typeof this.props.answers !== 'undefined' && this.props.answers.length > 0 && this.props.answers[i].answer}
              className={"component-bottom-margin"
              + (i === 0 ? "" : " component-top-margin")
              + (this.state.activeComponent !== i ? " inactive-component" : "")}
            />
          </div>)
        break
        default:
      }
    }

    return result
  }

  getPageComponents(uuid, pageIndex) {
    axios.post(rootUrl + '/api/v1/survey/get_translated_page_components', {
      surveyId: uuid,
      pageIndex: pageIndex,
      locale: this.props.locale
    }).then((res) => {
      let components = res.data
      console.log(components)
      let componentRefs = {}

      for (let i = 0; i < components.length; i++) {
        components[i].valid = false
        components[i].answer = null

        componentRefs[i] = React.createRef()
      }

      this.setState({
        components: components,
        loading: false,
        componentRefs: componentRefs
      }, () => {
        this.loadComponents()
        this.checkPageValidity()
      })
    }).catch((error) => {
      console.log(error)
    })
  }

  render() {
    return (
      <>
        {(this.state.loading || this.props.loading )&& <LoadingSpinner />}
        {!this.state.loading && !this.props.loading &&
          this.loadComponents()
        }
        {this.state.components && this.state.components.filter(x => !x.valid).length > 0  && this.state.components.filter(x => !x.valid).length < 10 && <div style={{border: '1px solid black', borderRadius: 20, padding: 20}} className={"full-width component-holder last-page-message" + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " arabic-text" : "")}>
          <FormattedMessage id={"survey.missing_questions"} defaultMessage={"U heeft de volgende verplichte* vragen nog niet ingevuld: "} />
          <br/>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            {this.state.components.map((x, index) => {return {valid: x.valid, index}}).filter(x => !x.valid).map(x => {
              return <a style={{margin: 2}} href={"#"} onClick={() => this.goToQuestion(x.index)}>{x.index + 1}</a>
            })}</div>
        </div>}
        <br/>
        <br/>
        <div className={"bottom-of-page"} ref={this.bottomOfPage} />
      </>
    )
  }
}

export default injectIntl(Page, {forwardRef: true})
