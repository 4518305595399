import React, { Component } from 'react'
import { SketchPicker } from 'react-color'
import {Button, Form} from "react-bootstrap"
import {NavLink} from 'react-router-dom'
import './SurveySettings.css'
import axios from 'axios'
import config, {rootUrl} from '../../../../config'
import {Dropdown} from "semantic-ui-react"
import InputDialog from "../../../../components/Dashboard/InputDialog/InputDialog"
import Swal from "sweetalert2"
import {FormattedMessage, injectIntl} from 'react-intl'
import Flags from 'country-flag-icons/react/3x2'
import TranslationsDialog from "../../../../components/Dashboard/TranslationsDialog/TranslationsDialog";

class SurveySettings extends Component {
  constructor(props) {
    super(props)

    /*
      SurveyName is the name of the survey.
      SurveyTitle is the title of the survey and shown as subtitle on all pages.
      SurveyColor is the color of the color block at the top of the survey.
      BeginText is the text shown at the start of the survey.
      EndText is the text shown at the end of the survey.
    */
    this.state = {
      surveyName: (this.props.surveyName ? this.props.surveyName : ""),
      surveyTitle: (this.props.surveyTitle ? this.props.surveyTitle : ""),
      surveyTitleTranslations: {},
      description: (this.props.description ? this.props.description : ""),
      surveyColor: (this.props.surveyColor ? this.props.surveyColor : "#3b699c"),
      beginText: (this.props.beginText ? this.props.beginText : ""),
      beginTextTranslations: {},
      endText: (this.props.endText ? this.props.endText : ""),
      endTextTranslations: {},
      displayColorPicker: false,
      projects: [],
      projectItems: [],
      projectIds: [],
      selectedProjectIndex: -1,
      showNewProjectDialog: false,
      translations: [this.props.intl.formatMessage({id: "misc.locale"})],
      locale: this.props.intl.formatMessage({id: "misc.locale"})
    }

    this.state.surveyTitleTranslations[this.state.locale] = ""
    this.state.beginTextTranslations[this.state.locale] = ""
    this.state.endTextTranslations[this.state.locale] = ""

    this.fetchProjects(() => {
      this.props.editSurveyId && this.fetchSurveySettings()
    })
  }

  getCountryFlag(locale, key) {
    if (locale === "nl") {
      return <Flags.NL title={this.props.intl.formatMessage({id: "languages.nl"})} className={"translation-flag"} key={key} onClick={() => this.handleTranslationChange("nl")} />
    } else if (locale === "en") {
      return <Flags.GB title={this.props.intl.formatMessage({id: "languages.en"})} className={"translation-flag"} key={key} onClick={() => this.handleTranslationChange("en")} />
    } else if (locale === "de") {
      return <Flags.DE title={this.props.intl.formatMessage({id: "languages.de"})} className={"translation-flag"} key={key} onClick={() => this.handleTranslationChange("de")} />
    } else if (locale === "de-at") {
      return <Flags.AT title={this.props.intl.formatMessage({id: "languages.de-at"})} className={"translation-flag"} key={key} onClick={() => this.handleTranslationChange("de-at")} />
    } else if (locale === "es") {
      return <Flags.ES title={this.props.intl.formatMessage({id: "languages.es"})} className={"translation-flag"} key={key} onClick={() => this.handleTranslationChange("es")} />
    } else if (locale === "bn") {
      return <Flags.IN title={this.props.intl.formatMessage({id: "languages.bn"})} className={"translation-flag"} key={key} onClick={() => this.handleTranslationChange("bn")} />
    } else if (locale === "tr") {
      return <Flags.TR title={this.props.intl.formatMessage({id: "languages.tr"})} className={"translation-flag"} key={key} onClick={() => this.handleTranslationChange("tr")} />
    } else if (locale === "ru") {
      return <Flags.RU title={this.props.intl.formatMessage({id: "languages.ru"})} className={"translation-flag"} key={key} onClick={() => this.handleTranslationChange("ru")} />
    } else if (locale === "se") {
      return <Flags.SE title={this.props.intl.formatMessage({id: "languages.se"})} className={"translation-flag"} key={key} onClick={() => this.handleTranslationChange("se")} />
    } else if (locale === "pl") {
      return <Flags.PL title={this.props.intl.formatMessage({id: "languages.pl"})} className={"translation-flag"} key={key} onClick={() => this.handleTranslationChange("pl")} />
    } else if (locale === "ar") {
      return <Flags.SA title={this.props.intl.formatMessage({id: "languages.ar"})} className={"translation-flag"} key={key} onClick={() => this.handleTranslationChange("ar")} />
    } else if (locale === "it") {
      return <Flags.IT title={this.props.intl.formatMessage({id: "languages.it"})} className={"translation-flag"} key={key} onClick={() => this.handleTranslationChange("it")} />
    } else if (locale === "fr") {
      return <Flags.FR title={this.props.intl.formatMessage({id: "languages.fr"})} className={"translation-flag"} key={key} onClick={() => this.handleTranslationChange("fr")} />
    }

    return null
  }

  fetchProjects(callback) {
    axios.get(config.rootUrl + '/api/v1/dashboard/projects')
      .then((result) => {
        this.setState({
          projects: result.data.projects
        }, () => {
          callback && callback()
        })
      })
  }

  handleClick() {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleClose() {
    this.setState({ displayColorPicker: false })
  }

  // This method saves the color in the state in hex value and passes it up to the surveyFrame.
  handleColorChange(color) {
    this.setState({ surveyColor: color.hex })
    this.props.onColorChange(color)
  }

  handleTranslationChange(locale) {
    let surveyTitleTranslations = this.state.surveyTitleTranslations
    let surveyTitle = ""
    let beginTextTranslations = this.state.beginTextTranslations
    let beginText = ""
    let endTextTranslations = this.state.endTextTranslations
    let endText = ""

    if (locale in surveyTitleTranslations) {
      surveyTitle = surveyTitleTranslations[locale]
    } else {
      surveyTitleTranslations[locale] = ""
    }

    if (locale in beginTextTranslations) {
      beginText = beginTextTranslations[locale]
    } else {
      beginTextTranslations[locale] = ""
    }

    if (locale in endTextTranslations) {
      endText = endTextTranslations[locale]
    } else {
      endTextTranslations[locale] = ""
    }

    this.setState({
      locale: locale,
      surveyTitleTranslations: surveyTitleTranslations,
      surveyTitle: surveyTitle,
      beginTextTranslations: beginTextTranslations,
      beginText: beginText,
      endTextTranslations: endTextTranslations,
      endText: endText
    }, () => {
      this.props.onSelectedTranslationChange(locale)
      this.props.onSurveyTitleChange(this.state.surveyTitle)
    })
  }

  getSurveySettings() {
    const data = {
      surveyName: this.state.surveyName,
      surveyTitle: this.state.surveyTitleTranslations,
      description: this.state.description,
      surveyColor: this.state.surveyColor.replace("#", ""),
      beginText: this.state.beginTextTranslations,
      endText: this.state.endTextTranslations,
      selectedProjectId: this.state.projectIds[this.state.selectedProjectIndex]
    }

    return data
  }

  fetchSurveySettings() {
    axios.get(rootUrl + '/api/v1/survey/get_survey_settings_translated/' + this.props.editSurveyId)
      .then((res) => {
        const settings = res.data
        let projectIndex = -1
        for (let i = 0; i < this.state.projects.length; i++) {
          if (this.state.projects[i].id === settings.project_id) {
            projectIndex = i
            break
          }
        }

        this.setState({
          surveyName: settings.internal_name,
          description: settings.description,
          surveyColor: settings.color,
          selectedProjectIndex: projectIndex,
          beginTextTranslations: settings.beginText,
          beginText: settings.beginText[this.state.locale],
          endTextTranslations: settings.endText,
          endText: settings.endText[this.state.locale],
          surveyTitleTranslations: settings.surveyTitle,
          surveyTitle: settings.surveyTitle[this.state.locale],
          translations: Object.keys(settings.surveyTitle)
        }, () => {
          this.onTranslationsChange(this.state.translations)
          this.props.onColorChange({hex: this.state.surveyColor})
        })
    })
  }

  componentDidMount() {
    this.getProjects()
  }

  insertProject(projectName, callback) {
    axios.post(rootUrl + '/api/v1/new_project',
      {
        projectName: projectName
      }).then((res) => {
      callback()
    }).catch((error) => {
      console.log(error)
    })
  }

  getProjects(callback) {
    axios.get(rootUrl + '/api/v1/dashboard/projects')
      .then((res) => {
        const projects = res.data["projects"]

        if (projects.length > 0) {
          this.setState({
              projectItems: projects.map(item => {
                return item["name"]
              }),
              projectIds: projects.map(item => {
                return item["id"]
              })
            },
            () => {
              typeof callback === 'function' && callback()
            })
        }
      }).catch((error) => {
      console.log(error)
    })
  }

  getProjectOptions() {
    let options = this.state.projectItems.map((item, index) => {
      return {key: index, text: item, value: this.state.projectIds[index]}
    })

    return options
  }

  changeSelectedProject(value) {
    const selectedProjectIndex = this.state.surveyIds.indexOf(value)

    this.setState({
      selectedProjectIndex: selectedProjectIndex
    }, () => {
      this.props.onProjectChange(value)
    })
  }

  validateAndSave() {
    if (this.state.surveyName === "" || this.state.beginText === "" || this.state.description === "" ||
        this.state.surveyTitle === "" || this.state.endText === "" || this.state.selectedProjectIndex === -1) {
      Swal.fire({
        title: 'Niet alle velden ingevuld',
        text: 'Niet alle instellingen zijn correct ingevuld. Controleer de instellingen en probeer het opnieuw.',
        type: 'warning',
        confirmButtonText: 'OK'
      })
    } else {
      this.props.onSave()
    }
  }

  onTranslationsChange(translations) {
    this.props.onTranslationsChange(translations)
  }

  changeBeginText(value) {
    let beginTextTranslations = this.state.beginTextTranslations
    beginTextTranslations[this.state.locale] = value

    this.setState({
      beginTextTranslations: beginTextTranslations,
      beginText: value
    })
  }

  changeEndText(value) {
    let endTextTranslations = this.state.endTextTranslations
    endTextTranslations[this.state.locale] = value

    this.setState({
      endTextTranslations: endTextTranslations,
      endText: value
    })
  }

  changeSurveyTitle(value) {
    let surveyTitleTranslations = this.state.surveyTitleTranslations
    surveyTitleTranslations[this.state.locale] = value

    this.setState({
      surveyTitleTranslations: surveyTitleTranslations,
      surveyTitle: value
    }, () => {
      this.props.onSurveyTitleChange(this.state.surveyTitle)
    })
  }

  render() {
    return (
      <div className="settings-box slide-up">
        <div className="col-12">
          <h4 className="header-title m-t-0"><i className="fa fa-cog" /> <FormattedMessage id={"survey_builder.global_settings"} defaultMessage={"Globale instellingen"} /></h4>
          <p className="text-muted font-13 m-b-30"><FormattedMessage id={"survey_builder.global_settings_info"} defaultMessage={"Onderstaande instellingen gelden voor de gehele vragenlijst"} /></p>

          <div className="row">
            <div className="col-xs-6">
              <Form.Group>
                <Form.Label><FormattedMessage id={"edit_survey.name"} defaultMessage={"Naam"} /></Form.Label>
                <Form.Control
                  value={this.state.surveyName}
                  onChange={(e) => this.setState({surveyName: e.target.value})}
                  required
                  placeholder={this.props.intl.formatMessage({id: "edit_survey.name_placeholder"})}
                  tabIndex={1}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label><FormattedMessage id={"edit_survey.start_text"} defaultMessage={"Begintekst"} /></Form.Label>
                <Form.Control
                  value={this.state.beginText}
                  onChange={(e) => this.changeBeginText(e.target.value)}
                  placeholder={this.props.intl.formatMessage({id: "edit_survey.start_text_placeholder"})}
                  tabIndex={3}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label><FormattedMessage id={"edit_survey.description"} defaultMessage={"Beschrijving"} /></Form.Label>
                <Form.Control
                  value={this.state.description}
                  onChange={(e) => this.setState({description: e.target.value})}
                  placeholder={this.props.intl.formatMessage({id: "edit_survey.description_placeholder"})}
                  tabIndex={5}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label><FormattedMessage id={"edit_survey.color"} defaultMessage={"Kleur"} /></Form.Label><br />
                <div className="settings-swatch" onClick={() => this.handleClick()}>
                  <div className="settings-colorbox" style={{background: this.state.surveyColor}} />
                </div>
              </Form.Group>

              {/* <Form.Group>
                <Form.Label>Logo</Form.Label><br />
                {this.state.filePreview && <><img alt="" src={this.state.filePreview} className={"preview-logo"} /><br /></>}
                {!this.state.filePreview && this.state.fileBase64 && <><img alt="" src={this.state.fileBase64} className={"preview-logo"} /><br /></>}
                <input
                  type="file"
                  className={"custom-file-upload"}
                  name={"custom-file-upload"}
                  id={"custom-file-upload"}
                  accept={".png,.jpg,.jpeg,.gif,.bmp"}
                  onChange={(e) => this.handleFileChosen(e.target.files[0])}
                  ref={this.fileUpload}
                />
                <label htmlFor="custom-file-upload" className={"btn btn-primary"}>
                  <i className={"fa fa-upload m-r-5"} /> {(this.state.file ? this.state.file.name : "Geen bestand gekozen")}
                </label>
                {this.state.file &&
                <span
                  className={"fa fa-remove remove-file-button m-l-10"}
                  onClick={() => {
                    this.setState({
                      file: null,
                      filePreview: null
                    }, () => {
                      this.fileUpload.current.value = ""
                    })
                  }}
                />}
              </Form.Group> */}
            </div>

            <div className="col-xs-6">
              <Form.Group>
                <Form.Label><FormattedMessage id={"edit_survey.title"} defaultMessage={"Titel"} /></Form.Label>
                <Form.Control
                  value={this.state.surveyTitle}
                  onChange={(e) => this.changeSurveyTitle(e.target.value)}
                  required
                  placeholder={this.props.intl.formatMessage({id: "edit_survey.title_placeholder"})}
                  tabIndex={2}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label><FormattedMessage id={"edit_survey.end_text"} defaultMessage={"Eindtekst/omleidings URL"} /></Form.Label>
                <Form.Control
                  value={this.state.endText}
                  onChange={(e) => this.changeEndText(e.target.value)}
                  placeholder={this.props.intl.formatMessage({id: "edit_survey.end_text_placeholder"})}
                  tabIndex={4}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label><FormattedMessage id={"survey_builder.project"} defaultMessage={"Onderzoek"} /></Form.Label>
                <br/>
                <Dropdown
                  placeholder={this.props.intl.formatMessage({id: "survey_builder.select_project"})}
                  onChange={(e, {value}) => {
                    this.changeSelectedProject(value)
                  }}
                  className={"full-width selection"}
                  text={(this.state.selectedProjectIndex !== -1 ? this.state.projectItems[this.state.selectedProjectIndex] : "")}
                  style={this.state.selectedProjectIndex !== -1 ? {color: "#000 !important"} : {}}
                >
                  <Dropdown.Menu>
                    {this.getProjectOptions().map((item, index) => {
                      return <Dropdown.Item
                                key={item.key}
                                value={item.value}
                                onClick={() => this.setState({selectedProjectIndex: index})}>
                                {item.text}
                             </Dropdown.Item>
                    })}
                    {this.state.projectItems.length > 0 && <Dropdown.Divider />}
                    <Dropdown.Item onClick={() => this.setState({showNewProjectDialog: true})}><i className={"fa fa-plus"} /> Een onderzoek aanmaken...</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>

              <Form.Group>
                <Form.Label><FormattedMessage id={"survey_builder.translations"} defaultMessage={"Vertalingen"} /> <i className={"fa fa-edit"} style={{fontSize: "12px", cursor: "pointer"}} onClick={() => this.setState({showTranslationsDialog: true})} /></Form.Label>
                <span className={"translation-flag-description"}>
                  <FormattedMessage id={"edit_survey.change_translation_description"} />
                </span>
                <div className={"translation-flag-container"}>
                  {this.state.translations.map((item, index) => {
                    return this.getCountryFlag(item, index)
                  })}
                </div>
              </Form.Group>

            </div>

            {this.state.showNewProjectDialog &&
              <InputDialog
                title={"Nieuwe project"}
                informationText={"Vul de naam voor het nieuwe project in en klik op OK om het aan te maken."}
                inputLabel={"Projectnaam"}
                onClose={() => this.setState({showNewProjectDialog: false})}
                onSubmit={(value) => {
                  this.insertProject(value, () => {
                    this.getProjects(() => {
                      this.setState({
                        showNewProjectDialog: false
                      })
                    })
                  })
                }}
              />
            }

            {this.state.showTranslationsDialog &&
              <TranslationsDialog
                onClose={() => this.setState({showTranslationsDialog: false})}
                selectedTranslations={this.state.translations}
                onSubmit={(translations) => {
                  this.setState({
                    translations: translations,
                    showTranslationsDialog: false
                  }, () => {
                    this.onTranslationsChange(this.state.translations)
                  })
                }}
              />
            }

            {this.state.displayColorPicker ?
              <div className="settings-popover">
                <div className="settings-cover" onClick={ this.handleClose.bind(this) }/>
                <SketchPicker color={ this.state.surveyColor } onChange={this.handleColorChange.bind(this)} />
              </div>
              : null }

          </div>

          <br />
          <Button className="btn btn-primary" onClick={() => this.validateAndSave()}>
            <i className="fa fa-save"/> <FormattedMessage id={"ui.save"} defaultMessage={"Opslaan"} />
          </Button>
          <NavLink to={'/vragenlijsten'}>
            <Button className="btn btn-secondary m-l-5"> <FormattedMessage id={"ui.cancel"} defaultMessage={"Annuleren"} /></Button>
          </NavLink>
        </div>
      </div>
    )
  }
}

export default injectIntl(SurveySettings, { forwardRef: true })
