import React, { Component } from 'react'
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import {Form} from "react-bootstrap"
import {Checkbox, Dropdown} from "semantic-ui-react"
import axios from 'axios'
import Swal from "sweetalert2"

const {rootUrl} = require("../../../config")

class EditClientDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inputText: (this.props.inputText ? this.props.inputText : ""),
      benchmarkSettings: this.props.settingsValue || null,
      benchmarkEnabled: false,
      possibleBenchmarkSettings: [],
      file: null,
      filePreview: null,
      fileBase64: null,
      template: this.props.templateId ? this.props.templateId : null,
      possibleTemplates: []
    }

    this.fileUpload = React.createRef()
    this.fetchBenchmarkSettings()
    this.fetchBenchmarkEnabled()
    this.fetchTemplateOptions()
    if (this.props.clientId) {
      this.getClientLogo()
    }
  }

  fetchTemplateOptions() {
    axios.post(rootUrl + '/api/v1/dashboard/projectssummary', {})
      .then(res => {
        this.setState({
          possibleTemplates: res.data.surveys
        })
      })
      .catch(e => console.log(e))
  }

  fetchBenchmarkSettings() {
    axios.get(rootUrl + '/api/v1/benchmark/all_benchmark_settings')
      .then(res => {
        this.setState({
          possibleBenchmarkSettings: res.data
        })
      })
      .catch(e => console.log(e))
  }

  fetchBenchmarkEnabled() {
    axios.get(rootUrl + '/api/v1/benchmark/benchmark_enabled/' + this.props.clientId)
      .then(res => {
        console.log(res)
        this.setState({benchmarkEnabled: res.data})
      })
      .catch(e => {
        console.log(e)
      })
  }

  toBase64(file, callback) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.setState({
        fileBase64: reader.result
      }, callback)
    }
  }

  getClientLogo() {
    axios.get(rootUrl + '/api/v1/administration/get_client_logo/' + this.props.clientId)
      .then((res) => {
        this.setState({
          fileBase64: res.data
        })
      })
  }

  handleFileChosen(file) {
    this.setState({
      file: file,
      filePreview: URL.createObjectURL(file)
    })
  }

  render() {
    return (
      <PopupDialog
        title={this.props.title}
        onClose={() => this.props.onClose()}
        style={{ marginTop: "100px" }}
      >
        {this.props.informationText}

        <Form.Group className={"m-t-20"}>
          <Form.Label>{this.props.inputLabel}</Form.Label>
          <Form.Control
            className={"form-control"}
            onChange={(e) => this.setState({ inputText: e.target.value })}
            value={this.state.inputText}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Benchmark zichtbaar voor klant</Form.Label><br/>
          <Checkbox
            type={"checkbox"}
            style={{
              marginTop: -12,
              lineHeight: 1.5
            }}
            checked={this.state.benchmarkEnabled}
            onClick={() => this.setState({ benchmarkEnabled: !this.state.benchmarkEnabled })}/>
        </Form.Group>

        <Form.Group>
          <Form.Label>Logo</Form.Label><br/>
          {this.state.filePreview && <><img alt="" src={this.state.filePreview} className={"preview-logo"}/><br/></>}
          {!this.state.filePreview && this.state.fileBase64 && <><img alt="" src={this.state.fileBase64}
                                                                      className={"preview-logo"}/><br/></>}
          <input
            type="file"
            className={"custom-file-upload"}
            name={"custom-file-upload"}
            id={"custom-file-upload"}
            accept={".png,.jpg,.jpeg,.gif,.bmp"}
            onChange={(e) => this.handleFileChosen(e.target.files[0])}
            ref={this.fileUpload}
          />
          <label htmlFor="custom-file-upload" className={"btn btn-primary"}>
            <i className={"fa fa-upload m-r-5"}/> {(this.state.file ? this.state.file.name : "Geen bestand gekozen")}
          </label>
          {this.state.file &&
            <span
              className={"fa fa-remove remove-file-button m-l-10"}
              onClick={() => {
                this.setState({
                  file: null,
                  filePreview: null
                }, () => {
                  this.fileUpload.current.value = ""
                })
              }}
            />}
        </Form.Group>

        <Form.Group className={"m-t-20"}>
          <Form.Label> Benchmark instelling </Form.Label><br/>
          <Dropdown
            placeholder='Selecteer een instelling...'
            selection
            options={this.state.possibleBenchmarkSettings.map((option, index) => {
              return { key: index, text: option.name, value: option.id }
            })}
            value={this.state.benchmarkSettings}
            onChange={(e, { value }) => this.setState({ benchmarkSettings: value })}
          />
        </Form.Group>

        <Form.Group className={'m-t-20'}>
          <Form.Label>Template: </Form.Label><br/>
          <Dropdown
            disabled={!(parseInt(localStorage.getItem('role')) && parseInt(localStorage.getItem('role')) === 1)}
            placeholder={'Selecteer vragenlijst'}
            selection
            options={this.state.possibleTemplates.map((option, index) => {
              return { key: index, text: option.survey_name, value: option.uuid }
            })}
            value={this.state.template}
            onChange={(e, { value }) => this.setState({ template: value })}
          />
        </Form.Group>

        <div style={{ overflow: "hidden" }}>
          <button
            className="btn btn-primary f-right"
            onClick={() => {
              if (this.state.file) {
                this.toBase64(this.state.file, () => {
                  this.props.onSubmit(this.state.inputText, this.state.benchmarkSettings, this.state.benchmarkEnabled, this.state.fileBase64, this.state.template)
                })
              } else {
                this.props.onSubmit(this.state.inputText, this.state.benchmarkSettings, this.state.benchmarkEnabled, null, this.state.template)
              }
            }}
            disabled={this.inputText === ""}>
            {this.props.submitButtonText || "Bevestigen"}
          </button>
          <button className="btn btn-secondary m-r-5 f-right" onClick={() => this.props.onClose()}> Annuleren</button>
        </div>
      </PopupDialog>
    )
  }
}

export default EditClientDialog
