import React, { Component } from 'react'
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import './RecipientsDialog.css'
import axios from 'axios'
import config from '../../../config'
// import {MDBDataTable} from "mdbreact";
import {Form} from "react-bootstrap";
import ListTable from "../../Miscellaneous/ListTable/ListTable"
import {injectIntl, FormattedMessage} from "react-intl"

// function boolToMsg(variable) {
//  return (variable ? "Ja" : "Nee")
// }

class RecipientsDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      emailValue: "",
      emails: [],
      columns: [
        {
          label: this.props.intl.formatMessage({id: "recipients.email"}),
          field: 'email',
          sort: 'asc'
        },
        {
          label: 'Uitgeschreven',
          field: 'unsubscribed',
          sort: 'asc'
        },
        {
          label: 'Vragenlijst geopend',
          field: 'opened_survey',
          sort: 'asc'
        }],
      rows: []
    }
  }

  componentDidMount() {
    this.fetchEmails()
  }

  fetchEmails() {
    axios.get(config.rootUrl + '/api/v1/broadcast/get_broadcast_recipients/' + this.props.mailingListId)
      .then((res) => {
        this.setState({
          emails: res.data
        })
      })
      .catch(e => console.log(e))
  }

  validateEmail(mail) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)
  }

  removeItem(row) {
    const rowIndex = parseInt(row[0]) - 1
    let emails = this.state.emails
    let toRemove = emails.splice(rowIndex, 1)[0]

    axios.post(config.rootUrl + '/api/v1/broadcast/remove_recipient_from_mailing', {email: toRemove, mailingListId: this.props.mailingListId})
      .then(() => {
        this.fetchEmails()
      })
      .catch(e => {
        console.log(e)
      })
  }

  addEmail() {
    if (!this.state.emails.includes(this.state.emailValue) && this.validateEmail(this.state.emailValue)) {
      axios.post(config.rootUrl + '/api/v1/broadcast/add_recipient_to_mailing', {email: this.state.emailValue, mailingListId: this.props.mailingListId})
        .then(() => {
          this.setState({
            emailValue: ""
          }, () => {
            this.fetchEmails()
          })
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

  render() {
    return (
      <PopupDialog title={this.props.title} onClose={() => this.props.onClose()}>
        {this.props.intl.formatMessage({id: "recipients.info"})}{this.props.surveyTitle}'.

        <hr className={"m-t-10 m-b-10"} />

        <Form.Group className={"m-t-20"}>
          <Form.Label><FormattedMessage id={"recipients.add_email"} /></Form.Label>
          <Form.Control
            placeholder={this.props.intl.formatMessage({id: "recipients.email_placeholder"})}
            className={"form-control add-email-textbox"}
            onChange={(e) => this.setState({emailValue: e.target.value})}
            value={this.state.emailValue}
          />
          <button
            className="btn btn-primary m-l-5 add-email-button"
            onClick={() => this.addEmail()}
            disabled={!(this.validateEmail(this.state.emailValue))}
          >
            <i className="fa fa-plus" />
          </button>
        </Form.Group>

        <ListTable headers={["#", this.props.intl.formatMessage({id: "recipients.email"})]}
                   rows={this.state.emails.map((email, index) => {
                     return [index+1, email]
                   })}
                   className={"m-t-10"}
                   removeButton
                   onRemoveButtonClicked={(row) => this.removeItem(row)}
                   key={42}
        />

        <button className="btn btn-secondary m-l-5" onClick={()=> this.props.onClose()}> <FormattedMessage id={"ui.close"} /></button>
      </PopupDialog>
    )
  }
}

export default injectIntl(RecipientsDialog)
