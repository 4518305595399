import React, {Component} from 'react'
import {Button} from 'react-bootstrap'
import {withRouter, Link} from 'react-router-dom'

class NotFound extends Component {
  
  render() {
    return (
      <div style={{margin: 30}}>
        <h1>Niet gevonden</h1>
          <Button as={Link} to='/dashboard'>
            Ga terug naar het dashboard
          </Button>
      </div>
    )
  }
}

export default withRouter(NotFound)