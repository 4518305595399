import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarChartWithErrorBars = ({ labels = [], datasets = [], surveyName = null, clusterName = null, selectedMetric = 'qi_score', onFilterClick=null, selectedDimension=null, maximalScoreLabel=null, negativeLabel='negative', positiveLabel='positive' }) => {
  const chartRef = React.useRef(null);
  
  const handleDownload = () => {
    if (chartRef && chartRef.current) {
      const link = document.createElement('a');
      link.download = `${surveyName || 'Survey'}-${clusterName || 'Cluster'}-chart.png`;
      link.href = chartRef.current.chartInstance.toBase64Image();
      link.click();
    }
  };

  const chartData = {
    labels,
    datasets: [
      {
        dimension_ids: datasets.map(x => x.dimension_id),
        label: surveyName ? surveyName : 'Survey',
        data: selectedMetric === 'qi_score' ? datasets.map(x => parseFloat(x.survey_qi_score?.toFixed(2) || 0)) : datasets.map(x => parseFloat(x.survey_avg_score?.toFixed(2) || 0)),
        backgroundColor: selectedMetric === 'qi_score' 
          ? datasets.map(x => {
              const surveyScore = parseFloat(x.survey_qi_score?.toFixed(2) || 0);
              const clusterScore = parseFloat(x.cluster_qi_score?.toFixed(2) || 0);
              const diff = surveyScore - clusterScore;
              if(!clusterScore){
                return '#4F81BD';
              }

              if(diff > 0) {
                  if((surveyScore - x.survey_confidence_interval_qi_score) > (clusterScore + x.cluster_confidence_interval_qi_score)) {
                    return '#C00000';
                  } else {
                    return '#4F81BD';
                  }
              } else if (diff < 0) {
                if((surveyScore + x.survey_confidence_interval_qi_score) < (clusterScore - x.cluster_confidence_interval_qi_score)) {
                  return '#00B050';
                } else {
                  return '#4F81BD';
                }
              } else {
                return '#4F81BD';
              }
            })
          : datasets.map(x => {
              const surveyScore = parseFloat(x.survey_avg_score?.toFixed(2) || 0);
              const clusterScore = parseFloat(x.cluster_avg_score?.toFixed(2) || 0);
              const diff = surveyScore - clusterScore;
              if(!clusterScore){
                return '#4F81BD';
              }
              if(diff < 0) {
                if((surveyScore + x.survey_confidence_interval_avg_score) < (clusterScore - x.cluster_confidence_interval_avg_score)) {
                  return '#C00000';
                } else {
                  return '#4F81BD';
                }
            } else if (diff > 0) {
              if((surveyScore - x.survey_confidence_interval_avg_score) > (clusterScore + x.cluster_confidence_interval_avg_score)) {
                return '#00B050';
              } else {
                return '#4F81BD';
              }
            } else {
              return '#4F81BD';
            }
            }),
            borderColor: selectedMetric === 'qi_score' 
            ? datasets.map(x => {
                const surveyScore = parseFloat(x.survey_qi_score?.toFixed(2) || 0);
                const clusterScore = parseFloat(x.cluster_qi_score?.toFixed(2) || 0);
                const diff = surveyScore - clusterScore;
                if(!clusterScore){
                  return '#4F81BD';
                }
  
                if(diff > 0) {
                    if((surveyScore - x.survey_confidence_interval_qi_score) > (clusterScore + x.cluster_confidence_interval_qi_score)) {
                      return '#C00000';
                    } else {
                      return '#4F81BD';
                    }
                } else if (diff < 0) {
                  if((surveyScore + x.survey_confidence_interval_qi_score) < (clusterScore - x.cluster_confidence_interval_qi_score)) {
                    return '#00B050';
                  } else {
                    return '#4F81BD';
                  }
                } else {
                  return '#4F81BD';
                }
              })
            : datasets.map(x => {
                const surveyScore = parseFloat(x.survey_avg_score?.toFixed(2) || 0);
                const clusterScore = parseFloat(x.cluster_avg_score?.toFixed(2) || 0);
                const diff = surveyScore - clusterScore;
                if(!clusterScore){
                  return '#4F81BD';
                }
                if(diff < 0) {
                  if((surveyScore + x.survey_confidence_interval_avg_score) < (clusterScore - x.cluster_confidence_interval_avg_score)) {
                    return '#C00000';
                  } else {
                    return '#4F81BD';
                  }
              } else if (diff > 0) {
                if((surveyScore - x.survey_confidence_interval_avg_score) > (clusterScore + x.cluster_confidence_interval_avg_score)) {
                  return '#00B050';
                } else {
                  return '#4F81BD';
                }
              } else {
                return '#4F81BD';
              }
              }),
        borderWidth: 1,
        errorMargins: selectedMetric === 'qi_score' ? datasets.map(x => x.survey_confidence_interval_qi_score) : datasets.map(x => x.survey_confidence_interval_avg_score)
      },
      {
        label: clusterName ? clusterName : 'Cluster',
        data: selectedMetric === 'qi_score' ? datasets.map(x => parseFloat(x.cluster_qi_score?.toFixed(2) || 0)) : datasets.map(x => parseFloat(x.cluster_avg_score?.toFixed(2) || 0)),
        backgroundColor: '#B9CDE5',
        borderColor: '#5895e6',
        borderWidth: 1,
        dimension_ids: datasets.map(x => x.dimension_id),
        errorMargins: selectedMetric === 'qi_score' ? datasets.map(x => x.cluster_confidence_interval_qi_score) : datasets.map(x => x.cluster_confidence_interval_avg_score)
      }
    ]
  };

  const clusterValuePlugin = {
    id: 'clusterValues',
    afterDatasetsDraw: (chart) => {
      const ctx = chart.ctx;
      const { datasets } = chart.config.data;

      datasets.forEach((dataset, index) => {
        const datasetClusterValues = dataset.clusterValues;

        dataset.data.forEach((value, i) => {
          const meta = chart.getDatasetMeta(index);
          const bar = meta.data[i];
          const { x } = bar.getCenterPoint();
          const clusterHeight = datasetClusterValues[i];
          const width = bar._model.width;

          const yTop = chart.scales['y-axis-0'].getPixelForValue(clusterHeight);

          ctx.save();
          ctx.strokeStyle = 'black';
          ctx.lineWidth = 5;

          ctx.beginPath();
          ctx.moveTo(x - (width/2 + 5), yTop);
          ctx.lineTo(x + (width/2 + 5), yTop);
          ctx.stroke();

          ctx.restore();
        });
      });
    }
  };

  const errorMarginPlugin = {
    id: 'errorBars',
    afterDatasetsDraw: (chart) => {
      const ctx = chart.ctx;
      const { datasets } = chart.config.data;

      datasets.forEach((dataset, index) => {
        const { errorMargins } = dataset;

        dataset.data.forEach((value, i) => {
          const meta = chart.getDatasetMeta(index);
          const bar = meta.data[i];
          const { x } = bar.getCenterPoint();
          const errorMargin = errorMargins[i];

          const yTop = chart.scales['y-axis-0'].getPixelForValue(value + errorMargin);
          const yBottom = chart.scales['y-axis-0'].getPixelForValue(value - errorMargin);

          ctx.save();
          ctx.strokeStyle = 'black';
          ctx.lineWidth = 1.5;

          // Vertical line
          ctx.beginPath();
          let correctedTop = Math.max(yTop, chart.chartArea.top + 20)
          let correctedBottom = Math.min(yBottom, chart.chartArea.bottom - 20)
          ctx.moveTo(x, correctedTop);
          ctx.lineTo(x, correctedBottom);
          ctx.stroke();

          // Top horizontal line
          ctx.beginPath();
          ctx.moveTo(x - 5, correctedTop);
          ctx.lineTo(x + 5, correctedTop);
          ctx.stroke();

          // Bottom horizontal line
          ctx.beginPath();
          ctx.moveTo(x - 5, correctedBottom);
          ctx.lineTo(x + 5, correctedBottom);
          ctx.stroke();

          ctx.restore();
        });
      });
    }
  };
  
  // New plugin for horizontal dotted line
  const horizontalLinePlugin = {
    id: 'horizontalLine',
    afterDatasetsDraw: (chart) => {
      const ctx = chart.ctx;
      const chartArea = chart.chartArea;
      
      // Get line value from options
      const lineValue = chart.options.plugins.horizontalLine.lineValue;
      const label = chart.options.plugins.horizontalLine.label;
      
      const yPosition = chart.scales['y-axis-0'].getPixelForValue(lineValue);
      
      // Only draw if the line is within the chart area
      if (yPosition >= chartArea.top && yPosition <= chartArea.bottom) {
        ctx.save();
        
        // Set line style
        ctx.strokeStyle = '#000000';
        ctx.lineWidth = 1;
        ctx.setLineDash([5, 5]); // Dotted line
        
        // Draw the horizontal line
        ctx.beginPath();
        ctx.moveTo(chartArea.left, yPosition);
        ctx.lineTo(chartArea.right, yPosition);
        ctx.stroke();
        
        // Add label
        ctx.font = '12px Arial';
        ctx.fillStyle = '#000000';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'bottom';
        ctx.fillText(`${label}`, chartArea.right - 100, yPosition - 5);
        
        ctx.restore();
      }
    }
  };

  const options = {
    legend: {
      position: 'bottom',
      onClick: null
    },
    title: {
      display: false,
    },
    scales: {
      yAxes: [{
          ticks:{
            beginAtZero: true,
            max: selectedMetric === 'qi_score' ? 15 : 5,
            callback: function(value, index, values) {
              if(selectedMetric === 'qi_score' && value === 15) { 
                return `15 (${negativeLabel})`
              }
              if(selectedMetric === 'avg_score' && value === 0) { 
                return `0 (${negativeLabel})`
              }

              if(selectedMetric === 'qi_score' && value === 0) { 
                return `0 (${positiveLabel})`
              }

              if(selectedMetric === 'avg_score' && value === 5) { 
                return `5 (${positiveLabel})`
              }

              return value;
            }
          }
        }],
        xAxes: [{
          ticks: {
            fontSize: selectedDimension ? 12: 16,
            fontStyle: 'bold',
            callback: function(value, index, values) {
                return value.length > 25 ? 
                    value.match(/(.{1,25}(?:\s|$))/g) :
                    [value];
            }
        }
        }]
    },
    plugins: {
      horizontalLine: {
        lineValue: selectedMetric === 'qi_score' ? 1 : 4,
        label: maximalScoreLabel
      }
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ 
        position: 'absolute', 
        top: '10px', 
        right: '10px', 
        zIndex: 10 
      }}>
        <button 
          onClick={handleDownload}
          title="Download chart as PNG"
          style={{ 
            padding: '6px 10px', 
            backgroundColor: '#4F81BD', 
            color: 'white', 
            border: 'none', 
            borderRadius: '4px',
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 1px 3px rgba(0,0,0,0.2)'
          }}
        >
          <svg 
            width="16" 
            height="16" 
            viewBox="0 0 16 16" 
            fill="currentColor" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 12l-4-4h2.5V3h3v5H12L8 12z" />
            <path d="M14 13v1H2v-1h12z" />
          </svg>
        </button>
      </div>
      <Bar 
        ref={chartRef}
        data={chartData} 
        options={options} 
        plugins={[errorMarginPlugin, horizontalLinePlugin]}
        onElementsClick={onFilterClick}
      />
    </div>
  );
};

export default BarChartWithErrorBars;
