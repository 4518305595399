import {Form} from "react-bootstrap"
// import {Dropdown} from "semantic-ui-react"
import ListTable from "../../../../components/Miscellaneous/ListTable/ListTable"
// import AddUserDialog from "../AddUserDialog/AddUserDialog"
import EditUserDialog from "../EditUserDialog/EditUserDialog"
import ChangeAccessDialog from "../ChangeAccessDialog/ChangeAccessDialog"
import React from "react"
import axios from "axios"
import {rootUrl} from "../../../../config"
import Swal from "sweetalert2"
import {injectIntl} from 'react-intl'

function AllUsersPanel(props) {
  const [userRequest, setUserRequest] = React.useState({loading: true, users: []})
  const [editUser, setEditUser] = React.useState(null)
  const [showEditUserDialog, setShowEditUserDialog] = React.useState(false)
  const [showChangeAccessDialog, setShowChangeAccessDialog] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState('')

  const fetchUsers = () => {
    axios.get(rootUrl + '/api/v1/administration/get_all_users')
      .then((res) => {
        setUserRequest({loading: false, users: res.data})
      })
      .catch(e => console.log(e))
  }

  if(userRequest.loading) {
    fetchUsers()
  }

  const handleEditButtonClick = (row) => {
    const index = row[0] - 1
    let user = userRequest.users.filter(row => {
      return searchValue === '' || (row.email && row.email.includes(searchValue)) || (row.username && row.username.includes(searchValue))
    })[index]
    if (user.email === null) user.email = ""
    setEditUser(user)
    setShowEditUserDialog(true)
  }

  const handleRemoveButtonClick = (row) => {
    const index = row[0] - 1
    let user = userRequest.users.filter(row => {
      return searchValue === '' || (row.email && row.email.includes(searchValue)) || (row.username && row.username.includes(searchValue))
    })[index]

    Swal.fire({
      title: props.intl.formatMessage({id: "dialogs.are_you_sure"}),
      text: props.intl.formatMessage({id: "dialogs.removal_cannot_be_reversed"}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: props.intl.formatMessage({id: "ui.remove"}),
      cancelButtonText: props.intl.formatMessage({id: "ui.cancel"})
    }).then((result) => {
      if (result.value) {
        axios.post(rootUrl + '/api/v1/administration/remove_user', {id: user.id})
          .then((res) => {
            fetchUsers()

            Swal.fire({
              title: props.intl.formatMessage({id: "dialogs.success"}),
              text: props.intl.formatMessage({id: "dialogs.removal_successful"}),
              type: 'success',
              confirmButtonText: 'OK'
            })
          })
          .catch(e => console.log(e))
      }
    })
  }

  const handleChangeAccessButtonClick = (row) => {
    const index = row[0] - 1
    let user = userRequest.users.filter(row => {
      return searchValue === '' || (row.email && row.email.includes(searchValue)) || (row.username && row.username.includes(searchValue))
    })[index]
    setEditUser(user)
    setShowChangeAccessDialog(true)
  }

  const roles = [
    {
      role: props.intl.formatMessage({id: "role.superadmin"}),
      value: 1
    },{
      role: props.intl.formatMessage({id: "role.admin"}),
      value: 2
    },{
      role: props.intl.formatMessage({id: "role.researcher"}),
      value: 3
    },{
      role: props.intl.formatMessage({id: "role.observer"}),
      value: 4
    }]

  const handleClose = () => {
    setShowChangeAccessDialog(false)
    setShowEditUserDialog(false)
    fetchUsers()
  }

  return (
    <>
      <div className={props.className ? props.className : ""}>
        <div className="col-12">
          <Form.Group>
            <Form.Control
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={props.intl.formatMessage({id: "users.search"})}
            />
          </Form.Group>

          <ListTable headers={["#", props.intl.formatMessage({id: "edit_user.username"}), props.intl.formatMessage({id: "edit_user.email"}), props.intl.formatMessage({id: "edit_user.role"})]}
                     rows={userRequest.users.filter(row => {
                       return searchValue === '' || (row.email && row.email.includes(searchValue)) || (row.username && row.username.includes(searchValue))
                     }).map((list, index) => {
                       return [index + 1,
                         list.username,
                         (list.email ? list.email : "-"),
                         roles[list.role - 1].role]
                     })}
                     onEditButtonClicked={(row) => {
                       handleEditButtonClick(row)
                     }}
                     onRemoveButtonClicked={(row) => {
                       handleRemoveButtonClick(row)
                     }}
                     onChangeAccessButtonClicked={(row) => {
                       handleChangeAccessButtonClick(row)
                     }}
                     editButton
                     // removeButton
                     changeAccessButton
          />
        </div>
      </div>


      {
        showEditUserDialog &&
        <EditUserDialog
          title={props.intl.formatMessage({id: "users.edit_user"})}
          editData={editUser}
          onClose={handleClose} />
      }

      {
        showChangeAccessDialog &&
        <ChangeAccessDialog
          editData={editUser}
          onClose={handleClose} />
      }
    </>
  )
}

export default injectIntl(AllUsersPanel)
