import React, {Component} from 'react'
import {Button, Form} from "react-bootstrap"
import {Link} from 'react-router-dom'
import axios from 'axios'
import Flags from 'country-flag-icons/react/3x2'
import { FormattedMessage, injectIntl } from 'react-intl'
import './Login.css'

axios.defaults.withCredentials = true
axios.defaults.headers['Pragma'] = 'no-cache' // IE11 is not allowed to cache axios call responses with this header.

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: "",
      password: "",
      refresh: false
    }
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0
  }

  /**
   * Sets the state of the controlId to the changed text.
   */
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  handleSubmit = event => {
    sessionStorage.removeItem('selectedDimension')
    this.login(this.state.username, this.state.password)
    event.preventDefault()
  }


  login = (username, password) => {
    this.props.logUserIn(username, password)
      .finally(
        () => this.setState({errorMessage: this.props.getErrorMessage()})
      )
  }

  render() {
    return (
      <>
        <div className="account-pages login-background"/>
        <div className="clearfix"/>
        <div className="wrapper-page">

          <div className="account-bg">
            <div className="card-box login-card">
              <div className="text-xs-center m-t-20">
                <Link to='/login' className="logo .live-green">
                  <img src="/images/logo60.png" alt="" id="login-logo" width="40" height="40"/><br/>
                  <span id="login-title" className="text-live-green">LiveResearch</span>
                </Link>
              </div>


              {/* Start form */}
              <div className="m-t-30 m-b-0">
                <div className="col-xs-12 text-xs-center">
                  <h6 className="text-muted text-uppercase m-b-0 m-t-0">Login</h6>
                </div>

                <div style={{marginLeft: "auto", marginRight: "auto", display: "flex", justifyContent: "center", marginTop: 30}} /*className={"login-flag-holder"}*/>
                  <Flags.NL title="Dutch" className="flag" onClick={() => this.props.onLanguageChanged("nl")} />
                  <Flags.DE title="German" className="flag" onClick={() => this.props.onLanguageChanged("de")}/>
                  <Flags.GB title="English" className="flag" onClick={() => this.props.onLanguageChanged("en")}/>
                  <Flags.FR title="French" className="flag" onClick={() => this.props.onLanguageChanged("fr")}/>

                </div>

                <form className="form-horizontal m-t-20" onSubmit={this.handleSubmit}>

                  <Form.Group className="col-xs-12" controlId="username">
                    <Form.Control
                      value={this.state.username}
                      onChange={this.handleChange}
                      type="username"
                      required
                      placeholder={this.props.intl.formatMessage({id: "login.username"})}
                    />
                  </Form.Group>

                  <Form.Group className="col-xs-12" controlId="password">
                    <Form.Control
                      value={this.state.password}
                      onChange={this.handleChange}
                      type="password"
                      required
                      placeholder={this.props.intl.formatMessage({id: "login.password"})}
                    />
                  </Form.Group>

                  <Form.Group className="checkbox checkbox-custom col-xs-12 m-t-20 m-l-5">
                    <input id="checkbox-signup" type="checkbox"/>
                    <label htmlFor="checkbox-signup">
                      <FormattedMessage id={"login.remember_me"} defaultMessage={"Onthoud mij"} />
                    </label>
                  </Form.Group>

                  <p style={{color: "#ff3905"}}>{this.state.errorMessage}</p>

                  <Button
                    className="col-xs-12 text-center m-t-10 btn btn-success btn-block waves-effect waves-light btn-live-green"
                    disabled={!this.validateForm()}
                    type="submit"
                  >
                    Login
                  </Button>


                  <Form.Group>
                    <div className="">
                      <Link to='/wachtwoord-vergeten' className="text-muted">
                        <i className="fa fa-lock m-r-5 col-sm-12 m-t-30 m-b-0"/>
                        <FormattedMessage id={"login.forgot_password"} defaultMessage={"Wachtwoord vergeten?"} />
                      </Link>
                    </div>
                  </Form.Group>

                </form>
              </div>
              {/* End form */}

            </div>
          </div>
        </div>
      </>
    )
  }
}

export default injectIntl(Login)
