import React, { Component } from 'react'
import {Dropdown} from 'semantic-ui-react'
import ComponentHolder from "../ComponentHolder/ComponentHolder"
import { injectIntl } from "react-intl"

class DropdownControl extends Component {
  constructor(props) {
    super(props)

    this.state = {
      index: (this.props.answer ? this.props.answer.option : -1),
      dropdownText: (this.props.answer ? this.props.options[this.props.answer.option].text : "Selecteer een optie...")
    }

    if (this.props.answer)
      this.props.validateAnswer(this.props.position, true, {option: this.state.index})
  }

  handleClick(event, data) {
    // Find index of selected option.
    let index = -1
    for (let i = 0; i < data.options.length; i++) {
      if (data.options[i].text === data.value) {
        index = i
        break
      }
    }

    console.log(data.value)

    // Set text in the dropdown control and validate answer to page.
    this.setState({
      index: index,
      dropdownText: data.value
    }, () => {
      this.props.validateAnswer(this.props.position, true, {option: index})
    })
  }

  loadOptions() {
    let result = []
    for (let i = 0; i < this.props.options.length; i++) {
      let option = this.props.options[i]
      result.push(
        <Dropdown.Item key={i}>
          {option.text.replace('&nbsp;', ' ')}
        </Dropdown.Item>
      )
    }

    return result
  }

  render() {
    return (
      <ComponentHolder {...this.props}>
        <Dropdown
          placeholder='Selecteer een optie...'
          onChange={this.handleClick.bind(this)}
          selection
          options={this.props.options.map((option, index) => {
            return {key: index, text: option.text, value: option.text}
          })}
          onSelect={() => document.dispatchEvent(new MouseEvent('click'))}
          value={this.state.dropdownText}
          className={(this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? "f-right clear-right" : "")}
        />
      </ComponentHolder>
    )
  }
}

export default injectIntl(DropdownControl)
