import React, { Component } from 'react'
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import {Form} from "react-bootstrap"
import {Checkbox, Dropdown} from "semantic-ui-react"
import axios from 'axios'

const {rootUrl} = require("../../../config")

class ExportRespondentCountDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inputText: (this.props.inputText ? this.props.inputText : ""),
      benchmarkSettings: this.props.settingsValue || null,
      benchmarkEnabled: false,
      possibleBenchmarkSettings: [],
      file: null,
      filePreview: null,
      fileBase64: null
    }
  }

  render() {
    return (
      <PopupDialog
        title={this.props.title}
        onClose={() => this.props.onClose()}
        style={{marginTop: "100px"}}
      >

        <Form.Group className={"m-t-20"}>
          <Form.Label>Startdatum (YYYY-MM-DD)</Form.Label>
          <Form.Control
            className={"form-control"}
            onChange={(e) => this.setState({startDate: e.target.value} )}
            value={this.state.startDate}
          />
        </Form.Group>
        <Form.Group className={"m-t-20"}>
          <Form.Label>Einddatum (YYYY-MM-DD)</Form.Label>
          <Form.Control
            className={"form-control"}
            onChange={(e) => this.setState({endDate: e.target.value} )}
            value={this.state.endDate}
          />
        </Form.Group>


        <div style={{overflow: "hidden"}}>
          <button
            className="btn btn-primary f-right"
            onClick={() => {
                this.props.onSubmit(this.state.startDate, this.state.endDate)
              }}
            disabled={!this.state.startDate || !this.state.endDate}>
            {this.props.submitButtonText || "Bevestigen"}
          </button>
          <button className="btn btn-secondary m-r-5 f-right" onClick={() => this.props.onClose()}> Annuleren</button>
        </div>
      </PopupDialog>
    )
  }
}

export default ExportRespondentCountDialog
