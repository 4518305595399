import React, { Component } from 'react'
import {Form} from "react-bootstrap"
import QuestionTypes from '../../QuestionTypes'
import { FormattedMessage } from 'react-intl'
import {Button} from 'react-bootstrap'

class RadiobuttonRange extends Component {
  constructor(props) {
    super(props)

    this.state = {
      radio: parseInt(this.props.min),
      openOption: (this.props.openOption ? this.props.openOption : null)
    }

  }

  addOpenOption() {
    this.setState({
      openOption: {placeholder: "Open antwoord...", value: "Anders...", translations: {}}
    }, () => {
      this.props.onOptionsChanged(this.state.openOption)
    })
  }

  removeOpenOption() {
    this.setState({
      openOption: null
    }, () => {
      this.props.onOptionsChanged(this.state.openOption)
    })
  }

  changeOpenOption(newValue) {
    let oldOpenOption = this.state.openOption
    oldOpenOption.value = newValue

    this.setState({
      openOption: oldOpenOption
    }, () => {
      this.props.onOptionsChanged(this.state.openOption)
    })
  }

  removeOption(key) {
    let oldOptions = this.state.options

    let index = oldOptions.findIndex(c => c.key === key)

    oldOptions.splice(index, 1)

    for (let i in oldOptions) {
      oldOptions[i].placeholder = "Optie " + (Number(i) + 1)
    }

    this.setState({options: oldOptions}, () => {
      this.props.onOptionsChanged(this.state.openOption)
    })
  }


  // This method saves the selected radiobutton to the state.
  handleClick = nr => {
    this.setState({
      radio: nr
    })
  }

  // Load radiobuttons with numeric labels from this.props.min to this.props.max
  loadOptions() {
    // Generate a random string of 10 characters to create a unique key.
    let randomString = Math.random().toString(36).substring(2, 12) + Math.random().toString(36).substring(2, 12)
    let result = []

    for (let i = parseInt(this.props.min); i <= parseInt(this.props.max); i++) {
      result.push(
        // <>
        <Form.Check
          key={i}
          type={"radio"}
          label={i.toString()}
          id={"radiobutton" + randomString + i.toString()}
          name={"radio-group"}
          checked={this.state.radio === i}
          onChange={() => this.handleClick(i)}
          className={"radio"}
        />

        /* {this.props.focused && this.state.openOption &&
        <Form.Group>
          <Form.Label className={"open-option-label"}>Open antwoord</Form.Label>
          <Form.Control
            required
            placeholder={this.state.openOption.placeholder}
            onChange={(e) => this.changeOpenOption(e.target.value)}
            className={"option-textbox m-r-5"}
            value={this.state.openOption.value}
            style={{display: "inline-block", width: "80%"}} />

          <Button
            className={"btn-danger option-remove dropdown-action-button"}
            onClick={() => this.removeOpenOption()}
          >
            <i className={"fa fa-remove"} />
          </Button>
        </Form.Group>
        }
        </> */
    )
    }
        
        // result.push(
        //   <>
        //       <Button
        //         className="btn btn-primary"
        //         onClick={() => this.addOpenOption()}>
        //         <FormattedMessage id={"component_builder.add_open_option"} defaultMessage={"Open antwoord toevoegen"} />
        //       </Button>
        //   </>
        // )

    return result
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.loadOptions()}
      </div>
      )
  }
}

export default RadiobuttonRange
