import React, { Component } from 'react'
import {Form} from "react-bootstrap"
import './GraphCardHolder.css'
import '../../Miscellaneous/DateRangeSlider/DateRangeSlider.css'
import TableCard from '../TableCard/TableCard'
import GraphCard from '../GraphCard/GraphCard'
import LoadingSpinner from '../../../components/Miscellaneous/LoadingSpinner/LoadingSpinner'
import ExportDialog from '../ExportDialog/ExportDialog'
import axios from 'axios'
import {rootUrl} from '../../../config'
import ColorSchemeDialog from "../ColorSchemeDialog/ColorSchemeDialog"
import LanguageDialog from "../LanguageDialog/LanguageDialog"
import ColorSchemeObjects from '../ColorSchemeObjects.json'
import DateRangeSlider from "../../Miscellaneous/DateRangeSlider/DateRangeSlider"
import FilterList from '../FilterList/FilterList'
import {Dropdown} from "semantic-ui-react"
import FilterListItem from "../FilterList/FilterListItem/FilterListItem"
import {FormattedMessage, injectIntl} from 'react-intl'
import NPSCard from "../NPSCard/NPSCard"
import IndicatorCard from "../IndicatorCard/IndicatorCard"

class GraphCardHolder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      results: [],
      surveyId: -1,
      filter: [],
      filterId: [],
      surveyName: "",
      locale: null,
      blockWidth: (this.props.blockWidth) ? this.props.blockWidth : 4,
      blockHeight: (this.props.blockHeight) ? this.props.blockHeight : 2,
      showExportDialog: false,
      colorSchemeName: this.props.colorSchemeName,
      surveyStart: null,
      surveyEnd: this.formatDate(new Date()),
      sliderFrom: null,
      sliderTo: this.formatDate(new Date()),
      selectedDimension: sessionStorage.getItem('selectedDimension') || this.props.intl.formatMessage({id: "dashboard.no_dimension_filter"}),
      numberOfRespondents: '...',
      numberOfUnfinishedRespondents: 0,
      translationFilter: "all",
      translations: []
    }

    this.dateSlider = React.createRef()
    this.dimensionFilter = React.createRef()
  }

  componentDidMount() {
    this.getSurveyTranslations()
    this.getSurveyName()
    this.getSurveyStart(() => {
      this.getSurveyResults(localStorage.getItem('selectedSurvey'), () => {})
    })
  }

  getSurveyResults(surveyId, callback) {
    var locale = localStorage.getItem("language_locale");
    if (surveyId !== -1) {
      let end = this.stringToDate(this.state.sliderTo)
      end = end.setDate(end.getDate() + 1)
      this.setState({
        loading: true
      }, () => {
        axios.post(rootUrl + '/api/v1/survey/get_survey_results/' + surveyId, {
            fromDate: this.state.sliderFrom !== null ? this.state.sliderFrom : this.state.surveyStart,
            toDate: this.formatDate(end),
            filter: this.state.filterId,
            locale: locale
          }
        ).then((res) => {
          this.setState({
            loading: false,
            surveyId: surveyId,
            results: res.data
          }, () => {
            this.getNumberOfRespondents()
            typeof callback === 'function' && callback()
          })
        }).catch((error) => {
          this.setState({
            loading: false,
            results: []
          })
          console.log(error.response.data)
        })
      })
    }
  }

  getResultCards() {
    let pieTypes = ["multiplechoice_single", "multiplechoice_multiple", "routing"]
    let tableTypes = ["open", "textbox", "email", "date"]
    let barTypes = ["number", "grade"]
    let npsTypes = ["net_promoter"]
    let lineTypes = ["histogram"]
    let indicatorTypes = ['indicator']

    let cards = []
    let result

    for (let i = 0; i < this.state.results.length; i++) {
      result = this.state.results[i]

      let character = result.question_text.charAt(0);

      if (Number.isInteger(+character)) {
        result.question_text = result.question_text.substring(3);
      }

      // Set some translation settings if this is the respondents graph.
      if (result.special_type === 'respondents') {
        result.question_text = this.props.intl.formatMessage({id: "dashboard.respondents"})
        result.question_text = result.title.charAt(0).toUpperCase() + result.title.slice(1) // Capitalize title
      }

      if (this.state.selectedDimension === this.props.intl.formatMessage({id: "dashboard.no_dimension_filter"}) || result.dimension_name === this.state.selectedDimension) {
        if (pieTypes.includes(result.type)) {
          cards.push(this.getGraphCard(result, 'pie', this.state.colorSchemeName + i + this.state.blockWidth, i))
        } else if (tableTypes.includes(result.type)) {
          cards.push(this.getTableCard(result, this.state.colorSchemeName + i + this.state.blockWidth, i))
        } else if (barTypes.includes(result.type)) {
          // if (result.component_position === 39 || result.component_position === 40) {
          //   cards.push(this.getBarChartCard(result, 'bar', this.state.colorSchemeName + i + this.state.blockWidth, i))
          // }
          // else {
            cards.push(this.getGraphCard(result, 'bar', this.state.colorSchemeName + i + this.state.blockWidth, i))
          // }
        } else if (lineTypes.includes(result.type)) {
          result.title = this.props.intl.formatMessage({id: "dashboard.capital_respondents"})
          cards.push(this.getGraphCard(result, 'line', this.state.colorSchemeName + i + this.state.blockWidth, i))
        }else if (npsTypes.includes(result.type)) {
          cards.push(this.getNPSCard(result, 'nps', this.state.colorSchemeName + i + this.state.blockWidth, i))
        } else if (indicatorTypes.includes(result.type)) {
          cards.push(this.getIndicatorCard(result, 'indicator', this.state.colorSchemeName + i + this.state.blockWidth, i))
        }
      }
    }

    return cards
  }

  getGraphCard(result, type, key, index) {
    const colorScheme = []
    return <GraphCard blockHeight={this.state.blockHeight}
                      blockWidth={this.state.blockWidth}
                      colorScheme={result.colors ?? this.getColorSchemeFromName(this.state.colorSchemeName)}
                      backgroundColor={type === 'bar' ? result.colors : '#006165'}
                      key={key}
                      chartType={type}
                      cardTitle={result.question_text}
                      dimensionTitle={result.dimension_name}
                      // cardText={result.question_text}
                      labels={result.labels}
                      data={result.data}
                      date={result.date}
                      tableVisible={this.state.blockWidth >= 4}
                      onFilterClick={this.handleFilter.bind(this)}
                      index={index}
    />
  }

  // getBarChartCard(result, type, key, index) {
  //   const colorScheme = []
  //   return <GraphCard blockHeight={this.state.blockHeight}
  //                     blockWidth={this.state.blockWidth}
  //                     backgroundColors={["#C00000", "#FF3300", "#FF6600", "#FF9900", "#FFCC00", "#FFFF33", "#CCFF66", "#92D050", "#00FF00", "#009900"]}
  //                     key={key}
  //                     chartType={type}
  //                     cardTitle={result.question_text}
  //                     dimensionTitle={result.dimension_name}
  //       // cardText={result.question_text}
  //                     labels={result.labels}
  //                     data={result.data}
  //                     date={result.date}
  //                     tableVisible={this.state.blockWidth >= 4}
  //                     onFilterClick={this.handleFilter.bind(this)}
  //                     index={index}
  //   />
  // }

  getNPSCard(result, type, key, index) {
    return <NPSCard blockHeight={this.state.blockHeight}
                      blockWidth={this.state.blockWidth}
                      colorScheme={this.getColorSchemeFromName(this.state.colorSchemeName)}
                      key={key}
                      chartType={type}
                      cardTitle={result.question_text}
                      dimensionTitle={result.dimension_name}
                      // cardText={result.question_text}
                      labels={result.labels}
                      data={result.data}
                      tableVisible={this.state.blockWidth >= 4}
                      onFilterClick={this.handleFilter.bind(this)}
                      index={index}
    />
  }

  getIndicatorCard(result, type, key, index) {
    return <IndicatorCard blockHeight={this.state.blockHeight}
                          blockWidth={this.state.blockWidth}
                          colorScheme={this.getColorSchemeFromName(this.state.colorSchemeName)}
                          key={key}
                          indicatorAmount={result.score}
                          chartType={type}
                          cardTitle={result.question_text}
                          dimensionTitle={result.dimension_name}
                          // cardText={result.question_text}
                          tableVisible={this.state.blockWidth >= 4}
                          index={index}
    />
  }


  handleFilter(e, index) {
    if (this.state.filter && this.state.filter.length < 3) {
      try {
        const ref = this.state.results[index].refs[e[0]._index]
        const newFilter = {
          filterText: (index+1) + ". " + this.state.results[index].labels[e[0]._index],
          questionText: this.state.results[index].question_text
        }

        this.setState(prevState => ({
          filter: [...prevState.filter, newFilter],
          filterId: [...prevState.filterId, ref]
        }))
        this.getSurveyResults(this.state.surveyId)
      } catch(e) {
        console.log(e)
      }
    }

  }

  getTableCard(result, key, index) {
    let columns = [
      {
        label: this.props.intl.formatMessage({id: "dashboard.table_timestamp"}),
        field: 'timestamp',
        sort: 'asc',
        width: 100
      },
      {
        label: this.props.intl.formatMessage({id: "dashboard.table_answer"}),
        field: 'answer',
        sort: 'asc',
        width: 200
      }]
    let rows = []

    for (let j = 0; j < result.answers.length; j++) {
      rows.push({
        timestamp: result.timestamps[j].substr(0,10),
        answer: result.answers[j]
      })
    }

    return <TableCard cardTitle={result.question_text}
                      // cardText={result.question_text}
                      key={key}
                      blockWidth={4}
                      columns={columns}
                      rows={rows}
                      index={index}
    />
  }

  clear() {
    this.setState({
      results: [],
      filter: [],
      filterId: []
    })
  }

  stringToDate(str) {
    var parts = str.split('-')
    var d = new Date(parts[0], parts[1] - 1, parts[2])
    return d
  }

  onColorSchemeChanged(colorScheme) {
    this.setState({
      showColorSchemeDialog: false,
      colorSchemeName: colorScheme
    })
  }

  onLanguageChanged(language) {
    this.setState({
      showLanguageDialog: false,
      colorSchemeName: language
    })
  }

  getColorSchemeFromName(colorScheme) {
    let scheme
    for (let key in ColorSchemeObjects.schemes) {
      scheme = ColorSchemeObjects.schemes[key]
      if (scheme.name === colorScheme) {
        return scheme.scheme
      }
    }
    return ColorSchemeObjects.schemes[0].scheme
  }

  getSurveyName() {
    if (localStorage.getItem('selectedSurvey')) {
      axios.post(rootUrl + '/api/v1/survey/get_survey_internal_name/' + localStorage.getItem('selectedSurvey'))
        .then((res) => {
          this.setState({
            surveyName: res.data
          })
        })
    }
  }

  getSurveyTranslations() {
    if (localStorage.getItem('selectedSurvey')) {
      axios.get(rootUrl + '/api/v1/survey/get_survey_translations/' + localStorage.getItem('selectedSurvey'))
        .then((res) => {
          let translations = res.data
          translations.unshift("all")

          this.setState({
            translations: res.data
          })
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

  getNumberOfRespondents() {
    let fromDate = this.state.sliderFrom
    let toDate = this.stringToDate(this.state.sliderTo)
    toDate = toDate.setDate(toDate.getDate() + 1)

    if (!fromDate) fromDate = '1970-1-1'

    if (localStorage.getItem('selectedSurvey')) {
      axios.post(rootUrl + '/api/v1/survey/get_number_of_respondents', {
        surveyId: localStorage.getItem('selectedSurvey'),
        fromDate: fromDate,
        toDate: this.formatDate(toDate)
      })
        .then((res) => {
          this.setState({
            numberOfRespondents: res.data
          })
        })
        .catch(e => {
          console.log(e)
          this.setState({
            numberOfRespondents: '...'
          })
        })

      axios.post(rootUrl + '/api/v1/survey/get_number_of_unfinished_respondents', {
        surveyId: localStorage.getItem('selectedSurvey'),
        fromDate: fromDate,
        toDate: this.formatDate(toDate)
      })
        .then((res) => {
          this.setState({
            numberOfUnfinishedRespondents: res.data
          })
        })
        .catch(e => {
          console.log(e)
          this.setState({
            numberOfUnfinishedRespondents: 0
          })
        })
    }
  }

  getSurveyStart(callback) {
    if (localStorage.getItem('selectedSurvey')) {
      axios.post(rootUrl + '/api/v1/survey/get_survey_start/' + localStorage.getItem('selectedSurvey'))
        .then((res) => {
          this.setState({
            sliderFrom: this.formatDate(new Date(Date.parse(res.data))),
            surveyStart: this.formatDate(new Date(Date.parse(res.data)))
          }, () => {
            this.updateSliderSettings()
            callback()
          })
        })
    }
  }

  handleDateSliderFinish(data) {
    this.setState({
      sliderFrom: data.from,
      sliderTo: data.to,
      numberOfRespondents: '...'
    }, () => {
      this.getSurveyResults(localStorage.getItem('selectedSurvey'), () => {})
      this.getNumberOfRespondents()
    })
  }

  updateSliderSettings() {
    this.setState({
      sliderFrom: this.state.surveyStart,
      sliderTo: this.state.surveyEnd
    }, () => {
      this.dateSlider.current.updateSettings(
        this.state.surveyStart,
        this.state.surveyEnd,
        this.state.sliderFrom,
        this.state.sliderTo)
      this.forceUpdate()
    })
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    return [year, month, day].join('-')
  }

  getDimensionOptions() {
    let labels = [this.props.intl.formatMessage({id: "dashboard.no_dimension_filter"})]
    let result

    for (let i = 0; i < this.state.results.length; i++) {
      result = this.state.results[i]
      if (result.dimension_name && !labels.includes(result.dimension_name)) {
        labels.push(result.dimension_name)
      }
    }

    let options = labels.map((label, index) => {
      return {key: index, text: label, value: label}
    })
    if(options.length > 1 && this.state.selectedDimension !== this.props.intl.formatMessage({id: "dashboard.no_dimension_filter"}) && !options.filter(x => x.value !== this.state.selectedDimension)) {
      this.setState({selectedDimension: 'Geen filter'})
    } else if(this.state.selectedDimension !== sessionStorage.getItem('selectedDimension') && options.filter(x => x.value === sessionStorage.getItem('selectedDimension')).length > 0) {
      this.setState({selectedDimension: sessionStorage.getItem('selectedDimension')})
    } else if(this.state.selectedDimension !== this.props.intl.formatMessage({id: "dashboard.no_dimension_filter"}) && options.filter(x => x.value === sessionStorage.getItem('selectedDimension')).length === 0) {
      this.setState({selectedDimension: this.props.intl.formatMessage({id: "dashboard.no_dimension_filter"})} )
    }
    return options
  }

  resetFilter(index) {
    let filter = [...this.state.filter]
    let filterId = [...this.state.filterId]
    filter.splice(index, 1)
    filterId.splice(index, 1)
    this.setState({
      filter: filter,
      filterId: filterId
    })
    this.getSurveyResults(this.state.surveyId)
  }


  render() {
    return (
      <>
        {this.state.showExportDialog &&


          <ExportDialog
            filterIds={this.state.filterId}
            surveyStart={this.state.surveyStart}
            sliderFrom={this.state.sliderFrom}
            sliderTo={this.state.sliderTo}
            onClose={() => this.setState({showExportDialog: false})}
          />}

        {this.state.showColorSchemeDialog &&
          <ColorSchemeDialog
            title={this.props.intl.formatMessage({id: "color_scheme.select_color_scheme"})}
            selected={this.state.colorSchemeName}
            onClose={() => this.setState({showColorSchemeDialog: false})}
            onColorSchemeChanged={(colorScheme) => this.onColorSchemeChanged(colorScheme)}
          />
        }

        {this.state.showLanguageDialog &&
          <LanguageDialog
            title={this.props.intl.formatMessage({ id: "language_filter.select_language_filter" })}
            selected={this.state.translationFilter}
            onClose={() => this.setState({ showLanguageDialog: false })}
            onTranslationChanged={(translationFilter) => this.setState({translationFilter: translationFilter})}
            translations={this.state.translations}
          />
        }

        <div className={"options-bar"}>
          <FilterList
            style={{flex: 1, marginTop: "5px", marginLeft: "4px"}}>
            <>
              {this.state.filter && this.state.filter.map((filter, index) => {
                return <FilterListItem
                    text={filter.filterText}
                    questionText={filter.questionText}
                    onDeleteItem={() => this.resetFilter(index)}
                  />
              })}
            </>
          </FilterList>

          <div>
            <button
              className="btn btn-secondary m-t-5 m-r-5 f-right"
              onClick={() => this.setState({showExportDialog: true})}
              disabled={!(localStorage.getItem('selectedSurvey'))}
            >
              <i className={"fa fa-arrow-alt-circle-down"} /> <FormattedMessage id={"dashboard.export_results"} defaultMessage={"Exporteer resultaten"} />
            </button>

            {/*<button*/}
            {/*  className="btn btn-secondary m-t-5 m-r-5 f-right"*/}
            {/*  onClick={() => this.setState({ showLanguageDialog: true })}*/}
            {/*>*/}
            {/*  <i className={"fa fa-globe"} /> <FormattedMessage id={"dashboard.select_language_filter"} defaultMessage={"Selecteer taal"} />*/}
            {/*</button>*/}

            <div className={"options-bar-divider f-right m-r-5"} />

            <button
              className="btn btn-secondary m-t-5 m-r-5 f-right"
              onClick={() => this.setState({blockWidth: 4})}
            >
              <i className={"zmdi zmdi-view-stream"} /> <FormattedMessage id={"dashboard.show_details"} defaultMessage={"Details weergeven"} />
            </button>

            <button
              className="btn btn-secondary m-t-5 m-r-5 f-right"
              onClick={() => this.setState({blockWidth: 2})}
            >
              <i className={"zmdi zmdi-view-dashboard"} /> <FormattedMessage id={"dashboard.hide_details"} defaultMessage={"Details verbergen"} />
            </button>

            <div className={"options-bar-divider f-right m-r-5 m-l-5"} />
          </div>
        </div>

        {localStorage.getItem('selectedSurvey') &&
        <>
          <div className={"card-box slide-up m-t-50"} key={this.state.surveyName}>
            <h2>{/*<span style={{color:"rgb(164, 173, 183)"}}>Vragenlijst:</span> */}
              {this.state.surveyName}
              <span style={{color:"rgb(164, 173, 183)"}} className={"m-l-10"}>({this.state.numberOfRespondents} <FormattedMessage id={"dashboard.respondents"} defaultMessage={"respondenten"}/>)</span>
              {/* {this.state.numberOfUnfinishedRespondents > 0 && ", " + this.props.intl.formatMessage({id: "dashboard.of_which"}) + " " + this.state.numberOfUnfinishedRespondents + " " + this.props.intl.formatMessage({id: "dashboard.not_finished"})}) */}
            </h2>
            <hr className={"m-t-20 m-b-20"}/>
            <div className={"range-slider-holder"}>
              {this.state.surveyStart &&
              <DateRangeSlider
                style={{marginLeft: "30px", marginRight: "30px"}}
                min={this.state.surveyStart}
                max={this.state.surveyEnd}
                from={this.state.sliderFrom}
                to={this.state.sliderTo}
                onFinish={(data) => this.handleDateSliderFinish(data)}
                ref={this.dateSlider}
              />}
            </div>

            {this.getDimensionOptions().length > 1 && <Form.Group className={"m-t-20 m-b-0"}>
              <Form.Label><FormattedMessage id={"dashboard.dimension_filter"} defaultMessage={"Domeinfilter"} /></Form.Label><br />
              <Dropdown
                placeholder='Selecteer een optie...'
                selection
                className={"full-width dropdown-full-height"}
                options={this.getDimensionOptions()}
                ref={this.dimensionFilter}
                value={this.state.selectedDimension}
                style={(this.state.selectedDimension !== this.props.intl.formatMessage({id: "dashboard.no_dimension_filter"}) ? {backgroundColor: "#f3f3f3", color: "#000"} : {})}
                onChange={(e, {value}) => {
                  sessionStorage.setItem('selectedDimension', value)
                  this.setState({
                    selectedDimension: value
                  })
                }}
              />
            </Form.Group>}
          </div>

          {!this.state.loading && this.state.results.length === 0 &&
          <div className={"slide-up no-results-placeholder"}>
            <FormattedMessage id={"dashboard.no_results"} defaultMessage={"Geen resultaten om weer te geven."} />
          </div>}

          <div className="GraphCardHolder">
            <>
              {this.state.loading && <LoadingSpinner className={"holder-spinner slide-up"}/>}
              {!this.state.loading && this.getResultCards()}
            </>
          </div>
        </>
        }
      </>
    )
  }
}

export default injectIntl(GraphCardHolder, { forwardRef: true })
