import React, {Component} from 'react'
import axios from "axios"
import {Redirect, Link} from 'react-router-dom'
import {Button} from "react-bootstrap"
import {injectIntl} from "react-intl"

const {rootUrl} = require("../../../config")

axios.defaults.withCredentials = true

class BenchmarkMaintenance extends Component {
  constructor(props) {
    super(props)

    this.state = {
      redirect: false
    }
  }

  renderRedirect = () => {
    if (this.state.redirect) return <Redirect to='/login'/>
  }

  render() {
    return (
      <div>
        {this.renderRedirect()}
        <div className="account-pages login-background"/>
        <div className="clearfix"/>
        <div className="wrapper-page">

          <div className="account-bg">
            <div className="card-box m-b-10 login-card">
              <div className="text-xs-center m-t-20">
                <Link to='/login' className="logo .live-green">
                  <img src="/images/logo60.png" alt="" id="login-logo" width="40" height="40"/><br/>
                  <span id="login-title" className="text-live-green">LiveResearch</span>
                </Link>
              </div>

              <div className="m-t-30 m-b-0">
                <div className="col-xs-12 text-xs-center">
                  <h6 className="text-muted text-uppercase m-b-0 m-t-0">Maintenance</h6>
                  <br/>
                  <p>The benchmarking tool of LiveResearch is currently down due to maintenance. </p>
                    <Link to='/vragenlijsten' className="text-muted">
                      <Button
                        className="col-xs-12 text-center m-t-10 btn btn-success btn-block waves-effect waves-light btn-live-green"
                        type="submit"
                      >
                        Return to surveys
                      </Button>
                    </Link>
                  <br/><br/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(BenchmarkMaintenance)
