import React, {Component} from 'react'
import CardBase from '../CardBase/CardBase'
import './NPSCard.css'
import {Doughnut} from 'react-chartjs-2'
import scrollToComponent from 'react-scroll-to-component'
import {FormattedMessage} from 'react-intl'
import injectIntl from "react-intl/lib/src/components/injectIntl"

class NPSCard extends Component {
  constructor(props) {
    super(props)
    this.cardRef = React.createRef()
    this.chartRef = React.createRef()

    // let promoters = 0;
    // let detractors = 0;
    // let passives = 0;
    let erg_moeilijk = 0;
    let moeilijk = 0;
    let verwachte_moeite = 0;
    let makkelijk = 0;
    let erg_makkelijk = 0;


    console.log(this.props.labels);

    // for(let i = 0; i < this.props.labels.length; i++) {
    //   if(this.props.labels[i] <= 1) {
    //     detractors += this.props.data[i]
    //   } else if (this.props.labels[i] === 2 || this.props.labels[i] === 3) {
    //     passives += this.props.data[i]
    //   } else if (this.props.labels[i] >= 4) {
    //     promoters += this.props.data[i]
    //   }
    // }

    for(let i = 0; i < this.props.labels.length; i++) {
      if(this.props.labels[i] === 1) {
        erg_moeilijk += this.props.data[i]
      } else if (this.props.labels[i] === 2) {
        moeilijk += this.props.data[i]
      } else if (this.props.labels[i] === 3) {
        verwachte_moeite += this.props.data[i]
      } else if (this.props.labels[i] === 4) {
        makkelijk += this.props.data[i]
      } else if (this.props.labels[i] === 5) {
        erg_makkelijk += this.props.data[i]
      }
    }

    // if (this.props.chartType === 'nps') {
    //   this.state = {
    //     detractors: detractors,
    //     passives: passives,
    //     promoters: promoters,
    //     nps: promoters / (detractors + promoters + passives) * 100 - detractors / (detractors + promoters + passives) * 100,
    //     chartData: {
    //       labels: ["Detractors", "Passives", "Promoters"],
    //       datasets: [{
    //         backgroundColor: ['rgb(244,66,55)', 'rgb(251,152,38)', 'rgb(76,175,80)'],
    //         borderColor: ['rgb(244,7,16)', 'rgb(251,122,19)', 'rgb(26,175,28)'],
    //         data: [detractors, passives, promoters],
    //       }]
    //     }
    //   }
    // }

    if (this.props.chartType === 'nps') {
      this.state = {
        erg_moeilijk: erg_moeilijk,
        moeilijk: moeilijk,
        verwachte_moeite: verwachte_moeite,
        makkelijk: makkelijk,
        erg_makkelijk: erg_makkelijk,
        // nps: promoters / (detractors + promoters + passives) * 100 - detractors / (detractors + promoters + passives) * 100,
        chartData: {
          labels: ["Erg moeilijk", "Moeilijk", "Verwacht moeite", "Makkelijk", "Erg makkelijk"],
          datasets: [{
            backgroundColor: ['rgb(244,66,55)', 'rgb(244,66,55)','rgb(251,152,38)', 'rgb(76,175,80)', 'rgb(76,175,80)'],
            borderColor: ['rgb(139,0,0)', 'rgb(139,0,0)', 'rgb(181,112,0)', 'rgb(15,96,00)', 'rgb(15,96,00)'],
            data: [erg_moeilijk, moeilijk, verwachte_moeite, makkelijk, erg_makkelijk],
          }]
        }
      }
    }

    this.state = {
      tableVisible: this.props.tableVisible,
      chartData: this.state.chartData,
      promoters: this.state.promoters,
      nps: this.state.nps,
      detractors: this.state.detractors,
      passives: this.state.passives,

      blockWidth: this.props.blockWidth,
      blockHeight: this.props.blockHeight
    }
  }


  handlePieClick(event) {
    this.props.onFilterClick(event, this.props.index)
  }

  downloadChart() {
    let el = this.chartRef.current.chartInstance.canvas
    let destinationCanvas = document.createElement("canvas")
    destinationCanvas.width = el.width
    destinationCanvas.height = el.height

    let destCtx = destinationCanvas.getContext('2d')
    destCtx.fillStyle = "#FFFFFF"
    destCtx.fillRect(0, 0, el.width, el.height)
    destCtx.drawImage(el, 0, 0)

    const a = document.createElement('a')
    a.download = this.props.cardTitle + ".png"
    a.href = destinationCanvas.toDataURL('image/png')
    document.body.appendChild(a)
    a.addEventListener('click', function () {
      a.parentNode.removeChild(a)
    })
    a.click()
  }

  getChart() {
    if (this.props.chartType === 'nps') {
      return <Doughnut ref={this.chartRef}
                  data={this.state.chartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    responsiveAnimationDuration: 0,
                    legend: {
                      display: true
                    }
                  }}
                  onElementsClick={e => this.handlePieClick(e)}
        />
    }
  }

  toggle() {
    let newWidth = (!this.state.tableVisible ? 4 : this.props.blockWidth)
    let newHeight = (!this.state.tableVisible ? 2 : this.props.blockHeight)

    this.setState({
      tableVisible: !this.state.tableVisible,
      blockWidth: newWidth,
      blockHeight: newHeight
    }, () => {
      this.cardRef.current.sizeChanged(newWidth, newHeight, () => {
        this.chartRef.current.chartInstance.resize() // Redraw chart when toggled

        if (this.state.tableVisible) {
          scrollToComponent(this.cardRef.current)
        }
      })
    })
  }

  getTableBody() {
    let labels = this.state.chartData.labels
    let numbers = this.state.chartData.datasets[0].data
    let total = numbers.reduce((partial_sum, a) => partial_sum + a, 0)
    let result = []

    for (let i = 0; i < labels.length; i++) {
      result.push(<tr key={i}>
        <td>{labels[i]}</td>
        <td>{numbers[i]}</td>
        <td>{(numbers[i] / total * 100).toFixed(1)}%</td>
      </tr>)
    }

    result.push(<tr key={labels.length}>
      <th></th>
      <th>{total}</th>
      <th>100%</th>
    </tr>)

    return result
  }

  render() {
    return (
      <CardBase
        ref={this.cardRef}
        blockHeight={this.state.blockHeight}
        blockWidth={this.state.blockWidth}
        cardTitle={this.props.index === 0 ? this.props.cardTitle :this.props.index + '. ' + this.props.cardTitle.replace('&nbsp;', ' ')}
        dimensionTitle={this.props.dimensionTitle}
        optionButtons={
          [
            <span onClick={this.toggle.bind(this)}
                  key={1}
                  style={{visibility: this.state.chartData.datasets[0].data.reduce((a, b) => a + b, 0) > 0 ? 'visible' : 'hidden'}}
                  className={"optionButton fa fa-" + (this.state.tableVisible ? "minus" : "plus")}/>,
            <span onClick={this.downloadChart.bind(this)}
                  key={2}
                  className="optionButton fa fa-download"/>
          ]
        }
        cardText={this.props.cardText.replace('&nbsp;', ' ')}>
        <div className={"result-content"}>
          <div className={"graphWrapper " + (this.state.tableVisible ? "expanded2" : "")}>
            {this.state.chartData.datasets[0].data.reduce((a, b) => a + b, 0) > 0 ? this.getChart() :
              <p>Nog geen data beschikbaar.</p>}
            {/* <h5 style={{color: this.state.nps > 0 ? 'rgb(76,175,80)' : 'rgb(244,66,55)'}}>NPS Score: {this.state.nps}</h5> */}

          </div>
          {this.state.tableVisible &&
          <div className="tableWrapper expanded2">
            <table className="table table-striped table-bordered resultsTable">
              <thead>
              <tr>
                <th scope="col"><FormattedMessage id={"dashboard.answer"} defaultMessage={"Antwoord"} /></th>
                <th scope="col"><FormattedMessage id={"dashboard.number_of_answers"} defaultMessage={"Aantal"} /></th>
                <th scope="col"><FormattedMessage id={"dashboard.percentage"} defaultMessage={"Percentage"} /></th>
              </tr>
              </thead>
              <tbody>
              {this.getTableBody()}
              </tbody>
            </table>
          </div>
          }
        </div>
      </CardBase>
    )
  }
}

export default injectIntl(NPSCard)
