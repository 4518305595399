import React, {Component} from 'react'
import axios from 'axios'
import sha256 from 'sha256'
import {Form} from "react-bootstrap"
import {FormattedMessage, injectIntl} from "react-intl"
import Button from "react-bootstrap/Button"
import NavLink from "react-router-dom/NavLink"
import Flags from "country-flag-icons/react/3x2"
import Swal from "sweetalert2"
import {Redirect, Link} from 'react-router-dom'

const {rootUrl} = require("../../../config")

class ServerStatus extends Component {

  constructor(props) {
    super(props)
    this.state = {
      token: '',
      serverStatus: "Onbekend",
      restarting: false,
      statusText: ''
    }
  }

  componentDidMount() {
    axios.get(rootUrl + '/status')
      .then(res => {
        this.setState({
          serverStatus: "geen problemen aanwezig"
        })
      })
      .catch(e => {
        this.setState({
          serverStatus: "Onbereikbaar"
        })
      })
  }

  attemptRestart() {
    this.setState({
      restarting: true
    }, () => {
      const testURL = 'https://backend-update.liveresearch.com';
      const myInit = {
        method: 'GET',
        mode: 'no-cors',
        headers: {
          'X-restart-token':this.state.token
        }
      };

      const myRequest = new Request(testURL, myInit);
      console.log(myRequest)
      if(this.state.token === 'SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c') {
        fetch(myRequest).then(function(response) {
          return response;
        }).then(function(response) {
          console.log(response);
        }).catch(function(e){
          console.log(e);
        });
      }

    })
  }

  render() {
    return (
      <>
        <div className="account-pages login-background"/>
        <div className="clearfix"/>
        <div className="wrapper-page">
          <div className="account-bg">
            <div className="card-box m-b-10 login-card">
              <div className="text-xs-center m-t-20">
                <Link to='/login' className="logo .live-green">
                  <img src="/images/logo60.png" alt="" id="login-logo" width="40" height="40"/><br/>
                  <span id="login-title" className="text-live-green">LiveResearch</span>
                </Link>
                <div className="col-xs-12 text-xs-center">
                  <h6 className="text-muted text-uppercase m-b-0 m-t-0">Server Status</h6>
                </div>
              </div>
              <br></br>
              <br></br>
              <h6 style={{color: this.state.serverStatus === 'Onbekend' ? 'orange' : this.state.serverStatus === 'Onbereikbaar' ? 'red' : 'green'}}>Server Status: {this.state.serverStatus}</h6>
              <Form.Group>
                <Form.Label>Restart-token</Form.Label>
                <Form.Control
                  value={this.state.token}
                  onChange={(e) => this.setState({token: e.target.value})}
                  type={"password"}
                  placeholder={"Token"}
                  disabled={this.state.restarting}
                />
              </Form.Group>
              <br></br>
              <Button disabled={this.state.restarting || !this.state.token} onClick={() =>this.attemptRestart()}>Restart server</Button>
              <NavLink class="f-right" to={'/login'}><Button variant='secondary'><FormattedMessage id={"new_account.login_page"} defaultMessage={"Inlogpagina"} /></Button></NavLink>
              <br/>
              <h6>{this.state.statusText}</h6>

            </div>
          </div>
        </div>
      </>
    )
  }


}

export default injectIntl(ServerStatus)
