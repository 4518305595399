import React, { Component } from 'react'
import {Button, Form} from "react-bootstrap"
import {injectIntl} from "react-intl"
import ComponentHolder from "../ComponentHolder/ComponentHolder"

class SingleMultipleChoice extends Component {
  constructor(props) {
    super(props)

    this.state = {
      radio: (this.props.answer ? this.props.answer.option : -1),
      openText: ((this.props.answer && this.props.answer.open) ? this.props.answer.open : ""),
      openOptionSelected: false
    }

    if (this.props.answer) this.props.validateAnswer(this.props.position, true, this.props.answer)
  }

  handleChange = nr => {
    let answer = {option: nr}
    let valid = true

    this.setState({
      openOptionSelected: this.props.options[nr].is_open
    })

    this.setState({
      radio: nr
    }, () => {
      if (!this.state.openOptionSelected) {
        this.props.validateAnswer(this.props.position, valid, answer)
      }
    })

    if (this.props.hasRoutingInformation) {
      if(this.props.options[nr].route_to_page) {
        this.props.onRoutingChanged(this.props.options[nr].route_to_page)
      } else if (this.props.options[nr].route_to_url) {
        this.props.onRoutingChanged(this.props.options[nr].route_to_url)
      }
    }
  }

  validateOpenAnswer() {
    let answer = {
      option: this.state.radio,
      open: this.state.openText
    }
    let valid = (this.state.openText !== "")

    this.props.validateAnswer(this.props.position, valid, answer)
  }

  loadOptions() {
    let result = []
    for (let i = 0; i < this.props.options.length; i++) {
      let option = this.props.options[i]
      // Generate random string to create unique ID for the control.
      let randomString = Math.random().toString(36).substring(2, 12) + Math.random().toString(36).substring(2, 12)
      result.push(
        <div
          className={(this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " f-right clear-right" : "")}
          key={i}>
          <Form.Check
            key={i}
            type={"radio"}
            label={option.text.replace('&nbsp;', ' ').split(' ').filter(x => !x.includes('http')).join(' ')}
            id={"radiobutton" + randomString + i.toString()}
            name={"radio-group"}
            checked={this.state.radio === i}
            onChange={() => {this.handleChange(i)}}
            className={"radio " + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " f-right clear-right" : "")}
          />
          {option.text.includes('http') && <img src={option.text.split(' ').filter(x => x.includes('http'))[0]} alt="" style={{maxWidth: 200, margin: 20}}/>

          }
          {option.is_open &&
            <>
              <input
                type={"text"}
                className={"survey-form-control mc-textbox" + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " arabic-text f-right clear-right" : "")}
                placeholder={this.props.intl.formatMessage({id: "survey.answer"})}
                onChange={(e) => {
                  this.setState({openText: e.target.value},
                    () => {
                      this.handleChange(i)
                    })
                }}
                value={this.state.openText}
              /><br />
              <Button
                className={"btn btn-primary mc-textbox" + (this.state.openOptionSelected ? "" : " d-none") + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " f-right clear-right" : "")}
                style={{marginTop:"-12px", "width":"100px"}}
                onClick={() => this.validateOpenAnswer()}
                onMouseEnter={() => this.validateOpenAnswer()}
                onMouseLeave={e => (e.target.style.backgroundColor = "#90EE90", e.target.style.borderColor = "#90EE90")}
                disabled={this.state.openText === ""}
              >
                OK
              </Button>
            </>
          }
        </div>
      )
    }

    return result
  }

  render() {
    return (
      <ComponentHolder {...this.props}>
        <Form>
         {this.loadOptions()}
        </Form>
      </ComponentHolder>
    )
  }
}

export default injectIntl(SingleMultipleChoice)
