import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

class ListTable extends Component {
  render() {
    return (
      <>
      <table className={"table table-striped " + this.props.className}>
        <thead>
        <tr>
          {this.props.headers.map((header, headerIndex) => {
            return <th key={headerIndex} scope="col">{header}</th>
          })}

          {(this.props.removeButton || this.props.editButton || this.props.duplicateButton || this.props.previewButton || this.props.shareButton || this.props.sendNowButton || this.props.recipientsButton || this.props.goToSurveyButton || this.props.exportRespondentCountButton) &&
            <th scope="col"/>
          }
        </tr>
        </thead>
        <tbody>
          {this.props.rows.map((row, rowIndex) => {
            return (
              <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => {
                      return (
                        <td key={cellIndex}>{cell}</td>
                      )
                    })
                  }

                  {(this.props.removeButton || this.props.editButton || this.props.duplicateButton || this.props.previewButton || this.props.shareButton || this.props.sendNowButton || this.props.recipientsButton || this.props.goToSurveyButton || this.props.exportRespondentCountButton) &&
                  <td>
                    {this.props.QRCodeButton &&
                    <button
                      className="btn btn-primary btn-sm m-t-5 m-r-5 f-right"
                      onClick={() => this.props.onQRCodeButtonClicked(row)}>
                      <i className="fa fa-qrcode"/>
                    </button>}
                    {this.props.removeButton && <button className="btn btn-danger btn-sm m-l-5 m-t-5 m-r-5 f-right" onClick={() => this.props.onRemoveButtonClicked(row)}>
                      <i className="fa fa-remove"/> <FormattedMessage id={"ui.remove"} defaultMessage={"Verwijderen"} />
                    </button>}
                    {this.props.changeAccessButton &&
                    <button
                      className="btn btn-success btn-sm m-l-5 m-t-5 f-right"
                      onClick={() => this.props.onChangeAccessButtonClicked(row)}>
                      <i className="fa fa-users"/> <FormattedMessage id={"ui.change_role"} defaultMessage={"Toegang aanpassen"} />
                    </button>}
                    {this.props.editButton &&
                      <button
                        className="btn btn-secondary btn-sm m-l-5 m-t-5 f-right"
                        onClick={() => this.props.onEditButtonClicked(row)}>
                          <i className="fa fa-edit"/> <FormattedMessage id={"ui.edit"} defaultMessage={"Aanpassen"} />
                      </button>}
                    {this.props.duplicateButton &&
                    <button
                      className="btn btn-secondary btn-sm m-l-5 m-t-5 f-right"
                      onClick={() => this.props.onCopyButtonClicked(row, false)}>
                      <i className="far fa-copy"/> <FormattedMessage id={"ui.copy"} defaultMessage={"Kopiëren"} />
                    </button>}
                    {this.props.duplicateRespondents &&
                    <button
                      className="btn btn-secondary btn-sm m-l-5 m-t-5 f-right"
                      onClick={() => this.props.onCopyButtonClicked(row, true)}>
                      <i className="far fa-copy"/> <FormattedMessage id={"ui.copy_respondents"} defaultMessage={"Kopiëren met respondenten"} />
                    </button>}
                    {this.props.previewButton &&
                    <button
                      className="btn btn-secondary btn-sm m-l-5 m-t-5 f-right"
                      onClick={() => this.props.onPreviewButtonClicked(row)}>
                      <i className="fa fa-eye"/> <FormattedMessage id={"ui.preview"} defaultMessage={"Preview"} />
                    </button>}
                    {this.props.shareButton &&
                      <button
                        className="btn btn-primary btn-sm m-t-5 f-right"
                        onClick={() => this.props.onShareButtonClicked(row)}>
                          <i className="fa fa-share-alt"/> <FormattedMessage id={"ui.share"} defaultMessage={"Delen"} />
                      </button>}
                    {this.props.sendNowButton &&
                    <button
                      className="btn btn-primary btn-sm m-t-5 m-l-5 f-right"
                      onClick={() => this.props.onSendNowButtonClicked(row)}>
                      <i className="fa fa-envelope"/> <FormattedMessage id={"ui.send_immediately"} defaultMessage={"Meteen versturen"} />
                    </button>}
                    {this.props.recipientsButton &&
                    <button
                      className="btn btn-secondary btn-sm m-t-5 f-right"
                      onClick={() => this.props.onRecipientsButtonClicked(row)}>
                      <i className="fa fa-address-book"/> <FormattedMessage id={"ui.recipients"} defaultMessage={"Ontvangers"} />
                    </button>}

                    {this.props.goToSurveyButton &&
                  <button
                    className="btn btn-success btn-sm m-t-5 m-r-5 f-right"
                    onClick={() => this.props.goToSurveyButtonClicked(row)}>
                    <i className="fa fa-link"/>
                  </button>}

                    {this.props.exportRespondentCountButton &&
                      <button
                        className="btn btn-success btn-sm m-t-5 m-r-5 f-right"
                        onClick={() => this.props.onExportRespondentCountButtonClicked(row)}>
                        <i className="fa fa-users"/><FormattedMessage id={"ui.export_respondent_numbers"} defaultMessage={"Exporteer respondentaantallen"} />
                      </button>}


                  </td>
                  }
              </tr>
            )
          })}
        </tbody>
      </table>
      </>
    )
  }
}

export default ListTable
