import React from 'react'
import {rootUrl} from '../config'

// Dashboard imports
import Login from '../screens/Dashboard/Login/Login'
import Statements from '../screens/Dashboard/Statements/Statement'
import Register from '../screens/Dashboard/Register/Register'
import Benchmark from "../screens/Dashboard/Benchmark/Benchmark"
import BenchmarkMaintenance from "../screens/Dashboard/Benchmark/BenchmarkMaintenance"
import Dashboard from '../screens/Dashboard/Dashboard/Dashboard'
import Results from '../screens/Dashboard/Results/Results'
import Broadcasts from '../screens/Dashboard/Broadcasts/Broadcasts'
import NewMailing from '../screens/Dashboard/NewMailing/NewMailing'
import ForgotPassword from '../screens/Dashboard/Login/ForgotPassword'
import Surveys from '../screens/Dashboard/Surveys/Surveys'
import Privacy from '../screens/Miscellaneous/Privacy/Privacy'
import PrivacyOld from '../screens/Miscellaneous/Privacy/PrivacyOld'
import Ferring_DPA from '../screens/Miscellaneous/DPA/Ferring_DPA'
import SurveyBuilder from '../screens/Dashboard/SurveyBuilder/SurveyBuilder'
import SurveyEditor from '../screens/Dashboard/SurveyBuilder/SurveyEditor'
import NotFound from '../screens/Dashboard/NotFound/NotFound'
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import Administration from "../screens/Dashboard/Administration/Administration"
import AskAQuestion from "../screens/Dashboard/AskAQuestion/AskAQuestion"
import Clusters from "../screens/Dashboard/Clusters/Clusters"
import ResetPassword from "../screens/Dashboard/ResetPassword/ResetPassword"
import LanguageSelection from "../screens/Survey/LanguageSelection/LanguageSelection"
import BenchmarkExplanation from "../screens/Miscellaneous/BenchmarkExplanation/BenchmarkExplanation"
// Survey imports
import Survey from '../screens/Survey/Survey/Survey'

import axios from 'axios'
import sha256 from 'sha256'
import RemoveFromMailing from "../screens/Dashboard/RemoveFromMailing/RemoveFromMailing"
import BenchmarkReport from "../screens/Dashboard/BenchmarkReport/BenchmarkReport"
import BenchmarkSettings from "../screens/Dashboard/BenchmarkSettings/BenchmarkSettings"

import {IntlProvider} from "react-intl"
import messages_nl from "../translations/nl.json"
import messages_de from "../translations/de.json"
import messages_en from "../translations/en.json"
import messages_ar from "../translations/ar.json"
import messages_es from "../translations/es.json"
import messages_fr from "../translations/fr.json"
import messages_it from "../translations/it.json"
import messages_pl from "../translations/pl.json"
import messages_ru from "../translations/ru.json"
import messages_se from "../translations/se.json"
import messages_tr from "../translations/tr.json"
import ServerStatus from "../screens/Dashboard/ServerStatus/ServerStatus"
import BenchmarkBarChart from "../screens/Dashboard/Benchmark/BenchmarkBarChart";
import TimeBenchmarkBarChart from 'screens/Dashboard/Benchmark/TimeBenchmarkBarChart'


const messages = {
  'nl': messages_nl,
  'de': messages_de,
  'en': messages_en,
  'ar': messages_ar,
  'es': messages_es,
  'fr': messages_fr,
  'it': messages_it,
  'pl': messages_pl,
  'ru': messages_ru,
  'se': messages_se,
  'tr': messages_tr,
}

axios.defaults.withCredentials = true

function PrivateRoute({component: Component, authed, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => (authed)
      ? <Component {...props} {...rest} />
      //: <Component {...props}/>}
        : <Redirect to={{pathname: '/login', state: {from: props.location}, search: props.location.search}}/>}
    />
  )
}

class Routes extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      authed: false,
      username: 'default',
      refresh: true,
      errorMessage: "",
      language: (localStorage.getItem("language_locale") ? localStorage.getItem("language_locale") : "en")
    }
  }

  onLanguageChangedRefreshPage(language) {
    this.setState({
      language: language
    }, () => {
      localStorage.setItem("language_locale", language)
      window.location.reload()
    })
  }

  onLanguageChanged(language) {
    this.setState({
      language: language
    }, () => {
      localStorage.setItem("language_locale", language)
    })
  }

  componentDidMount() {
    this.checkIfAlreadyLoggedIn()
      .then(() =>
        this.setState({authed: true})
      )
      .catch(() => {
        this.setState({authed: false})
      })
  }

  mapOldSurveyIds() {
    let newUrl = 'https://dashboard.liveresearch.com/language-selection/'

    //TODO new ids toevoegen
    let ids = [
      {
        oldId: '2YnXrECaQF3CaM8yH',
        oldIpadLink: 'NHra4JWNZ8YDT2pZW',
        newId: 'fce7da12-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: '2dzd56N52cBdsbbWB',
        oldIpadLink: 'YXLjDDD8St32qSkvJ',
        newId: 'fce7e09c-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: '3fbpdfHTpxh4sabGW',
        oldIpadLink: 'twdtQiH6fqRfnMH6L',
        newId: 'fce70403-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: '3rMJQzFBDA7TMuZPx',
        oldIpadLink: 'u5yprMc89MaZhi6zJ',
        newId: 'fce8088b-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: '6RZXPJTF3taBhRpp2',
        oldIpadLink: '4P4cmS2eX7XmMr83K',
        newId: 'fce7f130-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: '8t43DR6NQMvDz4xzN',
        oldIpadLink: '5g9Rkuy73YBDE9Evk',
        newId: 'fce7efdd-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: '9PE6iC5emSNmGs4Xo',
        oldIpadLink: 'okMAXdY8AjpE7ZQb4',
        newId: 'fce7db5b-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'AG6yW3JsibSkL2LQh',
        oldIpadLink: '9XsiAjCfguhp2ZERP',
        newId: 'fce7e595-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'BKAryMxhHehhSBvDc',
        oldIpadLink: 'g5bG2eSskGfRX3KPz',
        newId: 'fce7a469-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'CQPP8PYqeK76zgtM9',
        oldIpadLink: 'eterEMwPnHobww4xh',
        newId: 'fce7dce5-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'Cik2MkBTmfGNEN9pX',
        oldIpadLink: 'PjwzncA9PBGZ4gouH',
        newId: 'fce7f716-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'Egn7MaKpvDZyW6J5L',
        oldIpadLink: '7mBvELfRnSAWRyE7A',
        newId: 'fce70403-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'Gaz82snxveHKqYNhS',
        oldIpadLink: '4cgPhsQ8ZYdoYyXbd',
        newId: 'fce6fdf5-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'GnnbKacBR4qfDXdhu',
        oldIpadLink: 'rxBioeWnCKJF8tba8',
        newId: 'fce7e416-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'LwKk9QG8QaX2wGL2i',
        oldIpadLink: 'HkaYKKPdhTAfDB9Md',
        newId: 'fce7f2af-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'MadPKZywS2QYhE3YN',
        oldIpadLink: 'isRHWZQnmT8XmLCt3',
        newId: 'fce7e703-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'RDcmEAEQ5Hez662N5',
        oldIpadLink: 'xSNWBjt8uzzLjDgtq',
        newId: 'fce7d899-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'T4knhuoYjSt2HZyjE',
        oldIpadLink: 'ehgPy4S9KDGCeEhgF',
        newId: 'fce7ee5d-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'Tbfmpwk3ymEfQ6ibT',
        oldIpadLink: 'xNZoTZrNem5vLCJ7z',
        newId: 'fce70878-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'YAuEq8gs6zQxWRTMt',
        oldIpadLink: 'MQ5J4yqgim34btpBi',
        newId: 'fce7ebbb-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'Z6vqKQjuqXsvsGtHR',
        oldIpadLink: 'xEAyvxztaxRx6qx5a',
        newId: 'fce80328-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'azgcCvcR5SNJN5BBf',
        oldIpadLink: '8qnLxZHL6GneK4Qre',
        newId: 'fce7a469-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'facGTcD7dufjqruhD',
        oldIpadLink: 'siHQcL7api4cCxAdH',
        newId: 'fce7e264-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'gEqyWgztPY7LAL8LG',
        oldIpadLink: 'AHSYhHXosxjwXjCqg',
        newId: 'fce7dde3-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'hCMiErpzvZeQDHxby',
        oldIpadLink: 'e2vqG5X2dzeSdZEwJ',
        newId: 'fce7d74d-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'iMLawR4JwteH78rmX',
        oldIpadLink: 'qaCDbgCixSj7REcKi',
        newId: 'fce7ea75-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'kKfooakqFxFF8miXb',
        oldIpadLink: '4LiTWjKk98iuHKjR2',
        newId: 'fce70878-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'n9tPauar27TrKLHLB',
        oldIpadLink: 'frYSrQoRADDpL87mt',
        newId: 'fce7f40a-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'njnSFRou24Kwg3GT9',
        oldIpadLink: 'sR93rw6ct3QrcFKBK',
        newId: 'fce80a02-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'pLMzC7qw5S4WFJSom',
        oldIpadLink: 'ZcNZ9jXXa9mrYHnCG',
        newId: 'fce7f5bc-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'qGPafTLG7GZeTDgYw',
        oldIpadLink: 'dYDcqiZkf66XaAYBd',
        newId: 'fce7e91b-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'sAPr5oNpA9uDFHv8w',
        oldIpadLink: 'dJJGzn2rvy367Z2zg',
        newId: 'fce7d62f-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 't7EiFPj4MBseMG565',
        oldIpadLink: '6ePFHqSCmhq6uTZXP',
        newId: 'fce7ed2e-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'vJm5LgFwz5DCA3BWA',
        oldIpadLink: '7yANpnaRfuczjNt43',
        newId: 'fce8069c-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'wxK9LitXcJ5xh5K8a',
        oldIpadLink: 'oyYfSut28AvMehmXc',
        newId: 'fce6fdf5-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'vXgWy2fKtGk7p7PET',
        oldIpadLink: 'HZ5YRPZ3ADhijvRNc',
        newId: 'fce7df4b-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'zqBFChgZQqEoyxYxz',
        oldIpadLink: 'Kui6m8F4XeFjKb8HQ',
        newId: 'fce80495-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'k7z97DWyq9ycAP3RP',
        oldIpadLink: 'L2y4nKzikfkC3dMtt',
        newId: 'fce80b59-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'ihPSoWFAE662vfKE8',
        oldIpadLink: 'b3Fw7tSSeGo2f7a82',
        newId: 'fce716fc-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: '2RxucBbtkuWf4REQb',
        oldIpadLink: 'FqLKyeAfGBhZQDs2p',
        newId: 'fce7a469-689e-11eb-8479-5616cdf577f8'
      },
      {
        oldId: 'LYpcgaj9hazg99kZK',
        oldIpadLink: 'LYpcgaj9hazg99kZK',
        newId: 'b10be9f8-c686-4879-9b84-2319be1558aa'
      }

    ]

    let windowId = window.location.pathname.substring(1)


    for(let i in ids) {
      if(windowId === ids[i].oldId || windowId === ids[i].oldIpadLink) {
        newUrl += ids[i].newId
        break
      }
    }
    return newUrl
  }

  render() {
    if(window.location.origin.includes('survey.liveresearch.com')) {
      window.location = this.mapOldSurveyIds()
    } else {
      return (
        <IntlProvider
          messages={messages[this.state.language]}
          locale={this.state.language}>
          <BrowserRouter>
            <Switch>
              <Route exact path='/remove-from-mailing/:id/:email/:language'
                render={(props) => <RemoveFromMailing {...props}/>}
              />
              <Route
                exact path="/login"
                render={(props) =>
                  (this.state.authed)
                    ? <Redirect to={{pathname: '/dashboard', state: {from: props.location}, search: props.location.search}}/>
                    : <Login
                      {...props}
                      onLanguageChanged={(lang) => this.onLanguageChanged(lang)}
                      logUserIn={this.logUserIn.bind(this)}
                      getErrorMessage={this.getErrorMessage.bind(this)}
                    />
                }
              />

              <Route exact path={['/registreren', '/registrieren', '/register', "/inscription"]} onRegister={(username, email, password) => this.register(username, email, password)} component={Register}/>
              <Route exact path={['/nieuwsbrief', '/newsletter']} component={BenchmarkReport}/>
              <Route exact path='/reset_password/:userId/:token/:locale' onLanguageChanged={(lang) => this.onLanguageChanged(lang)} render={(props) => <ResetPassword {...props} onLanguageChanged={(lang) => this.onLanguageChanged(lang)}/>} />
              <PrivateRoute authed={this.state.authed} path='/dashboard' onLanguageChanged={(lang) => this.onLanguageChangedRefreshPage(lang)} component={Dashboard}/>
              <PrivateRoute authed={this.state.authed} path="/resultaten" onLanguageChanged={(lang) => this.onLanguageChanged(lang)} component={Results}/>
              <PrivateRoute exact authed={this.state.authed} path={["/verzendlijsten", "/versandlisten", "/broadcasts", "/diffusions"]} onLanguageChanged={(lang) => this.onLanguageChanged(lang)} component={Broadcasts}/>
              <PrivateRoute authed={this.state.authed} path={["/verzendlijsten/nieuwe-verzendlijst", "/versandlisten/neue-versandliste", "/broadcasts/new-broadcast", "/diffusions/nouvelle-diffusion"]} onLanguageChanged={(lang) => this.onLanguageChanged(lang)} component={NewMailing}/>
              <PrivateRoute exact authed={this.state.authed} path={["/vragenlijsten", "/alle-fragebogen", "/surveys", "/enquêtes"]} onLanguageChanged={(lang) => this.onLanguageChanged(lang)} component={Surveys}/>
              <PrivateRoute authed={this.state.authed} path={["/vragenlijsten/nieuwe-vragenlijst", "/alle-fragebogen/neuer-fragebogen", "/surveys/new-survey", "/enquêtes/nouvelle-enquête"]} onLanguageChanged={(lang) => this.onLanguageChanged(lang)} component={SurveyBuilder}/>
              <PrivateRoute authed={this.state.authed} path={["/vragenlijsten/vragenlijst-aanpassen/:editSurveyId", "/alle-fragebogen/fragebogen-andern/:editSurveyId", "/surveys/edit-survey/:editSurveyId", "/enquêtes/modifier-enquête/:editSurveyId"]} onLanguageChanged={(lang) => this.onLanguageChanged(lang)} component={SurveyEditor}/>
              <PrivateRoute authed={this.state.authed} path={["/benchmark", "/benchmarking", "/référence"]} onLanguageChanged={(lang) => this.onLanguageChangedRefreshPage(lang)} component={BenchmarkBarChart}/>
              <PrivateRoute authed={this.state.authed} path={["/benchmark-time", "/benchmarking-time", "/référence-temps"]} onLanguageChanged={(lang) => this.onLanguageChangedRefreshPage(lang)} component={TimeBenchmarkBarChart}/>
              <PrivateRoute authed={this.state.authed} path={["/administratie", "/administration"]} onLanguageChanged={(lang) => this.onLanguageChanged(lang)} component={Administration}/>
              <PrivateRoute authed={this.state.authed} path={["/benchmark-settings", "/benchmarking-einstellungen", "/benchmark-settings", "/paramètres-référence"]} onLanguageChanged={(lang) => this.onLanguageChanged(lang)} component={BenchmarkSettings}/>
              <PrivateRoute authed={this.state.authed} path={["/benchmark-clusters-aanpassen/:id", "/benchmarking-clusters-andern/:id", "/adjust-benchmark-clusters/:id", "/ajuster-clusters-référence/:id"]} component={Clusters}/>
              <PrivateRoute authed={this.state.authed} path={["/stel-een-vraag", "/eine-frage-stellen", "/ask-a-question", "/poser-question"]} onLanguageChanged={(lang) => this.onLanguageChanged(lang)} component={AskAQuestion}/>
              <Route path={["/benchmark-explanation"]} onLanguageChanged={(lang) => this.onLanguageChanged(lang)} component={BenchmarkExplanation}/>

              <Route path={["/wachtwoord-vergeten", "/passwort-vergessen", "/forgot-password", "/mot-de-passe-oublié"]} component={ForgotPassword}/>
              <Route path={["/reset-password", "/reset-passwort", "/reset-password", "/réinitialiser-mot-de-passe"]} component={NotFound}/>
              <Route
                path="/logout"
                render={() => {
                  return this.logUserOut()
                }}
              />
              <Route path={["/vragenlijst/:id", "/fragebogen/:id", "/survey/:id", "/cuestionario/:id", "/questionnaire/:id", "/questionario/:id", "/ankiety/:id", "/anketa/:id", "/frageformularet/:id", "/anketin/:id", "/aistibyan/:id", "/enquête/:id"]} render={(props) => <Survey {...props} onLanguageChanged={(lang) => this.onLanguageChanged(lang)} />} />
              <Route path={"/language-selection/:id"} component={LanguageSelection} />
              <Route path={"/language-selection/:id/preview"} component={LanguageSelection} />
              {/* <Route path={"/privacy-statement-ferring"} component={PrivacyOld} /> */}
              <Route path={"/privacy-statement"} authed={this.state.authed} component={Privacy} />
              <Route path={"/dpa/ferring"} authed={this.state.authed} component={Ferring_DPA} />
              <Route path={"/statement"} component={Statements} />
              <Route path={"/restart"} component={ServerStatus} />

              <PrivateRoute authed={this.state.authed} onLanguageChanged={(lang) => this.onLanguageChangedRefreshPage(lang)} component={Dashboard}/>
            </Switch>
          </BrowserRouter>
        </IntlProvider>
    )}
  }

  /**
   * Logs user out by calling the server logout and locally logging out.
   * @returns {Promise<void>}
   */
  logUserOut = () => {
    localStorage.removeItem('selectedSurvey')
    localStorage.removeItem('username')
    localStorage.removeItem('organization_id')
    localStorage.removeItem('role')
    localStorage.removeItem('benchmark_enabled')


    axios.get(rootUrl + '/api/v1/logout')
      .finally(() => {
          this.setState({authed: false})
        }
      )
    return (<Redirect to='/login'/>)

  }

  /**
   * Logs user in by calling the server.
   * @param username
   * @param password
   * @returns {Promise<*>}
   */
  logUserIn = async (username, password) => {
    axios.post(rootUrl + '/api/v1/login', {
      'password': sha256(password),
      'username': username
    }).then(res => {
      const data = res.data
      localStorage.setItem('username', data.username)
      localStorage.setItem('organization_id', data.organization_id)
      localStorage.setItem('organization_name', data.organization_name)
      localStorage.setItem('versionNumber', data.versionNumber)
      console.log('Version number: ' + data.versionNumber)
      localStorage.setItem('role', data.role)
      localStorage.setItem('benchmark_enabled', data.benchmark_enabled)
      localStorage.setItem('signed_statements', data.signed_statements)
      if (localStorage.getItem('language_locale') === null) {
        localStorage.setItem('language_locale', 'en')
      }
    }).then(() => {
      const organization_id = localStorage.getItem('organization_id');
      axios.post(rootUrl + '/api/v1/administration/verify_client', {
        'organization_id': organization_id,
      })
      .then(res => {
        const client_id = res.data[0]
        if (client_id.id == 1 || client_id.id == 8) {
          const signed_statements = localStorage.getItem('signed_statements');
          if (signed_statements == 1) {
            this.setState({authed: true})
          } else {
            window.location.href = "/statement";
          }
        } else {
            this.setState({authed: true})
        }
      }).catch(err => {
        console.log(err)
        let message = '' //TODO
        if (err.response) {
          message = err.response.data
        }
        this.setState({errorMessage: message})
      })
      // const organization_id = localStorage.getItem('organization_id');
      // if (organization_id == 1){
      //   const signed_statements = localStorage.getItem('signed_statements');
      //   if (signed_statements == 1) {
      //     this.setState({authed: true})
      //   } else {
      //     window.location.href = "/statement";
      //   }
      // } else {
      //   this.setState({authed: true})
      // }
    }).catch(err => {
      console.log(err)
      let message = '' //TODO
      if (err.response) {
        message = err.response.data
      }
      this.setState({errorMessage: message})
    })
  }

  // checkStatements = async (username) => {
  //   console.log("hij is hier aangekomen")
  //   axios.post(rootUrl + '/api/v1/check-statements', {
  //     'username': username
  //   }).then(() => {
  //     // window.location.href = "/dashboard";
  //   }).catch(err => {
  //     console.log(err)
  //   })
  // }



  /**
   * Gets the error message from
   * @returns {string|*}
   */
  getErrorMessage = () => {
    this.setState({refresh: !this.state.refresh})
    return this.state.errorMessage
  }

  /**
   * Checks if the user is already logged in using sessions.
   */
  checkIfAlreadyLoggedIn = async () => {
    try {
      await axios.get(rootUrl + '/api/v1/isSessionValid')
      return Promise.resolve()
    } catch (e) {
      localStorage.removeItem('selectedSurvey')
      return Promise.reject()
    }
  }
}

export {Routes as default}
