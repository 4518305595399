import React, { Component } from 'react'
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import {Form} from "react-bootstrap"

class InputDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inputText: (this.props.inputText ? this.props.inputText : "")
    }
  }

  render() {
    return (
      <PopupDialog
        title={this.props.title}
        onClose={() => this.props.onClose()}
        style={{marginTop: "100px"}}
      >
        {this.props.informationText}

        <Form.Group className={"m-t-20"}>
          <Form.Label>{this.props.inputLabel}</Form.Label>
          <Form.Control
            className={"form-control"}
            onChange={(e) => this.setState({inputText: e.target.value} )}
            value={this.state.inputText}
          />
        </Form.Group>
        <div style={{overflow: "hidden"}}>
          <button
            className="btn btn-primary f-right"
            onClick={() => this.props.onSubmit(this.state.inputText)}
            disabled={this.state.inputText === ""}>
            {this.props.submitButtonText || "Bevestigen"}
          </button>
          <button className="btn btn-secondary m-r-5 f-right" onClick={() => this.props.onClose()}> Annuleren</button>
        </div>
      </PopupDialog>
    )
  }
}

export default InputDialog
