import React, {Component} from 'react'
import Topbar from '../Topbar/Topbar'
import Sidebar from '../Sidebar/Sidebar'
import './DashboardFrame.css'
import EditPasswordDialog from "../EditPasswordDialog/EditPasswordDialog";

class DashboardFrame extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showEditPasswordDialog: false
    }
  }

  surveyChangedHandler(id) {
    this.forceUpdate()
    if (typeof (this.props.onSurveyChanged) === 'function')
      this.props.onSurveyChanged(id)
  }

  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          onLanguageChanged={(language) => this.props.onLanguageChanged(language)}
          editPasswordButtonClicked={() => this.setState({showEditPasswordDialog: true})}
        />
        <Topbar
          {...this.props}
          onSurveyChanged={this.surveyChangedHandler.bind(this)}
          title={this.props.dashboardTitle}
          username={this.props.username}
          selectedId={this.props.selectedId}
        />
        <div className="content-page">
          <div className="content">
            {this.props.children}
          </div>
        </div>

        {this.state.showEditPasswordDialog &&
          <EditPasswordDialog
            title={"Wachtwoord aanpassen"}
            onClose={() => this.setState({showEditPasswordDialog: false})} />
        }
      </>
    )
  }
}

export default DashboardFrame
