import React, { Component } from 'react'
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import emailTemplateNL from "./email-template-nl"
import emailTemplateDE from "./email-template-de"
import emailTemplateEN from "./email-template-en"
import emailTemplateExemption from "./email-template-nl-single-button"

import logoBase64 from "./LiveResearch-logo"
import './EmailPreviewDialog.css'
import {FormattedMessage, injectIntl} from "react-intl";

const exceptions = [
  4,
  6,
  20,
  22,
  60,
  294,
  296,
  475,
  542,
  543,
  545,
  579,
  623,
  624
]

class EmailPreviewDialog extends Component {
  constructor(props) {
    super(props)
    let locale = this.props.intl.formatMessage({id: "misc.locale"})
    let markup = emailTemplateNL
    if (locale === "de") {
      markup = emailTemplateDE
    } else if (locale === "en") {
      markup = emailTemplateEN
    }
    if (exceptions.includes(this.props.surveyId)) {
      markup = emailTemplateExemption
    }


    let valueMap = {
      "{{title}}": this.props.title,
      "{{{message}}}": this.replaceAll(this.props.message, {"\n": "<br />"}),
      "{{buttoncolor}}": this.props.buttonColor,
      "{{backgroundcolor}}": "#eee",
      "{{gotosurvey}}": "https://dashboard.liveresearch.com/vragenlijst/...",
      "{{logo}}": logoBase64
    }

    if (locale === "de") {
      valueMap["{{gotosurvey}}"] = "https://dashboard.liveresearch.com/fragebogen/..."
    } else if (locale === "en") {
      valueMap["{{gotosurvey}}"] = "https://dashboard.liveresearch.com/survey/..."
    }

    this.state = {
      email: this.replaceAll(markup, valueMap)
    }
  }

  replaceAll(str, mapObj){
    let re = new RegExp(Object.keys(mapObj).join("|"),"gi")

    return str.replace(re, function(matched){
      return mapObj[matched.toLowerCase()]
    })
  }

  render() {
    return (
      <PopupDialog
        title={this.props.intl.formatMessage({id: "broadcast_preview.title"})}
        onClose={() => this.props.onClose()}
        style={{marginTop: "100px", width: "1000px"}}
      >
        <FormattedMessage id={"broadcast_preview.info"} defaultMessage={"Dit is de e-mail zoals hij verstuurd zal worden naar de ontvangers van de verzendlijst."} />
        <br />

        <iframe
          className={"email-template-holder m-t-20 m-b-20"}
          srcDoc={this.state.email}
          title={"E-mail preview"} />

        <br />
        <button className="btn btn-secondary" onClick={() => this.props.onClose()}> <FormattedMessage id={"ui.close"} /></button>
      </PopupDialog>
    )
  }
}

export default injectIntl(EmailPreviewDialog)
