import React, { Component } from 'react'
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import {Form} from "react-bootstrap"
import LanguageItem from "./LanguageItem/LanguageItem"
import './LanguageItem/LanguageItem'
import {FormattedMessage, injectIntl} from 'react-intl'

class LanguageDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTranslation: this.props.selected,
      translations: this.props.translations
    }
  }

  onCheckedChanged(name) {
    this.setState({
      selectedTranslation: name
    })
  }

  render() {
    return (
      <PopupDialog
        title={this.props.title}
        onClose={() => this.props.onClose()}
      >
        <FormattedMessage id={"language_filter.info_text"} defaultMessage={"De taal van de resultaten kan hier worden aangepast."} />

        <Form.Group className={"m-t-20 m-b-20"}>
          {
            this.state.translations.map((item, index) => {
              return <LanguageItem
                key={index}
                index={index}
                label={this.props.intl.formatMessage({ id: "languages." + item })}
                checked={this.state.selectedTranslation === item}
                onCheckedChanged={() => this.onCheckedChanged(item)}
              />
            })
          }
        </Form.Group>

        <button
          className={"btn btn-primary"}
          onClick={() => {
            this.props.onTranslationChanged(this.state.selectedTranslation)
            this.props.onClose()
          }}
          
        >
          <FormattedMessage id={"ui.save"} defaultMessage={"Opslaan"} />
        </button>
        <button className="btn btn-secondary m-l-5" onClick={()=> this.props.onClose()}> <FormattedMessage id={"ui.cancel"} defaultMessage={"Annuleren"} /></button>
      </PopupDialog>
    )
  }
}

export default injectIntl(LanguageDialog)