import React, { Component } from 'react'
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import {Form} from "react-bootstrap"
import ColorSchemeItem from "./ColorSchemeItem/ColorSchemeItem"
import './ColorSchemeItem/ColorSchemeItem'
import ColorSchemeObjects from '../ColorSchemeObjects.json'
import {FormattedMessage, injectIntl} from 'react-intl'

class ColorSchemeDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedScheme: this.props.selected
    }
  }

  onCheckedChanged(name) {
    this.setState({
      selectedScheme: name
    })

    localStorage.setItem("colorScheme", name)
  }

  render() {
    return (
      <PopupDialog
        title={this.props.title}
        onClose={() => this.props.onClose()}
      >
        <FormattedMessage id={"color_scheme.info_text"} defaultMessage={"Het kleurenschema van de grafieken van de resultaten kan hier worden aangepast."} />

        <Form.Group className={"m-t-20 m-b-20"}>
          {
            ColorSchemeObjects.schemes.map((item, index) => {
              return <ColorSchemeItem
                key={index}
                index={index}
                label={this.props.intl.formatMessage({id: item.name})}
                scheme={item.scheme}
                checked={this.state.selectedScheme === item.name}
                onCheckedChanged={() => this.onCheckedChanged(item.name)}
              />
            })
          }
        </Form.Group>

        <button
          className={"btn btn-primary"}
          onClick={() => this.props.onColorSchemeChanged(this.state.selectedScheme)}
        >
          <i className="fa fa-paint-brush"/> <FormattedMessage id={"ui.save"} defaultMessage={"Opslaan"} />
        </button>
        <button className="btn btn-secondary m-l-5" onClick={()=> this.props.onClose()}> <FormattedMessage id={"ui.cancel"} defaultMessage={"Annuleren"} /></button>
      </PopupDialog>
    )
  }
}

export default injectIntl(ColorSchemeDialog)