import React, {Component} from 'react'
import axios from 'axios'
import sha256 from 'sha256'
import {Form} from "react-bootstrap"
import {FormattedMessage, injectIntl} from "react-intl"
import Button from "react-bootstrap/Button"
import NavLink from "react-router-dom/NavLink"
import Flags from "country-flag-icons/react/3x2"
import Swal from "sweetalert2"
import {Redirect, Link} from 'react-router-dom'

const {rootUrl} = require("../../../config")

class ResetPassword extends Component {

  constructor(props) {
    super(props)
    this.state = {
      password: '',
      repeatPassword: '',
      redirect: false
    }
  }

  componentDidMount() {
    this.props.onLanguageChanged(this.props.match.params.locale)
  }


  confirm() {
    if(this.state.password !== this.state.repeatPassword) {
      Swal.fire({
        title: this.props.intl.formatMessage({id: "new_account.passwords_do_not_match"}),
        type: 'error',
        confirmButtonText: 'OK'
      })
      return;
    }
    if(this.state.password.length === 0) {
      Swal.fire({
        title: this.props.intl.formatMessage({id: "new_account.no_password_entered"}),
        type: 'error',
        confirmButtonText: 'OK'
      })
      return;
    }
    if(this.state.password.length < 8) {
      Swal.fire({
        title: this.props.intl.formatMessage({id: "new_account.password_too_short"}),
        type: 'error',
        confirmButtonText: 'OK'
      })
      return;
    }

    axios.post(rootUrl + '/api/v1/administration/reset_password', {password: sha256(this.state.password), user_id: this.props.match.params.userId, token: sha256(this.props.match.params.token)})
      .then(res => {
        Swal.fire({
          title: this.props.intl.formatMessage({id: "new_account.password_changed"}),
          type: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          this.setState({redirect: true})
        })
      })
      .catch(e => {
        Swal.fire({
          title: this.props.intl.formatMessage({id: "new_account.something_went_wrong"}),
          type: 'error',
          confirmButtonText: 'OK'
        })
        console.log(e)
      })
  }

  renderRedirect = () => {
    if (this.state.redirect) return <Redirect to='/login'/>
  }

  render() {
    return (
      <>
      {this.renderRedirect()}
        <div className="account-pages login-background"/>
        <div className="clearfix"/>
        <div className="wrapper-page">
      <div className="account-bg">
        <div className="card-box m-b-10 login-card">
        <div className="text-xs-center m-t-20">
          <Link to='/login' className="logo .live-green">
            <img src="/images/logo60.png" alt="" id="login-logo" width="40" height="40"/><br/>
            <span id="login-title" className="text-live-green">LiveResearch</span>
          </Link>
          <div className="col-xs-12 text-xs-center">
                  <h6 className="text-muted text-uppercase m-b-0 m-t-0"><FormattedMessage id={"forgot_password.forgot_password"} defaultMessage={"Wachtwoord vergeten"} /></h6>
          </div>
        </div>
        <br></br>
        <br></br>
        <Form.Group>
          <Form.Label><FormattedMessage id={"new_account.password"} defaultMessage={"Wachtwoord"} /></Form.Label>
          <Form.Control
            value={this.state.password}
            onChange={(e) => this.setState({password: e.target.value})}
            type={"password"}
            placeholder={this.props.intl.formatMessage({id: "new_account.password"})}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label><FormattedMessage id={"new_account.repeat_password"} defaultMessage={"Herhaal wachtwoord"} /></Form.Label>
          <Form.Control
            value={this.state.repeatPassword}
            onChange={(e) => this.setState({repeatPassword: e.target.value})}
            type={"password"}
            placeholder={this.props.intl.formatMessage({id: "new_account.repeat_password"})}
          />
        </Form.Group>
        <br></br>
        <Button onClick={() => this.confirm()}><FormattedMessage id={"new_account.confirm"} defaultMessage={"Bevestigen"} /></Button>
        <NavLink class="f-right" to={'/login'}><Button variant='secondary'><FormattedMessage id={"new_account.login_page"} defaultMessage={"Inlogpagina"} /></Button></NavLink>
        <br/>
        <div className={"login-flag-holder"}>
          <Flags.NL title="Dutch" className="flag" style={{marginRight: "5px"}} onClick={() => this.props.onLanguageChanged('nl')} />
          <Flags.DE title="German" className="flag" onClick={() => this.props.onLanguageChanged("de")}/>
          <Flags.GB title="English" className="flag" onClick={() => this.props.onLanguageChanged("en")}/>
        </div>
      </div>
      </div>
      </div>
    </>
    )
  }


}

export default injectIntl(ResetPassword)
