import DashboardFrame from "../../../components/Dashboard/DashboardFrame/DashboardFrame"
import React from "react"
import axios from 'axios'
// import Accordion from "react-bootstrap/Accordion"/
// import Card from "react-bootstrap/Card"
import Button from 'react-bootstrap/Button'
// import {List} from "semantic-ui-react"
// import {CardBody} from "mdbreact"
import EditBenchmarkSettingDialog from "../../../components/Dashboard/EditBenchmarkSettingDialog/EditBenchmarkSettingDialog"
import {injectIntl} from 'react-intl'

const {rootUrl} = require("../../../config")

function BenchmarkSettings(props) {
  const [clusters, setClusters] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [dialog, setDialog] = React.useState(null)

  const fetchData = () => {
    axios.get(rootUrl + '/api/v1/benchmark/all_benchmark_settings')
      .then((res) => {
        console.log(res)
        setClusters(res.data)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const onClose = () => {
    setLoading(true)
    setDialog(null)
  }

  if(loading) {
    fetchData()
  }

  return (
    <DashboardFrame
      title={props.intl.formatMessage({id: "edit_benchmark.edit_benchmark"})}
      dashboardTitle={props.intl.formatMessage({id: "edit_benchmark.edit_benchmark"})}
      onLanguageChanged={(language) => props.onLanguageChanged(language)}>
      <div className="card-box slide-up">
        <div className="col-12">
          <h4 className="header-title m-t-0">{props.intl.formatMessage({id: "edit_benchmark.edit_benchmark"})}</h4>
          <p className="text-muted font-13 m-b-30">{props.intl.formatMessage({id: "edit_benchmark.info"})}</p>
          <div className="row">
            {
              clusters.map((cluster, index) => {
                return (
                 <div className='card-box' key={index}>
                   <div className='card-body'>
                     <div className='col-12'>
                       {cluster.name}
                       <br/>
                       <Button variant='link' onClick={() => setDialog(cluster.id)}>
                         {props.intl.formatMessage({id: "ui.edit"})}
                       </Button>
                     </div>

                   </div>
                 </div>
                )
              })
            }

            <div className='card-box'>
              <div className='card-body'>
                <div className='col-12'>
                  {props.intl.formatMessage({id: "edit_benchmark.create_new_settings"})}
                  <br/>
                  <Button variant='link' onClick={() => setDialog(true)}>
                    {props.intl.formatMessage({id: "ui.create"})}
                  </Button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {
        (dialog || dialog > 0)&& <EditBenchmarkSettingDialog onClose={onClose} title={props.intl.formatMessage({id: "edit_benchmark.edit_benchmark_settings"})} currentSettingId={dialog}/>
      }
    </DashboardFrame>
  )
}

export default injectIntl(BenchmarkSettings)
