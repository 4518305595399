import React, { Component } from 'react'
import {Form} from "react-bootstrap"
import './ColorSchemeItem.css'

class ColorSchemeItem extends Component {
  render() {
    let randomString = Math.random().toString(36).substring(2, 12) + Math.random().toString(36).substring(2, 12)

    return (
      <>
        <Form.Check
          key={this.props.index}
          type={"radio"}
          label={this.props.label}
          id={"radiobutton" + randomString + this.props.index.toString()}
          name={"color-group"}
          checked={this.props.checked}
          onChange={() => this.props.onCheckedChanged()}
          className={"radio"}
          style={{display: "inline-block"}}
        />
        <div className={"f-right"}>
          {this.props.scheme.map((item, index) => {
            return <div key={index} className={"color-box"} style={{background: item}} />
          })}
        </div><br />
      </>
    )
  }
}

export default ColorSchemeItem