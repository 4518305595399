import React, { Component } from 'react'
import PopupDialog from "../../../../components/Miscellaneous/PopupDialog/PopupDialog"
import {Form} from "react-bootstrap"
import axios from "axios"
import {rootUrl} from "../../../../config"
import Swal from "sweetalert2"
import {Checkbox, Dropdown} from "semantic-ui-react"
import {FormattedMessage, injectIntl} from 'react-intl'

import sha256 from 'sha256'
import CheckedListTable from "../../../../components/Miscellaneous/CheckedListTable/CheckedListTable"

class EditUserDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: this.props.editData.username,
      changePassword: false,
      password: "",
      email: this.props.editData.email,
      role: this.props.editData.role,
      organizationId: this.props.editData.organizationId,
      projects: [],
      selectedProjects: [],
      user_role: ""
    }
  }

  componentDidMount() {
    var user_role = localStorage.getItem("role");
    this.setState({
      user_role: user_role
    })
    this.getProjects()
  }

  saveUser() {
    let data = {
      id: this.props.editData.id,
      username: this.state.username,
      email: this.state.email,
      organizationId: this.props.editData.organizationId,
      role: this.state.role
    }

    if (this.state.changePassword) {
      data.password = sha256(this.state.password)
    }

    if (data.role === 4) {
      data.observerProjects = this.state.selectedProjects
    }

    axios.post(rootUrl + '/api/v1/administration/save_user', data
    ).then((res) => {
      Swal.fire({
        title: 'Gelukt',
        text: 'De gebruiker is succesvol opgeslagen!',
        type: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        this.props.onClose()
      })
    }).catch((error) => {
      console.log(error.response.data)
      const errorCode = error.response.data
      let errorText = 'Het is niet gelukt om de gebruiker op te slaan. Controleer uw internetverbinding.'

      if (errorCode === "ER_DUP_ENTRY") {
        errorText = 'Deze gebruikersnaam of e-mailadres bestaat al. Kies een andere.'
      }

      Swal.fire({
        title: 'Oeps, er is iets misgegaan...',
        text: errorText,
        type: 'error',
        confirmButtonText: 'OK'
      })
    })
  }

  getProjects(callback) {
    axios.get(rootUrl + '/api/v1/administration/projects/' + this.props.editData.organizationId + '/' + this.props.editData.id)
      .then((res) => {
        let projects = res.data["projects"]

        this.setState({
            projects: projects
          },() => {
            typeof callback === 'function' && callback()
          })
      }).catch((error) => {
      console.log(error)
    })
  }

  removeUser(username, role) {
    const name = username
    const rol = role

    Swal.fire({
      title: 'Gebruiker verwijderen',
      text: "Weet je zeker dat je gebruiker " + name + " wilt verwijderen?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Verwijderen',
      cancelButtonText: 'Annuleren'
    }).then((result) => {
      if (result) {
        axios.post(rootUrl + '/api/v1/administration/remove_user',
          {id: name, role: rol}
        ).then((res) => {
          Swal.fire({
            title: `Gebruiker verwijderd.`,
            type: 'success'
          })
        }).catch((error) => {
          Swal.fire({
            title: `Gebruiker verwijderen niet gelukt!`,
            type: 'warning'
          })
          // console.log(error.response.data)
        })
      }
    })
  }

  onCheckedChanged(checked) {
    const projects = this.state.projects
    const selectedProjects = []

    checked.map((item, index) => {
      if (item) {
        selectedProjects.push(projects[index].id)
      }
    })

    this.setState({
      selectedProjects: selectedProjects
    })
  }

  render() {
    const roles = [
      {
        role: this.props.intl.formatMessage({id: "role.superadmin"}),
        value: 1
      },{
        role: this.props.intl.formatMessage({id: "role.admin"}),
        value: 2
      },{
        role: this.props.intl.formatMessage({id: "role.researcher"}),
        value: 3
      },{
        role: this.props.intl.formatMessage({id: "role.observer"}),
        value: 4
      }]

    return (
      <PopupDialog
        title={this.props.title}
        onClose={() => this.props.onClose()}
      >
        <FormattedMessage id={"edit_user.info"} defaultMessage={"De gebruiker kan hier worden aangepast en opgeslagen."} />

        <div className="row m-t-30 m-b-30">
          <div className="col-xs-12">
            <Form.Group>
              <Form.Label><FormattedMessage id={"edit_user.username"} defaultMessage={"Gebruikersnaam"} /></Form.Label>
              <Form.Control
                value={this.state.username}
                onChange={(e) => this.setState({username: e.target.value})}
                required
                placeholder={this.props.intl.formatMessage({id: "edit_user.username"})}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label><FormattedMessage id={"edit_user.email"} defaultMessage={"E-mail"} /></Form.Label>
              <Form.Control
                value={this.state.email}
                onChange={(e) => this.setState({email: e.target.value})}
                required
                type={"email"}
                placeholder={this.props.intl.formatMessage({id: "edit_user.email"})}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label><FormattedMessage id={"edit_user.password"} defaultMessage={"Wachtwoord"} /></Form.Label><br />
              <Checkbox
                type={"checkbox"}
                className={"checkbox"}
                checked={this.state.changePassword}
                label={this.props.intl.formatMessage({id: "edit_user.change_password"})}
                onClick={() => this.setState({changePassword: !this.state.changePassword})} />
              <Form.Control
                value={this.state.password}
                onChange={(e) => this.setState({password: e.target.value})}
                type={"password"}
                placeholder={this.props.intl.formatMessage({id: "edit_user.password"})}
                className={"m-t-10"}
                disabled={!this.state.changePassword}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label><FormattedMessage id={"edit_user.role"} defaultMessage={"Rol"} /></Form.Label><br />
              <Dropdown
                placeholder={this.props.intl.formatMessage({id: "edit_user.role_placeholder"})}
                selection
                options={roles.map((option, index) => {
                  return {key: index, text: option.role, value: option.value}
                }).filter(item => item.value >= parseInt(localStorage.getItem('role')))}
                value={this.state.role}
                onChange={(e, {value}) => {
                  this.setState({role: value})
                }}
              />
            </Form.Group>

            {parseInt(this.state.role) === 4 &&
            <>
              <Form.Group>
                <Form.Label><FormattedMessage id={"edit_user.access"} defaultMessage={"Toegang"} /></Form.Label><br/>
                <CheckedListTable
                  headers={["#", this.props.intl.formatMessage({id: "edit_user.name"}), this.props.intl.formatMessage({id: "edit_user.access"})]}
                  rows={this.state.projects.map((item, index) => {
                    return [index+1, item["name"], "X"]
                  })}
                  checkColumnIndex={2}
                  onCheckedChanged={(checked) => this.onCheckedChanged(checked)}
                  initialStates={this.state.projects.map(item => {
                    return !!(item.observer_has_access)
                  })}
                />
              </Form.Group>
            </>
            }
          </div>
        </div>

        <button
          className="btn btn-primary"
          onClick={() => this.saveUser()}
        >
          <i className="fa fa-save"/> <FormattedMessage id={"ui.save"} />
        </button>
        <button className="btn btn-secondary m-l-5" onClick={()=> this.props.onClose()}> <FormattedMessage id={"ui.cancel"} /></button>
        {parseInt(this.state.user_role) === 1 &&
            <>
              <button
                      className="btn btn-danger f-right"
                      onClick={() => this.removeUser(this.state.username, this.state.user_role)}>
                      <i className="fa fa-remove"/> <FormattedMessage id={"ui.remove"} defaultMessage={"Verwijderen"} />
              </button>
            </>
        }
      </PopupDialog>
    )
  }
}

export default injectIntl(EditUserDialog)
