import React, {Component} from 'react'
import axios from "axios"
import Swal from 'sweetalert2'
import {Redirect, Link} from 'react-router-dom'
import {FormattedMessage, injectIntl} from "react-intl"

const {rootUrl} = require("../../../config")

axios.defaults.withCredentials = true

class ForgotPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      redirect: false
    }
  }

  resetPassword = async (event) => {
    event.preventDefault()

    try {
      axios.post(rootUrl + '/api/v1/forgot-password', {
        'email': this.state.email
      }).then((res) => {
        Swal.fire({
          title: this.props.intl.formatMessage({id: "dialogs.success"}),
          text: this.props.intl.formatMessage({id: "login.password_reset_info"}),
          type: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          this.setState({redirect: true})
        })
      }).catch((err) => {
        console.log(err)
        Swal.fire({
          title: this.props.intl.formatMessage({id: "login.unknown_email_title"}),
          text: this.props.intl.formatMessage({id: "login.unknown_email"}),
          type: 'error',
          confirmButtonText: 'OK'
        })
      })
    } catch (error) {
      if (error.response) {
        this.setState({
          errorMessage: error.response.data
        })
      } else {
        this.setState({
          errorMessage: error.message
        })
      }
    }
  }

  /**
   * Sets the state of the controlId to the changed text.
   */
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  renderRedirect = () => {
    if (this.state.redirect) return <Redirect to='/login'/>
  }

  render() {
    return (
      <div>
        {this.renderRedirect()}
        <div className="account-pages login-background"/>
        <div className="clearfix"/>
        <div className="wrapper-page">

          <div className="account-bg">
            <div className="card-box m-b-10 login-card">
              <div className="text-xs-center m-t-20">
                <Link to='/login' className="logo .live-green">
                  <img src="/images/logo60.png" alt="" id="login-logo" width="40" height="40"/><br/>
                  <span id="login-title" className="text-live-green">LiveResearch</span>
                </Link>
              </div>

              {/* Start form */}
              <div className="m-t-30 m-b-0">
                <div className="col-xs-12 text-xs-center">
                  <h6 className="text-muted text-uppercase m-b-0 m-t-0"><FormattedMessage id={"forgot_password.forgot_password"} defaultMessage={"Wachtwoord vergeten"} /></h6>
                </div>
                <form className="form-horizontal m-t-20" onSubmit={this.resetPassword}>
                  <div className="form-group">
                    <div className="col-xs-12">
                      <input className="form-control" id="email" name="email" type="email" placeholder="E-mail" required
                             value={this.state.email} onChange={this.handleChange}/>
                    </div>
                  </div>

                  <div className="form-group text-center">
                    <div className="col-xs-12">
                      <button className="btn btn-success btn-block waves-effect waves-light btn-live-green"
                              type="submit"><FormattedMessage id={"forgot_password.send_email"} defaultMessage={"Stuur e-mail"} />
                      </button>
                      <p id="emailsent" hidden><FormattedMessage id={"forgot_password.email_sent"} defaultMessage={"E-mail verstuurd."} /></p>
                    </div>
                  </div>

                  <div className="form-group text-center">
                    <div className="col-xs-12">
                      <Link to='/login' className="btn btn-success btn-block waves-effect waves-light btn-live-green">
                        <FormattedMessage id={"forgot_password.back_to_login"} defaultMessage={"Terug naar login"} />
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(ForgotPassword)
