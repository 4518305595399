import React, { Component } from 'react'
import {Form} from "react-bootstrap"
import './LanguageItem.css'

class LanguageItem extends Component {
  render() {
    let randomString = Math.random().toString(36).substring(2, 12) + Math.random().toString(36).substring(2, 12)

    return (
      <>
        <Form.Check
          key={this.props.index}
          type={"radio"}
          label={this.props.label}
          id={"radiobutton" + randomString + this.props.index.toString()}
          name={"language-group"}
          checked={this.props.checked}
          onChange={() => this.props.onCheckedChanged()}
          className={"radio"}
          style={{display: "inline-block"}}
        />
        <br />
      </>
    )
  }
}

export default LanguageItem