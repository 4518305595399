import React, { Component } from 'react'
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import LoadingSpinner from "../../Miscellaneous/LoadingSpinner/LoadingSpinner"
import {FormattedMessage, injectIntl} from "react-intl"
// import QrCodeWithLogo from "qrcode-with-logos"
import axios from "axios"
import {rootUrl} from "../../../config"
import {QRCode} from "react-qrcode-logo"

class ShowQRCodeDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      logoBase64: ''
    }
    this.getSurveyLogo(this.props.surveyUUID)
  }


  getSurveyLogo(uuid) {
    axios.get(rootUrl + '/api/v1/survey/get_survey_logo/' + uuid)
      .then((res) => {

        var i = new Image();

        i.onload = () => {
          this.setState({
            x: i.width,
            y: i.height
          })
        };

        i.src = res.data;

        this.setState({
          logoBase64: res.data,
          loading: false
        })
      })
      .catch(e => {
        this.setState({
          loading: false
        })
        console.log(e)
      })
  }


  downloadQR() {
    var canvas = document.getElementById("react-qrcode-logo");
// Convert the canvas to data
    var image = canvas.toDataURL();
// Create a link
    var aDownloadLink = document.createElement('a');
// Add the name of the file to the link
    aDownloadLink.download = 'canvas_image.png';
// Attach the data to the link
    aDownloadLink.href = image;
// Get the code to click the download link
    aDownloadLink.click();
  }

  minus() {
    this.setState({
      x: this.state.x * 0.8,
      y: this.state.y * 0.8
    })

  }

  plus() {
    this.setState({
      x: this.state.x * 1.2,
      y: this.state.y * 1.2
    })

  }

  render() {
    console.log("Rendering" + this.state.x + " " + this.state.y)
    return (
      <PopupDialog
        title={this.props.title}
        onClose={() => this.props.onClose()}
      >
        {this.state.loading &&
        <LoadingSpinner className={"modal-loading"} />
        }

        <div>
          <QRCode value={"https://dashboard.liveresearch.com/language-selection/" + this.props.surveyId} logoImage={this.state.logoBase64} size={250} logoWidth={this.state.x} logoHeight={this.state.y}/>
          <br/>
          {
            this.state.y && this.state.x &&
              <div>
                <button className={'btn btn-info'} style={{margin: 10}} onClick={() => {this.minus()}}>-</button>
                <button className={'btn btn-info'} style={{margin: 10}} onClick={() => {this.plus()}}>+</button>
              </div>
          }


          <button className={'btn btn-info'} onClick={this.downloadQR}>Download QR code</button>
          <button className="btn btn-secondary m-l-5" onClick={()=> this.props.onClose()}> <FormattedMessage id={"ui.cancel"} /></button>

        </div>


      </PopupDialog>
    )
  }
}

export default injectIntl(ShowQRCodeDialog)
