import React, { Component } from 'react'
import PopupDialog from "../../../../components/Miscellaneous/PopupDialog/PopupDialog"
import {Form} from "react-bootstrap"
import axios from "axios"
import {rootUrl} from "../../../../config"
import Swal from "sweetalert2"
import {Dropdown} from "semantic-ui-react"
import {FormattedMessage, injectIntl} from 'react-intl'

import sha256 from 'sha256'
import CheckedListTable from "../../../../components/Miscellaneous/CheckedListTable/CheckedListTable"

class AddUserDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: "",
      email: "",
      password: "",
      role: 3,
      projects: [],
      selectedProjects: []
    }
  }

  componentDidMount() {
    this.getProjects()
  }

  insertUser() {
    let data = {
      username: this.state.username,
      email: this.state.email,
      password: sha256(this.state.password),
      organizationId: this.props.organizationId,
      role: this.state.role,
      locale: localStorage.getItem('language_locale')
    }

    if (data.role === 4) {
      data.observerProjects = this.state.selectedProjects
    }

    axios.post(rootUrl + '/api/v1/administration/insert_user', data
    ).then((res) => {
      this.props.onClose()
    }).catch((error) => {
      const errorCode = error.response.data
      let errorText = this.props.intl.formatMessage({id: "dialogs.error_message"})

      if (errorCode === "ER_DUP_ENTRY") {
        errorText = this.props.intl.formatMessage({id: "dialogs.email_already_exists"})
      }

      Swal.fire({
        title: this.props.intl.formatMessage({id: "dialogs.error_title"}),
        text: errorText,
        type: 'warning',
        confirmButtonText: 'OK'
      })
    })
  }

  getProjects(callback) {
    axios.get(rootUrl + '/api/v1/administration/projects_from_organization/' + this.props.organizationId)
      .then((res) => {
        this.setState({
            projects: res.data["projects"]
          },() => {
            typeof callback === 'function' && callback()
          })
      }).catch((error) => {
      console.log(error)
    })
  }

  onCheckedChanged(checked) {
    const projects = this.state.projects
    const selectedProjects = []

    checked.map((item, index) => {
      if (item) {
        selectedProjects.push(projects[index].id)
      }
    })

    this.setState({
      selectedProjects: selectedProjects
    })
  }

  render() {
    const roles = [
      {
        role: this.props.intl.formatMessage({id: "role.superadmin"}),
        value: 1
      },{
        role: this.props.intl.formatMessage({id: "role.admin"}),
        value: 2
      },{
        role: this.props.intl.formatMessage({id: "role.researcher"}),
        value: 3
      },{
        role: this.props.intl.formatMessage({id: "role.observer"}),
        value: 4
      }]

    return (
      <PopupDialog
        title={this.props.title}
        onClose={() => this.props.onClose()}
      >
        <FormattedMessage id={"add_user.info"} defaultMessage={"U kunt hier een gebruiker toevoegen."} />

        <div className="row m-t-30 m-b-30">
          <div className="col-xs-12">
            <Form.Group>
              <Form.Label><FormattedMessage id={"edit_user.username"} defaultMessage={"Gebruikersnaam"} /></Form.Label>
              <Form.Control
                value={this.state.username}
                onChange={(e) => this.setState({username: e.target.value})}
                required
                placeholder={this.props.intl.formatMessage({id: "edit_user.username"})}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label><FormattedMessage id={"edit_user.email"} defaultMessage={"E-mail"} /></Form.Label>
              <Form.Control
                value={this.state.email}
                onChange={(e) => this.setState({email: e.target.value})}
                required
                type={"email"}
                placeholder={this.props.intl.formatMessage({id: "edit_user.email"})}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label><FormattedMessage id={"edit_user.password"} defaultMessage={"Wachtwoord"} /></Form.Label>
              <Form.Control
                value={this.state.password}
                onChange={(e) => this.setState({password: e.target.value})}
                type={"password"}
                placeholder={this.props.intl.formatMessage({id: "edit_user.password"})}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label><FormattedMessage id={"edit_user.role"} defaultMessage={"Rol"} /></Form.Label><br />
              <Dropdown
                placeholder={this.props.intl.formatMessage({id: "edit_user.role_placeholder"})}
                selection
                options={roles.map((option, index) => {
                  return {key: index, text: option.role, value: option.value}
                }).filter(item => item.value >= parseInt(localStorage.getItem('role')))}
                value={this.state.role}
                onChange={(e, {value}) => {
                  this.setState({role: value})
                }}
              />
            </Form.Group>

            {parseInt(this.state.role) === 4 &&
            <>
              <Form.Group>
                <Form.Label><FormattedMessage id={"edit_user.access"} defaultMessage={"Toegang"} /></Form.Label><br/>
                <CheckedListTable
                  headers={["#", "Naam", "Toegang"]}
                  rows={this.state.projects.map((item, index) => {
                    return [index+1, item["name"], "X"]
                  })}
                  checkColumnIndex={2}
                  onCheckedChanged={(checked) => this.onCheckedChanged(checked)}
                />
              </Form.Group>
            </>
            }
          </div>
        </div>

        <button
          className="btn btn-primary"
          onClick={() => this.insertUser()}
        >
          <i className="fa fa-save"/> <FormattedMessage id={"ui.save"} defaultMessage={"Opslaan"} />
        </button>
        <button className="btn btn-secondary m-l-5" onClick={()=> this.props.onClose()}> <FormattedMessage id={"ui.cancel"} defaultMessage={"Annuleren"} /></button>
      </PopupDialog>
    )
  }
}

export default injectIntl(AddUserDialog)
