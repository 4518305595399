import React, { Component } from 'react'
import ContentEditable from 'react-contenteditable'
import PageBuilder from '../PageBuilder/PageBuilder'
import {Progress} from 'semantic-ui-react'
import '../../../Survey/Survey/Survey.css'
import './SurveyFrame.css'
import axios from "axios"
import {rootUrl} from "../../../../config"
import {FormattedMessage, injectIntl} from 'react-intl'
import Flags from "country-flag-icons/react/3x2"
import QuestionTypes from '../PageBuilder/QuestionTypes'

class SurveyFrame extends Component {
  constructor(props) {
    super(props)

    /*
      SurveyTitle is the actual title of the survey and is shown as a subtitle in the UI.
      Color is the color of the block at the top of the survey.
      Pages holds every page with the title of the page and the components:
        - Components have a type as specified in QuestionTypes.json
        - Components have options if they are a multiple choice component.
        - Components have a name.
        - Components have a required boolean.
      CurrentPageIndex specifies the page in the pages array that is currently being edited.
     */
    this.state = {
      surveyTitle: "",
      color: "#3b699c",
      projectId: -1,
      pages: [{
        title: {},
        components: []
      }],
      currentPageIndex: 0,
      redirect: false,
      dimensions: [],
      selectedTranslation: this.props.intl.formatMessage({id: "misc.locale"}),
      translations: [this.props.intl.formatMessage({id: "misc.locale"})]
    }
    this.state.pages[0].title[this.props.intl.formatMessage({id: "misc.locale"})] = this.props.intl.formatMessage({id: "survey_builder.title_of_page"})

    this.currentPage = React.createRef()

    this.props.editSurveyId && this.getEditComponents()
  }

  componentDidMount() {
    this.state.dimensions.length === 0 && this.fetchDimensions()
  }

  fetchDimensions() {
    axios.get(rootUrl + '/api/v1/benchmark/dimensions')
      .then((res) => {
        res.data.unshift({id: -1, name: this.props.intl.formatMessage({id: "component_builder.select_dimension"}), locale: this.props.intl.formatMessage({id: "misc.locale"})})
        this.setState({dimensions: res.data.filter(x => x.locale === this.props.intl.formatMessage({id: "misc.locale"}))})
      })
  }

  changeColor = (color) => {
    this.setState({color: color.hex})
  }

  changeSelectedTranslation = (locale) => {
    this.setState({selectedTranslation: locale}, () => {
      let pages = this.state.pages
      for (let i = 0; i < pages.length; i++) {
        pages[i] = this.updatePageOptionValues(pages[i])
      }

      this.setState({
        pages: pages
      })
    })
  }

  updatePageOptionValues = (page) => {
    for (let i = 0; i < page.components.length; i++) {
      let component = page.components[i]

      if (this.isOptionComponent(component)) {
        component = this.updateComponentOptionValues(component)
        page.components[i] = component
      }
    }
    return page
  }

  updateComponentOptionValues = (component) => {
    for (let i = 0; i < component.options.length; i++) {
      let option = component.options[i]

      option.value = option.translations[this.state.selectedTranslation]

      component.options[i] = option
    }

    if (component.openOption) {
      if (component.openOption.translations[this.state.selectedTranslation]) {
        // Translation already exists
        component.openOption.value = component.openOption.translations[this.state.selectedTranslation]
      } else {
        // Translation does not exist, so fill in empty value
        component.openOption.translations[this.state.selectedTranslation] = ""
        component.openOption.value = ""
      }
    }

    return component
  }

  isOptionComponent(component) {
    const optionComponentTypes = [QuestionTypes.Dropdown, QuestionTypes.Likert, QuestionTypes.MultipleChoiceSingle, QuestionTypes.MultipleChoiceMultiple, QuestionTypes.Routing]
    return optionComponentTypes.includes(component.type)
  }

  changeTranslations = (translations) => {
    let pages = this.state.pages
    pages = this.updateTitleTranslations(pages, translations)

    this.setState({
      translations: translations,
      pages: pages
    }, () => {
      if (!this.state.translations.includes(this.state.selectedTranslation)) {
        this.changeSelectedTranslation(this.state.translations[0])
      }
    })
  }

  updateTitleTranslations = (pages, translations) => {
    for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
      let page = pages[pageIndex]

      let titlesToDelete = Object.keys(page.title).filter((key) => { return !translations.includes(key) })
      let titlesToAdd = translations.filter((translation) => { return !Object.keys(page.title).includes(translation) })

      titlesToDelete.forEach((title) => delete page.title[title])
      titlesToAdd.forEach((translation) => page.title[translation] = this.props.intl.formatMessage({id: "survey_builder.title_of_page"}))

      pages[pageIndex] = page
    }
    return pages
  }

  changeSurveyTitle = (title) => {
    this.setState({surveyTitle: title})
  }

  changeProjectId = (id) => {
    this.setState({projectId: id})
  }

  getTitle = () => {
    if (this.isInformationPage()) {
      return (this.state.surveyTitle === "" ? this.props.intl.formatMessage({id: "survey_builder.title_of_survey"}) : this.state.surveyTitle)
    } else {
      return (this.state.pages[this.state.currentPageIndex].title === ""
        ? "Titel van pagina 1"
        : this.state.pages[this.state.currentPageIndex].title[this.state.selectedTranslation])
    }
  }

  getSubtitle = () => {
    if (this.isInformationPage()) {
      return ""
    } else {
      return (this.state.surveyTitle === "" ? this.props.intl.formatMessage({id: "survey_builder.title_of_survey"}) : this.state.surveyTitle)
    }
  }

  // Create a new page with no components and add it to the state pages array.
  addPage = (callback) => {
    const page = {
      title: {},
      components: []
    }

    this.state.translations.forEach((translation) => {
      page.title[translation] = this.props.intl.formatMessage({id: "survey_builder.title_of_page"})
    })

    this.setState({
      pages: [...this.state.pages, page]
    }, callback)
  }

  // Go backward 1 page if possible.
  goBackward() {
    let pages = this.state.pages
    pages[this.state.currentPageIndex].components = this.currentPage.current.getComponents()

    this.setState({
      pages: pages,
      currentPageIndex: this.state.currentPageIndex - 1
    })
  }

  // Go forward 1 page if possible, otherwise create a new page and go there.
  goForward() {
    if (this.state.currentPageIndex === this.state.pages.length - 1) {
      this.addPage(() => {
        this.setState({
          currentPageIndex: this.state.currentPageIndex + 1
        })
      })
    } else {
      this.setState({
        currentPageIndex: this.state.currentPageIndex + 1
      })
    }
  }

  // Currently unused.
  isInformationPage = () => {
    return (this.state.currentPageIndex === -1 || this.state.currentPageIndex === this.state.pages.length)
  }

  getCurrentPage = () => {
    return this.state.pages[this.state.currentPageIndex]
  }

  // Changes the title of the page that is currently being edited.
  handleTitleChange = (e) => {
    let currentPages = this.state.pages
    currentPages[this.state.currentPageIndex].title[this.state.selectedTranslation] = e.target.value
    console.log(currentPages)

    this.setState({pages: currentPages})
  }

  // Components have changed inside the page, so update the state pages array.
  onComponentsChangedHandler(components) {
    let pages = this.state.pages
    pages[this.state.currentPageIndex].components = components

    // console.log(pages)

    this.setState({
      pages: pages
    })
  }

  getSurveyPages() {
    // console.log(this.state.pages)
    return this.state.pages
  }

  getCountryFlag(locale) {
    if (locale === "nl") {
      return <Flags.NL title={this.props.intl.formatMessage({id: "edit_survey.current_translation_indicator"})} className={"translation-indicator"} />
    } else if (locale === "en") {
      return <Flags.GB title={this.props.intl.formatMessage({id: "edit_survey.current_translation_indicator"})} className={"translation-indicator"} />
    } else if (locale === "de") {
      return <Flags.DE title={this.props.intl.formatMessage({id: "edit_survey.current_translation_indicator"})} className={"translation-indicator"} />
    } else if (locale === "de-at") {
      return <Flags.AT title={this.props.intl.formatMessage({id: "edit_survey.current_translation_indicator"})} className={"translation-indicator"} />
    } else if (locale === "es") {
      return <Flags.ES title={this.props.intl.formatMessage({id: "edit_survey.current_translation_indicator"})} className={"translation-indicator"} />
    } else if (locale === "bn") {
      return <Flags.IN title={this.props.intl.formatMessage({id: "edit_survey.current_translation_indicator"})} className={"translation-indicator"} />
    } else if (locale === "tr") {
      return <Flags.TR title={this.props.intl.formatMessage({id: "edit_survey.current_translation_indicator"})} className={"translation-indicator"} />
    } else if (locale === "ru") {
      return <Flags.RU title={this.props.intl.formatMessage({id: "edit_survey.current_translation_indicator"})} className={"translation-indicator"} />
    } else if (locale === "se") {
      return <Flags.SE title={this.props.intl.formatMessage({id: "edit_survey.current_translation_indicator"})} className={"translation-indicator"} />
    } else if (locale === "pl") {
      return <Flags.PL title={this.props.intl.formatMessage({id: "edit_survey.current_translation_indicator"})} className={"translation-indicator"} />
    } else if (locale === "ar") {
      return <Flags.SA title={this.props.intl.formatMessage({id: "edit_survey.current_translation_indicator"})} className={"translation-indicator"} />
    } else if (locale === "it") {
      return <Flags.IT title={this.props.intl.formatMessage({id: "edit_survey.current_translation_indicator"})} className={"translation-indicator"} />
    }

    return null
  }

  getEditComponents() {
    axios.get(rootUrl + '/api/v1/survey/get_survey_pages/' + this.props.editSurveyId).then((res) => {
      let getNextPage = (pages, i, result) => {
        const page = pages[i]

        axios.get(rootUrl + '/api/v1/survey/get_page_components/' + this.props.editSurveyId + '/' + page.position
        ).then((res) => {
          let components = res.data
          let pageComponents = []
          let typeMap = {
            1: "MultipleChoiceSingle",
            2: "MultipleChoiceMultiple",
            3: "Dropdown",
            4: "OpenLong",
            5: "OpenShort",
            6: "Date",
            7: "Email",
            8: "Number",
            9: "Grade",
            10: "NetPromoter",
            11: "Routing",
            12: "NetPromoterRouting"
          }

          for (let i = 0; i < components.length; i++) {
            // Add every component to pageComponents.
            const component = components[i]
            let newComponent = {}
            newComponent.name = component.title
            newComponent.questionText = component.text
            newComponent.weight = component.weight
            newComponent.hasWeight = component.weight > 0.00
            newComponent.focused = false
            newComponent.selectedDimension = component.dimension_id ? component.dimension_id : -1
            newComponent.dimensions = []
            newComponent.type = typeMap[component.type]
            newComponent.openQuestion = false
            newComponent.required = component.required
            newComponent.options = []
            if ("options" in component) {
              // Question has options, insert options.

              component.options.forEach(option => {
                if (!option.is_open) {

                  // Regular option
                  newComponent.options.push({
                    key: option.position,
                    translations: option.translations,
                    value: option.translations[this.state.selectedTranslation],
                    weight: option.weight,
                    routeToPage: option.route_to_page,
                    url: option.route_to_url
                  })

                } else {
                  // Open option
                  newComponent.openQuestion = true
                  newComponent.openOption = {translations: option.translations, value: option.translations[this.state.selectedTranslation]}
                }
              })
            }

            pageComponents.push(newComponent)
          }

          result.push({
            title: page.title,
            components: pageComponents
          })

          if (i < pages.length - 1) {
            getNextPage(pages, i + 1, result)
          } else {
            this.setState({
              pages: result
            })
          }
        }).catch((error) => {
          console.log(error)
        })
      }

      getNextPage(res.data, 0, [])
    })
  }

  render() {
    // Generate a random string of 10 characters to create a unique key.
    let randomString = Math.random().toString(36).substring(2, 12) + Math.random().toString(36).substring(2, 12)

    return (
      <div className={"survey-frame slide-up"}>
        <div className="survey">
          <div className={"translation-indicator-holder"}>
            {this.getCountryFlag(this.state.selectedTranslation)}
          </div>

          <div style={{background: this.state.color}} className={"survey-main-top"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"col-xs-12 main-title"}>
                  <h1 className={"header header1"}>
                    <ContentEditable
                      html={this.getTitle()} // innerHTML of the editable div
                      disabled={false}       // use true to disable edition
                      onChange={this.handleTitleChange} // handle innerHTML change
                      className={"editable-text"}
                    />
                  </h1>
                  <p>
                    {this.getSubtitle()}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={"container survey-container"}>
            <div className={"survey-panel"}>
              <div className="survey-top">
                <Progress value={this.state.currentPageIndex+1} total={this.state.pages.length} progress='ratio' />
              </div>

              <div className="survey-content">
                  <PageBuilder
                    ref={this.currentPage}
                    onComponentsChanged={(page) => this.onComponentsChangedHandler(page)}
                    components={this.state.pages[this.state.currentPageIndex].components}
                    key={randomString}
                    dimensions={this.state.dimensions}
                    selectedTranslation={this.state.selectedTranslation}
                  />
              </div>

              <div className="survey-bottom">
                <button
                  name={"backward"}
                  className={"backward"}
                  onClick={this.goBackward.bind(this)}
                  disabled={this.state.currentPageIndex === 0}
                >
                  <FormattedMessage id={"survey_builder.previous"} defaultMessage={"Vorige"} />
                </button>

                <div className={"button-divider"}>
                </div>

                <button
                  name={"forward"}
                  className={"forward" + (this.state.currentPageIndex === this.state.pages.length - 1 ? " new-page" : "")}
                  onClick={this.goForward.bind(this)}
                  disabled={this.state.pages[this.state.currentPageIndex].components.length === 0}
                >
                  {this.state.currentPageIndex === this.state.pages.length - 1
                    ? <><i className="fa fa-plus" /> <FormattedMessage id={"survey_builder.new_page"} defaultMessage={"Nieuwe pagina"} /></>
                    : <FormattedMessage id={"survey_builder.next"} defaultMessage={"Volgende"} />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(SurveyFrame, {forwardRef: true})
