import React, { Component } from 'react'
import DashboardFrame from '../../../components/Dashboard/DashboardFrame/DashboardFrame'
import GraphCardHolder from '../../../components/Dashboard/GraphCardHolder/GraphCardHolder'
import SelectSurvey from "../../../components/Miscellaneous/SelectSurvey/SelectSurvey"
import {withRouter} from 'react-router'
import './Dashboard.css'

class Dashboard extends Component {
  constructor(props) {
    super(props)

    this.surveyHolder = React.createRef()

    this.state = {
      selectedId: localStorage.getItem('selectedSurvey') || -1
    }
  }

  surveyChangedHandler(id) {
    this.setState({selectedId: id})
    this.surveyHolder.current.clear()
    this.surveyHolder.current.getSurveyName()
    this.surveyHolder.current.getNumberOfRespondents()
    this.surveyHolder.current.getSurveyTranslations()
    this.surveyHolder.current.getSurveyStart(() => {
      this.surveyHolder.current.getSurveyResults(id)
    })
  }

  render() {
    let colorScheme = (localStorage.getItem("colorScheme") ? localStorage.getItem("colorScheme") : "color_scheme.liveresearch")

    return (
      <DashboardFrame
        onLanguageChanged={(language) => this.props.onLanguageChanged(language)}
        onSurveyChanged={this.surveyChangedHandler.bind(this)}
        title="Dashboard"
        dashboardTitle="Dashboard"
        selectedId={this.state.selectedId}
        {...this.props}
      >
        {!localStorage.getItem('selectedSurvey') && <SelectSurvey onSurveyChanged={this.surveyChangedHandler.bind(this)}/>}
        <GraphCardHolder
          ref={this.surveyHolder}
          page="dashboard"
          colorSchemeName={colorScheme}
          blockWidth={2}
          blockHeight={2}
        />
      </DashboardFrame>
    )
  }
}

export default withRouter(Dashboard)
