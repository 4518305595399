import React, { Component } from 'react'
import {Button} from "react-bootstrap"
import {injectIntl} from "react-intl"
import ComponentHolder from "../ComponentHolder/ComponentHolder"

class TextboxControl extends Component {
  constructor(props) {
    super(props)
    this.inputControl = React.createRef()

    this.state = {
      answer: (this.props.answer ? this.props.answer : ""),
      valid: false
    }
  }

  handleChange(event) {
    let value = event.target.value

    if (this.props.type === "email" || this.props.type === "date") {
      this.setState({
        answer: value,
        valid: (this.inputControl.current.validity.valid && value !== "")}, () => {
        this.props.validateAnswer(this.props.position, this.state.valid, this.inputControl.current.value, false)
      })
    } else {
      this.setState({
        answer: value,
        valid: (value !== "")
      }, () => {
        this.props.validateAnswer(this.props.position, this.state.valid, this.inputControl.current.value, false)
      })
    }
  }

  validateAnswer() {
    let value = this.inputControl.current.value
    let valid = true

    if (this.props.type === "email" || this.props.type === "date") {
      valid = (this.inputControl.current.validity.valid && value !== "")
    }

    this.props.validateAnswer(this.props.position, valid, value)
  }

  render() {
    return (
      <ComponentHolder {...this.props}>
        <input
          ref={this.inputControl}
          className={"survey-form-control"  + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " arabic-text f-right" : "")}
          onChange={(e) => this.handleChange(e)}
          type={this.props.type}
          placeholder={this.props.placeholder}
          value={this.state.answer}
        /><br />
        <Button
          className={"btn btn-primary" + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " f-right" : "")}
          onClick={() => this.validateAnswer()}
          onMouseEnter={() => this.validateAnswer()}
          onMouseLeave={e => (e.target.style.backgroundColor = "#90EE90", e.target.style.borderColor = "#90EE90")}
          disabled={(!this.state.valid && this.props.required) || (this.props.type === "email" && !this.state.valid)}
          style={{"marginTop":"-12px", "clear": "right", "width":"100px"}}>
          OK
        </Button>
      </ComponentHolder>
    )
  }
}

export default injectIntl(TextboxControl)
