import React, {Component} from 'react'
import {Button, Form} from "react-bootstrap"
import {Link} from 'react-router-dom'
import {rootUrl} from '../../../config'
import axios from 'axios'
import Flags from 'country-flag-icons/react/3x2'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Text, StyleSheet } from "react";
import './Statement.css'

axios.defaults.withCredentials = true
axios.defaults.headers['Pragma'] = 'no-cache' // IE11 is not allowed to cache axios call responses with this header.

class Statement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: "",
      password: "",
      organization_id: 0,
      language_locale: "",
      refresh: false,
      isNotPressed: true,
    }
  }

  // validateForm() {
  //   return this.state.username.length > 0 && this.state.password.length > 0
  // }

  validateForm(e) {
    const checked = e;
    const username = localStorage.getItem('username');
    const organization_id = localStorage.getItem('organization_id');
    const language_locale = localStorage.getItem('language_locale');
    this.setState({username: username})
    this.setState({organization_id: organization_id})
    this.setState({language_locale: language_locale})
    if (checked) {
      this.setState({isNotPressed: false})
    }
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  handleSubmit = event => {
    sessionStorage.removeItem('selectedDimension')
    this.checkStatements(this.state.username)
    event.preventDefault()
  }


    checkStatements = async () => {
      axios.post(rootUrl + '/api/v1/check-statements', {
        'username': this.state.username,
        'organization_id': this.state.organization_id,
        'language_locale': this.state.language_locale
      }).then(() => {
        window.location.href = "/dashboard";
      }).catch(err => {
        console.log(err)
      })
    }
  

  render() {
    return (
      <>
        <div className="account-pages login-background"/>
        <div className="clearfix"/>
        <div className="wrapper-page">

          <div className="account-bg-statements">
            <div className="card-box login-card">
              <div className="text-xs-center m-t-20">
                <Link to='/login' className="logo .live-green">
                  <img src="/images/logo60.png" alt="" id="login-logo" width="40" height="40"/><br/>
                  <span id="login-title" className="text-live-green">LiveResearch</span>
                </Link>
              </div>


              {/* Start form */}
              <div className="m-t-30 m-b-0">
                <div className="col-xs-12 text-xs-center">
                  <h6 className="text-muted text-uppercase m-b-0 m-t-0">Data Processing Agreement</h6>
                </div>

                {/* <div className={"login-flag-holder"}>
                  <Flags.NL title="Dutch" className="flag" style={{marginRight: "5px"}} onClick={() => this.props.onLanguageChanged("nl")} />
                  <Flags.DE title="German" className="flag" onClick={() => this.props.onLanguageChanged("de")}/>
                  <Flags.GB title="English" className="flag" onClick={() => this.props.onLanguageChanged("en")}/>
                </div> */}

                <form className="form-horizontal m-t-20" onSubmit={this.handleSubmit}>
                <p style={{textAlign: "center"}}>{this.props.intl.formatMessage({id: "ferring.dpa.aanhef"})}</p>
                <p style={{textAlign: "center"}}>{this.props.intl.formatMessage({id: "ferring.dpa.deel1"})}</p>
                <p style={{textAlign: "center"}}>{this.props.intl.formatMessage({id: "ferring.dpa.deel2"})} <p style={{display: "inline"}}><a href="https://dashboard.liveresearch.com/privacy-statement">{this.props.intl.formatMessage({id: "ferring.dpa.link"})}</a></p></p>

                  <Form.Group className="checkbox checkbox-custom col-xs-12 m-t-20 m-l-5">
                    <input id="checkbox-signup" type="checkbox" onClick={(e) => {this.validateForm(e)}}/>
                    <label htmlFor="checkbox-signup">
                    {this.props.intl.formatMessage({id: "ferring.dpa.voorwaarden"})}
                    </label>
                  </Form.Group>

                  <p style={{color: "#ff3905"}}>{this.state.errorMessage}</p>

                  <Button
                    className="col-xs-12 text-center m-t-10 btn btn-success btn-block waves-effect waves-light btn-live-green"
                    disabled={this.state.isNotPressed != false}
                    // type="submit"
                    onClick={this.checkStatements}
                  >
                    {this.props.intl.formatMessage({id: "ferring.dpa.verder"})}
                  </Button>


                  <Form.Group>
                    <div className="">
                    </div>
                  </Form.Group>

                </form>
              </div>
              {/* End form */}

            </div>
          </div>
        </div>
      </>
    )
  }
}

export default injectIntl(Statement)
