import React, { Component } from 'react'
import {Button} from "react-bootstrap"
import {injectIntl} from 'react-intl'
import ComponentHolder from "../ComponentHolder/ComponentHolder"

class TextareaControl extends Component {
  constructor(props) {
    super(props)
    this.inputControl = React.createRef()

    this.state = {
      answer: (this.props.answer ? this.props.answer : ""),
      valid: !this.props.required
    }
  }

  handleChange(event) {
    this.setState({
      answer: event.target.value,
      valid: ((event.target.value !== "") && this.props.required) || !this.props.required
    }, () => {
      this.props.validateAnswer(this.props.position, this.state.valid, this.inputControl.current.value, false)
    })
  }

  validateAnswer() {
    this.props.validateAnswer(this.props.position, this.state.valid, this.inputControl.current.value)
  }

  render() {
    return (
      <ComponentHolder {...this.props}>
        <textarea
          className={"survey-form-control open-textarea" + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " arabic-text" : "")}
          placeholder={this.props.intl.formatMessage({id: "survey.answer"})}
          onChange={this.handleChange.bind(this)}
          value={this.state.answer}
          ref={this.inputControl}
        /><br />
        <Button
          className={"btn btn-primary" + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " f-right" : "")}
          onClick={() => this.validateAnswer()}
          onMouseEnter={() => this.validateAnswer()}
          onMouseLeave={e => (e.target.style.backgroundColor = "#90EE90", e.target.style.borderColor = "#90EE90")}
          disabled={!this.state.valid && this.props.required}
          style={{"marginTop":"10px", "width":"100px"}}>
          OK
        </Button>
      </ComponentHolder>
    )
  }
}

export default injectIntl(TextareaControl)
