import React, {Component} from 'react'
import {Button, Form} from 'react-bootstrap'
import {Dropdown} from 'semantic-ui-react'
import './MultipleChoice.css'
import './Routing.css'
import {FormattedMessage, injectIntl} from "react-intl"
import QuestionTypes from '../../QuestionTypes'


class Routing extends Component {
  constructor(props) {
    super(props)

    let sampleOptions = []
    const numberOfOptions = 2
    for (let optionNumber = 0; optionNumber < numberOfOptions; optionNumber++) {
      let option = {key: optionNumber, value: null, weight: 0, routingIndex: 0, url: null, translations: {}}
      option["translations"][this.props.selectedTranslation] = ""
      sampleOptions.push(option)
    }

    if(props.type === QuestionTypes.NetPromoter) {
      sampleOptions = [
        // Initial options of the multiple choice component.
        {key: 0, placeholder: "0", value: 0, weight: 0, routingIndex: 0, url: null, routeToPage: null, translations: {}},
        {key: 1, placeholder: "1", value: 1, weight: 0, routingIndex: 0, url: null, routeToPage: null, translations: {}},
        {key: 2, placeholder: "2", value: 2, weight: 0, routingIndex: 0, url: null, routeToPage: null, translations: {}},
        {key: 3, placeholder: "3", value: 3, weight: 0, routingIndex: 0, url: null, routeToPage: null, translations: {}},
        {key: 4, placeholder: "4", value: 4, weight: 0, routingIndex: 0, url: null, routeToPage: null, translations: {}},
        {key: 5, placeholder: "5", value: 5, weight: 0, routingIndex: 0, url: null, routeToPage: null, translations: {}},
        {key: 6, placeholder: "6", value: 6, weight: 0, routingIndex: 0, url: null, routeToPage: null, translations: {}},
        {key: 7, placeholder: "7", value: 7, weight: 0, routingIndex: 0, url: null, routeToPage: null, translations: {}},
        {key: 8, placeholder: "8", value: 8, weight: 0, routingIndex: 0, url: null, routeToPage: null, translations: {}},
        {key: 9, placeholder: "9", value: 9, weight: 0, routingIndex: 0, url: null, routeToPage: null, translations: {}},
        {key: 10, placeholder: "10", value: 10, weight: 0, routingIndex: 0, url: null, routeToPage: null, translations: {}}
      ]
    }

    /**
     * @type {{answers: *[]}}
     */

    this.state = {
      options: ((this.props.options && this.props.options.length !== 0) ? this.props.options.map(x => {return {...x, routingIndex: x.routeToPage ? 3 : x.url ? 2 : 0}}) : sampleOptions)
      //options: ((this.props.options && this.props.options.length !== 0) ? this.props.options : sampleOptions)
    }
    //this.props.onOptionsChanged(this.state.options, this.state.openOption)
  }

  /**
   * This method will change the state within the answers state.
   * This.props.onOptionsChanged is called to pass the new options up to surveyFrame.
   * @param event
   */
  handleChangeMC = event => {
    let oldOptions = this.state.options
    oldOptions[event.target.id].value = event.target.value
    this.setState({
      options: oldOptions
    }, () => {
      this.props.onOptionsChanged(this.state.options, this.state.openOption)
    })
  }

  handleChangeURL = event => {
    let oldOptions = this.state.options
    oldOptions[event.target.id].url = event.target.value
    this.setState({
      options: oldOptions
    }, () => {
      this.props.onOptionsChanged(this.state.options, this.state.openOption)
    })
  }

  handleChangeRouteToPage = event => {
    let oldOptions = this.state.options
    oldOptions[event.target.id].routeToPage = event.target.value
    
    this.setState({
      options: oldOptions
    }, () => {
      this.props.onOptionsChanged(this.state.options, this.state.openOption)
    })
  }

  /**
   * This method will change the states
   * of the Form Answers that have a controlId.
   * This.props.onOptionsChanged is called to pass the new options up to surveyFrame.
   */
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, () => {
      this.props.onOptionsChanged(this.state.options, this.state.openOption)
    })
  }

  /**
   * Adds an option to the multiplechoice
   * question.
   */
  addOption() {
    let oldOptions = this.state.options
    let length = oldOptions.length - 1
    let newKey = 0
    if (oldOptions[length]) {
      newKey = oldOptions[length].key + 1
    }
    const newControl = {key: newKey, weight: 0, routingIndex: 0, translations: {}}
    oldOptions[length + 1] = newControl
    this.setState({options: oldOptions}, () => {
      this.props.onOptionsChanged(this.state.options, this.state.openOption)
    })
  }

  /**
   * Removes one option within the multiple choice question.
   * Each option has a unique key.
   * @param key
   */
  removeOption(key) {
    let oldOptions = this.state.options

    let index = oldOptions.findIndex(c => c.key === key)

    oldOptions.splice(index, 1)

    this.setState({options: oldOptions}, () => {
      this.props.onOptionsChanged(this.state.options, this.state.openOption)
    })
  }

  getRoutingOptions() {
    const options = [
      {key: 0, text: this.props.intl.formatMessage({id: "component_builder.next_page"}), value: 0},
      {key: 1, text: this.props.intl.formatMessage({id: "component_builder.end_of_survey"}), value: 1},
      {key: 2, text: "Naar URL", value: 2},
      {key: 3, text: "Naar andere pagina..", value: 3}
    ]

    return options
  }

  render() {
    // Generate a random string of 10 characters to create a unique key.
    let randomString = Math.random().toString(36).substring(2, 12) + Math.random().toString(36).substring(2, 12)

    return (
      <>
        {/*all form answers of one multiple choice question with options to delete*/}
        {this.state.options.map((con, index) =>
          <Form.Group controlId={index} key={con.key}>
            {!this.props.focused &&
            <Form.Check
              type={"radio"}
              label={(con.value || con.value === 0 ? con.value : this.props.intl.formatMessage({id: "component_builder.no_option_entered"}))}
              name={"multi-group"}
              id={"multibutton" + randomString + index.toString()}
              className={"radio"}
            />}

            {this.props.focused &&
            <>
              <Form.Control
                required
                key={con.key}
                placeholder={con.placeholder}
                onChange={this.handleChangeMC}
                className={"option-textbox m-r-5"}
                value={(con.value ? con.value : "")}
                style={{display: "inline-block", width: "40%"}} />
              <i className={"fa fa-arrow-right m-r-5"} />
              <Dropdown
                className={"routing-dropdown"}
                placeholder="Selecteer een antwoord..."
                onChange={(e, {value}) => {
                  e.preventDefault()
                  let newOptions = this.state.options
                  newOptions[index].routingIndex = value
                  this.setState({
                    options: newOptions
                  }, () => this.props.onOptionsChanged(this.state.options, null))
                }}
                style={{display: "inline-block", width: "30%"}}
                selection
                options={this.getRoutingOptions()}
                value={con.routingIndex}
              />
              {
                con.routingIndex === 2 &&
                <Form.Control
                  key={con.key + '-url'}
                  placeholder={'url'}
                  value={(con.url ? con.url : "")}
                  className={"option-textbox m-r-5"}
                  onChange={this.handleChangeURL}
                  style={{display: "inline-block", width: "20%"}}
                />
              }
              {
                con.routingIndex === 3 &&
                <Form.Control
                  key={con.key + '-url'}
                  type={'number'}
                  placeholder={'pagina'}
                  value={(con.routeToPage ? con.routeToPage : "")}
                  className={"option-textbox m-r-5"}
                  onChange={this.handleChangeRouteToPage}
                  style={{display: "inline-block", width: "20%"}}
                />
              }
              <Button
                className={"btn-danger option-remove dropdown-action-button"}
                onClick={() => this.removeOption(con.key)}
              >
                <i className={"fa fa-remove"} />
              </Button>
            </>
            }
          </Form.Group>
        )}

        {this.props.focused && this.state.openOption && this.props.type !== QuestionTypes.NetPromoter &&
        <Form.Group>
          <Form.Label className={"open-option-label"}><FormattedMessage id={"component_builder.open_answer"} defaultMessage={"Open antwoord"} /></Form.Label>
          <Form.Control
            required
            key={this.state.options.length}
            placeholder={this.state.openOption.placeholder}
            onChange={(e) => this.changeOpenOption(e.target.value)}
            className={"option-textbox m-r-5"}
            value={this.state.openOption.value}
            style={{display: "inline-block", width: "80%"}} />

          <Button
            className={"btn-danger option-remove dropdown-action-button"}
            onClick={() => this.removeOpenOption()}
          >
            <i className={"fa fa-remove"} />
          </Button>
        </Form.Group>
        }

        {/* Buttons for adding (open) options */}
        {this.props.focused && this.props.type !== QuestionTypes.NetPromoter &&
        <>
          <Button
            className="btn btn-primary"
            onClick={() => this.addOption()}>
            <i className={"fa fa-plus"} /> <FormattedMessage id={"component_builder.add_option"} defaultMessage={"Optie toevoegen"} />
          </Button>
        </>
        }
      </>
    )
  }
}

export default injectIntl(Routing)
