import React, { Component } from 'react'
import './Privacy.css'

class PrivacyOld extends Component {
  render() {
    return (
      <div className="survey">
        <div className={"survey-main-top"} style={{backgroundColor: "#3B699C"}}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-xs-12 main-title"}>
                <h1 className={"header header1"}>
                  Privacy statement
                </h1>
                <p>LiveResearch survey application</p>
              </div>
            </div>
          </div>
        </div>

        <div className={"container survey-container"}>
          <div className={"survey-panel"}>
            <div className="survey-top" />
            <div className="survey-content privacy-content">
              <div className={"row"}>
                Connect SB BV Operating under the name of LiveResearch respects the privacy of its clients, in particular their rights regarding the automatic processing of personal & suvey data. We have therefore formulated and implemented a policy on complete transparency with our customers with regard to the processing of personal data, its purpose(s) and the possibilities to exercise your legal rights in the best possible way.<br /><br />

                If you require any additional information about the protection of personal data, please visit the website of the Dutch Data Protection Authority (Autoriteit Persoonsgegevens): <a href={"https://autoriteitpersoonsgegevens.nl/nl"}>https://autoriteitpersoonsgegevens.nl/nl</a>.<br /><br /><br />

                <h4>Article 1 - Legal definition</h4>
                Party responsible for processing personal data (hereinafter: "the controller"): Connect SB BV, established in Hanzeweg 14, 2803 MC Gouda, The Netherlands, Chamber of Commerce number: 27325577.<br /><br /><br />


                <h4>Article 2 - The processing of personal data</h4>
                <ol className={"fix-ordered-list-style"}>
                  <li>Personal data means any information relating to an identified or identifiable natural person (‘data subject’).</li>
                  <li>An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</li>
                    <li>We process the following categories of personal data from you:
                      <ul className={"fix-unordered-list-style m-l-20"}>
                        <li>Survey data</li>
                        <li>email address for invitation purposes only</li>
                      </ul>
                    </li>
                    <li>Collected survey data cannot be traced back to an individual natural person</li>
                </ol><br /><br /><br />

                <h4>Article 3 - Purpose of processing</h4>
                We do not collect your personal information without a purpose. Your personal data are processed for a better service to you.<br /><br /><br />

                <h4>Article 4 - Registration of personal data</h4>
                Your personal data are recorded in an (electronic) register.<br /><br /><br />

                <h4>Article 5 - Your rights regarding information</h4>
                <ol className={"fix-ordered-list-style"}>
                  <li>Pursuant to Article 13 paragraph 2 sub b GDPR each data subject has the right to information on and access to, rectification, erasure and restriction of processing of his personal data, as well as the right to object to the processing and the right to data portability.</li>
                  <li>You can exercise these rights by contacting us at paul.brinkhof@weconnect.nl.</li>
                  <li>Each request must be accompanied by a copy of a valid ID, on which you put your signature and state the address where we can contact you.</li>
                  <li>Within one month of the submitted request, you will receive an answer from us.</li>
                  <li>Depending on the complexity and the number of the requests this period may be extended to two months.</li>
                </ol><br />

                <h4>Article 6 - Legal obligations</h4>
                In case of infringement of any law or regulation, of which a visitor is suspected and for which the authorities require the personal data collected by the collector, they will be provided to them after an explicit and reasoned request of those authorities, after which these personal data do not fall anymore under the protection of the provisions of this Privacy policy.<br /><br /><br />

                <h4>Article 7 - Commercial offers</h4>
                <ol className={"fix-ordered-list-style"}>
                  <li>You may receive commercial offers from the collector. If you do not wish to receive them (anymore), please send us an email to the following address: paul.brinkhof@weconnect.nl.</li>
                  <li>Your personal data will not be used by our partners for commercial purposes.</li>
                </ol><br /><br /><br />

                <h4>Article 8 - Data retention</h4>
                The collected data are used and retained for the duration determined by law.<br /><br /><br />

                <h4>Article 9 - Applicable Law</h4>
                These conditions are governed by Dutch law. The court in the district where the collector has its place of business has the sole jurisdiction if any dispute regarding these conditions may arise, save when a legal exception applies.<br /><br /><br />


                <h4>Article 10 - Contact</h4>
                For requests, questions, product information or more information, please contact: Paul Brinkhof, paul.brinkhof@weconnect.nl.<br /><br />

                This privacy statement applies from 03 december 2020 on until further notice.

              </div>
            </div>

            <div className="survey-bottom" />
          </div>
        </div>
      </div>
    )
  }
}

export default PrivacyOld
