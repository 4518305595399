import React, { Component } from 'react'
import {Checkbox} from "semantic-ui-react"
import './CheckedListTable.css'

class CheckedListTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checked: this.props.rows.map(() => {
        return false
      })
    }
  }

  componentDidUpdate() {
    if (Array.isArray(this.props.initialStates) &&
      this.props.initialStates !== this.state.checked &&
      this.props.initialStates.length !== this.state.checked.length) {
      this.setState({
        checked: this.props.initialStates
      })
    }
  }

  handleCheck(index) {
    const checkedItems = this.state.checked
    checkedItems[index] = !checkedItems[index]

    this.setState({
      checked: checkedItems
    }, () => {
      if (this.props.onCheckedChanged) {
        this.props.onCheckedChanged(this.state.checked)
      }
    })
  }

  render() {
    return (
      <>
      <table className={"table table-striped " + this.props.className}>
        <thead>
        <tr>
          {this.props.headers.map((header, headerIndex) => {
            return <th key={headerIndex} scope="col">{header}</th>
          })}
        </tr>
        </thead>
        <tbody>
          {this.props.rows.map((row, rowIndex) => {
            return (
              <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => {
                      return (
                        <td key={cellIndex}>
                          {cellIndex === this.props.checkColumnIndex &&
                            <Checkbox
                              checked={this.state.checked[rowIndex]}
                              onClick={() => this.handleCheck(rowIndex)}
                            />
                          }

                          {cellIndex !== this.props.checkColumnIndex &&
                            cell
                          }
                        </td>
                      )
                    })
                  }
              </tr>
            )
          })}
        </tbody>
      </table>
      </>
    )
  }
}

export default CheckedListTable
