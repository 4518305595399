import React from "react"
import axios from 'axios'

const {rootUrl} = require("../../../config")

export default function RemoveFromMailing(props) {
  const [loading, setLoading] = React.useState(true)
  const [text, setText] = React.useState('Een moment geduld alstublieft...')
  console.log(props)

  if(loading) {
    const id = props.match.params.id
    const email = props.match.params.email
    axios.post(rootUrl + '/api/v1/remove-from-mailing', {uuid: id, email: email})
      .then(() => {
        if (props.match.params.language === "nl") {
          setText('U bent verwijderd van de verzendlijst.')
        } else if (props.match.params.language === "de") {
          setText('Sie wurden von der Mailingliste entfernt.')
        } else if (props.match.params.language === "en") {
          setText('You have been removed from the mailing list.')
        } else {
          setText('U bent verwijderd van de verzendlijst.')
        }
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        if (props.match.params.language === "nl") {
          setText('Er is iets fout gegaan.')
        } else if (props.match.params.language === "de") {
          setText('Etwas ist schief gelaufen.')
        } else if (props.match.params.language === "de") {
          setText('Something went wrong.')
        } else {
          setText('Er is iets fout gegaan.')
        }
        setLoading(false)
      })
  }

  return (
    <h3 style={{margin: 40}}>{text}</h3>
  )
}
