import React, { Component } from 'react'
import ListTable from "../../Miscellaneous/ListTable/ListTable"
import PopupDialog from "../../Miscellaneous/PopupDialog/PopupDialog"
import './TranslationsDialog.css'
import {Form} from "react-bootstrap"
import {FormattedMessage, injectIntl} from "react-intl"
import {Dropdown} from "semantic-ui-react"

class TranslationsDialog extends Component {
  constructor(props) {
    super(props)

    let selectedTranslations = []
    let availableTranslations = ["nl", "en", "de", "de-at", "es", "bn", "tr", "ru", "se", "pl", "ar", "it", "fr"]

    if (this.props.selectedTranslations) {
      for (let i = 0; i < this.props.selectedTranslations.length; i++) {
        let translation = this.props.selectedTranslations[i]
        selectedTranslations.push(translation)
        availableTranslations.splice(availableTranslations.indexOf(translation), 1)
      }
    }

    this.state = {
      availableTranslations: availableTranslations,
      selectedTranslations: selectedTranslations,
      selectedTranslation: null
    }
  }

  addTranslation(translation) {
    let selectedTranslations = this.state.selectedTranslations
    let availableTranslations = this.state.availableTranslations

    selectedTranslations.push(translation)
    availableTranslations.splice(availableTranslations.indexOf(translation), 1)

    this.setState({
      selectedTranslations: selectedTranslations,
      availableTranslations: availableTranslations
    })
  }

  removeItem(row) {
    const rowIndex = parseInt(row[0]) - 1
    let selectedTranslations = this.state.selectedTranslations
    let availableTranslations = this.state.availableTranslations
    let toRemove = selectedTranslations.splice(rowIndex, 1)[0]

    availableTranslations.push(toRemove)

    this.setState({
      selectedTranslations: selectedTranslations,
      availableTranslations: availableTranslations
    })
  }

  render() {
    return (
      <PopupDialog title={this.props.intl.formatMessage({id: "translations_dialog.title"})} onClose={() => this.props.onClose()} style={{marginTop: "100px"}}>
        <FormattedMessage id={"translations_dialog.info"} />

        <Form.Group className={"m-t-20"}>
          <Form.Label><FormattedMessage id={"translations_dialog.add_translation"} defaultMessage={"Vertaling toevoegen"} /></Form.Label><br />
          <Dropdown
            style={{marginBottom: 5}}
            placeholder={this.props.intl.formatMessage({id: "translations_dialog.select_translation"})}
            selection
            options={this.state.availableTranslations.map((option, index) => {
              return {key: index, text: this.props.intl.formatMessage({id: "languages." + option}), value: option}
            })}
            value={this.state.selectedTranslation}
            onChange={(e, {value}) => this.setState({selectedTranslation: value})}
          />
          <button
            className="btn btn-primary m-l-5 add-email-button"
            onClick={() => this.addTranslation(this.state.selectedTranslation)}
          >
            <i className="fa fa-plus" />
          </button>
        </Form.Group>

        <ListTable headers={["#", this.props.intl.formatMessage({id: "translations_dialog.translations"})]}
                   rows={this.state.selectedTranslations.map((translation, index) => {
                     return [index+1, this.props.intl.formatMessage({id: "languages." + translation})]
                   })}
                   className={"m-t-10"}
                   removeButton
                   onRemoveButtonClicked={(row) => this.removeItem(row)}
                   key={42}
        />

        <button className="btn btn-primary" onClick={() => {
          if (this.state.selectedTranslations.length > 0) {
            this.props.onSubmit(this.state.selectedTranslations)
          }
        }}>
          <i className="fa fa-save"/> <FormattedMessage id={"ui.save"} defaultMessage={"Opslaan"} />
        </button>
        <button className="btn btn-secondary m-l-5" onClick={()=> this.props.onClose()}> <FormattedMessage id={"ui.close"} defaultMessage={"Sluiten"} /></button>
      </PopupDialog>
    )
  }
}

export default injectIntl(TranslationsDialog)
