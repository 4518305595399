import React, { Component } from 'react'
import './Topbar.css'
import TopbarDropdown from './TopbarDropdown'
import Button from "react-bootstrap/Button"
import axios from 'axios'

const {rootUrl} = require("../../../config")


class Topbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedProject: -1,
      selectedSurvey: this.props.selectedId,
    }

    this.surveysDropdown = React.createRef()
    this.projectsDropdown = React.createRef()
  }


  setActiveIndex(index, loadingParams) {
    const id = this.state.ids[index]
    if (!id) {
      console.log("no id is loaded.", index)
      return
    }
    if (this.state.type === 'project') {
      this.props.updateProjectSelection(id, index, loadingParams)
    }
    if (this.state.type === 'survey') {
      this.props.updateSurveySelection(id, index, loadingParams)
    }
    this.setState({selectedIndex: index})
  }

  changeProject(id) {
    if (id) {
      this.setState({selectedProject: id})
      localStorage.setItem('selectedProject', id)
      localStorage.setItem('selectedSurvey', '')
      this.surveysDropdown.current.getSurveys(id)
      this.props.onSurveyChanged(-1)
    }
  }

  changeSurvey(id) {
    this.setState({selectedSurvey:id})
    localStorage.setItem('selectedSurvey', id)
    this.props.onSurveyChanged(id)
  }

  render() {
      return (
          <div className="topbar">
              <div className="topbar-left">
                  <span className="logo">
                    <img src="/images/logo60.png" id="main-logo" width="20" height="20" alt="Logo" />
                    <span> LiveResearch</span>
                  </span>
              </div>

              <nav className="navbar navbar-custom">
                  <ul className="nav navbar-nav">
                      <li className="nav-item">
                          <button className="button-menu-mobile open-left waves-light waves-effect hidden">
                              <i className="zmdi zmdi-menu"/>
                          </button>
                      </li>
                      <li className="nav-item">
                          <span className="page-title">{this.props.title}</span>
                      </li>
                  </ul>

                  <ul className="nav navbar-nav pull-right">
                    <li className="nav-item project-dropdown-nav">
                      {["Resultaten", "Dashboard", "Benchmark", "Benchmarking", "Référence", ].includes(this.props.title)  ?
                        <TopbarDropdown
                          selectedId={this.state.selectedSurvey}
                          ref={this.surveysDropdown}
                          onChange={this.changeSurvey.bind(this)}
                          type='surveys' /> :
                        null}
                    </li>
                    </ul>
              </nav>
          </div>
      )
  }
}

export default Topbar
