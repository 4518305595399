import React, { Component } from 'react'
import {Button} from "react-bootstrap"
import SurveyDropdown from '../SurveyDropdown/SurveyDropdown'
import {FormattedMessage} from 'react-intl'

class SelectSurvey extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedId: -1
    }
  }

  changeSurvey(id) {
    localStorage.setItem('selectedSurvey', id)
    this.props.onSurveyChanged(id)
  }

  render() {
    return (
      <div className="select-survey card-box slide-up">
        <h4 className="header-title m-t-0"><i className={"zmdi zmdi-search-in-file"} /> <FormattedMessage id={"dashboard.select_survey"} defaultMessage={"Selecteer een vragenlijst"} /></h4>
        <p className="text-muted font-13 m-b-30"><FormattedMessage id={"dashboard.select_survey_info"} default={"Selecteer een vragenlijst om verder te gaan"} /></p>

        <SurveyDropdown
          className={"full-width"}
          onChange={(id) => this.setState({selectedId: id})}
          onSurveyChanged={(id) => this.changeSurvey(id)}
        />

        <Button
          className={"btn btn-primary f-right m-t-25"}
          disabled={this.state.selectedId === -1}
          onClick={() => this.changeSurvey(this.state.selectedId)}>
          <FormattedMessage id={"ui.next"} defaultMessage={"Doorgaan"} />
        </Button>
      </div>
    )
  }
}

export default SelectSurvey
