import React, { Component } from 'react'
import {Button, Form} from "react-bootstrap"
import {injectIntl} from 'react-intl'
import ComponentHolder from "../ComponentHolder/ComponentHolder";

class MultipleChoice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: (this.props.answer ? this.props.answer.options : []),
      openText: ((this.props.answer && this.props.answer.open) ? this.props.answer.open : ""),
      valid: true
    }
  }

  handleChange = (nr, check) => {
    let checked = this.state.checked
    if (check) {
      checked.push(nr)
      checked.sort(function(a, b){return a-b})
    } else {
      checked.splice(checked.indexOf(nr), 1)
    }

    this.setState({
      checked: checked
    }, () => {
      this.validateQuestion()
    })
  }

  validateQuestion() {
    let checked = this.state.checked
    let valid = true

    for (let i = 0; i < checked.length; i++) {
      if (this.props.options[checked[i]].is_open) {
        valid = (this.state.openText !== "")
      }
    }

    this.setState({
      valid: valid
    })
  }

  loadOptions() {
    let result = []
    for (let i = 0; i < this.props.options.length; i++) {
      let option = this.props.options[i]
      // Generate random string to create unique ID for the control.
      let randomString = Math.random().toString(36).substring(2, 12) + Math.random().toString(36).substring(2, 12)
      result.push(
        <React.Fragment key={i}>
          <Form.Check
            key={i}
            type={"checkbox"}
            label={option.text.replace('&nbsp;', ' ')}
            id={"checkbox" + randomString + i.toString()}
            onChange={(e) => this.handleChange(i, e.target.checked)}
            //checked={this.props.answer && this.props.answer.options.includes(i)}
            checked={this.state.checked.includes(i)}
            className={"checkbox" + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " f-right clear-right" : "")}
          />
          {option.is_open &&
            <input
              id={"open" + i}
              type={"text"}
              className={"survey-form-control mc-textbox" + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " f-right clear-right" : "")}
              placeholder={this.props.intl.formatMessage({id: "survey.answer"})}
              onChange={(e) => {
                this.setState({openText: e.target.value},
                  () => {
                    this.validateQuestion()
                  })
              }}
              value={this.state.openText}
            />}
        </React.Fragment>
      )
    }

    return result
  }

  validateAnswer() {
    let answer = {options: this.state.checked}
    let checked = this.state.checked
    let valid = true

    for (let i = 0; i < checked.length; i++) {
      if (this.props.options[checked[i]].is_open) {
        answer.open = this.state.openText
        answer.openOption = checked[i]
        valid = (this.state.openText !== "")
      }
    }

    this.props.validateAnswer(this.props.position, valid, answer)
  }

  render() {
    return (
      <ComponentHolder {...this.props}>
        <Form>
         {this.loadOptions()}
        </Form>
        <Button
          className={"btn btn-primary" + (this.props.intl.formatMessage({id: "misc.locale"}) === "ar" ? " f-right clear-right" : "")}
          onClick={() => this.validateAnswer()}
          onMouseEnter={() => this.validateAnswer()}
          onMouseLeave={e => (e.target.style.backgroundColor = "#90EE90", e.target.style.borderColor = "#90EE90")}
          disabled={!this.state.valid && this.props.required}
          style={{"marginTop":"10px", "width":"100px"}}>
          OK
        </Button>
      </ComponentHolder>
    )
  }
}

export default injectIntl(MultipleChoice)
