import React, { Component } from 'react'
import './DPA.css'

class Ferring_DPA extends Component {
  render() {
    return (
      <div className="survey">
        <div className={"survey-main-top"} style={{backgroundColor: "#3B699C"}}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-xs-12 main-title"}>
                <h1 className={"header header1"}>
                  Data Processing Agreement
                </h1>
                <p>Ferring Pharmaceuticals</p>
              </div>
            </div>
          </div>
        </div>

        <div className={"container survey-container"}>
          <div className={"survey-panel"}>
            <div className="survey-top" />
            <div className="survey-content privacy-content">
              <div className={"row"}>
              Fertility clinics are provided with access to the LiveResearch platform (the “Platform”) by Connect SB B.V. (“ConnectSB”). This access is provided to the clinic on behalf of Ferring, a pharmaceutical company, pursuant to an agreement between the clinic and Ferring. The clinic can make use of the LiveResearch platform for research purposes. The Platform provides access to a dashboard with anonymous survey data and enables the clinic to upload names and e-mail addresses of patients with the purpose of inviting them to complete a questionnaire.<br /><br />

              For the uploaded names and e-mail addresses of patients and the connection between the patient and the clinic, ConnectSB acts as the processor of the clinic. As your processor we are presenting you with this data processing agreement. When you log on to the Platform for the first time, you are requested to accept it prior to being able to upload recipient e-mail data and/or use the dashboard functionality of the Platform. Your acceptance is issued by clicking on the “I accept” button that we show to you. When you accept it, we will send you a confirmation e-mail that has the text of this DPA attached to it.<br /><br />

              This DPA is effective as of the date you accept it and is executed between ConnectSB and the clinic that you represent as identified in your access credentials to the Platform. It ends when your access credentials to the Platform are revoked or when you have stopped using it for six months.<br /><br />

                <ol className={"fix-unordered-list-style"}>
                <h4><li>Definitions &amp; interpretation</li></h4><br />
                  <ol className={"fix-unordered-list-style"}>
                  <li>&emsp;In this DPA, unless otherwise defined, all capitalised words and expressions shall have the following meaning:</li>
                    <ol className={"fix-alphabetic-list-style"}>
                    <li>&emsp;<b> Clinic</b> means the fertility clinic or hospital identified in the access credentials to the Platform. For example, if an e-mail address johndoe@erasmusmc.nl is used, the ErasmusMC in Rotterdam is considered to be the clinic.</li>
                    <li>&emsp;<b>Data Protection Law</b>  means data protection legislation or any statutory equivalent in force applicable to the Processing of Personal Data, including the GDPR.</li>
                    <li>&emsp;<b>EEA</b>  means the European Economic Area. </li>
                    <li>&emsp;<b>GDPR</b> means Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the Processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC.</li>
                    <li>&emsp;<b>Personal Data</b> means any Personal Data Processed by the ConnectSB on behalf of the Clinic pursuant to or in connection with this DPA.</li>
                    <li>&emsp;<b>Personal Data Breach</b> means any breach of security measures used by the ConnectSB to secure Personal Data, that has led to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to Personal Data transmitted, stored or otherwise processed by the ConnectSB. </li>
                    <li>&emsp;<b>Services</b> means the services provided by ConnectSB to the Clinic, as described in the introduction to this DPA.</li>
                    <li>&emsp;<b>Subprocessor</b> means a person or entity subcontracted by ConnectSB to Process Personal Data.  </li>
                    </ol><br />

                  <li>The terms <b style={{display: "inline"}}>Controller, Processor, Data Subject, Personal Data, Processing and Supervisory Authority</b> shall have the meaning given to them in the GDPR.</li> 
                  </ol><br /><br />
                
                
                <h4><li>Processing Personal Data</li></h4><br />
                  <ol className={"fix-unordered-list-style"}>
                  <li>ConnectSB is the Processor of Personal Data and the Clinic is the Controller. </li>
                  <li>Schedule 1 (Details of Processing activities) contains details about the Processing of Personal Data by Processor, including the purpose and nature of the Processing, type of Personal Data, categories of Data Subjects and duration of the Processing. </li> 
                  <li>The Processor will (and will procure that Subprocessors will): </li> 
                    <ol className={"fix-alphabetic-list-style"}>
                    <li>&emsp;have no independent rights in relation to Personal Data and only process Personal Data on behalf of and for the benefit of the Clinic, to provide the Clinic with the Services and in accordance with the Clinic's instructions, unless required to do so by applicable law to which the Processor is subject, in which case the Processor shall inform the Clinic of that legal requirement before the Processing of that Personal Data;</li>
                    <li>&emsp;immediately inform the Clinic if, in its opinion, an instruction received from the Clinic infringes Data Protection Law; and </li>
                    <li>&emsp;notify the Clinic promptly in the event that it is unable to comply with this DPA or its obligations under Data Protection Law or if it has reason to believe that the legislation applicable to it is likely to have a substantial adverse effect on the obligations provided under this DPA or otherwise prevents it from fulfilling the instructions received from the Clinic under this DPA. </li>
                    </ol><br /><br />
                  </ol>  

                <h4><li>Right and obligations of Processor</li></h4><br />
                  <ol className={"fix-unordered-list-style"}>
                  <li>The Processor will:</li>
                    <ol className={"fix-alphabetic-list-style"}>
                    <li>&emsp;keep Personal Data confidential and take appropriate technical and organisational security measures to protect Personal Data against unauthorised or unlawful Processing, accidental loss or damage or destruction. Schedule 2 (Technical and organisational measures) contains the minimum security measures to be implemented by the Processor. This includes protecting Personal Data against Personal Data Breach. In assessing the appropriate level of security, the parties shall take due account of the state of the art, the costs of implementation, the nature, scope, context and purposes of Processing and the risks involved for the Data Subjects;</li>
                    <li>&emsp;only grant access to Personal Data to persons under the Processor’s authority who have committed themselves to confidentiality or are under an appropriate statutory obligation of confidentiality and only on a need-to-know basis; and </li>
                    <li>&emsp;provide all cooperation and assistance to the Clinic as may reasonably be required to allow the Clinic to comply with its obligations under Data Protection Law, including in relation to data security, Personal Data Breach notifications, data protection impact assessments, prior consultation with Supervisory Authorities, the fulfilment of data subjects' rights, and any enquiry, notice or investigation by a Supervisory Authority. </li>
                    </ol><br /><br />
                  </ol>  
                

                <h4><li>Personal Data Breaches</li></h4><br />
                  <ol className={"fix-unordered-list-style"}>
                  <li>The Processor will (and shall procure that all its Subprocessors will) immediately, but in any event within 72 hours after he has taken note of it, inform the Clinic in written of any actual or suspected Personal Data Breach. The Processor must take all adequate remedial measures immediately and must promptly provide the Clinic with all relevant information and assistance as requested by Clinic regarding the actual or suspected Personal Data Breach. The notification of a Personal Data Breach to the Clinic will include at a minimum:</li>
                    <ol className={"fix-alphabetic-list-style"}>
                    <li>&emsp;a description of the Personal Data Breach, including the date and time the Personal Data Breach was discovered;</li>
                    <li>&emsp;an overview of the affected Personal Data and the categories and number of affected Data Subjects; </li>
                    <li>&emsp;information on the (expected) consequences of the Personal Data Breach; and</li>
                    <li>&emsp;a description of the measures taken by the Processor to limit the consequences of the Personal Data Breach.</li>
                    </ol><br />
                  <li>The Processor shall document any Personal Data Breaches, including the facts relating to the Personal Data Breach, the consequences therefor and the corrective actions taken, as well as any other relevant information regarding the Personal Data Breach.</li>
                  </ol>  <br /><br />
                   

                <h4><li>Subprocessors</li></h4><br />
                  <ol className={"fix-unordered-list-style"}>
                  <li>The Clinic hereby grants the Processor general written authorisation for the engagement of Subprocessors from an agreed list, under the conditions that the Processor shall remain fully liable to the Clinic as regards the fulfilment of the obligations of the Subprocessor and that the Processor and the Subprocessor have entered into an agreement that imposes obligations on the Subprocessor that are no less restrictive than those imposed on the Processor under this DPA. The Processor shall notify the Controller of any failure by the Sub-processor to fulfil its contractual obligations. A copy of such agreements shall be submitted to the Clinic upon the Clinic's request. To the extent necessary to protect business secret or other confidential information, including personal data, the Processor may redact the text of the agreement prior to sharing the copy.</li>
                  <li>The Processor shall inform the Clinic in writing of any intended changes concerning the addition or replacement of Subprocessors via the Platform using at least fifteen (15) days in advance, thereby giving the Clinic the opportunity to object to such changes prior to the engagement of the concerned Subprocessor(s). The Subprocessors listed in Schedule 1 are hereby approved by the Clinic. </li>
                  </ol>  <br /><br />
                

                <h4><li>Audit rights</li></h4><br />
                  <ol className={"fix-unordered-list-style"}>
                  <li>The Processor shall make available to the Clinic all information necessary to demonstrate compliance with the obligations laid down in Article 28 GDPR and allow for and contribute to audits, including inspections, conducted by Clinic or another auditor mandated by the Clinic. </li>
                </ol>  <br /><br />
                
                <h4><li>Data transfers</li></h4><br />
                  <ol className={"fix-unordered-list-style"}>
                  <li>Processor shall process (or arrange the Processing of) Personal Data solely within the borders of the European Economic Area (“EEA”).</li>
                </ol>  <br /><br />
                

                <h4><li>Termination and erasure and return of data </li></h4><br />
                  <ol className={"fix-unordered-list-style"}>
                  <li>On termination of the access of the Clinic to the Platform or if the Clinic stopped using the Platform for a period of six months, the Processor will destroy all Personal Data in its possession, custody and control, except for such information as must be retained under applicable law. </li>
                  <li>To the extent that the Processor retains any such Personal Data because such retention is required under applicable law, this DPA will remain in full effect and the Processor will immediately destroy all such Personal Data so retained once such retention is no longer required under applicable laws. </li>
                  <li>At such time when Personal Data is either returned or destroyed in full, this DPA will expire automatically. </li>
                </ol> <br /><br />

                <h4><li>Liability </li></h4><br />
                  <ol className={"fix-unordered-list-style"}>
                  <li>The Processor is liable for any direct damages arising out of or in connection with a breach of this DPA, Data Protection Law or Clinic's instructions under this DPA, by the Processor or any Subprocessor, their directors, officers, employees or other individuals working under their control and/or supervision.  </li>
                  <li>The Processor shall, without limitation, indemnify and hold the Clinic harmless for any costs, claims, losses, damages, liabilities and expenses (including legal expenses) resulting from a breach of this DPA, Data Protection Law or Clinic's instructions under this DPA by the Processor or any Subprocessor, their directors, officers, employees or other individuals working under their control and/or supervision. </li>
                  <li>The Processor’s aggregate liability pursuant to this DPA including the Processor’s liability for any indemnification claims made pursuant to this DPA, shall not exceed a collective amount of EURO 5.000, -.</li>
                </ol> <br /><br />

                <h4><li>Applicable law and forum </li></h4><br />
                  <ol className={"fix-unordered-list-style"}>
                  <li>This DPA and any dispute or claim arising out of it or in connection with it, its subject matter or formation shall be governed by and construed in accordance with the laws of the Netherlands.</li>
                  <li>Any dispute, controversy or claim arising out of or in connection with this DPA, its subject matter or formation shall be submitted to the exclusive jurisdiction of the competent courts in the legal district of the Hague the Netherlands. </li>
                </ol> </ol> <br /><br />

                <h4>Schedule 1: Details of Processing activities</h4><br /><br/>
                <table>
                  <tr>
                    <th>Short description services</th>
                    <th><i>Processor owns and operates a LiveResearch platform.  The Platform has the ability to measure the quality of patient care in fertility clinics. The Platform is using the Patient-Centredness Questionnaire Infertility (PCQ-Infertility). The Clinic can make use of the Platform for research purposes. The Platform provides access to a dashboard with anonymous survey data and enables the clinic to upload names and e-mail addresses of patients with the purpose of inviting them to complete a questionnaire.</i></th>
                  </tr>
                  <tr>
                    <th>Duration of the Processing</th>
                    <th><i>E-mail addresses that were uploaded to the Platform are automatically deleted direct after the e-mail invite has been sent. No other personal data is being processed.</i></th>
                  </tr>
                  <tr>
                    <th>Purpose of the Processing</th>
                    <th><i>Research purposes.</i></th>
                  </tr>
                  <tr>
                    <th>Nature of the Processing</th>
                    <th><i>Collection of anonymized survey data and sending e-mail invites.</i></th>
                  </tr>
                  <tr>
                    <th>Types of Personal Data</th>
                    <th><i>E-mail addresses and anonymized survey data.</i></th>
                  </tr>
                  <tr>
                    <th>Types of Special Categories of Personal Data (if applicable)</th>
                    <th><i>Health related data as the email address identifies the fact that the Data Subject is a patient of a fertility clinic.</i></th>
                  </tr>
                  <tr>
                    <th>Categories of data subject</th>
                    <th><i>Patients of fertility clinics.</i></th>
                  </tr>
                  <tr>
                    <th>Approved Subprocessors</th>
                    <th><i>True Hosting, part of Broad Horizon (hosting of the platform). </i></th>
                  </tr>
                  <tr>
                    <th>Processing location</th>
                    <th><i>The Netherlands.</i></th>
                  </tr>
                  <tr>
                    <th>Data Transfers and safeguards (if applicable)</th>
                    <th><i>Not applicable.</i></th>
                  </tr>
                </table>
                

                <p className={"addition"}>The Processor is liable for any direct damages arising out of or in connection with a breach of this DPA, Data Protection Law or Clinic's instructions under this DPA, by the Processor or any Subprocessor, their directors, officers, employees or other individuals working under their control and/or supervision.  </p>

                <h4>Schedule 2: Technical and organisational measures</h4><br />
                Taking into account the nature, scope, context and purposes of the Processing activity as well as the risk for the rights and freedoms of natural persons, the Processor shall implement security measures to ensure an appropriate level of security. <br /><br />

                True Hosting is certified according to ISO 9001, ISO 27001 and ISO 7510-081.

              </div>
            </div>

            <div className="survey-bottom" />
          </div>
        </div>
      </div>
    )
  }
}

export default Ferring_DPA
